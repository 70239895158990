<template>
  <div id="pageConsumer">
    <dashboard></dashboard>
    <barraLateral></barraLateral>
  </div>
</template>
<script>
import barraLateral from "../components/barra-lateral-adm.vue";
import dashboard from "../components/dashboard-adm.vue";
export default {
  components: {
    barraLateral,
    dashboard,
  },
  data() {},
};
</script>
<style scoped>
#pageConsumer {
  width: 100%;
  height: 800px;
}
* {
  margin-top: -1%;
}
</style>