<template>
    <div style="width:82%;position:absolute;left:18%;top:0%;margin-left:0%;"> 
        <br>
        <br>
        
        <div id="boxAssessment">
            <br>
            <h1 class="text-center">Roteiro para trabalhar com o cliente desempregado</h1>
            <br>
            <h3><b>I – Estados emocionais</b></h3>
            <h5>[Desenvolvido a partir do trabalho de Harold Kaufman]</h5>
            <br>
            <h5>Avalie em qual dos estágios emocionais do desempregado seu cliente se encontra e utilize as técnicas
adequadas para lidar com cada um deles.</h5>
           
            <br>
            <br>
            <h5>1. Antecipação: ajudar o cliente a controlar a ansiedade e a superar o choque; auxiliá-lo a elaborar
um plano de ação eficaz para encontrar emprego.</h5>
            <br>
            <br>
            <h5>2. Esforço concentrado: rever com o cliente suas estratégias de busca de emprego; ajudá-lo a manter
uma rotina equilibrada e controlar o stress.</h5>
            <br>
            <br>
            <h5>3. Desmotivação: trabalhar autoestima, motivação, crenças limitantes, atitudes, relacionamentos, foco e
objetivos.</h5>
            <br>
            <br>
            <h5>4. Resignação: trabalhar crenças limitantes e motivação. Usar técnicas de controle de stress. Levar
o cliente a assumir responsabilidades e mudar de comportamento.</h5>
            <br>
            <br>
            
            <hr>
            <br>
            <br>
            

            <h3><b>II – Saúde mental e emocional</b></h3>
            <h5>[Desenvolvido a partir do
trabalho de Marie Jahoda]</h5>
            <br>
            <h5>Verifique como o cliente está lidando com as 5 categorias fundamentais para a boa saúde mental e
emocional e atue para que essas categorias não se deteriorem.</h5>
            <br>
            <h5>1. Estruturação do tempo: ajude o cliente a organizar seu tempo durante a fase de desemprego.</h5>
            <br>
            <h5>2. Contato social: trabalhe o autocontrole e o equilíbrio emocional do cliente para evitar conflitos e tendência ao isolamento.</h5>
            <br>
            <h5>3. Esforço coletivo: trabalhe os objetivos e a visão de futuro do cliente. Verifique como ele pode en-
volver a família nesse processo.</h5>
            <br>
            <h5>4. Identidade social: faça com que o cliente identifique aspectos mais profundos de sua identidade. Colabore para que ele descubra e valorize outros
papéis, além do papel de profissional.</h5>
            <br>
            <h5>5. Atividade regular: elabore com ele um roteiro de atividades variadas e regulares – incluindo a busca
por emprego, que deve ser focada e estruturada.</h5>
            <br>
            <br>
            <hr>
            <br>
            <br>
          
            <h5>Comentários e anotações</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <div style="display: flex; justify-content: center;"> 
                <button class="btn" >Salvar resultados</button>
            </div>
            <br>
        </div>   
    </div>
</template>

<style scoped>
*
{
    font-family: roboto;
}
#boxAssessment
{
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    
    color: black;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
a:hover{
    color:#fec961;
}
</style>

<script>
export default {
    
   
}
</script>