<template>
  <div>
    <Navbar></Navbar>
    <Text1></Text1>
    <Text2></Text2>
    <Text3></Text3>
    <Text4></Text4>
    <Text5></Text5>
    <Text6></Text6>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from "../components/navbar.vue";
import Footer from "../components/footer.vue";
import Text1 from "../components/text1-conheca";
import Text2 from "../components/text2-conheca";
import Text3 from "../components/text3-conheca";
import Text4 from "../components/text4-conheca";
import Text5 from "../components/text5-conheca";
import Text6 from "../components/text6-conheca";

export default {
  components: {
    Navbar,
    Footer,
    Text1,
    Text2,
    Text3,
    Text4,
    Text5,
    Text6,
  },
};
</script>