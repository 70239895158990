<template>
  <div id="pageMap">
    <formColaboradores></formColaboradores>
    <barraLateral></barraLateral>
  </div>
</template>
<script>
import barraLateral from "../components/barra-lateral-empresa.vue";
import formColaboradores from "../components/form-colaboradores.vue";

export default {
  components: {
    barraLateral,
    formColaboradores,
  },
};
</script>
<style scoped>
#pageMap {
  width: 100%;
  height: 900px;
}
* {
  background-color: #f7f8fc;
  margin-top: -1%;
}
</style>