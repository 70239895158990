<template>
  <div id="text1" class="container-fluid">
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <div class="row" id="rowText">
      <div class="col"></div>

      <div class="col" id="text">
        <br />
        <h1>A Florescer</h1>
        <p>
          A Florescer é uma empresa especializada no desenvolvimento do
          comportamento<br />humano, aliada a Psicologia Positiva, estudo
          científico dos pontos fortes que permitem que as pessoas, os líderes,
          as empresas e as comunidades se prosperem.
        </p>

        <p>
          A Psicologia Positiva é o mais moderno estudo da Psicologia,
          desenvolvido por Martin Seligman Ph.D. - Professor na Universidade da
          Pensilvânia, nos Estados Unidos.
        </p>

        <p>
          É uma ciência , uma estrutura de conhecimentos e métodos que conduzem
          a uma percepção da vida, onde o foco é a felicidade , bem-estar,
          vitalidade e motivação.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#text {
  margin-right: 7.8%;
  text-align: right;
  margin-top: 108px;
}
img {
  position: relative;
  left: -9%;
  top: -7%;
  width: 800px;
}
h1 {
  color: #ab3585;
  font-size: 3.514vw;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  margin-bottom: 2.083vw;
}
p {
  color: #ab3585;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1.111vw;
}

#text1 {
  background-repeat: no-repeat;
  display: block;
  background-size: cover;
  width: 100vw;
  z-index: 1;
  height: 700px;
  margin-top: -12%;
  background-image: url("../../imgs/bloco_flor.png");
}
#textura {
  width: 100%;
  margin-top: -100%;
}

@media (min-width: 1105px) and (max-width: 1322px) {
  #text1 {
    margin-top: -15%;
  }
}
@media (min-width: 925px) and (max-width: 1104px) {
  #text1 {
    margin-top: -18%;
  }
  p {
    font-size: 1.211vw;
  }
}
@media (min-width: 550px) and (max-width: 924px) {
  #text1 {
    margin-top: -26%;
  }
  p {
    font-size: 1.811vw;
  }
}
@media (min-width: 447px) and (max-width: 549px) {
  #text1 {
    margin-top: -32%;
  }
  p {
    font-size: 2.211vw;
  }
}
@media (min-width: 371px) and (max-width: 446px) {
  #text1 {
    margin-top: -39%;
  }
  p {
    font-size: 2.511vw;
  }
}
@media (min-width: 0px) and (max-width: 370px) {
  #text1 {
    margin-top: -45%;
  }
  p {
    font-size: 2.711vw;
  }
}
</style>