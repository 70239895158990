<template>
    <div style="width:82%;position:absolute;left:18%;top:0%;margin-left:0%;"> 
        <br>
        <br>
       
        <div id="boxAssessment" >
            <br>
            <h1 class="text-center">Helicopter view pessoal</h1>
            <br>
            <h5>Construção de um barco forte</h5>
            <br>
            <h5><b>1-	DIREÇÃO</b></h5>
             
            <h5>Qual o propósito com os resultados que você busca em sua vida?</h5> 
            <textarea onpaste="return false" ondrop="return false" required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>Quais são as buscas emocionais e materiais?</h5> 
            <textarea onpaste="return false" ondrop="return false" required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>Como você gostaria de ser reconhecido e conhecido pelas pessoas à sua volta, familiares, chefes e seus clientes?</h5> 
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5><b>2 - AUTOCONCEITO</b></h5>
             
            <h5>Quais as suas forças?</h5> 
            <textarea onpaste="return false" ondrop="return false" required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>Quais são seus diferenciais como pessoa?</h5> 
            <textarea onpaste="return false" ondrop="return false" required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>Quais os valores que determinam as premissas para um bom relacionamento entre você e as pessoas à sua volta?</h5> 
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5><b>3 - CONHECIMENTO DE PAPEIS</b></h5>
             
            <h5>Quais os principais papeis que você desempenha hoje em todas as dimensões de sua vida (pessoal, profissional, relacional e espiritual) ?</h5> 
            <br>
            <div class="row">
                <div class="col" id="col1">
                    <input class="inp1" placeholder="Nome do papel" type="text"/>
                </div>
                <div class="col" id="col2">
                    <input class="inp2" placeholder="Propósito nesse papel"   type="text"/>
                </div>
            </div>
            <br>
            <div class="row" style="margin-left:6px;">
                <button class="btn" v-on:click="addInput()">Adicionar</button>
                
                <button style="margin-left:20px" class="btn" v-on:click="removeInput()">Remover</button>
            </div>
            <br>
            <br>
            <br>
            <br>

            <h5>Acalmando os mares</h5>
            <br>
            <h5><b>4 - JULGAMENTO SISTÊMICO</b></h5>
            <h5>Qual a sua área de atuação (pessoal, profissional, relacional, espiritual) ?</h5> 
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>Quais regras devem ser respeitadas para que você navegue bem por esse mercado?</h5> 
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>O que é preciso desenvolver para ser querido por esse mercado?</h5> 
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5><b>5-	ENTENDIMENTO DO OUTRO</b></h5>
            <h5>Quem é o seu cliente (pode ser família ou pessoa relacionado ao trabalho)? Ex: irmã</h5> 
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>Como o seu cliente gosta de ser abordado?</h5> 
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>Quais os sonhos e objetivos do seu cliente?</h5> 
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>Quais os medos do seu cliente?</h5> 
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5><b>6 - PENSAMENTO PRÁTICO</b></h5>	
            <h5>Quais as oportunidades para que você obtenha êxito no que deseja?</h5> 
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>Quais as ações para conquistar essas oportunidades?</h5> 
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>O que é preciso aprender?</h5> 
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>Quais as possíveis ameaças?</h5> 
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>Como minimizar essas ameaças?</h5> 
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5><b>7 - FECHAMENTO</b></h5>	
            <h5>Quais aprendizados você teve ao responder essa ferramenta?</h5> 
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>Qual ação você poderia ter em cima desse aprendizado?</h5> 
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>Como você irá se lembrar de fazer isso?</h5> 
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>Depende de quem fazer essa ação e tornar sua vida melhor?</h5> 
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>O quanto você está comprometido a fazer essa ação?</h5> 
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>Qual o resultado que você espera ter realizando esta tarefa?</h5> 
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>De 0 a 10, o quanto realizar esta tarefa irá impactar positivamente na realização do seu objetivo?</h5> 
            <input id="level" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel').innerHTML = this.value;"  min="0" max="10" value="5" />
            <h1 id="showLevel" class="numberLevel">5</h1>
            <br>
            <br>
            <br>
            <br>
           

            <div style="display: flex; justify-content: center;"> 
                   <button class="btn" >Salvar resultados</button>
            </div>
            <br>
        </div>
    </div>
</template>

<style scoped>
input[type='range'] {
  cursor: pointer;
}

input[type='range'],
input[type='range']::-webkit-slider-runnable-track,
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}

/** 
 * Use o pseudo-seletor "::-webkit-slider-runnable-track" para customizar o "trilho" do campo.
 */
input[type='range']::-webkit-slider-runnable-track {
  
  height: 10px;
  background: #983e7b;
  border-radius: 13px;
}
input[type='range']::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  margin-top: -4.5px;
  background-color: #fec961;
  border-radius: 20px;
}
#level{
    background-color: white;
    border-color:#983e7b;
    height:50px;
    border-radius:10px;
    width:100%;
}
.inp1
{
    width:100%;
}
.inp2
{
    width:100%;
}
h5
{
    text-align:justify;

}
p
{
    text-align: justify;
}

#boxAssessment
{
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
    border-radius: 15px;
    text-align: center;
    color: black;
}
*
{  
    font-family: roboto;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
        align-items: center;
        height:30px;
}
a:hover{
    color:#fec961;
}

</style>

<script>
export default {
    data(){

        return{
           first:0
        }
        
    },
    methods:
    {
        removeInput: function()
        {
            var col1 = document.getElementById("col1");
            if(col1.children.length > 2)
            {
                
                var child1 =col1.lastElementChild;
                col1.removeChild(child1);
                child1 =col1.lastElementChild;
                col1.removeChild(child1);
                var col2 = document.getElementById("col2");
                var child2 =col2.lastElementChild;
                col2.removeChild(child2);
            }
        },
        addInput: function()
        {
            
            var inp = document.createElement("input");
           
            inp.style.width ="100%";
            inp.style.marginTop = "10px";
            inp.type = "text";
            inp.class="inp1";
            inp.placeholder = "Nome do papel";
            var col1 = document.getElementById("col1");
            
            var br = document.createElement("br");
            col1.appendChild(br);
            
            col1.appendChild(inp);
            this.addInput2();
            this.first = 1;
        },
        addInput2: function()
        {
            var col2 = document.getElementById("col2");
            var inp = document.createElement("input");
            
            inp.style.width ="100%";
            
            inp.style.marginTop = "10px";
            inp.type = "text";
            inp.class="inp2";
            inp.placeholder = "Propósito nesse papel";
            
            
            col2.appendChild(inp);
            

        }
    }
  
}
</script>