<template>
  <div>
    <button style="margin-top: -350px; position: absolute" v-on:click="directToHome()" class="btn">
      Entrar em ação
    </button>
    <div id="text2">
      <img style="z-index: 1111" id="curvature" src="../../imgs/curvatura_sobre_nos.png" />
      <div id="rowText" class="row">
        <div id="story" class="col-lg-7 col-12">
          <h1>Nossa História</h1>
          <br />
          <p>
            A Florescer Desenvolvimento foi criada em 2017 quando a Fundadora
            Giliani Souza, teve um propósito muito forte: ajudar as pessoas,
            através da escuta e do direcionamento.<br /><br />
            Nos últimos anos, isso foi ficando cada vez mais INTENSO e seu
            propósito foi mergulhado cada vez mais em trazer uma solução de
            democratizar o acesso ao atendimento psicológico, aproximando
            pacientes e profissionais por meio da internet.<br /><br />

            Desde então, o sonho se torna cada dia mais real, com mais pessoas
            no time fazendo o sonho acontecer e percebemos que estamos a caminho
            de proporcionar algo a mais grandioso na vida das pessoas.<br /><br />

            Por meio das nossas soluções tecnológicas, pessoas que buscam
            conectarem com si mesmas, e encontrarem respostas em que vem
            buscando sobre suas potencialidades, poderão ser atendidas por
            profissionais altamente capacitados, através do uso do dispositivo
            com internet de modo seguro e prático, criando conexões prospera e
            realizando mudanças transformadoras em todas as dimensões da vida.
          </p>
        </div>
        <div id="colPhoto" class="col-lg-5 col-12">
          <img id="photo" src="../../imgs/foto_giliani.png" />
        </div>
        <br />
      </div>
      <br />
      <br />
      <br />
      <p id="sentence1" class="text-center">
        A empresa Florescer nasceu da Ideologia do florescimento humano para
        um<br />
        despertar dos talentos e da capacidade do uso das forças e virtudes
        humanas.<br />
        <br />
        E para atingir o florescimento, serão necessários passar por algumas
        etapas,<br />
        na qual podemos comparar com um jardim em construção.<br /><br />

        Vamos pensar em três principais ingredientes:
      </p>
      <br />
      <div class="row" id="rowImgs">
        <div class="col-md-2 offset-md-3 col-12 colImg">
          <img src="../../imgs/semente.png" />
        </div>
        <div class="col-md-2 col-12 colImg">
          <img src="../../imgs/saco.png" />
        </div>
        <div class="col-md-2 col-12 colImg">
          <img src="../../imgs/pega_mao.png" />
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <p id="sentence2" class="text-center">
        Para que essas sementes germinem e nasçam,<br />faz-se necessário alguns
        cuidados diários. Concorda?
      </p>
      <p class="text-center">
        o preparo do <b>PLANTIO</b> é algo fundamental para que o <b>SEMEAR</b><br />obtenha êxito e sucesso na sua
        <b>COLHEITA</b>
      </p>
      <br />
      <br />
      <div class="row" id="rowText2">
        <div id="colHand" class="col-lg-6 col-12">
          <img id="hand" src="../../imgs/segurando_flor.png" />
        </div>
        <div class="col-lg-6 col-12">
          <br />
          <br />
          <br />
          <br />
          <p id="sentence3" class="text-center">
            E nesse sentindo, é fundamental cuidar, zelar pelo solo fértil e ter
            um clima<br />harmônico, para que então as <b>SEMENTES</b> que são
            os nossos colaboradores e<br />vidas de pessoas em geral, consigam
            experimentar e explorar sua essência,<br />
            competência e habilidades, na execução do seu dia a dia.<br /><br />

            <br />A <b>Terra</b> aqui citada está sendo representada pelas
            <b>empresas, casa, escolas.</b>
          </p>
        </div>
      </div>
      <div class="row" id="rowText3">
        <div id="col" class="col-lg-6">
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <p id="sentence4">
            Os <b>JARDINEIROS</b>, por sua vez, são representados por todos os
            profissionais, líderes e gestores, que precisam estar atentos
            diariamente a estes importantes cuidados, a fim de minimizar as
            ameaças externas e proporcionar todo o cuidado e condições a cada
            sementinha, e então será possível o Florescimento do que há de
            melhor de cada um.
          </p>
        </div>
        <div id="colTime" class="col-lg-6">
          <img id="time" src="../../imgs/time.png" />
        </div>
      </div>
      <img id="imgCurve" src="../../imgs/onda2.png" />
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    directToHome() {
      sessionStorage.setItem("aboutUs", "true");
      this.$router.push("/#/");
    },
  },
};
</script>
<style scoped>
.btn:hover {
  background-color: #fff051;
}

button {
  border-color: transparent;
  margin-left: 7.8%;
  margin-top: 24px;
  background-color: #fabe0a;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  width: 180px;
  height: 52px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  color: #692b55;
}

button:hover {
  color: #692b55;
}

#time {
  width: 36.25vw;
}

#sentence4 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  text-align: justify;
}

#sentence3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
}

#hand {
  width: 33.889vw;
}

#sentence2 {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
}

#photo {
  margin-top: 50px;
  width: 35.208vw;
}

p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ab3585;
}

#rowText {
  margin-left: 7.8%;
  margin-top: -50px;
}

#rowText2 {
  margin-left: 7.8%;
  margin-right: 7.8%;
}

#rowText3 {
  margin-left: 7.8%;
  margin-right: 7.8%;
}

#text2 {
  width: 100vw;
}

#curvature {
  margin-top: -28%;
  background-size: cover;
  background-position: center center;
  width: 100%;
}

h1 {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  color: #ab3585;
}

@media (min-width: 993px) and (max-width: 1200px) {
  #photo {
    margin-top: 160px;
  }
}

@media (max-width: 992px) {
  #colTime {
    display: flex;
    justify-content: center;
  }

  #colHand {
    display: flex;
    justify-content: center;
  }

  #colPhoto {
    display: flex;
    justify-content: center;
  }

  #photo {
    width: 55.208vw;
  }

  #story {
    margin-right: 7.8%;
  }

  .colImg {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }

  #rowText {
    margin-top: -10px;
    margin-right: 7.8%;
  }
}

@media (min-width: 0px) and (max-width: 576px) {
  #imgCurve {
    width: 100%;
    height: 50px;
  }
}
</style>