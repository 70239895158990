<template>
    <div style="width:82%;position:absolute;left:18%;top:0%;margin-left:0%;"> 
        <br>
        <br>
        
        <div id="boxAssessment">
            <br>
            <h1 class="text-center">Resourceful coach</h1>
            <br>
            <h5><b>A palavra inglesa resourceful significa:</b></h5>
            <ul>
                <li>Capaz de agir com eficácia e imaginação, especialmente em situações difíceis;</li>
                <li>Posse de recursos internos; e</li>
                <li>Também é sinônimo de engenhoso, capaz, criativo, habilidoso...</li>
            </ul>
            <br>
            <h5>O professor Guy Claxton (University of Bristol) descreve essa capacidade como “foco direcionado aos
aspectos cognitivos da aprendizagem”, o que inclui as habilidades de:</h5>
           <ul>
                <li>Questionar</li>
                <li>Conectar (ligar conceitos, ideias, experiências...)</li>
                <li>Imaginar</li>
                <li>Raciocinar</li>
                <li>Capitalizar (usar recursos de forma inteligente)</li>
            </ul>
            <br>
            <h5>Para ser um resourceful coach, você deve expandir e utilizar ao máximo essa capacidade. Uma das
formas de fazer isso é saber tirar proveito de suas experiências – ou das lições que você aprendeu ao
acompanhar as experiências de outras pessoas – e adaptar o conhecimento adquirido para lidar com

situações similares. O questionário a seguir irá ajudá-lo a fazer isso. O objetivo é trabalhar com o co-
achee desempregado, mas o questionário aplica-se a qualquer situação.</h5>
            <br>
            <br>
            <h5>Você já enfrentou um desafio semelhante ao que o cliente está trazendo? Se não enfrentou, conhe-
ce alguém que passou por essa situação?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>O que você sentiu quando enfrentou esse desafio? Quais foram os aspectos mais difíceis com os
quais você teve que lidar? (Se você estiver se referindo à experiência de outra pessoa, lembre-se do
que ela passou ou converse com ela e lhe faça essas perguntas).</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Como você (ou a pessoa) superou o desafio? Quais foram as atitudes, ações, crenças e habilidades que
mais ajudaram?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>De que forma essa experiência pode ajudá-lo a desenvolver uma sessão de coaching para um cliente
que esteja enfrentando um desafio semelhante? (Lembre-se de que estamos falando em termos de
técnicas, insights e recursos, pois o coach não julga, não compara e não aconselha).</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>Comentários e anotações</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <div style="display: flex; justify-content: center;"> 
                 <button class="btn" >Salvar resultados</button>
            </div>
            <br>
        </div>   
    </div>
</template>

<style scoped>
*
{
    font-family: roboto;
}
#boxAssessment
{
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    
    color: black;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
a:hover{
    color:#fec961;
}
</style>

<script>
export default {
    
   
}
</script>