<template>
  <div id="box">
    <br />
    <br />
    <br />
    <h1>
      <span>Metodologias Florescer</span><br />praticadas para semear o
      Florescimento em sua empresa
    </h1>
    <br />
    <br />
    <br />
    <div class="row rowPicture">
      <div class="col-sm">
        <div style="display: flex; justify-content: center">
          <img src="../../imgs/treinamentos_empresas.png" />
        </div>

        <div style="display: flex; justify-content: center">
          <p>treinamentos e dinâmicas empresariais</p>
        </div>
      </div>
      <div class="col-sm">
        <div style="display: flex; justify-content: center">
          <img src="../../imgs/clima.png" />
        </div>

        <div style="display: flex; justify-content: center">
          <p>Gestão do clima e Fit Cultural</p>
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
    <div class="row rowPicture">
      <div class="col-sm">
        <div style="display: flex; justify-content: center">
          <img src="../../imgs/mapeamento_empresas.png" />
        </div>
        <div style="display: flex; justify-content: center">
          <p>Mapeamento de Gestão emocional</p>
        </div>
      </div>
      <div class="col-sm">
        <div style="display: flex; justify-content: center">
          <img src="../../imgs/personalizados.png" />
        </div>
        <div style="display: flex; justify-content: center">
          <p>Módulos de Treinamentos Personalizados</p>
        </div>
      </div>
    </div>
    <br />
  </div>
</template>

<script>
export default {
  methods: {},
};
</script>
<style scoped>
.rowPicture {
  margin-left: 8.3%;
  margin-right: 8.3%;
}

#box {
  height: 900px;
  background-color: #692b55;
  width: 100vw;
}
h1 {
  text-align: center;
  color: white;
  font-size: 3.111vw;
  margin-left: 8.3%;
  margin-right: 8.3%;
}
span {
  font-weight: bold;
  color: #ffe169;
}
p {
  text-align: center;
  color: white;
  margin-top: 5%;
  font-size: 1.611vw;
}

@media (min-width: 0px) and (max-width: 575px) {
  #box {
    height: 1280px;
  }
  img {
    margin-top: 10%;
  }
  p {
    font-size: 2.511vw;
  }
}
</style>
