<template>
  <div id="boxCarousel" class="container-fluid">
    <h1>QUEM ACREDITA NA FLORESCER</h1>
    <br />
    <br />
    <br />
    <div id="carousel1" class="carousel">
      <div>
        <img id="logo1" src="../../imgs/logo1.png" alt="js" />
      </div>
      <div>
        <img id="logo2" src="../../imgs/logo2.png" alt="java" />
      </div>
      <div>
        <img id="logo3" src="../../imgs/logo9.png" alt="python" />
      </div>
      <div>
        <img id="logo4" src="../../imgs/logo4.png" alt="kotlin" />
      </div>

      <div>
        <img id="logo5" src="../../imgs/logo5.png" alt="scala" />
      </div>

      <div>
        <img id="logo6" src="../../imgs/logo6.png" alt="C#" />
      </div>

      <div>
        <img id="logo7" src="../../imgs/logo7.png" alt="PHP" />
      </div>

      <div>
        <img id="logo8" src="../../imgs/logo8.png" alt="PHP" />
      </div>

      <div>
        <img id="logo9" src="../../imgs/logo10.png" alt="PHP" />
      </div>

      <div>
        <img id="logo10" src="../../imgs/logo11.png" alt="PHP" />
      </div>

      <div>
        <img id="logo11" src="../../imgs/logo12.png" alt="PHP" />
      </div>

      <div>
        <img id="logo12" src="../../imgs/logo13.png" alt="PHP" />
      </div>

      <div>
        <img id="logo13" src="../../imgs/logo14.png" alt="PHP" />
      </div>

      <div>
        <img id="logo14" src="../../imgs/logo_girassol.png" alt="PHP" />
      </div>

      <div>
        <img id="logo1" src="../../imgs/logo1.png" alt="js" />
      </div>

      <div>
        <img id="logo2" src="../../imgs/logo2.png" alt="java" />
      </div>
    </div>
  </div>
</template>
<script type="text/javascript" src="//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js"></script>
<script type="text/javascript" src="https://code.jquery.com/jquery-1.11.0.min.js"></script>
<script type="text/javascript" src="https://code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
<script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.7.1/slick.js"></script>
<script>
export default {
  mounted() {
    $(".carousel").slick({
      dots: true,
      rows: 2,
      infinite: false,
      speed: 300,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 4,
      slidesToScroll: 4,

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 932,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: true,
            variableWidth: true,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: false,
            variableWidth: true,
            centerMode: true,
            autoplaySpeed: 1000,
          },
        },
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ],
    });
  },
};
</script>
<style scoped>
#logo1 {
  margin-top: 30px;
  width: 250px;
  height: 70px;
}
#logo2 {
  margin-top: 50px;
  width: 230px;
  height: 150px;
}
#logo3 {
  margin-left: 50px;
  width: 140px;
  height: 120px;
}
#logo4 {
  margin-top: 60px;
  width: 230px;
  height: 90px;
}
#logo5 {
  margin-left: 30px;
  width: 150px;
  height: 120px;
}
#logo6 {
  margin-top: 40px;
  width: 250px;
  height: 120px;
}
#logo7 {
  margin-top: 10px;
  width: 250px;
  height: 90px;
}
#logo8 {
  margin-top: 75px;
  margin-left: 20px;
  width: 230px;
  height: 110px;
}
#logo9 {
  margin-top: 20px;
  margin-left: 10px;
  width: 250px;
  height: 90px;
}
#logo10 {
  margin-top: 80px;
  width: 250px;
  height: 80px;
}
#logo11 {
  margin-top: 20px;
  width: 250px;
  height: 80px;
}
#logo12 {
  margin-left: 30px;
  margin-top: 90px;
  width: 200px;
  height: 80px;
}
#logo13 {
  margin-top: 30px;
  width: 245px;
  height: 70px;
}
#logo14 {
  margin-top: 75px;
  width: 280px;
  height: 140px;
}
h1 {
  margin-top: 96px;
  font-weight: bold;
  text-align: center;
  color: #ab3585;
  font-size: 32px;
}
#boxCarousel {
  height: 600px;
}
img {
  width: 14.641vw;
  height: 14.641vw;
}
.carousel {
  width: 1000px;
  margin: 0 auto;
}
</style>