<template>
  <div class="wrapper">
    <nav id="sidebar">
      <div class="sidebar-header">
        <img width="200px" src="../../imgs/logo_branco.jpg" />
      </div>

      <ul class="list-unstyled components">
        <li>
          <router-link to="/pagina-inicial-adm" class="nav-link"
            ><img
              class="link"
              width="24px"
              style="margin-right: 10px"
              src="../../imgs/dashboard.png"
            />Dashboard</router-link
          >
        </li>
        <li>
          <router-link to="/pagina-inicial-adm" class="nav-link"
            ><img
              class="link"
              style="margin-right: 14px"
              src="../../imgs/meu_perfil.png"
            />Especialistas</router-link
          >
        </li>
        <li>
          <router-link to="/pagina-inicial-adm" class="nav-link"
            ><img
              class="link"
              style="margin-right: 10px"
              src="../../imgs/companhia.png"
            />Empresas</router-link
          >
        </li>

        <li>
          <router-link to="/pagina-inicial-adm"
            ><img
              class="link"
              style="margin-right: 10px"
              src="../../imgs/clientes2.png"
            />Pessoas físicas</router-link
          >
        </li>
        <li>
          <router-link to="/pagina-inicial-adm"
            ><img
              class="link"
              style="margin-right: 10px"
              src="../../imgs/roda.png"
            />Configurações</router-link
          >
        </li>
        <li v-on:click="back()">
          <router-link to="/pagina-inicial-adm"
            ><img
              style="margin-right: 10px"
              class="link"
              src="../../imgs/sair.png"
            />Sair</router-link
          >
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
export default {
  methods: {
    back: function () {
      localStorage.clear();
      localStorage.setItem("popup", 1);
      this.$router.push("login-cliente");
    },
    changePage: function () {
      window.location.href = "http://localhost:8080/pagina-ferramentas";
    },
  },
};
</script>



<style scoped>
.link {
  margin-left: 50px;
}
.sublink {
  margin-left: 5px;
}
.wrapper {
  display: flex;
  width: 100%;
}

#sidebar {
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 999;
  background: #7386d5;
  color: #fff;
  transition: all 0.9s;
}

#sidebar.active {
  margin-left: -250px;
}
a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
p {
  font-family: "Poppins", sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
}

#sidebar {
  background: #983e7b;
  color: #fff;
  transition: all 0.9s;
}
#sidebar {
  min-width: 250px;
  max-width: 250px;
  min-height: 100vh;
}
#sidebar .sidebar-header {
  padding: 20px;
  background: #983e7b;
}

#sidebar ul.components {
  padding: 20px 0;
  border-bottom: 1px solid #983e7b;
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 0.7em;
  display: block;
}
#sidebar ul li a:hover {
  color: #fff;
  background: rgb(255, 255, 255, 0.1);
  cursor: pointer;
}

#sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: #fff;
  background: #e5b259;
}
ul ul a {
  font-size: 0.6em !important;
  padding-left: 30px !important;
  background: #aa468a;
}
</style>