<template>
  <div>
    <Navbar></Navbar>
    <div id="rowConsumer" class="row">
      <div class="col-sm" id="message">
        <br />
        <br />
        <div style="display: flex; justify-content: center">
          <img id="imgLogo" src="../../imgs/florescer_desenvolvimento.png" />
        </div>
        <br />
        <h2>Faça seu login e potencialize as forças de sua equipe.</h2>
        <br />
        <div style="display: flex; justify-content: center">
          <img id="imgConsumer" src="../../imgs/login-empresa.png" />
        </div>
      </div>

      <div class="col-sm" id="formConsumer">
        <br />

        <div id="form">
          <br />
          <br />
          <h3 style="text-align: center">Olá! É bom vê-lo novamente.</h3>
          <br />
          <p id="msg1" style="color: red">{{ msgError }}</p>
          <form>
            <div>
              <label for="name">Email:</label>
              <br />
              <input class="input1 form-control" type="text" id="email" />
            </div>
            <br />
            <div>
              <label for="name">Senha:</label>
              <br />
              <input
                class="input1 form-control"
                type="password"
                maxlength="10"
                id="password"
              />
              <span id="eye" v-on:click="showPassword()">&#128065;</span>
            </div>
            <br />

            <div style="display: flex; justify-content: center">
              <button
                id="btnLogin"
                v-on:click="toEnter($event)"
                class="text-center"
              >
                ENTRAR
              </button>
            </div>
            <br />
            <p style="color: gray; text-align: center">
              Ainda não faz parte da Florescer?
            </p>
            <div style="display: flex; justify-content: center">
              <button
                v-on:click="toRegister($event)"
                id="btnRegister"
                class="text-center"
              >
                FAÇA SEU CADASTRO
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import axios from "axios";
import Navbar from "../components/navbar.vue";
import Footer from "../components/footer.vue";
export default {
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      msgError: "",
      keyEye: false,
    };
  },
  methods: {
    showPassword: function () {
      if (this.keyEye == false) {
        document.getElementById("password").type = "text";
        document.getElementById("eye").style.opacity = "10";
        this.keyEye = true;
      } else {
        document.getElementById("password").type = "password";
        document.getElementById("eye").style.opacity = "0.2";
        this.keyEye = false;
      }
    },
    toRegister: function (event) {
      event.preventDefault();
      this.$router.push("cadastro-empresa");
    },
    toEnter: function (event) {
      event.preventDefault();
      const email = document.getElementById("email").value;
      const password = document.getElementById("password").value;
      if (!email || !password) {
        this.msgError = "Preencha com seus dados de login";
      } else {
        axios
          .post("http://api.florescer.b2ml.com.br:15200/api/login-company", {
            email: email,
            password: password,
          })
          .then(
            (response) => {
              if (response.data.token) {
                localStorage.setItem("userToken", response.data.token);
                localStorage.setItem("idCompany", response.data.company.id);
                console.log(response.data.company.id);
                this.$router.push("pagina-inicial-empresa");
              }
            },
            (error) => {
              console.log(error);
              this.msgError = "Email ou senha inválido";
            }
          );
      }
    },
  },
};
</script>
<style scoped>
#eye {
  font-size: 25px;
  opacity: 0.2;
  position: relative;
  top: -43px;
  left: 90%;
  cursor: pointer;
}
#btnLogin {
  background-color: #983e7b;
  color: white;
  border-radius: 10px;
  border-color: transparent;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
}
#btnRegister {
  background-color: transparent;
  color: #983e7b;
  border-radius: 10px;
  border-color: #983e7b;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
}
#inputSpecialty {
  margin-left: 35%;
}
.labelFormation {
  font-size: 10px;
}
legend {
  font-size: 15px;
  font-weight: bold;
}
label {
  font-size: 14px;
}
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background-clip: content-box;
  background-color: #d3d3d3;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 2px;
}

input[type="checkbox"]:checked {
  background-color: #983e7b;
}

.labelArea {
  position: relative;
  bottom: 2px;
}
.button {
  background-color: #983e7b;
  color: white;
  border-radius: 10px;
  border-color: transparent;
  text-align: center;
  width: 40px;
  cursor: pointer;
}
#resume {
  resize: none;
  border-color: #983e7b;
  height: 377px;
  border-radius: 10px;
  width: 100%;
}
.input2 {
  border-color: #983e7b;
  height: 50px;
  border-radius: 10px;
  width: 100%;
  border-width: 1px;
}
.input1 {
  border-width: 1px;
  border-color: #983e7b;
  height: 50px;
  border-radius: 10px;
  width: 100%;
}
#form {
  margin-left: 5%;
  margin-right: 5%;
}

button {
  background-color: #983e7b;
  color: white;
  border-radius: 10px;
  border-color: transparent;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}
#formConsumer {
  width: 65%;
  background-color: #983e7b17;
  height: 100%;
}
#message {
  width: 35%;
  background-color: white;
  height: 100%;
}
#rowConsumer {
  width: 100%;
  margin: 0px;
  height: 680px;
}

input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background-clip: content-box;
  background-color: #d3d3d3;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 5px;
}

input[type="radio"]:checked {
  background-color: #983e7b;
}

.labelSpecialty {
  position: relative;
  bottom: 2px;
}

#textDescription {
  color: grey;
  text-align: center;
}

h2 {
  color: black;
  text-align: center;
}

#imgLogo {
  width: 320px;
  height: 120px;
}

#imgConsumer {
  width: 400px;
}
</style>