<template>
    <div style="width:82%;position:absolute;left:18%;top:0%;margin-left:0%;"> 
        <br>
        <br>
        
        <div id="boxAssessment">
            <br>
            <h1 class="text-center">Ensaio Mental</h1>
            <br>
            <h5>[Desenvolvido a partir do trabalho de Manz & Neck]</h5>
            <br>
            <br>
            <h3><b>Passo 1 – Estado emocional desejado</b></h3>
            <br>
            <h5>Imagine que você é um diretor de cinema e vai revisar uma cena para melhorá-la: veja-se
numa grande tela mental, exibindo o comportamento que você quer mudar ou melhorar (lem-
bre-se da última vez que você exibiu o comportamento a ser melhorado).</h5>
            <br>
            <br>
            <br>
            <br>
            <h3><b>Passo 2 – Decida o que você quer mudar ou melhorar</b></h3>
            <br>
            <h5>Após visualizar a si mesmo, identifique e decida o que você quer mudar ou melhorar. Se ne-
cessário, busque modelos externos (O que alguém que você admira muito faria no seu lugar?).</h5>
            <br>
            <br>
            <br>
            <br>
            <h3><b>Passo 3 – Edite o filme alterando tudo o que você decidiu alterar</b></h3>
            <br>
            <h5>Veja-se novamente no filme, mas, desta vez, fazendo diferente. Crie suas falas mentalmente,
vendo-se na tela o tempo todo.</h5>
            <br>
            <br>
            <br>
            <br>
            <h3><b>Passo 4 – Entre no filme e se transforme nesta pessoa</b></h3>
            <br>
            <h5>Entre no filme mental como se o fato estivesse acontecendo agora e veja tudo o que você veria
através dos seus olhos, ouça tudo e, principalmente, sinta dentro de você todas as sensações
que você quer sentir quando estiver fazendo de forma diferente o que você deseja.</h5>
            <br>
            <br>
            <br>
            <br>
            <h3><b>Passo 5 – Crie uma conexão com o futuro</b></h3>
            <br>
            <h5>Pense no seu futuro próximo, quando você quer passar a exibir este novo comportamento ou
atitude, e imagine este momento.</h5>
            <br>
            <br>
            <h5>Comentários e anotações</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <div style="display: flex; justify-content: center;"> 
                  <button class="btn" >Salvar resultados</button>
            </div>
            <br>


        </div>
    </div>
</template>

<style scoped>
*
{
    font-family: roboto;
}
#boxAssessment
{
    
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    
    color: black;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
</style>

<script>
export default {
     data() {
        return {
            
           
            opcoes: [
               {n: '0'},
               {n: '1'},
               {n: '2'},
               {n: '3'},
               {n: '4'},
               {n: '5'},
               {n: '6'},
               {n: '7'},
               {n: '8'},
               {n: '9'},
               {n: '10'},
            ]
        }
     }
   
}
</script>