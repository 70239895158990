 <template>
  <div>
    <Navbar></Navbar>
    <Text1></Text1>
    <Text2></Text2>
    <Text3></Text3>
    <Text4></Text4>
    <Text5></Text5>
    <br />
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../components/navbar.vue";
import Footer from "../components/footer.vue";
import Text1 from "../components/text1-torne";
import Text2 from "../components/text2-torne";
import Text3 from "../components/text3-torne";
import Text4 from "../components/text4-torne";
import Text5 from "../components/text5-torne";

export default {
  components: {
    Text1,
    Text2,
    Text3,
    Text4,
    Text5,
    Navbar,
    Footer,
  },
};
</script>
<style scoped>
</style>