<template>
  <div>
    <Text1></Text1>
    <Text2></Text2>
    <br />
    <Text3></Text3>

    <Text3Home></Text3Home>
    <Text7></Text7>
  </div>
</template>

<script>
import Text1 from "../components/text1-modulos.vue";

import Text3 from "../components/text3-modulos.vue";
import Text2 from "../components/text2-modulos";
import Text7 from "../components/text-7-para-empresas.vue";
import Text3Home from "../components/text3-home.vue";

export default {
  components: {
    Text1,
    Text3,
    Text7,
    Text3Home,
    Text2,
  },
};
</script>