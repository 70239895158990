<template>
  <div>
    <Navbar></Navbar>
    <div id="rowCompany" class="row">
      <div class="col-sm" id="message">
        <br />
        <br />
        <div style="display: flex; justify-content: center">
          <img id="imgLogo" src="../../imgs/florescer_desenvolvimento.png" />
        </div>
        <br />
        <h2>
          Cadastre-se e descubra como potencializar os resultados da sua equipe!
        </h2>

        <div style="display: flex; justify-content: center">
          <img id="imgCompany" src="../../imgs/empresa_psi.png" />
        </div>
      </div>

      <div class="col-sm" id="formCompany">
        <br />
        <br />

        <div id="form">
          <p id="msg1" style="color: red">{{ msgError }}</p>
          <form>
            <div>
              <label for="name">Nome fantasia da empresa: *</label>
              <br />
              <input class="input1" type="text" id="name" />
            </div>
            <br />
            <div>
              <label for="nameResponsable">Nome do responsável: *</label>
              <br />
              <input class="input1" type="text" id="nameResponsable" />
            </div>
            <br />

            <div class="row">
              <div class="col">
                <label for="email">E-mail do responsável: *</label>
                <br />
                <input class="input2" type="email" id="email" />
              </div>
              <div class="col">
                <label for="tel">Telefone/Celular responsável: *</label>
                <br />
                <input
                  class="input2"
                  v-on:blur="editTel()"
                  maxlength="15"
                  type="text"
                  id="tel"
                />
              </div>
            </div>
            <br />

            <div>
              <label for="howFind">Como nos encontrou? *</label>
              <br />
              <select
                class="custom-select input1"
                v-on:click="showTextBox()"
                id="howFind"
                style="color: gray"
                required="required"
              >
                <option
                  style="color: gray"
                  v-for="op in howFind"
                  :key="op.midia"
                >
                  {{ op.midia }}
                </option>
              </select>
            </div>
            <br />
            <div v-if="boxOthers == true">
              <label for="name">Em qual outro meio nos encontrou? *</label>
              <br />
              <input class="input1" type="text" id="other" />
              <br />
              <br />
            </div>

            <div class="row">
              <div class="col">
                <label for="password">Senha: *</label>
                <br />
                <input
                  maxlength="10"
                  class="input1"
                  v-on:click="showMsgPassword()"
                  type="password"
                  id="password"
                />
              </div>
              <div class="col">
                <label for="confirmPassword">Confirme sua senha: *</label>
                <br />
                <input
                  maxlength="10"
                  class="input1"
                  v-on:click="showMsgPassword()"
                  type="password"
                  id="confirmPassword"
                />
              </div>
            </div>
            <br />
            <div style="display: flex; justify-content: center">
              <input id="agreeTerms" type="checkbox" />
              <label>Ao registrar você concorda com nossos termos uso</label>
            </div>
            <br />

            <div style="display: flex; justify-content: center">
              <button v-on:click="verifyForm($event)">Registrar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import api from "../services/api.js";
import Navbar from "../components/navbar.vue";
import Footer from "../components/footer.vue";

export default {
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      nameCompany: "",
      nameResponsable: "",
      email: "",
      tel: "",
      howFindSelected: "",
      password: "",
      confirmPassword: "",
      boxOthers: false,
      msgError: "",
      howFind: [
        { midia: "" },
        { midia: "Facebook" },
        { midia: "Twitter" },
        { midia: "Google" },
        { midia: "Instagram" },
        { midia: "Youtube" },
        { midia: "Noticias" },
        { midia: "Indicação de amigo" },
        { midia: "LinkedIn" },
        { midia: "Outros" },
      ],
    };
  },
  methods: {
    increaseSize: function () {
      document.getElementById("rowCompany").style.height = "900px";
    },
    decreaseSize: function () {
      document.getElementById("rowCompany").style.height = "800px";
    },
    showTextBox: function () {
      const howFind = document.getElementById("howFind").value;

      if (howFind == "Outros") {
        this.boxOthers = true;
        this.increaseSize();
      } else {
        this.boxOthers = false;
        this.decreaseSize();
      }
    },
    showMsgPassword: function () {
      this.msgError =
        "A senha deve conter pelo menos 6 caracteres, com letras e números";
    },
    verifyForm: function (event) {
      event.preventDefault();
      this.nameCompany = document.getElementById("name").value;
      this.nameResponsable = document.getElementById("nameResponsable").value;
      this.email = document.getElementById("email").value;
      this.tel = document.getElementById("tel").value;
      this.howFindSelected = document.getElementById("howFind").value;
      this.password = document.getElementById("password").value;
      this.confirmPassword = document.getElementById("confirmPassword").value;

      if (this.boxOthers == true) {
        var otherMedia = document.getElementById("other").value;
        this.howFind = otherMedia;
      }

      const agreeTerms = document.getElementById("agreeTerms");
      const passwordValid = this.verifyPassword(this.password);

      if (!this.nameCompany) {
        this.msgError = "Preencha todos os dados obrigatórios!";
      } else if (!this.nameResponsable) {
        this.msgError = "Preencha todos os dados obrigatórios!";
      } else if (!this.email) {
        this.msgError = "Preencha todos os dados obrigatórios!";
      } else if (!this.tel) {
        this.msgError = "Preencha todos os dados obrigatórios!";
      } else if (this.howFindSelected == "") {
        this.msgError = "Preencha todos os dados obrigatórios!";
      } else if (this.boxOthers == true && !otherMedia) {
        this.msgError = "Preencha todos os dados obrigatórios!";
      } else if (!this.password) {
        this.msgError = "Crie sua senha!";
      } else if (!this.confirmPassword) {
        this.msgError = "Confirme sua senha!";
      } else if (this.password != this.confirmPassword) {
        this.msgError = "As senhas não correspondem";
      } else if (passwordValid == false) {
        this.showMsgPassword();
      } else if (!agreeTerms.checked) {
        this.msgError = "Aceite os termos de uso para se cadastrar";
      } else {
        this.sendData();
      }
    },
    sendData: function () {
      try {
        api
          .post("/initial-company-register", {
            nameFantasy: this.nameCompany,
            nameResponsable: this.nameResponsable,
            name: this.nameCompany,
            email: this.email,
            phoneNumber: this.tel,
            password: this.password,
            howFindUs: this.howFindSelected,
            roleType: "Company",
            useTerms: true,
          })
          .then(
            (response) => {
              console.log(response);
              this.$router.push("login-cliente");
            },
            (error) => {
              console.log(error);
              this.msgError = "Erro";
            }
          );
      } catch (e) {
        console.log(e);
      }
    },
    verifyPassword: function (password) {
      if (password.length >= 6) {
        if (/[0-9]/.test(password)) {
          if (/[a-z]/.test(password) || /[A-Z]/.test(password)) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    editTel: function () {
      var tel = document.getElementById("tel").value;

      if (tel.length >= 11) {
        const ddd = tel.slice(0, 2);
        const part1 = tel.slice(2, 7);
        const part2 = tel.slice(7, 11);
        if (
          isNaN(ddd) == true ||
          isNaN(part1) == true ||
          isNaN(part2) == true
        ) {
          document.getElementById("tel").value = "(11)99999-9999";
        } else {
          tel = `(${ddd})${part1}-${part2}`;
          document.getElementById("tel").value = tel;
        }
      } else if (tel.length == 10) {
        const ddd = tel.slice(0, 2);
        const part1 = tel.slice(2, 6);
        const part2 = tel.slice(6, 10);
        if (
          isNaN(ddd) == true ||
          isNaN(part1) == true ||
          isNaN(part2) == true
        ) {
          document.getElementById("tel").value = "(11)99999-9999";
        } else {
          tel = `(${ddd})${part1}-${part2}`;
          document.getElementById("tel").value = tel;
        }
      }
    },
  },
};
</script>
<style scoped>
label {
  font-size: 14px;
}
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background-clip: content-box;
  background-color: #d3d3d3;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 2px;
}

input[type="checkbox"]:checked {
  background-color: #983e7b;
}

.labelArea {
  position: relative;
  bottom: 2px;
}
.button {
  background-color: #983e7b;
  color: white;
  border-radius: 10px;
  border-color: transparent;
  text-align: center;
  width: 40px;
  cursor: pointer;
}

.input2 {
  border-color: #983e7b;
  height: 50px;
  border-radius: 10px;
  width: 100%;
  border-width: 1px;
}
.input1 {
  border-width: 1px;
  border-color: #983e7b;
  height: 50px;
  border-radius: 10px;
  width: 100%;
}
#form {
  margin-left: 5%;
  margin-right: 5%;
}

button {
  background-color: #983e7b;
  color: white;
  border-radius: 10px;
  border-color: transparent;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}
#formCompany {
  width: 65%;
  background-color: #983e7b17;
  height: 100%;
}
#message {
  width: 35%;
  background-color: white;
  height: 100%;
}
#rowCompany {
  width: 100%;
  margin: 0px;
  height: 800px;
}

input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background-clip: content-box;
  background-color: #d3d3d3;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 5px;
}

input[type="radio"]:checked {
  background-color: #983e7b;
}

.labelCompany {
  position: relative;
  bottom: 2px;
}

#textDescription {
  color: grey;
  text-align: center;
}
h2 {
  color: black;
  text-align: center;
}
#imgLogo {
  width: 320px;
  height: 120px;
}
#imgCompany {
  width: 400px;
}
</style>