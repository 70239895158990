<template>
    <div style="width:82%;position:absolute;left:18%;top:0%;margin-left:0%;"> 
        <br>
        <br>
        
        <div id="boxAssessment">
            <br>
            <h1 class="text-center">Análise Estratégica SWOT</h1>
            <br>
            <h5>[Desenvolvido a partir do trabalho de Kenneth Andrews e Roland Christensen]</h5>
            <br>
            
            <table>
                
                    <tr>
                        <th colspan="2">Cenário 1</th>
                    </tr>
                
                
                <tr >
                    <td colspan="2">
                        <input style="width:100%" placeholder="Digite o titulo do cenário" type="text">
                    </td>
                </tr>
                <tr>
                    <td style="height:50px">
                        Forças
                    </td>
                    <td style="height:50px">
                        Fraquezas
                    </td>
                </tr>
                <tr>
                    <td>
                        <input style="width:100%;height:100px" type="text">
                    </td>
                    <td>
                        <input style="width:100%;height:100px"  type="text">
                    </td>
                </tr>
                <tr>
                    <td style="height:50px">
                        Oportunidades
                    </td>
                    <td style="height:50px">
                        Ameaças
                    </td>
                </tr>
                <tr>
                    <td>
                        <input style="width:100%;height:100px" type="text">
                    </td>
                    <td>
                        <input style="width:100%;height:100px"  type="text">
                    </td>
                </tr>
            </table>   
            <br>
            <br>
            <br>
            <table>
                
                    <tr>
                        <th colspan="2">Cenário 2</th>
                    </tr>
                
                
                <tr >
                    <td colspan="2">
                        <input style="width:100%" placeholder="Digite o titulo do cenário" type="text">
                    </td>
                </tr>
                <tr>
                    <td style="height:50px">
                        Forças
                    </td>
                    <td style="height:50px">
                        Fraquezas
                    </td>
                </tr>
                <tr>
                    <td>
                        <input style="width:100%;height:100px" type="text">
                    </td>
                    <td>
                        <input style="width:100%;height:100px"  type="text">
                    </td>
                </tr>
                <tr>
                    <td style="height:50px">
                        Oportunidades
                    </td>
                    <td style="height:50px">
                        Ameaças
                    </td>
                </tr>
                <tr>
                    <td>
                        <input style="width:100%;height:100px" type="text">
                    </td>
                    <td>
                        <input style="width:100%;height:100px"  type="text">
                    </td>
                </tr>
            </table>   
            <br>
            <br>
            <br>
            <table>
                
                    <tr>
                        <th colspan="2">Cenário 3</th>
                    </tr>
                
                
                <tr >
                    <td colspan="2">
                        <input style="width:100%" placeholder="Digite o titulo do cenário" type="text">
                    </td>
                </tr>
                <tr>
                    <td style="height:50px">
                        Forças
                    </td>
                    <td style="height:50px">
                        Fraquezas
                    </td>
                </tr>
                <tr>
                    <td>
                        <input style="width:100%;height:100px" type="text">
                    </td>
                    <td>
                        <input style="width:100%;height:100px"  type="text">
                    </td>
                </tr>
                <tr>
                    <td style="height:50px">
                        Oportunidades
                    </td>
                    <td style="height:50px">
                        Ameaças
                    </td>
                </tr>
                <tr>
                    <td>
                        <input style="width:100%;height:100px" type="text">
                    </td>
                    <td>
                        <input style="width:100%;height:100px"  type="text">
                    </td>
                </tr>
            </table>   
            <br>
            <br>
            <br>
            <table>
                
                    <tr>
                        <th colspan="2">Cenário 4</th>
                    </tr>
                
                
                <tr >
                    <td colspan="2">
                        <input style="width:100%" placeholder="Digite o titulo do cenário" type="text">
                    </td>
                </tr>
                <tr>
                    <td style="height:50px">
                        Forças
                    </td>
                    <td style="height:50px">
                        Fraquezas
                    </td>
                </tr>
                <tr>
                    <td>
                        <input style="width:100%;height:100px" type="text">
                    </td>
                    <td>
                        <input style="width:100%;height:100px"  type="text">
                    </td>
                </tr>
                <tr>
                    <td style="height:50px">
                        Oportunidades
                    </td>
                    <td style="height:50px">
                        Ameaças
                    </td>
                </tr>
                <tr>
                    <td>
                        <input style="width:100%;height:100px" type="text">
                    </td>
                    <td>
                        <input style="width:100%;height:100px"  type="text">
                    </td>
                </tr>
            </table>   
            <br>
            <br>
            <br>
           
            <div style="display: flex; justify-content: center;"> 
                <button class="btn" >Salvar resultados</button>
            </div>
            <br>

        </div>
        <br>
        <br>
        <br>
    </div>
</template>

<style scoped>
table tr td
{
    text-align: center;
    width:1000px;
    border-bottom-color:#D3D3D3;
    border-bottom-style: solid;
     
    border-width: 1px;
}
table tr th
{
    border-top-color:#D3D3D3;
    border-top-style: solid;
     
    border-width: 1px;
    text-align: center;
    width:1000px;
}
*
{
    font-family: roboto;
}
#boxAssessment
{
    
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    
    color: black;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
</style>

<script>
export default {
     data() {
        return {
            
           
            opcoes: [
               {n: '0'},
               {n: '1'},
               {n: '2'},
               {n: '3'},
               {n: '4'},
               {n: '5'},
               {n: '6'},
               {n: '7'},
               {n: '8'},
               {n: '9'},
               {n: '10'},
            ]
        }
     }
   
}
</script>