<template>
  <div>
    <br />
    <br />
    <h1>
      O melhor benefício de saúde emocional e bem-estar<br />para colaboradores,
      otimizando processo, gerando cooperação e sinergia entre times!
    </h1>

    <div class="row" id="text">
      <div class="col-md col-sm-12">
        <br />
        <br />
        <br />
        <p>
          <span>&#10004;</span>Avalie seus colaboradores através de uma
          dashboard que tabulamos todas informações de forma personalizada;<br />
        </p>

        <p>
          <span>&#10004;</span>Retenha os melhores talentos, diminuindo o
          absenteísmo e presenteísmo, melhorando a harmonia do trabalho e
          aumentamos os resultados;
        </p>
      </div>

      <div id="colImg" class="col-md col-sm-12">
        <img src="../../imgs/notebook_empresa.png" />
      </div>
      <div class="col-md col-sm-12">
        <br />
        <br />
        <br />
        <p>
          <span>&#10004;</span>Como resultado, aumentamos o nível de felicidade,
          engajamento e motivação dentro do ambiente de trabalho;<br />
        </p>

        <p></p>
        <p>
          <span>&#10004;</span>Meça e avalie a saúde emocional através de
          ferramentas de neurociência, PNL e da psicologia positiva;
        </p>
      </div>
    </div>
    <div style="display: flex; justify-content: center">
      <img id="arrow" src="../../imgs/seta_empresas.png" />
    </div>
    <br />
    <br />
    <div style="display: flex; justify-content: center">
      <a :href="VUE_APP_URL_ESPECIALISTA + '/cadastro-empresa'"><button
          class="btn">CADASTRAR-SE</button></a>
    </div>
    <br />
    <br />
  </div>
</template>

<script>
export default {
  data(){
    return {
      VUE_APP_URL_ESPECIALISTA: process.env.VUE_APP_URL_ESPECIALISTA
    }
  }
};
</script>
<style scoped>
.btn {
  background-color: #fabe0a;
  padding: 10px;
  border-radius: 6px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  color: #692b55;
  max-width: 260px;
}

.btn:hover {
  background-color: #fff051;
}

#arrow {
  width: 8.056vw;
}

img {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 80px;
  width: 33.556vw;
  height: 20.569vw;
}

p {
  font-size: 1.111vw;
}

#text {
  margin-top: 104px;
  margin-left: 7.8%;
  margin-right: 7.8%;
  text-align: justify;
}

h1 {
  font-size: 2vw;
  text-align: center;
  color: #ab3585;
  margin-left: 7.8%;
  margin-right: 7.8%;
}

span {
  font-size: 35px;
  color: #ab3585;
}

@media (min-width: 993px) and (max-width: 1024px) {
  img {
    margin-top: 23%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  img {
    margin-top: 30%;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  p {
    font-size: 2.222vw;
  }

  #colImg {
    display: flex;
    justify-content: center;
  }

  img {
    width: 43.556vw;
    height: 30.569vw;
  }

  h1 {
    font-size: 3vw;
  }
}
</style>