<template>
    <div style="width:82%;position:absolute;left:18%;top:0%;margin-left:0%;"> 
        <br>
        <br>
        
        <div id="boxAssessment" >
            <br>
            <h1 class="text-center">Teoria da auto eficácia</h1>
            <br>
           
            
            <br>
            <h3><b>Etapa 1 - Relembrando seus sucessos</b></h3>
            <br>
            <h5><b>Passo 1 - Listar mudanças positivas importantes que você já fez em sua vida.</b></h5>
            <br>
            <h5>Liste algumas mudanças positivas importantes que você já fez em sua vida </h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <hr>

             
            <br>
        
            <h5><b>Passo 2 - Selecionar e explorar o maior desafio.</b></h5>
            <br>
            <h5>Releia sua lista de mudanças e escolha aquela que representou o maior desafio. Depois responda: </h5>
            <br>
            <h5>Quando e por que você começou a pensar em fazer essa mudança? </h5>
            
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <h5>O que estava acontecendo em sua vida naquela época? </h5>
            
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>

            <h5>Você realizou a mudança de uma vez ou em pequeno passos? Quais foram esses passos? </h5>
            
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
        
            <br>
            <h5>Liste as coisas que mais ajudaram você a ter sucesso nessa mudança (suas forças, crenças, seus recursos, comportamentos, suas atitudes, estratégias e seus valores) </h5>
            
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            
            <br>
            <h5>Como você se sente hoje quando pensa nessa mudança?</h5>
            
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <hr>
            <br>
            <h3><b>Etapa 2 - Desenhando seu sucesso</b></h3>
            <br>
            <h5><b>Passo 1 - Elencar os seus sonhos</b></h5>
            <br>
            <h5>Quais são seus sonhos e esperanças para o futuro</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5><b>Passo 2 - Identificar o que esta acontecendo</b></h5>
            <br>
            <h5>O que você pode fazer agora para que esses sonhos se tornem realidade?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5><b>Passo 3 - Definir os próximos passos.</b></h5>
            <br>
            <h5>O que mais você pode fazer?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <hr>
            <br>
            <h3><b>Etapa 2 - Monte seu brasão</b></h3>
            <br>
            <div class="row">
                <div class="col">
                    <h5><b>Passo 1 - Criando a identidade do Brasão</b></h5>
                    <p>Coloque o nome do cliente e 3 forças que o representam?</p>
                    <br>
                    <h5><b>Passo 2: Atribuindo os valores</b></h5>
                    <p>peça para o cliente 3 valores que o acompanham nessa conquista.</p>
                    <br>
                    <h5><b>Passo 3: Atribuindo as forças ou qualidades</b></h5>
                    <p>Escreva 3 forças, qualidades ou características que o cliente admira e que o ajudariam a conquistar seu objetivo. </p>
                    <br>
                    <h5><b>Passo 4: Definindo seu objetivo principal</b></h5>
                    <p>Peça para o cliente dizer uma palavra que expresse seu objetivo mais estimulante.</p>
                    <br>
                    <h5><b>Passo 5: Usando sua maior conquista</b></h5>
                    <p>O espaço 5 é reservado à maior conquista do cliente. Qual foi ela? Peça para o seu cliente escolha uma imagem que represente esse sucesso</p>
                    <br>
                    <h5><b>Passo 6: Um símbolo de orgulho</b></h5>
                    <p>Peça para o seu cliente escolher um simbolo que ele se orgulha muito</p>
                    <br>
                    <h5><b>Passo 7: Definindo seu lema</b></h5>
                    <p>Escreva o lema do cliente, uma frase curta, que represente sua missão ou
aquilo que você mais quer ser, ter ou fazer. Quanto maior for a capacidade da frase de deixar
você emocionado, mais poderosa ela será.</p>
                    <br>
                    


                </div>

                <div class="col">
                    <div id="brasao">
                        <div style="width:100%;border-bottom-style:solid;border-color:black;height:100px">
                            
                            <input value="BRASÃO DE" type="text" style="text-align:center;width:100%;height:100%">

                        </div>

                        <div style="width:100%;border-bottom-style:solid;border-color:black;height:150px;margin:0px;padding:0px" >
                            <div class="row"  style="margin-left:1px;width:100%;height:100%;">
                                <div class="col" style="border-right-style:solid;border-color:black;margin:0px;padding:0px">
                                   <input placeholder="1" type="text" style="text-align:center;width:100%;height:100%;">
                                </div>
                                <div class="col" style="border-right-style:solid;border-color:black;margin:0px;padding:0px">
                                   <input placeholder="2" type="text" style="text-align:center;width:100%;height:100%">
                                </div>
                                <div  class="col" style="margin:0px;padding:0px" >
                                   <input placeholder="3" type="text" style="text-align:center;width:100%;height:100%">
                                </div>
                            </div>
                        </div>
                        
                        <div style="width:100%;border-bottom-style:solid;border-color:black;height:150px">
                                <input placeholder="4" type="text" style="text-align:center;width:100%;height:100%">
                        </div>

                        <div id="inputBottom">    
                            <div class="row" style="height:100%"> 
                               <div style="height:100%;border-right-style:solid;border-color:black;" class="col">
                                   <input id="file1"  type="file" style="width:100%">
                                   
                               </div>
                               <div style="height:100%;" class="col">
                                   <input id="file2" type="file" style="width:100%">
                                   
                                </div>
                            </div>
                        </div>


                    </div>
                    <div>
                        <br>
                        <input style="width:80%;margin-left:10%;text-align:center" placeholder="Lema" type="text">

                    </div>
                </div>

            </div>
            <br>
            
            <h5><b>Passo 8: Usando a força do brasão</b></h5>
                    <p>Olhe bem para o seu brasão e responda:
                    </p>
                    
                    <ul>
                        <li>O que este brasão me faz sentir?</li>
                        <li>O que ele significa neste momento de minha vida?</li>
                        <li>O que eu descobri ou redescobri sobre mim mesmo enquanto fazia (ou enquanto
apreciava) o meu brasão?</li>

                    </ul>
            <br>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>

            
          
           <br>
           <br>
           <div style="display: flex; justify-content: center;"> 
                <button class="btn" >Salvar resultados</button>
            </div>
            <br>

        </div>
    </div>
</template>

<style scoped>
#inputBottom
{
    width:100%;
    border-color:black;
    border-style:solid;
    border-bottom-left-radius:150px;
    border-bottom-right-radius:150px;
    height:100px;
}
h5
{
    text-align:justify;    
}
p
{
    text-align: justify;
}
table tr td
{
    text-align: center;
    width:150px;
    border-bottom-color:#D3D3D3;
    border-bottom-style: solid;
     
    border-width: 1px;
}
table tr th
{
    border-top-color:#D3D3D3;
    border-top-style: solid;
     
    border-width: 1px;
    text-align: center;
    width:150px;
}



*
{
    font-family: roboto;
}

#boxAssessment
{
    
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    
    color: black;

}
*
{
    
    font-family: roboto;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
a:hover{
    color:#fec961;
}

</style>

<script>
export default {
    
    
}
</script>