<template>
    <div style="width:82%;position:absolute;left:18%;top:0%;margin-left:0%;"> 
        <br>
        <br>
        
        <div id="boxAssessment">
            <br>
            <h1 class="text-center">Plano de desenvolvimento de carreira</h1>
            <br>
            <h3><b>Etapa 1: Autoeficácia</b></h3>
            <br>
            <h5>Aplique o teste e confira o nível de autoeficácia de seu cliente. Para
elevá-la, você pode:</h5>
            <ul>
                <li>Trabalhar crenças fortalecedoras.</li>
                <li>Conduzir visualizações para que o cliente reviva suas conquistas
passadas e aprenda a valorizá-las.</li>
                <li>Segmentar objetivos para facilitar sua consecução e, com isso,
fortalecer a autoestima do cliente.</li>

            </ul>
            <br>
            <br>
            <hr>
            
            <h3><b>Etapa 2: Expectativas de Resultados</b></h3>
            <br>
            <br>
            
            <h5>Faça perguntas para verificar as expectativas de resultados do cliente.</h5>
            <br>
            <br>
            <p>Que resultados você espera obter com isso?</p>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <p>Quais são suas expectativas profissionais?</p>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <p>Quais são suas expectativas para o futuro?</p>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            
            <p>Se as expectativas forem baixas, explore e trabalhe os motivos pelos
            quais isso acontece (baixa autoestima ou autoconfiança, objetivos pouco 
            motivadores, incongruência de valores, falta de um propósito definido etc.).</p>
            
            <br>
            <br>
            
            <hr>

            <h3><b>Etapa 3: Objetivos</b></h3>
            <br>
            <h5>Comece acessando a situação atual do cliente. Pergunte:</h5>
            <br>
            <br>
            <p>Em que ponto de sua carreira você está agora?</p>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
           
            <p>Como você chegou aí?</p>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <p>Quais foram os pontos fortes que o ajudaram a chegar onde você
está, e que pdem ajudá-lo a ir mais além?</p>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <h5>Depois, identifique a situação desejada, verificando:</h5>
            <br>
            <br>
            <p>Quais são os objetivos profissionais do cliente</p>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <p>Quais são os prazos para a realização desses objetivos</p>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <p>Quais são os obstáculos e como eles podem ser superados</p>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <p>Que ações devem ser empreendidas para que os objetivos sejam
atingidos</p>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <p><b>Confira sempre: motivação e comprometimento.</b></p>
            <br>
            <br>
        
            <div style="display: flex; justify-content: center;"> 
                <button class="btn" >Salvar resultados</button>
            </div>
            <br>
        </div>   
    </div>
</template>

<style scoped>
*
{
    font-family: roboto;
}
#boxAssessment
{
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    
    color: black;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
a:hover{
    color:#fec961;
}
</style>

<script>
export default {
    
   
}
</script>