<template>
    <div style="width:82%;position:absolute;left:18%;top:0%;margin-left:0%;"> 
        <br>
        <br>
        
        <div id="boxAssessment">
            <br>
            <h1 class="text-center">Funcionamento otimizado</h1>
            <br>
            <br>
            <h5>No que diz respeito a delegação de tarefas, minha atitude é:</h5>
            <input name="question1" type="radio">
            <label>&nbsp;&nbsp;Delegar o máximo possível.</label><br>
            <input name="question1"  type="radio">
            <label>&nbsp;&nbsp;Tenho restrições quanto à delegação, porque é mais prático fazer do que explicar.</label><br>
            <input name="question1"  type="radio">
            <label>&nbsp;&nbsp;Defino o que só eu posso fazer e delego o resto, de acordo com a experiência de cada um.</label><br>
           
            <hr>
            <br>
            <br>
            <h5>No que diz respeito a ajudar outras pessoas, minha atitude:</h5>
            <input name="question2" type="radio">
            <label>&nbsp;&nbsp;Gostaria de ajudar mais, mas mal dou conta de minhas próprias atribuições.</label><br>
            <input name="question2"  type="radio">
            <label>&nbsp;&nbsp;Nunca me recuso a ajudar a alguém. Se acho que as pessoas precisam, ajudo mesmo que elas não me peçam. </label><br>
            <input name="question2"  type="radio">
            <label>&nbsp;&nbsp;Ajudo quem precisa, mas muitas vezes a melhor forma de ajudar é encorajando a pessoa a ajudar a si mesma.</label><br>
            <hr>
            <br>
            <br>
            <h5>Quando estou trabalhando com um jovem em início de carreira, minha atitude é:</h5>
            <input name="question3" type="radio">
            <label>&nbsp;&nbsp;Não me envolver. É responsabilidade da empresa providenciar o treinamento dele.</label><br>
            <input name="question3"  type="radio">
            <label>&nbsp;&nbsp;Pensar que vai sobrar pra min. E sobra mesmo. Acabo de me responsabilizar pelo novato.</label><br>
            <input name="question3"  type="radio">
            <label>&nbsp;&nbsp;Orientar, mas sempre dando espaço para que ele tenha a oportunidade de fazer e aprender </label><br>
            
            <hr>
            <br>
            <br>
            <h5>Quando o assunto são os cuidados comigo mesmo, minha atitude é: </h5>
            <input name="question4" type="radio">
            <label>&nbsp;&nbsp;Gosto de me manter saudável e com boa aparência, mas não persisto muito.</label><br>
            <input name="question4"  type="radio">
            <label>&nbsp;&nbsp;Com todos os meus compromissos e responsabilidades, não me sobra tempo para isso.</label><br>
            <input name="question4"  type="radio">
            <label>&nbsp;&nbsp;Sempre dou um jeito de me cuidar. Caso contrário, acabo comprometendo minha energia e minha disposição.</label><br>
            
            <hr>
            <br>
            <br>
            <h5>Assinale a opção que melhor descreve seus objetivos pessoais.</h5>
            <input name="question5" type="radio">
            <label>&nbsp;&nbsp;Coisas que eu pretendo alcançar um dia.</label><br>
            <input name="question5"  type="radio">
            <label>&nbsp;&nbsp;Coisas que eu preciso alcançar pelo bem da minha família ou empresa</label><br>
            <input name="question5"  type="radio">
            <label>&nbsp;&nbsp;Coisas que possuem profundo Significado e propósito para mim</label><br>
            <hr>
            <br>
            <h5>Se tivesse que descrever minha rotina eu diria que ele é:</h5>
            <input name="question6" type="radio">
            <label>&nbsp;&nbsp;Caótica</label><br>
            <input name="question6"  type="radio">
            <label>&nbsp;&nbsp;Extenuante/exaustiva</label><br>
            <input name="question6"  type="radio">
            <label>&nbsp;&nbsp;Produtiva</label><br>
            <hr>
            <br>

            <h5>Em relação a meus compromissos:</h5>
            <input name="question7" type="radio">
            <label>&nbsp;&nbsp;Tenho dificuldade para dar conta de tudo.</label><br>
            <input name="question7"  type="radio">
            <label>&nbsp;&nbsp;Tenho de dar conta de tudo, mesmo que precise me sacrificar.</label><br>
            <input name="question7"  type="radio">
            <label>&nbsp;&nbsp;Dou conta de tudo, porque me organizo para isso.</label><br>
            <hr>
            <br>

            <h5>No que diz respeito a responsabilidade:</h5>
            <input name="question8" type="radio">
            <label>&nbsp;&nbsp;Odeio quando me cobram mais responsabilidade</label><br>
            <input name="question8"  type="radio">
            <label>&nbsp;&nbsp;Assumir responsabilidade é uma questão de obrigação.</label><br>
            <input name="question8"  type="radio">
            <label>&nbsp;&nbsp;Sei distinguir entre minhas responsabilidades e a dos outros.</label><br>
            <hr>
            <br>

             <h5>Muitas das coisas que faço é porque:</h5>
            <input name="question9" type="radio">
            <label>&nbsp;&nbsp;Outros me mandaram fazer.</label><br>
            <input name="question9"  type="radio">
            <label>&nbsp;&nbsp;Sinto-me obrigado a fazer.</label><br>
            <input name="question9"  type="radio">
            <label>&nbsp;&nbsp;Escolhi fazer.</label><br>
            <hr>
            <br>
            <h5>Comentários e anotações</h5>
            
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <div style="display: flex; justify-content: center;"> 
                   <button class="btn" >Salvar resultados</button>
            </div>
            <br>
            <br>
        </div>   
    </div>
</template>

<style scoped>
input
{
    margin-bottom: -2px;
}
input[type="radio"] {

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  
 
  width: 15px;
  height: 15px;
  

  background-clip: content-box;
  
  background-color: #D3D3D3;
  border-radius: 50%;
}


input[type="radio"]:checked {
  background-color: #983e7b;
  
}
*
{
    font-family: roboto;
}
#boxAssessment
{
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    
    color: black;
}

.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
a:hover{
    color:#fec961;
}

 
</style>

<script>
export default {
    
   
}
</script>