<template>
    <div style="height:100%;width:82%;position:absolute;left:18%;top:0%;margin-left:0%;"> 
        <br>
        <br>
        
        <div id="boxAssessment">
            <br>
            <h1 class="text-center">Avaliação de Resultados do desenvolvimento do cliente</h1>
            <br>
            <div class="row mb-3">
                <div class="col">
                        <h6 class="text-center"><b>Resultados esperados conforme definição de objetivos</b></h6>
                </div>
                <div class="col">
                        <h6 class="text-center "><b>Realização (0 - 10)</b></h6>
                </div>
                 <div class="col">
                        <h6 class="text-center"><b>Desenvolvimento do cliente (envolvimento)</b></h6>
                </div>
                <div class="col">
                        <h6 class="text-center"><b>Nível de comprometimento (0 - 10)</b></h6>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    
                    
                    <input type="text"   style="width:100%">
                    <input type="text" class="mt-2" style="width:100%">
                    <input type="text" class="mt-2" style="width:100%">
                    <input type="text" class="mt-2" style="width:100%">
                    <input type="text" class="mt-2" style="width:100%">
                    <input type="text" class="mt-2" style="width:100%">
                </div> 
                <div class="col">
                    
                   
                    <input class="text-center" type="number" min="0" max="10" style="width:100%">
                    <input class="text-center mt-2" type="number" min="0" max="10" style="width:100%">
                    <input class="text-center mt-2" type="number" min="0" max="10" style="width:100%">
                    <input class="text-center mt-2" type="number" min="0" max="10" style="width:100%">
                    <input class="text-center mt-2" type="number" min="0" max="10" style="width:100%">
                    <input class="text-center mt-2" type="number" min="0" max="10" style="width:100%">
                </div> 
                <div class="col">
                    
                    <input class="text-center" type="text" max="10" style="width:100%">
                    <input class="text-center mt-2" type="text" max="10" style="width:100%">
                    <input class="text-center mt-2" type="text" max="10" style="width:100%">
                    <input class="text-center mt-2" type="text" max="10" style="width:100%">
                    <input class="text-center mt-2" type="text" max="10" style="width:100%">
                    <input class="text-center mt-2" type="text" max="10" style="width:100%">
                </div> 
                <div class="col">
                    
                    <input class="text-center" type="number"  min="0" max="10" style="width:100%">
                    <input class="text-center mt-2" type="number"  min="0" max="10" style="width:100%">
                    <input class="text-center mt-2" type="number"  min="0" max="10" style="width:100%">
                    <input class="text-center mt-2" type="number"  min="0" max="10" style="width:100%">
                    <input class="text-center mt-2" type="number" max="10" style="width:100%">
                    <input class="text-center mt-2" type="number"  min="0" max="10" style="width:100%">
                </div>      
            </div>
            <br>
            <br>
            <div style="display: flex; justify-content: center;"> 
                 <button class="btn" >Salvar resultados</button>
            </div>
            <br>


        </div>
    </div>
</template>

<style scoped>
#boxAssessment
{
    
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    
    color: black;
}
*
{
    font-family: roboto;
}

.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
</style>

<script>
export default {
     data() {
        return {
            
           
            opcoes: [
               {n: '0'},
               {n: '1'},
               {n: '2'},
               {n: '3'},
               {n: '4'},
               {n: '5'},
               {n: '6'},
               {n: '7'},
               {n: '8'},
               {n: '9'},
               {n: '10'},
            ]
        }
     }
   
}
</script>