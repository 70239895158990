<template>
    <div  style="width:82%;position:absolute;left:18%;top:0%;margin-left:0%;"> 
        <br>
        <br>
       
        <div id="boxAssessment" >
            <br>
            <h1 class="text-center">Escala de satisfação com a vida</h1>
            <h5>(Diener, Emmons, Larsen & Griffin, 1985)</h5>
            <br>
            <br>
            <table>
                <tr>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                </tr>
                <tr>
                    <td>Discordo totalmente</td>
                    <td>Discordo</td>
                    <td>Discordo parcialmente</td>
                    <td>Não concordo, nem discordo</td>
                    <td>Concordo parcialmente</td>
                    <td>Concordo</td>
                    <td>Concordo totalmente</td>
                </tr>
            </table>
           
            <br>
            <br>
            <h5>Usando uma escala de 1 a 7, indique seu grau de concordância com cada uma das afirmações a seguir:</h5>
            <br>
            <h5>Em muitos aspectos a minha vida aproxima-se do que eu considero ideal.</h5>
            <br>
            <input id="level1" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel1').innerHTML = this.value;"  min="1" max="7" value="1" />
            <h1 id="showLevel1" class="numberLevel">1</h1>
            <br>
            <hr>
            <br>
            <h5>Minhas condições de vida são excelentes.</h5>
            <br>
            <input id="level2" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel2').innerHTML = this.value;"  min="1" max="7" value="1" />
            <h1 id="showLevel2" class="numberLevel">1</h1>
            <br>
            <hr>
            <br>
            <h5>Estou satisfeito com a minha vida.</h5>
            <br>
            <input id="level3" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel3').innerHTML = this.value;"  min="1" max="7" value="1" />
            <h1 id="showLevel3" class="numberLevel">1</h1>
            <br>
            <hr>
            <br>
            <h5>Até agora tenho conseguido as coisas importantes que quero da vida</h5>
            <br>
            <input id="level4" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel4').innerHTML = this.value;"  min="1" max="7" value="1" />
            <h1 id="showLevel4" class="numberLevel">1</h1>
            <br>
            <hr>
            <br>
            <h5>Se eu pudesse viver minha vida denovo, eu não alteraria praticamente nada</h5>
            <br>
            <input id="level5" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel5').innerHTML = this.value;"  min="1" max="7" value="1" />
            <h1 id="showLevel5" class="numberLevel">1</h1>
            <br>
            <hr>
            <br>
            <h2>Total: {{total}}</h2>
            <h5>{{msg}}</h5>
            <br>
            <br>
            <h5>Comentários e anotações</h5>
            
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <div style="display: flex; justify-content: center;"> 
                   
                    <button class="btn" >Salvar resultados</button>

            </div>
            <br>
            <br>
        </div>   
    </div>
</template>
<script>
export default {
    mounted()
    {
        
    },
    data() {
        return {
           msg: "",
           nivel1:4,
           nivel2:4,
           nivel3:4,
           nivel4:4,
           nivel5:4,
           valueInput1:0,
           total:16
            
        }
    },
    methods:
    {
        alterValue: function()
        {
             this.nivel1 = parseInt(document.getElementById("level1").value);
             this.nivel2 = parseInt(document.getElementById("level2").value);
             this.nivel3 = parseInt(document.getElementById("level3").value);
             this.nivel4 = parseInt(document.getElementById("level4").value);
             this.nivel5 = parseInt(document.getElementById("level5").value);
             this.total = this.nivel1 + this.nivel2 + this.nivel3 + this.nivel4 + this.nivel5;   
             this.changeMsg(); 
        },
        changeMsg: function()
        {
            if(this.total <= 9)
            {
                this.msg = "05 a 09 - Extremamente infeliz - Sentem-se infelizes com tudo, indicando desequilibrio em diversas áreas de sua vida, nesse caso é indicado a ajuda de um especialista imediatamente."
            }
            else    
                if(this.total >= 10 && this.total <= 14)
                {
                    this.msg = "10 a 14 - Insatisfeito - Pessoas nessa faixa de pontuação sentem-se substancialmente insatisfeitas com suas vidas. Elas podem estar mal em em diversas áreas e muito mal em uma ou duas áreas de suas vidas.";
                }
                else
                    if(this.total >= 15 && this.total <= 19)
                    {
                        this.msg = "15 a 19 - Pouco satisfeito - Pessoas que pontuam nesta faixa geralmente possuem pequenos, porém significativos, problemas em diversas áreas de suas vidas. Ou então, estão bem em diversas áreas, mas possuem uma área que representa um problema susbstacial para elas."
                    }
                    else
                        if(this.total >= 20 && this.total <=35 )
                        {
                            this.msg = "20 a 35 - De satisfeito a extremamente satisfeito - As pessoas que estão na faixa acima de 20 pontos, estão caminhando para a busca de uma maior significado de vida e que muitas"
                                        "coisas estão indo muito bem em sua vida, sente uma motivação e uma votade imensa de vencer. Para a maioria das pessoas que estão nessa faixa, percebe a vida como prazerosa"
                                        "e os principais dominios de suas existências funcionam a contento: Trabalho, família, relacionamentos, saúde, qualidade de vida, espiritualidade e paz de espírito.";
                        }

        }
    }
}
</script>

<style scoped>
input[type='range'] {
  cursor: pointer;
}

input[type='range'],
input[type='range']::-webkit-slider-runnable-track,
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}

/** 
 * Use o pseudo-seletor "::-webkit-slider-runnable-track" para customizar o "trilho" do campo.
 */
input[type='range']::-webkit-slider-runnable-track {
  
  height: 10px;
  background: #983e7b;
  border-radius: 13px;
}

/** 
 * Use o pseudo-seletor "::-webkit-slider-thumb" para customizar o "seletor" do campo.
 */
input[type='range']::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  margin-top: -4.5px;
  background-color: #fec961;
  border-radius: 20px;
}
input{
    background-color: white;
    border-color:#983e7b;
    height:50px;
    border-radius:10px;
}
h2
{
    text-align:justify;
}    
.numberLevel
{
    text-align:center;
    color:#983e7b;
}
.inputLevel
{
    width:100%;
}
h5
{
    text-align:justify;    
}
p
{
    text-align: justify;
}
table tr td
{
    text-align: center;
    width:150px;
    border-bottom-color:#D3D3D3;
    border-bottom-style: solid;
     
    border-width: 1px;
}
table tr th
{
    border-top-color:#D3D3D3;
    border-top-style: solid;
     
    border-width: 1px;
    text-align: center;
    width:150px;
}



*
{
    font-family: roboto;
}

#boxAssessment
{
    
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    
    color: black;

}
*
{
    
    font-family: roboto;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
a:hover{
    color:#fec961;
}

 
</style>

