<template>
    <div style="height:806px;width:82%;background-color:#f7f8fc;position:absolute;left:18%">
      <br>
        <br>
        <div class="row" style="margin-left:5%">
            <div class="col-sm">
                <h6 ><b>Dashboard do Cliente - Bem-vindo (a)</b></h6>
            </div>
            <div class="col-sm" >
                <div class="row" style="margin-top:2px">
                    <div class="col">
                        <img style="width:20px;height:20px" src="../../imgs/notificacao.png">
                    </div>
                    <div class="col">
                        <div class="row" >
                            <h6 style="margin-left:50%">{{fisrtName}}</h6>
                            <img style="width:20px;height:20px;margin-left:20px" src="../../imgs/usuario-de-perfil.png">
                        </div>
                    </div>
                </div>
                
                
                   
            </div>
        </div>
        <br>
        <br>
        <br>
        <div class="row" style="margin-left:5%;" >
            <div class="col">
                <div class="box">
                    <h5>Próxima sessão</h5>
                    <div style="display: flex; justify-content: center;"> 
                        <table id="table1">
                            <tr>
                                <th>Data</th>
                                <th>Horário</th>
                                <th>Profissional</th>
                                <th>Programa</th>
                            </tr>
                            <tr>
                                <td>20/08</td>
                                <td>14:00</td>
                                <td>Giliani</td>
                                <td>Desenvolvimento profissional</td>
                            </tr>

                        </table>
                    </div>
                </div>
            </div>
            <div class="col" >
                <div class="box" id="boxSession">
                    
                   
                    <h3 style="margin-top:10%">Acessar sessão</h3>
                </div>
            </div>
           


        </div>
        <br>
        <br>
        <div class="row"  style="margin-left:5%">
            <div class="col">
                <div id="boxUser">
                    <div style="display: flex; justify-content: center;"> 
                        <img  src="../../imgs/foto_perfil.jpg" width="300px">
                          
                    </div>
                    <br>
                    <br>
                    <h2 style="text-align:center">{{userName}}</h2>
                    
                </div>
            </div>
            <div class="col">
                <div id="boxClient">
                    <br>
                    <table style="width:100%">
                        <tr>
                            <th>Plano de ação</th>
                            <th>Data de inicio</th>
                            <th>Prazo de conclusão</th>
                        </tr>
                        <tr>
                            <td>Fazer exercicio</td>
                            <td>01/06/2021</td>
                            <td>30/06/2021</td>
                            
                        </tr>
                        <tr>
                            <td>Ler 1 livro</td>
                            <td>01/06/2021</td>
                            <td>30/06/2021</td>
                            
                        </tr>
                        <tr>
                            <td>Fazer uma viagem</td>
                            <td>01/06/2021</td>
                            <td>30/06/2021</td>
                            
                        </tr>
                   
                    </table>
                    <div style="display: flex; justify-content: center;"> 
                         <span id="set" style="font-size:30px;color:#D3D3D3">&#8249; &#8250;</span>
                    </div>
                </div>
            </div>
        </div>  
        
    </div>
</template>
<script>
export default{
    mounted()
    {
        this.userName = localStorage.getItem('userName');
        this.fisrtName = this.userName.split(' ',1);
        this.fisrtName = this.fisrtName[0];
        
    },
    data()
    {
        return{
            userName:"",
            fisrtName:""
        }
    }
}


</script>
<style scoped>
#boxSession
{
    background-color: #983e7b; 
    color:#D3D3D3;
    opacity: 0.5;
}
#table1 
{
    width:100%;
    height:80px;
}
#table1 tr td
{
    border-style:solid;
    border-left-color:transparent;
    border-top-color:#D3D3D3;
    border-right-color:transparent;
    border-bottom-color:transparent;
    border-width: 1px;
   
    
}
#table1 tr th
{
    color:#D3D3D3;
    background-color:#983e7b; 
    font-weight: normal;
}
#set
{
    cursor: pointer;
}
table tr th
{
    font-size:12px;
    text-align:center;
}
table tr td
{
    text-align:center;
}
#boxClient
{
    border-style: solid;
    background-color: white;
    border-radius: 15px;
    border-color: #D3D3D3;
    margin-right: 8%;
    height:400px;
    border-width: 1px;
}
#boxUser
{
    border-style: solid;
    background-color: white;
    border-radius: 15px;
    border-color: #D3D3D3;
    
    width:450px;
    height:400px;
    border-width: 1px;
}

.box
{
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
    height:120px;
    width:450px;
    border-radius: 15px;
    text-align: center;
    color: black;
}

</style>