<template>
  <div id="pageSpecialist">
    <perfil></perfil>
    <barraLateral></barraLateral>
  </div>
</template>
<script>
import barraLateral from "../components/barra-lateral-cliente.vue";
import perfil from "../components/pagina-perfil-cliente.vue";
export default {
  components: {
    barraLateral,
    perfil,
  },
};
</script>
<style scoped>
#pageSpecialist {
  width: 100%;
  height: 2000px;
  margin-top: -1%;
}
</style>