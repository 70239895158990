<template>
  <div id="header2">
          
        <div class="row" >
            <div id="colText" class="col">
                <h1 style="margin-top:10%;"><b>O que te <span>motiva</span></b>
                </h1>
                <h1><b>a entrar em ação?</b></h1><br>
                <h5>Buscamos semear o melhor para a sua vida todos os dias trazendo soluções inovadores. </h5>
            </div>
            <div id="colImage" style="height:50vh;z-index:4" class="col">
<img id="imgConheca" src="../../imgs/img_conheca.png">
            </div>
        </div><br><br>
        <div style="width:100%;z-index:0;position:relative;">
            <h1 id="sentence"><b>QUEM SOMOS</b></h1>
        </div>
        <div style="z-index:1;position:relative;"><img id="ondulatoria" src="../../imgs/ondulatoria.png"></div>
        <div id="divBtn">
            <router-link to="/parceiro" class="nav-link"><button class="btnTO ">CONHEÇA MAIS A PLATAFORMA</button>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
  
};
</script>

<style scoped>
@media (min-width: 1260px)
{
    h1 {
            margin-left: 10%;
            color: #F6C56F;
            font-size: 50px;
            font-family: Montserrat;
    }

    h5 {
            margin-left: 10%;
            color: white;
            font-size: 20px;
    }

    #imgConheca {
            position:absolute;
            left:20%;
            width: 80%;
            height: 400px;

    }

    #divBtn{
            position: relative;
            z-index: 3;
            display: flex;
            justify-content: center;
            margin-top: -8%;
    }

    #ondulatoria{
            margin-top: -8%;
            width: 100%;
    }

    #sentence {
            color: white;
            width:100vw;
            font-size: 178px;
            opacity: 0.2;
            margin-left:1%;
            
            margin-top:-2%;

    }

    .btnTO {
            font-size:15px;
            background-image: linear-gradient(180deg, #fd5392, #f86f64);
            color: white;
            border: solid transparent;
            width: 350px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            border-radius: 15px;
            font-family: Montserrat;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 1px;
            padding-right: 1px;
    }

    video {
            width: 100%;
    }

    #text {
            margin: 20px;
    }

    #header2 {
            width: 100%;
            height: 520px;
            background-color: #BB6081;
            font-size: 62.5%;
    }

    span {
            color: #F6C56F;
            font-weight: bold;
            font-size: 60px;
            font-family: Monotype Corsiva;

    }

    .cover {
            color: white;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 30%;
            left: 0;
    }

    .btnTO:hover {
            -webkit-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2);

    }

    #header {
            width: 100%;

    }

    * {
            margin: 0;
            padding: 0;
    }
}

@media (min-width: 1200px) and (max-width:1259px)
{
    h1 {
            margin-left: 10%;
            color: #F6C56F;
            font-size: 50px;
            font-family: Montserrat;
    }

    h5 {
            margin-left: 10%;
            color: white;
            font-size: 20px;
    }

    #imgConheca {
            position:absolute;
            left:20%;
            width: 80%;
            height: 400px;

    }

    #divBtn{
            position: relative;
            z-index: 3;
            display: flex;
            justify-content: center;
            margin-top: -8%;
    }

    #ondulatoria{
            margin-top: -8%;
            width: 100%;
    }

    #sentence {
            color: white;
            width:100vw;
            font-size: 160px;
            opacity: 0.2;
            margin-left:1%;
            

    }

    .btnTO {
            background-image: linear-gradient(180deg, #fd5392, #f86f64);
            color: white;
            border: solid transparent;
            width: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            border-radius: 10px;
            font-family: Montserrat;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 1px;
            padding-right: 1px;
    }

    video {
            width: 100%;
    }

    #text {
            margin: 20px;
    }

    #header2 {
            width: 100%;
            height: 520px;
            background-color: #BB6081;
    }

    span {
            color: #F6C56F;
            font-weight: bold;
            font-size: 60px;
            font-family: Monotype Corsiva;

    }

    .cover {
            color: white;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 30%;
            left: 0;
    }

    .btnTO:hover {
            -webkit-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2);

    }

    #header {
            width: 100%;

    }

    * {
            margin: 0;
            padding: 0;
    }
}

@media (min-width: 1125px) and (max-width:1199px)
{
    h1 {
            margin-left: 10%;
            color: #F6C56F;
            font-size: 50px;
            font-family: Montserrat;
    }

    h5 {
            margin-left: 10%;
            color: white;
            font-size: 20px;
    }

    #imgConheca {
            position:absolute;
            left:20%;
            width: 80%;
            height: 400px;

    }

    #divBtn{
            position: relative;
            z-index: 3;
            display: flex;
            justify-content: center;
            margin-top: -8%;
    }

    #ondulatoria{
            margin-top: -8%;
            width: 100%;
    }

    #sentence {
            color: white;
            width:100vw;
            font-size: 150px;
            opacity: 0.2;
            margin-left:2%;
            margin-top:2%;
            

    }

    .btnTO {
            background-image: linear-gradient(180deg, #fd5392, #f86f64);
            color: white;
            border: solid transparent;
            width: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            border-radius: 10px;
            font-family: Montserrat;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 1px;
            padding-right: 1px;
    }

    video {
            width: 100%;
    }

    #text {
            margin: 20px;
    }

    #header2 {
            width: 100%;
            height: 520px;
            background-color: #BB6081;
    }

    span {
            color: #F6C56F;
            font-weight: bold;
            font-size: 60px;
            font-family: Monotype Corsiva;

    }

    .cover {
            color: white;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 30%;
            left: 0;
    }

    .btnTO:hover {
            -webkit-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2);

    }

    #header {
            width: 100%;

    }

    * {
            margin: 0;
            padding: 0;
    }
}

@media (min-width: 1040px) and (max-width:1124px)
{
    h1 {
            margin-left: 10%;
            color: #F6C56F;
            font-size: 50px;
            font-family: Montserrat;
    }

    h5 {
            margin-left: 10%;
            color: white;
            font-size: 20px;
    }

    #imgConheca {
            position:absolute;
            left:20%;
            width: 80%;
            height: 400px;

    }

    #divBtn{
            position: relative;
            z-index: 3;
            display: flex;
            justify-content: center;
            margin-top: -8%;
    }

    #ondulatoria{
            margin-top: -8%;
            width: 100%;
    }

    #sentence {
            color: white;
            width:100vw;
            font-size: 130px;
            opacity: 0.2;
            margin-left:6%;
            margin-top:5%;
            

    }

    .btnTO {
            background-image: linear-gradient(180deg, #fd5392, #f86f64);
            color: white;
            border: solid transparent;
            width: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            border-radius: 10px;
            font-family: Montserrat;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 1px;
            padding-right: 1px;
    }

    video {
            width: 100%;
    }

    #text {
            margin: 20px;
    }

    #header2 {
            width: 100%;
            height: 520px;
            background-color: #BB6081;
    }

    span {
            color: #F6C56F;
            font-weight: bold;
            font-size: 60px;
            font-family: Monotype Corsiva;

    }

    .cover {
            color: white;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 30%;
            left: 0;
    }

    .btnTO:hover {
            -webkit-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2);

    }

    #header {
            width: 100%;

    }

    * {
            margin: 0;
            padding: 0;
    }
}

@media (min-width: 938px) and (max-width:1039px)
{
    h1 {
            margin-left: 10%;
            color: #F6C56F;
            font-size: 50px;
            font-family: Montserrat;
    }

    h5 {
            margin-left: 10%;
            color: white;
            font-size: 20px;
    }

    #imgConheca {
            position:absolute;
            left:10%;
            width: 90%;
            height: 400px;

    }

    #divBtn{
            position: relative;
            z-index: 3;
            display: flex;
            justify-content: center;
            margin-top: -8%;
    }

    #ondulatoria{
            margin-top: -5%;
            width: 100%;
    }

    #sentence {
            color: white;
            width:80%;
            font-size: 100px;
            opacity: 0.2;
            text-align:center;
            margin-left:10%;
            margin-top:0px;
            
            

    }

    .btnTO {
            background-image: linear-gradient(180deg, #fd5392, #f86f64);
            color: white;
            border: solid transparent;
            width: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            border-radius: 10px;
            font-family: Montserrat;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 1px;
            padding-right: 1px;
    }

    video {
            width: 100%;
    }

    #text {
            margin: 20px;
    }

    #header2 {
            width: 100%;
            height: 520px;
            background-color: #BB6081;
    }

    span {
            color: #F6C56F;
            font-weight: bold;
            font-size: 60px;
            font-family: Monotype Corsiva;

    }

    .cover {
            color: white;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 30%;
            left: 0;
    }

    .btnTO:hover {
            -webkit-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2);

    }

    #header {
            width: 100%;

    }

    * {
            margin: 0;
            padding: 0;
    }
}

@media (min-width: 882px) and (max-width:937px)
{
    h1 {
            margin-left: 10%;
            color: #F6C56F;
            font-size: 50px;
            font-family: Montserrat;
    }

    h5 {
            margin-left: 10%;
            color: white;
            font-size: 20px;
    }

    #imgConheca {
            position:absolute;
            left:10%;
            width: 90%;
            height: 400px;

    }

    #divBtn{
            position: relative;
            z-index: 3;
            display: flex;
            justify-content: center;
            margin-top: -8%;
    }

    #ondulatoria{
            margin-top: -6%;
            width: 100%;
    }

    #sentence {
            color: white;
            width:80%;
            font-size: 80px;
            opacity: 0.2;
            text-align:center;
            margin-left:10%;
            margin-top:5%;
            

    }

    .btnTO {
            background-image: linear-gradient(180deg, #fd5392, #f86f64);
            color: white;
            border: solid transparent;
            width: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            border-radius: 10px;
            font-family: Montserrat;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 1px;
            padding-right: 1px;
    }

    video {
            width: 100%;
    }

    #text {
            margin: 20px;
    }

    #header2 {
            width: 100%;
            height: 520px;
            background-color: #BB6081;
    }

    span {
            color: #F6C56F;
            font-weight: bold;
            font-size: 60px;
            font-family: Monotype Corsiva;

    }

    .cover {
            color: white;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 30%;
            left: 0;
    }

    .btnTO:hover {
            -webkit-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2);

    }

    #header {
            width: 100%;

    }

    * {
            margin: 0;
            padding: 0;
    }
}

@media (min-width: 768px) and (max-width:881px)
{
    h1 {
            margin-left: 10%;
            color: #F6C56F;
            font-size: 50px;
            font-family: Montserrat;
    }

    h5 {
            margin-left: 10%;
            color: white;
            font-size: 20px;
    }

    #imgConheca {
            position:absolute;
            left:0%;
            width: 100%;
            height: 400px;

    }

    #divBtn{
            position: relative;
            z-index: 3;
            display: flex;
            justify-content: center;
            margin-top: -8%;
    }

    #ondulatoria{
            margin-top: -4%;
            width: 100%;
    }

    #sentence {
            color: white;
            width:80%;
            font-size: 60px;
            opacity: 0.2;
            text-align:center;
            margin-left:10%;
            margin-top:2%;
            

    }

    .btnTO {
            background-image: linear-gradient(180deg, #fd5392, #f86f64);
            color: white;
            border: solid transparent;
            width: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            border-radius: 10px;
            font-family: Montserrat;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 1px;
            padding-right: 1px;
    }

    video {
            width: 100%;
    }

    #text {
            margin: 20px;
    }

    #header2 {
            width: 100%;
            height: 520px;
            background-color: #BB6081;
    }

    span {
            color: #F6C56F;
            font-weight: bold;
            font-size: 60px;
            font-family: Monotype Corsiva;

    }

    .cover {
            color: white;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 30%;
            left: 0;
    }

    .btnTO:hover {
            -webkit-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2);

    }

    #header {
            width: 100%;

    }

    * {
            margin: 0;
            padding: 0;
    }
}

@media (min-width: 700px) and (max-width:767px)
{
    #colText
    {
        width:100%;
    }
    #colImage
    {
        display:none;
    }
    h1 {
            margin-left: 10%;
            color: #F6C56F;
            font-size: 50px;
            font-family: Montserrat;
    }

    h5 {
            margin-right:10%;
            margin-left: 10%;
            color: white;
            font-size: 20px;
    }

    #imgConheca {
            display:none;

    }

    #divBtn{
            position: relative;
            z-index: 3;
            display: flex;
            justify-content: center;
            margin-top: -8%;
    }

    #ondulatoria{
            margin-top: -4%;
            width: 100%;
    }

    #sentence {
            color: white;
            width:80%;
            font-size: 60px;
            opacity: 0.2;
            text-align:center;
            margin-left:10%;
            margin-top:3%;
            

    }

    .btnTO {
            background-image: linear-gradient(180deg, #fd5392, #f86f64);
            color: white;
            border: solid transparent;
            width: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            border-radius: 10px;
            font-family: Montserrat;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 1px;
            padding-right: 1px;
    }

    video {
            width: 100%;
    }

    #text {
            margin: 20px;
    }

    #header2 {
            width: 100%;
            height: 455px;
            background-color: #BB6081;
    }

    span {
            color: #F6C56F;
            font-weight: bold;
            font-size: 60px;
            font-family: Monotype Corsiva;

    }

    .cover {
            color: white;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 30%;
            left: 0;
    }

    .btnTO:hover {
            -webkit-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2);

    }

    #header {
            width: 100%;

    }

    * {
            margin: 0;
            padding: 0;
    }
}

@media (min-width: 632px) and (max-width:699px)
{
    #colText
    {
        width:100%;
    }
    #colImage
    {
        display:none;
    }
    h1 {
            margin-left: 10%;
            color: #F6C56F;
            font-size: 50px;
            font-family: Montserrat;
    }

    h5 {
            margin-right:10%;
            margin-left: 10%;
            color: white;
            font-size: 20px;
    }

    #imgConheca {
            display:none;

    }

    #divBtn{
            position: relative;
            z-index: 3;
            display: flex;
            justify-content: center;
            margin-top: -10%;
    }

    #ondulatoria{
            margin-top: -4%;
            width: 100%;
    }

    #sentence {
            color: white;
            width:80%;
            font-size: 60px;
            opacity: 0.2;
            text-align:center;
            margin-left:10%;
            margin-top:5%;
            

    }

    .btnTO {
            background-image: linear-gradient(180deg, #fd5392, #f86f64);
            color: white;
            border: solid transparent;
            width: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            border-radius: 10px;
            font-family: Montserrat;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 1px;
            padding-right: 1px;
    }

    video {
            width: 100%;
    }

    #text {
            margin: 20px;
    }

    #header2 {
            width: 100%;
            height: 455px;
            background-color: #BB6081;
    }

    span {
            color: #F6C56F;
            font-weight: bold;
            font-size: 60px;
            font-family: Monotype Corsiva;

    }

    .cover {
            color: white;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 30%;
            left: 0;
    }

    .btnTO:hover {
            -webkit-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2);

    }

    #header {
            width: 100%;

    }

    * {
            margin: 0;
            padding: 0;
    }
}

@media (min-width: 600px) and (max-width:631px)
{
    #colText
    {
        width:100%;
    }
    #colImage
    {
        display:none;
    }
    h1 {
            margin-left: 10%;
            color: #F6C56F;
            font-size: 50px;
            font-family: Montserrat;
    }

    h5 {
            margin-right:10%;
            margin-left: 10%;
            color: white;
            font-size: 20px;
    }

    #imgConheca {
            display:none;

    }

    #divBtn{
            position: relative;
            z-index: 3;
            display: flex;
            justify-content: center;
            margin-top: -10%;
    }

    #ondulatoria{
            margin-top: -4%;
            width: 100%;
    }

    #sentence {
            color: white;
            width:80%;
            font-size: 60px;
            opacity: 0.2;
            text-align:center;
            margin-left:10%;
            margin-top:5%;
            

    }

    .btnTO {
            background-image: linear-gradient(180deg, #fd5392, #f86f64);
            color: white;
            border: solid transparent;
            width: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            border-radius: 10px;
            font-family: Montserrat;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 1px;
            padding-right: 1px;
    }

    video {
            width: 100%;
    }

    #text {
            margin: 20px;
    }

    #header2 {
            width: 100%;
            height: 448px;
            background-color: #BB6081;
    }

    span {
            color: #F6C56F;
            font-weight: bold;
            font-size: 60px;
            font-family: Monotype Corsiva;

    }

    .cover {
            color: white;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 30%;
            left: 0;
    }

    .btnTO:hover {
            -webkit-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2);

    }

    #header {
            width: 100%;

    }

    * {
            margin: 0;
            padding: 0;
    }
}

@media (min-width:565px) and (max-width:599px)
{
    #colText
    {
        width:100%;
    }
    #colImage
    {
        display:none;
    }
    h1 {
            margin-left: 10%;
            color: #F6C56F;
            font-size: 50px;
            font-family: Montserrat;
    }

    h5 {
            margin-right:10%;
            margin-left: 10%;
            color: white;
            font-size: 20px;
    }

    #imgConheca {
            display:none;

    }

    #divBtn{
            position: relative;
            z-index: 3;
            display: flex;
            justify-content: center;
            margin-top: -10%;
    }

    #ondulatoria{
            margin-top: -4%;
            width: 100%;
    }

    #sentence {
            color: white;
            width:80%;
            font-size: 60px;
            opacity: 0.2;
            text-align:center;
            margin-left:10%;
            margin-top:5%;
            

    }

    .btnTO {
            background-image: linear-gradient(180deg, #fd5392, #f86f64);
            color: white;
            border: solid transparent;
            width: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            border-radius: 10px;
            font-family: Montserrat;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 1px;
            padding-right: 1px;
    }

    video {
            width: 100%;
    }

    #text {
            margin: 20px;
    }

    #header2 {
            width: 100%;
            height: 440px;
            background-color: #BB6081;
    }

    span {
            color: #F6C56F;
            font-weight: bold;
            font-size: 60px;
            font-family: Monotype Corsiva;

    }

    .cover {
            color: white;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 30%;
            left: 0;
    }

    .btnTO:hover {
            -webkit-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2);

    }

    #header {
            width: 100%;

    }

    * {
            margin: 0;
            padding: 0;
    }
}

@media (min-width:534px) and (max-width:564px)
{
    #colText
    {
        width:100%;
    }
    #colImage
    {
        display:none;
    }
    h1 {
            margin-left: 10%;
            color: #F6C56F;
            font-size: 50px;
            font-family: Montserrat;
    }

    h5 {
            margin-right:10%;
            margin-left: 10%;
            color: white;
            font-size: 20px;
    }

    #imgConheca {
            display:none;

    }

    #divBtn{
            position: relative;
            z-index: 3;
            display: flex;
            justify-content: center;
            margin-top: -10%;
    }

    #ondulatoria{
            margin-top: -4%;
            width: 100%;
    }

    #sentence {
            color: white;
            width:80%;
            font-size: 50px;
            opacity: 0.2;
            text-align:center;
            margin-left:10%;
            margin-top:5%;
            

    }

    .btnTO {
            background-image: linear-gradient(180deg, #fd5392, #f86f64);
            color: white;
            border: solid transparent;
            width: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            border-radius: 10px;
            font-family: Montserrat;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 1px;
            padding-right: 1px;
    }

    video {
            width: 100%;
    }

    #text {
            margin: 20px;
    }

    #header2 {
            width: 100%;
            height: 425px;
            background-color: #BB6081;
    }

    span {
            color: #F6C56F;
            font-weight: bold;
            font-size: 60px;
            font-family: Monotype Corsiva;

    }

    .cover {
            color: white;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 30%;
            left: 0;
    }

    .btnTO:hover {
            -webkit-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2);

    }

    #header {
            width: 100%;

    }

    * {
            margin: 0;
            padding: 0;
    }
}

@media (min-width:495px) and (max-width:533px)
{
    #colText
    {
        width:100%;
    }
    #colImage
    {
        display:none;
    }
    h1 {
            margin-right:10%;
            margin-left: 10%;
            color: #F6C56F;
            font-size: 50px;
            font-family: Montserrat;
    }

    h5 {
            margin-right:10%;
            margin-left: 10%;
            color: white;
            font-size: 20px;
    }

    #imgConheca {
            display:none;

    }

    #divBtn{
            position: relative;
            z-index: 3;
            display: flex;
            justify-content: center;
            margin-top: -10%;
    }

    #ondulatoria{
            margin-top: -3%;
            width: 100%;
    }

    #sentence {
            color: white;
            width:80%;
            font-size: 30px;
            opacity: 0.2;
            text-align:center;
            margin-left:10%;
            margin-top:0%;
            

    }

    .btnTO {
            background-image: linear-gradient(180deg, #fd5392, #f86f64);
            color: white;
            border: solid transparent;
            width: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            border-radius: 10px;
            font-family: Montserrat;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 1px;
            padding-right: 1px;
    }

    video {
            width: 100%;
    }

    #text {
            margin: 20px;
    }

    #header2 {
            width: 100%;
            height: 427px;
            background-color: #BB6081;
    }

    span {
            color: #F6C56F;
            font-weight: bold;
            font-size: 60px;
            font-family: Monotype Corsiva;

    }

    .cover {
            color: white;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 30%;
            left: 0;
    }

    .btnTO:hover {
            -webkit-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2);

    }

    #header {
            width: 100%;

    }

    * {
            margin: 0;
            padding: 0;
    }
}

@media (min-width:495px) and (max-width:533px)
{
    #colText
    {
        width:100%;
    }
    #colImage
    {
        display:none;
    }
    h1 {
            margin-right:10%;
            margin-left: 10%;
            color: #F6C56F;
            font-size: 50px;
            font-family: Montserrat;
    }

    h5 {
            margin-right:10%;
            margin-left: 10%;
            color: white;
            font-size: 20px;
    }

    #imgConheca {
            display:none;

    }

    #divBtn{
            position: relative;
            z-index: 3;
            display: flex;
            justify-content: center;
            margin-top: -10%;
    }

    #ondulatoria{
            margin-top: -3%;
            width: 100%;
    }

    #sentence {
            color: white;
            width:80%;
            font-size: 30px;
            opacity: 0.2;
            text-align:center;
            margin-left:10%;
            margin-top:0%;
            

    }

    .btnTO {
            background-image: linear-gradient(180deg, #fd5392, #f86f64);
            color: white;
            border: solid transparent;
            width: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            border-radius: 10px;
            font-family: Montserrat;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 1px;
            padding-right: 1px;
    }

    video {
            width: 100%;
    }

    #text {
            margin: 20px;
    }

    #header2 {
            width: 100%;
            height: 427px;
            background-color: #BB6081;
    }

    span {
            color: #F6C56F;
            font-weight: bold;
            font-size: 60px;
            font-family: Monotype Corsiva;

    }

    .cover {
            color: white;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 30%;
            left: 0;
    }

    .btnTO:hover {
            -webkit-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2);

    }

    #header {
            width: 100%;

    }

    * {
            margin: 0;
            padding: 0;
    }
}

@media (min-width:480px) and (max-width:494px)
{
    #colText
    {
        width:100%;
    }
    #colImage{
        display:none;

    }
    h1 {
            margin-right:10%;
            margin-left: 10%;
            color: #F6C56F;
            font-size: 40px;
            font-family: Montserrat;
    }

    h5 {
            margin-right:10%;
            margin-left: 10%;
            color: white;
            font-size: 20px;
    }

    #imgConheca {
            display:none;

    }

    #divBtn{
            position: relative;
            z-index: 3;
            display: flex;
            justify-content: center;
            margin-top: -10%;
    }

    #ondulatoria{
            margin-top: -3%;
            width: 100%;
    }

    #sentence {
            color: white;
            width:80%;
            font-size: 30px;
            opacity: 0.2;
            text-align:center;
            margin-left:10%;
            margin-top:10%;
            

    }

    .btnTO {
            font-size:10px;
            background-image: linear-gradient(180deg, #fd5392, #f86f64);
            color: white;
            border: solid transparent;
            width: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            border-radius: 10px;
            font-family: Montserrat;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 1px;
            padding-right: 1px;
    }

    video {
            width: 100%;
    }

    #text {
            margin: 20px;
    }

    #header2 {
            width: 100%;
            height: 425px;
            background-color: #BB6081;
    }

    span {
            color: #F6C56F;
            font-weight: bold;
            font-size: 60px;
            font-family: Monotype Corsiva;

    }

    .cover {
            color: white;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 30%;
            left: 0;
    }

    .btnTO:hover {
            -webkit-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2);

    }

    #header {
            width: 100%;

    }

    * {
            margin: 0;
            padding: 0;
    }
}

@media (min-width:435px) and (max-width:479px)
{
    #colText{
        width:100%;
    }

    #colImage{
        display:none;
    }

    h1 {
            margin-right:10%;
            margin-left: 10%;
            color: #F6C56F;
            font-size: 20px;
            font-family: Montserrat;
    }

    h5 {
            margin-right:10%;
            margin-left: 10%;
            color: white;
            font-size: 16px;
    }

    #imgConheca {
            display:none;

    }

    #divBtn{
            position: relative;
            z-index: 3;
            display: flex;
            justify-content: center;
            margin-top: -10%;
    }

    #ondulatoria{
            margin-top: -3%;
            width: 100%;
    }

    #sentence {
            color: white;
            width:80%;
            font-size: 30px;
            opacity: 0.2;
            text-align:center;
            margin-left:10%;
            margin-top:1%;
            

    }

    .btnTO {
            font-size:10px;
            background-image: linear-gradient(180deg, #fd5392, #f86f64);
            color: white;
            border: solid transparent;
            width: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            border-radius: 10px;
            font-family: Montserrat;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 1px;
            padding-right: 1px;
    }

    video {
            width: 100%;
    }

    #text {
            margin: 20px;
    }

    #header2 {
            width: 100%;
            height: 405px;
            background-color: #BB6081;
    }

    span {
            color: #F6C56F;
            font-weight: bold;
            font-size: 60px;
            font-family: Monotype Corsiva;

    }

    .cover {
            color: white;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 30%;
            left: 0;
    }

    .btnTO:hover {
            -webkit-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2);

    }

    #header {
            width: 100%;

    }

    * {
            margin: 0;
            padding: 0;
    }
}

@media (min-width:405px) and (max-width:434px)
{
    #colText{
        width:100%;
    }

    #colImage{
        display:none;
    }
    
    h1 {
            margin-right:10%;
            margin-left: 10%;
            color: #F6C56F;
            font-size: 30px;
            font-family: Montserrat;
    }

    h5 {
            margin-right:10%;
            margin-left: 10%;
            color: white;
            font-size: 20px;
    }

    #imgConheca {
            display:none;

    }

    #divBtn{
            position: relative;
            z-index: 3;
            display: flex;
            justify-content: center;
            margin-top: -10%;
    }

    #ondulatoria{
            margin-top: -3%;
            width: 100%;
    }

    #sentence {
            color: white;
            width:80%;
            font-size: 30px;
            opacity: 0.2;
            text-align:center;
            margin-left:10%;
            margin-top:5%;
            

    }

    .btnTO {
            font-size:10px;
            background-image: linear-gradient(180deg, #fd5392, #f86f64);
            color: white;
            border: solid transparent;
            width: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            border-radius: 10px;
            font-family: Montserrat;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 1px;
            padding-right: 1px;
    }

    video {
            width: 100%;
    }

    #text {
            margin: 20px;
    }

    #header2 {
            width: 100%;
            height: 375px;
            background-color: #BB6081;
    }

    span {
            color: #F6C56F;
            font-weight: bold;
            font-size: 60px;
            font-family: Monotype Corsiva;

    }

    .cover {
            color: white;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 30%;
            left: 0;
    }

    .btnTO:hover {
            -webkit-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2);

    }

    #header {
            width: 100%;

    }

    * {
            margin: 0;
            padding: 0;
    }
}

@media (min-width:375px) and (max-width:404px)
{
    #colText
    {
        width:100%;
    }
    #colImage
    {
        display:none;
    }
    h1 {
            margin-right:10%;
            margin-left: 10%;
            color: #F6C56F;
            font-size: 30px;
            font-family: Montserrat;
    }

    h5 {
            margin-right:10%;
            margin-left: 10%;
            color: white;
            font-size: 20px;
    }

    #imgConheca {
            display:none;

    }

    #divBtn{
            position: relative;
            z-index: 3;
            display: flex;
            justify-content: center;
            margin-top: -10%;
    }

    #ondulatoria{
            margin-top: -2%;
            width: 100%;
    }

    #sentence {
            color: white;
            width:80%;
            font-size: 25px;
            opacity: 0.2;
            text-align:center;
            margin-left:10%;
            margin-top:5%;
            

    }

    .btnTO {
            font-size:8px;
            background-image: linear-gradient(180deg, #fd5392, #f86f64);
            color: white;
            border: solid transparent;
            width: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            border-radius: 10px;
            font-family: Montserrat;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 1px;
            padding-right: 1px;
    }

    video {
            width: 100%;
    }

    #text {
            margin: 20px;
    }

    #header2 {
            width: 100%;
            height: 365px;
            background-color: #BB6081;
    }

    span {
            color: #F6C56F;
            font-weight: bold;
            font-size: 60px;
            font-family: Monotype Corsiva;

    }

    .cover {
            color: white;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 30%;
            left: 0;
    }

    .btnTO:hover {
            -webkit-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2);

    }

    #header {
            width: 100%;

    }

    * {
            margin: 0;
            padding: 0;
    }
}

@media (min-width:358px) and (max-width:374px)
{
    #colText
    {
        width:100%;
    }
    #colImage
    {
        display:none;
    }
    h1 {
            margin-right:10%;
            margin-left: 10%;
            color: #F6C56F;
            font-size: 25px;
            font-family: Montserrat;
    }

    h5 {
            margin-right:10%;
            margin-left: 10%;
            color: white;
            font-size: 15px;
    }

    #imgConheca {
            display:none;

    }

    #divBtn{
            position: relative;
            z-index: 3;
            display: flex;
            justify-content: center;
            margin-top: -10%;
    }

    #ondulatoria{
            margin-top: -3%;
            width: 100%;
    }

    #sentence {
            color: white;
            width:80%;
            font-size: 25px;
            opacity: 0.2;
            text-align:center;
            margin-left:10%;
            margin-top:5%;
            

    }

    .btnTO {
            font-size:8px;
            background-image: linear-gradient(180deg, #fd5392, #f86f64);
            color: white;
            border: solid transparent;
            width: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            border-radius: 10px;
            font-family: Montserrat;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 1px;
            padding-right: 1px;
    }

    video {
            width: 100%;
    }

    #text {
            margin: 20px;
    }

    #header2 {
            width: 100%;
            height: 306px;
            background-color: #BB6081;
    }

    span {
            color: #F6C56F;
            font-weight: bold;
            font-size: 35px;
            font-family: Monotype Corsiva;

    }

    .cover {
            color: white;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 30%;
            left: 0;
    }

    .btnTO:hover {
            -webkit-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2);

    }

    #header {
            width: 100%;

    }

    * {
            margin: 0;
            padding: 0;
    }
}

@media (min-width:320px) and (max-width:357px)
{
    #colText
    {
        width:100%;
    }
    #colImage
    {
        display:none;
    }
    h1 {
            margin-right:10%;
            margin-left: 10%;
            color: #F6C56F;
            font-size: 25px;
            font-family: Montserrat;
    }

    h5 {
            margin-right:10%;
            margin-left: 10%;
            color: white;
            font-size: 15px;
    }

    #imgConheca {
            display:none;

    }

    #divBtn{
            position: relative;
            z-index: 3;
            display: flex;
            justify-content: center;
            margin-top: -10%;
    }

    #ondulatoria{
            margin-top: -3%;
            width: 100%;
    }

    #sentence {
            color: white;
            width:80%;
            font-size: 25px;
            opacity: 0.2;
            text-align:center;
            margin-left:10%;
            margin-top:5%;
            

    }

    .btnTO {
            font-size:8px;
            background-image: linear-gradient(180deg, #fd5392, #f86f64);
            color: white;
            border: solid transparent;
            width: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            border-radius: 10px;
            font-family: Montserrat;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 1px;
            padding-right: 1px;
    }

    video {
            width: 100%;
    }

    #text {
            margin: 20px;
    }

    #header2 {
            width: 100%;
            height: 300px;
            background-color: #BB6081;
    }

    span {
            color: #F6C56F;
            font-weight: bold;
            font-size: 35px;
            font-family: Monotype Corsiva;

    }

    .cover {
            color: white;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 30%;
            left: 0;
    }

    .btnTO:hover {
            -webkit-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2);

    }

    #header {
            width: 100%;

    }

    * {
            margin: 0;
            padding: 0;
    }
}

@media (min-width:0px) and (max-width:319px)
{
    #colText
    {
        width:100%;
    }
    #colImage
    {
        display:none;
    }
    h1 {
            margin-right:10%;
            margin-left: 10%;
            color: #F6C56F;
            font-size: 25px;
            font-family: Montserrat;
    }

    h5 {
            margin-right:10%;
            margin-left: 10%;
            color: white;
            font-size: 15px;
    }

    #imgConheca {
            display:none;

    }

    #divBtn{
            position: relative;
            z-index: 3;
            display: flex;
            justify-content: center;
            margin-top: -10%;
    }

    #ondulatoria{
            margin-top: -3%;
            width: 100%;
    }

    #sentence {
            color: white;
            width:80%;
            font-size: 25px;
            opacity: 0.2;
            text-align:center;
            margin-left:10%;
            margin-top:5%;
            

    }

    .btnTO {
            font-size:8px;
            background-image: linear-gradient(180deg, #fd5392, #f86f64);
            color: white;
            border: solid transparent;
            width: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            border-radius: 10px;
            font-family: Montserrat;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 1px;
            padding-right: 1px;
    }

    video {
            width: 100%;
    }

    #text {
            margin: 20px;
    }

    #header2 {
            width: 100%;
            height: 295px;
            background-color: #BB6081;
    }

    span {
            color: #F6C56F;
            font-weight: bold;
            font-size: 35px;
            font-family: Monotype Corsiva;

    }

    .cover {
            color: white;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 30%;
            left: 0;
    }

    .btnTO:hover {
            -webkit-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2);

    }

    #header {
            width: 100%;

    }

    * {
            margin: 0;
            padding: 0;
    }
}

</style>