<template>
  <div id="pageMap">
    <listColaboradores></listColaboradores>
    <barraLateral></barraLateral>
  </div>
</template>
<script>
import barraLateral from "../components/barra-lateral-empresa.vue";
import listColaboradores from "../components/list-colaboradores.vue";

export default {
  components: {
    barraLateral,
    listColaboradores,
  },
};
</script>
<style scoped>
#pageMap {
  width: 100%;
  height: 4500px;
}
* {
  background-color: #f7f8fc;
  margin-top: -1%;
}
</style>