<template>
  <div style="margin-left: 10%; margin-right: 10%">
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    <div class="row">
      <div class="col">
        <br />

        <p style="text-align: justify; font-size: 20px">
          Os <span>JARDINEIROS</span>, por sua vez, são representados por todos
          os profissionais, líderes e gestores, que precisam estar atentos
          diariamente a estes importantes cuidados, a fim de minimizar as
          ameaças externas e proporcionar todo o cuidado e condições a cada
          sementinha, e então será possível o Florescimento do que há de melhor
          de cada um.
        </p>
      </div>

      <div class="col">
        <img style="margin-left: 26%" src="../../imgs/florescer.png" />
      </div>
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    <div
      style="
        position: relative;
        z-index: 3;
        display: flex;
        justify-content: center;
        margin-top: -120px;
      "
    >
      <router-link to="/parceiro" class="nav-link"
        ><button class="btnTO">
          CONHEÇA NOSSOS METÓDOS PARA EMPRESAS
        </button></router-link
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style scoped>
#textStory {
  text-align: center;
}
span {
  color: #bb6081;
  font-weight: bold;
}
.btnTO {
  background-image: linear-gradient(180deg, #fd5392, #f86f64);
  color: white;
  border: solid transparent;
  width: 420px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 10px;
  font-family: Montserrat;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 4px;
  padding-right: 4px;
}

.btnTO:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
</style>
