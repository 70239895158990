<template>
  <div id="text4">
    <br />
    <br />
    <br />
    <h1 id="title">Vantagens Exclusivas</h1>
    <p id="subtitle">
      Psicólogos e Coaches especialistas na área de Psicologia Positiva podem se
      tornar um de nossos parceiros
    </p>
    <br />
    <br />
    <br />
    <br />
    <br />

    <!-- quadros para desktop -->
    <div id="rowDesktop">
      <div class="row rowBoxs">
        <div class="col box">
          <div class="frame">
            <img src="../../imgs/Frame.png" />
          </div>
          <h2>Horários com agenda flexível</h2>
          <p class="textBox">
            Nossa equipe comercial te ajudará a montar a sua agenda e você terá
            a liberdade para atender seus clientes de onde estiver.
          </p>
        </div>
        <div style="margin-left: 32px" class="col box">
          <div class="frame">
            <img src="../../imgs/Frame-2.png" />
          </div>
          <h2>Atendimentos online e personalizados</h2>
          <p class="textBox">
            Você utilizará os assessments disponibilizado pela plataforma, afim
            de pradonizar os resultados voltado a psicologia positiva e trazer a
            excelência nos resultados.
          </p>
        </div>
        <div style="margin-left: 32px" class="col box">
          <div class="frame">
            <img src="../../imgs/Frame-4.png" />
          </div>
          <h2>Mentoria e Treinamento</h2>
          <p class="textBox">
            Suporte e acompanhamento semanal com a psicóloga e responsável
            técnica.
          </p>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div class="row rowBoxs">
        <div class="col box">
          <div class="frame">
            <img src="../../imgs/Frame-1.png" />
          </div>
          <h2>Baixo Custo</h2>
          <p class="textBox">
            Com a nossa plataforma você fica tranquilo(a), temos toda
            infraestrutura que você precisa para atender seus pacientes da
            melhor forma possível e com um custo relativamente mais barato.
          </p>
        </div>
        <div style="margin-left: 32px" class="col box">
          <div class="frame">
            <img src="../../imgs/Frame-3.png" />
          </div>
          <h2>Segurança e Sigilo</h2>
          <p class="textBox">
            Nossa plataforma possui certificados de seguranças internacionais
            impossibilitando o vazamento de informações durante a seção
          </p>
        </div>
        <div style="margin-left: 32px" class="col box">
          <div class="frame">
            <img src="../../imgs/Frame-5.png" />
          </div>
          <h2>Cientes que selecionamentos para você</h2>
          <p class="textBox">
            Tenha sua carteira de clientes exclusiva, sem se preocupar em correr
            atrás. Nós preparamos tudo para você!
          </p>
        </div>
      </div>
    </div>

    <!-- quadros para Mobile -->
    <div id="rowMobile">
      <div class="row rowBoxs">
        <div class="col box">
          <div class="frame">
            <img src="../../imgs/Frame.png" />
          </div>
          <h2>Horários com agenda flexível</h2>
          <p class="textBox">
            Nossa equipe comercial te ajudará a montar a sua agenda e você terá
            a liberdade para atender seus clientes de onde estiver.
          </p>
        </div>
        <div class="withMl col box">
          <div class="frame">
            <img src="../../imgs/Frame-2.png" />
          </div>
          <h2>Atendimentos online e personalizados</h2>
          <p class="textBox">
            Você utilizará os assessments disponibilizado pela plataforma, afim
            de pradonizar os resultados voltado a psicologia positiva e trazer a
            excelência nos resultados.
          </p>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div class="row rowBoxs">
        <div class="col box">
          <div class="frame">
            <img src="../../imgs/Frame-4.png" />
          </div>
          <h2>Mentoria e Treinamento</h2>
          <p class="textBox">
            Suporte e acompanhamento semanal com a psicóloga e responsável
            técnica.
          </p>
        </div>
        <div class="withMl col box">
          <div class="frame">
            <img src="../../imgs/Frame-1.png" />
          </div>
          <h2>Baixo Custo</h2>
          <p class="textBox">
            Com a nossa plataforma você fica tranquilo(a), temos toda
            infraestrutura que você precisa para atender seus pacientes da
            melhor forma possível e com um custo relativamente mais barato.
          </p>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div class="row rowBoxs">
        <div class="col box">
          <div class="frame">
            <img src="../../imgs/Frame-3.png" />
          </div>
          <h2>Segurança e Sigilo</h2>
          <p class="textBox">
            Nossa plataforma possui certificados de seguranças internacionais
            impossibilitando o vazamento de informações durante a seção
          </p>
        </div>
        <div class="withMl col box">
          <div class="frame">
            <img src="../../imgs/Frame-5.png" />
          </div>
          <h2>Cientes que selecionamentos para você</h2>
          <p class="textBox">
            Tenha sua carteira de clientes exclusiva, sem se preocupar em correr
            atrás. Nós preparamos tudo para você!
          </p>
        </div>
      </div>
    </div>

    <br />
    <br />
    <h1 id="lastTitle">
      Venha realizar atendimentos junto ao<br />nosso time de especialistas
    </h1>
    <br />
    <div id="divBtnRegister">
      <a :href="VUE_APP_URL_ESPECIALISTA + '/cadastro-especialista'"><button
          class="btn">CADASTRE-SE CONOSCO</button></a>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      VUE_APP_URL_ESPECIALISTA: process.env.VUE_APP_URL_ESPECIALISTA
    }
  }
};
</script>

<style scoped>
#divBtnRegister {
  margin-left: 40.5%;
  margin-right: 40.5%;
}

.withMl {
  margin-left: 32px;
}

#rowMobile {
  display: none;
}

button {
  font-family: Source Sans Pro;
  width: 263px;
  height: 52px;
  color: #692b55;
  border-style: solid;
  border-color: #fabe0a;
  background-color: #fabe0a;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding-left: 8px;
  border-width: 2px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-right: 8.3%;
}

button:hover {
  background-color: #fff051;
  border-color: #fff051;
}

#lastTitle {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
}

.textBox {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  color: #ab3585;
}

h2 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
  color: #ab3585;
}

img {
  width: 102px;
  height: 102px;
  margin-top: 9px;
}

.frame {
  margin-top: -60px;
  background-color: #ab3585;
  border-radius: 100%;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
}

.rowBoxs {
  margin-left: 7.8%;
  margin-right: 7.8%;
}

.box {
  background-color: white;
  width: 384px;
  height: 245px;
  background: #ffffff;
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 12px;
}

#subtitle {
  color: white;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
}

#text4 {
  height: 1166px;
  background-color: #ab3585;
}

h1 {
  color: white;
  text-align: center;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
}

p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

@media (min-width: 655px) and (max-width: 850px) {
  h2 {
    font-size: 12px;
  }

  .textBox {
    font-size: 10px;
  }

  #text4 {
    height: 1266px;
    background-color: #ab3585;
  }
}

@media (min-width: 450px) and (max-width: 654px) {
  #divBtnRegister {
    margin-left: 20.5%;
    margin-right: 20.5%;
  }

  #text4 {
    height: 1566px;
    background-color: #ab3585;
  }

  h2 {
    font-size: 12px;
  }

  .textBox {
    font-size: 10px;
  }

  #rowMobile {
    display: inline;
  }

  #rowDesktop {
    display: none;
  }

  #lastTitle {
    font-size: 20px;
  }
}

@media (min-width: 356px) and (max-width: 449px) {
  #divBtnRegister {
    margin-left: 20.5%;
    margin-right: 20.5%;
  }

  #text4 {
    height: 1566px;
    background-color: #ab3585;
  }

  h2 {
    font-size: 10px;
  }

  .textBox {
    font-size: 8px;
  }

  #rowMobile {
    display: inline;
  }

  #rowDesktop {
    display: none;
  }

  #lastTitle {
    font-size: 20px;
  }
}

@media (min-width: 0px) and (max-width: 355px) {
  #divBtnRegister {
    margin-left: 20.5%;
    margin-right: 20.5%;
  }

  .box {
    margin-bottom: 100px;
  }

  .withMl {
    margin-left: 0px;
  }

  #text4 {
    height: 2866px;
    background-color: #ab3585;
  }

  h2 {
    font-size: 12px;
  }

  .textBox {
    font-size: 10px;
  }

  #rowMobile {
    display: inline;
  }

  #rowDesktop {
    display: none;
  }

  #lastTitle {
    font-size: 20px;
  }
}
</style>
