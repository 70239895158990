<template>
  <div class="container-fluid">
    <!-- implementar com bootstrap -->
    <br />
    <br />
    <div id="boxAgenda" class="ml-5 mr-5 mt-3">
      <br />
      <h1 class="text-center">Agenda</h1>
      <div>
        <br />
        <br />

        <div>
          <div
            width="100%"
            class="row"
            style="
              height: 30px;
              display: flex;
              justify-content: center;
              background-color: #983e7b;
              color: white;
            "
          >
            <img
              v-on:click="previow()"
              v-if="mounth == 1 || mounth == 2"
              src="../../imgs/seta_esquerda.jpg"
              class="mt-2 mb-2 ml-2 mr-2"
              style="height: 10px"
            />
            <p>{{ mounthName[mounth] }}</p>
            <img
              v-on:click="next()"
              v-if="mounth == 0 || mounth == 1"
              src="../../imgs/seta_direita.jpg"
              class="mt-2 mb-2 ml-2 mr-2"
              style="height: 10px"
            />
          </div>
          <!-- janeiro -->
          <transition>
            <table
              key="table1"
              v-if="mounth == 0"
              style="width: 100%"
              class="
                table
                is-bordered is-striped is-narrow is-hoverable is-fullwidth
                has-text-centered
              "
            >
              <tr>
                <td>Dom</td>
                <td>Seg</td>
                <td>Ter</td>
                <td>Quar</td>
                <td>Quin</td>
                <td>Sex</td>
                <td>Sab</td>
              </tr>
              <tr>
                <td colspan="5"></td>
                <td id="1j" v-on:click="showTime(1)">1</td>
                <td id="2j" v-on:click="showTime(2)">2</td>
              </tr>
              <tr>
                <td id="3j" v-on:click="showTime(3)">3</td>
                <td id="4j" v-on:click="showTime(4)">4</td>
                <td id="5j" v-on:click="showTime(5)">5</td>
                <td id="6j" v-on:click="showTime(6)">6</td>
                <td id="7j" v-on:click="showTime(7)">7</td>
                <td id="8j" v-on:click="showTime(8)">8</td>
                <td id="9j" v-on:click="showTime(9)">9</td>
              </tr>
              <tr>
                <td id="10j" v-on:click="showTime(10)">10</td>
                <td id="11j" v-on:click="showTime(11)">11</td>
                <td id="12j" v-on:click="showTime(12)">12</td>
                <td id="13j" v-on:click="showTime(13)">13</td>
                <td id="14j" v-on:click="showTime(14)">14</td>
                <td id="15j" v-on:click="showTime(15)">15</td>
                <td id="16j" v-on:click="showTime(16)">16</td>
              </tr>
              <tr>
                <td id="17j" v-on:click="showTime(17)">17</td>
                <td id="18j" v-on:click="showTime(18)">18</td>
                <td id="19j" v-on:click="showTime(19)">19</td>
                <td id="20j" v-on:click="showTime(20)">20</td>
                <td id="21j" v-on:click="showTime(21)">21</td>
                <td id="22j" v-on:click="showTime(22)">22</td>
                <td id="23j" v-on:click="showTime(23)">23</td>
              </tr>
              <tr>
                <td id="24j" v-on:click="showTime(24)">24</td>
                <td id="25j" v-on:click="showTime(25)">25</td>
                <td id="26j" v-on:click="showTime(26)">26</td>
                <td id="27j" v-on:click="showTime(27)">27</td>
                <td id="28j" v-on:click="showTime(28)">28</td>
                <td id="29j" v-on:click="showTime(29)">29</td>
                <td id="30j" v-on:click="showTime(30)">30</td>
              </tr>
              <tr>
                <td id="31j" v-on:click="showTime(31)">31</td>
              </tr>
            </table>

            <!--fevereiro -->

            <table
              key="table2"
              v-if="mounth == 1"
              style="width: 100%"
              class="
                table
                is-bordered is-striped is-narrow is-hoverable is-fullwidth
                has-text-centered
              "
            >
              <tr>
                <td>Dom</td>
                <td>Seg</td>
                <td>Ter</td>
                <td>Quar</td>
                <td>Quin</td>
                <td>Sex</td>
                <td>Sab</td>
              </tr>
              <tr>
                <td></td>
                <td id="1f" v-on:click="showTime(1)">1</td>
                <td id="2f" v-on:click="showTime(2)">2</td>
                <td id="3f" v-on:click="showTime(3)">3</td>
                <td id="4f" v-on:click="showTime(4)">4</td>
                <td id="5f" v-on:click="showTime(5)">5</td>
                <td id="6f" v-on:click="showTime(6)">6</td>
              </tr>
              <tr>
                <td id="7f" v-on:click="showTime(7)">7</td>
                <td id="8f" v-on:click="showTime(8)">8</td>
                <td id="9f" v-on:click="showTime(9)">9</td>
                <td id="10f" v-on:click="showTime(10)">10</td>
                <td id="11f" v-on:click="showTime(11)">11</td>
                <td id="12f" v-on:click="showTime(12)">12</td>
                <td id="13f" v-on:click="showTime(13)">13</td>
              </tr>
              <tr>
                <td id="14f" v-on:click="showTime(14)">14</td>
                <td id="15f" v-on:click="showTime(15)">15</td>
                <td id="16f" v-on:click="showTime(16)">16</td>
                <td id="17f" v-on:click="showTime(17)">17</td>
                <td id="18f" v-on:click="showTime(18)">18</td>
                <td id="19f" v-on:click="showTime(19)">19</td>
                <td id="20f" v-on:click="showTime(20)">20</td>
              </tr>
              <tr>
                <td id="21f" v-on:click="showTime(21)">21</td>
                <td id="22f" v-on:click="showTime(22)">22</td>
                <td id="23f" v-on:click="showTime(23)">23</td>
                <td id="24f" v-on:click="showTime(24)">24</td>
                <td id="25f" v-on:click="showTime(25)">25</td>
                <td id="26f" v-on:click="showTime(26)">26</td>
                <td id="27f" v-on:click="showTime(27)">27</td>
              </tr>
              <tr>
                <td id="28f" v-on:click="showTime(28)">28</td>
              </tr>
              <tr></tr>
            </table>

            <!-- Março -->
            <table
              key="table3"
              v-if="mounth == 2"
              style="width: 100%"
              class="
                table
                is-bordered is-striped is-narrow is-hoverable is-fullwidth
                has-text-centered
              "
            >
              <tr>
                <td>Dom</td>
                <td>Seg</td>
                <td>Ter</td>
                <td>Quar</td>
                <td>Quin</td>
                <td>Sex</td>
                <td>Sab</td>
              </tr>
              <tr>
                <td></td>
                <td id="1m" v-on:click="showTime(1)">1</td>
                <td id="2m" v-on:click="showTime(2)">2</td>
                <td id="3m" v-on:click="showTime(3)">3</td>
                <td id="4m" v-on:click="showTime(4)">4</td>
                <td id="5m" v-on:click="showTime(5)">5</td>
                <td id="6m" v-on:click="showTime(6)">6</td>
              </tr>
              <tr>
                <td id="7m" v-on:click="showTime(7)">7</td>
                <td id="8m" v-on:click="showTime(8)">8</td>
                <td id="9m" v-on:click="showTime(9)">9</td>
                <td id="10m" v-on:click="showTime(10)">10</td>
                <td id="11m" v-on:click="showTime(11)">11</td>
                <td id="12m" v-on:click="showTime(12)">12</td>
                <td id="13m" v-on:click="showTime(13)">13</td>
              </tr>
              <tr>
                <td id="14m" v-on:click="showTime(14)">14</td>
                <td id="15m" v-on:click="showTime(15)">15</td>
                <td id="16m" v-on:click="showTime(16)">16</td>
                <td id="17m" v-on:click="showTime(17)">17</td>
                <td id="18m" v-on:click="showTime(18)">18</td>
                <td id="19m" v-on:click="showTime(19)">19</td>
                <td id="20m" v-on:click="showTime(20)">20</td>
              </tr>
              <tr>
                <td id="21m" v-on:click="showTime(21)">21</td>
                <td id="22m" v-on:click="showTime(22)">22</td>
                <td id="23m" v-on:click="showTime(23)">23</td>
                <td id="24m" v-on:click="showTime(24)">24</td>
                <td id="25m" v-on:click="showTime(25)">25</td>
                <td id="26m" v-on:click="showTime(26)">26</td>
                <td id="27m" v-on:click="showTime(27)">27</td>
              </tr>
              <tr>
                <td id="28m" v-on:click="showTime(28)">28</td>
                <td id="29m" v-on:click="showTime(29)">29</td>
                <td id="30m" v-on:click="showTime(30)">30</td>
                <td id="31m" v-on:click="showTime(31)">31</td>
              </tr>
              <tr></tr>
            </table>
          </transition>

          <div class="row">
            <img
              src="../../imgs/rosa.jpg"
              style="height: 10px; width: 10px"
              class="mt-2 mr-2"
            />
            <p width="20px">disponível para atendimento online</p>
          </div>
          <div class="row">
            <img
              src="../../imgs/rosa_claro.jpg"
              style="height: 10px; width: 10px"
              class="mt-2 mr-2"
            />
            <p width="20px">disponível para atendimento presencial</p>
          </div>
          <div class="row">
            <img
              src="../../imgs/roxo.jpg"
              style="height: 10px; width: 10px"
              class="mt-2 mr-2"
            />
            <p width="20px">dias com clientes agendados</p>
          </div>
          <br />

          <div v-html="text"></div>
          <br />
          <button class="btn" v-on:click="$root.pushState('pages-footer')">
            Edite sua agenda
          </button>
        </div>
        <br />
        <br />
      </div>
    </div>
    <br />
    <br />
  </div>
</template>

<script>
export default {
  data() {
    return {
      click: 0,
      day: 0,
      mounth: 0,
      mounthName: ["Janeiro", "Fevereiro", "Março", "Abril"],
      mounthCode: ["j", "f", "m", "a"],
      lastDay: 0,
      text: "",
      agend: [2, 8, 10, 15, 19],
    };
  },
  mounted() {
    document.getElementById("2j").style.backgroundColor = "#DDA0DD";
    document.getElementById("8j").style.backgroundColor = "#EE82EE";
    document.getElementById("10j").style.backgroundColor = "#DDA0DD";
    document.getElementById("15j").style.backgroundColor = "#EE82EE";
    document.getElementById("19j").style.backgroundColor = "#BA55D3";
  },
  methods: {
    changeColor: function () {
      if (
        this.day != this.agend[0] &&
        this.day != this.agend[1] &&
        this.day != this.agend[2] &&
        this.day != this.agend[3] &&
        this.day != this.agend[4]
      ) {
        document.getElementById(
          this.day + this.mounthCode[this.mounth]
        ).style.backgroundColor = "rgb(254, 201, 97)";
      }
      if (this.lastDay != 0) {
        document.getElementById(
          this.lastDay + this.mounthCode[this.mounth]
        ).style.backgroundColor = "white";
      }
      if (
        this.day != this.agend[0] &&
        this.day != this.agend[1] &&
        this.day != this.agend[2] &&
        this.day != this.agend[3] &&
        this.day != this.agend[4]
      ) {
        this.lastDay = this.day;
      }
    },
    showTime: function (dayV) {
      this.day = dayV;
      this.click = 1;
      this.changeColor();
      if (dayV == this.agend[0] || dayV == this.agend[2]) {
        this.text =
          "<p class='bold'>Horários marcados como disponíveis para atendimento presencial</p><br><p>15:00 - disponível para atendimento </p><br><p>17:00 - disponível para atendimento</p><br>";
      } else if (dayV == this.agend[1] || dayV == this.agend[3]) {
        this.text =
          "<p class='bold'>Horários marcados como disponíveis para atendimento online</p><br><p>15:00 - disponível para atendimento</p><br><p>17:00 - disponível para atendimento </p><br>";
      } else if (dayV == this.agend[4]) {
        this.text =
          "<p class='bold'>Clientes agendados nesse dia</p><br><p>Paulo Roberto da silva - 15:00</p><br><p>Maria Ferreira - 18:00</p><br>";
      } else {
        this.text =
          "<p class='bold'>Você não marcou horários disponíveis nesse dia </p><br>";
      }
    },
    online: function () {
      this.click = 2;
    },
    presencial: function () {
      this.click = 3;
    },
    agendar: function () {
      this.click = 4;
    },
    next: function () {
      this.mounth = this.mounth + 1;
      this.clear();
    },
    previow: function () {
      this.mounth = this.mounth - 1;
      this.clear();
    },
  },
};
</script>

<style scoped>
tr td {
  text-align: center;
}
#boxAgenda {
  border-radius: 2%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin: 0 auto;
  padding: 30px;
}
.btn {
  background-color: #983e7b;
  color: white;
  border: none;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}
</style>