var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"806px","width":"82%","background-color":"#f7f8fc","position":"absolute","left":"18%"}},[_c('br'),_c('br'),_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-sm",staticStyle:{"margin-left":"10%"}},[_c('div',{staticClass:"row",staticStyle:{"margin-top":"2px"}},[_vm._m(1),_c('div',{staticClass:"col"},[_c('div',{staticClass:"row"},[_c('h6',{staticStyle:{"margin-left":"50%"}},[_vm._v(_vm._s(_vm.fisrtName))]),_c('img',{staticStyle:{"width":"20px","height":"20px","margin-left":"20px"},attrs:{"src":require("../../imgs/usuario-de-perfil.png")}})])])])])]),_c('br'),_c('br'),_c('br'),_vm._m(2),_c('br'),_c('br'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{attrs:{"id":"boxUser"}},[_vm._m(3),_vm._m(4),_c('h2',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(_vm.userName))])])]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm"},[_c('h6',{staticStyle:{"margin-left":"10%"}},[_c('b',[_vm._v("Dashboard Especialista - Bem-vindo (a)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('img',{staticStyle:{"width":"20px","height":"20px"},attrs:{"src":require("../../imgs/notificacao.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row",staticStyle:{"margin-left":"3%"}},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"box"},[_c('p',[_vm._v("Clientes em atendimento")]),_c('h1',[_vm._v("6")])])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"box"},[_c('p',[_vm._v("Empresas em atendimento")]),_c('h1',[_vm._v("2")])])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"box"},[_c('p',[_vm._v("Sessões Realizadas")]),_c('br'),_c('h1',[_vm._v("10")])])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"box"},[_c('p',[_vm._v("Processos concluídos")]),_c('br'),_c('h1',[_vm._v("1")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('img',{attrs:{"src":require("../../imgs/foto_perfil.jpg"),"width":"300px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('img',{attrs:{"src":require("../../imgs/estrelas.png"),"width":"300px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('div',{attrs:{"id":"boxClient"}},[_c('br'),_c('table',{staticStyle:{"width":"100%"}},[_c('tr',[_c('th',[_vm._v("Clientes")]),_c('th',[_vm._v("Programa de desenvolvimento")]),_c('th',[_vm._v("Próxima sessão")])]),_c('tr',[_c('td',[_vm._v("Thiago")]),_c('td',[_vm._v("Pessoal")]),_c('td',[_vm._v("30/06/2021")])]),_c('tr',[_c('td',[_vm._v("Matheus")]),_c('td',[_vm._v("Profissional")]),_c('td',[_vm._v("10/07/2021")])]),_c('tr',[_c('td',[_vm._v("Ana")]),_c('td',[_vm._v("Relacional")]),_c('td',[_vm._v("20/07/2021")])]),_c('tr',[_c('td',[_vm._v("Lucas")]),_c('td',[_vm._v("Relacional")]),_c('td',[_vm._v("20/07/2021")])]),_c('tr',[_c('td',[_vm._v("Hyago")]),_c('td',[_vm._v("Relacional")]),_c('td',[_vm._v("20/07/2021")])]),_c('tr',[_c('td',[_vm._v("Gabriel")]),_c('td',[_vm._v("Relacional")]),_c('td',[_vm._v("20/07/2021")])]),_c('tr',[_c('td',[_vm._v("Igor")]),_c('td',[_vm._v("Relacional")]),_c('td',[_vm._v("20/07/2021")])]),_c('tr',[_c('td',[_vm._v("Luanda")]),_c('td',[_vm._v("Relacional")]),_c('td',[_vm._v("20/07/2021")])]),_c('tr',[_c('td',[_vm._v("Ruan")]),_c('td',[_vm._v("Pessoal")]),_c('td',[_vm._v("20/07/2021")])]),_c('tr',[_c('td',[_vm._v("Felipe")]),_c('td',[_vm._v("Relacional")]),_c('td',[_vm._v("20/07/2021")])]),_c('tr',[_c('td',[_vm._v("Luan")]),_c('td',[_vm._v("Relacional")]),_c('td',[_vm._v("20/07/2021")])])]),_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('span',{staticStyle:{"font-size":"30px","color":"#D3D3D3"},attrs:{"id":"set"}},[_vm._v("‹ ›")])])])])
}]

export { render, staticRenderFns }