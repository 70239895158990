<template>
  <div id="pageMap">
    <perguntasMapeamento></perguntasMapeamento>
    <barraLateral></barraLateral>
  </div>
</template>
<script>
import barraLateral from "../components/barra-lateral-empresa.vue";
import perguntasMapeamento from "../components/perguntas-mapeamento.vue";

export default {
  components: {
    barraLateral,
    perguntasMapeamento,
  },
};
</script>
<style scoped>
#pageMap {
  width: 100%;
  height: 6300px;
}
* {
  background-color: #f7f8fc;
  margin-top: -1%;
}
</style>