<template>
    <div style="width:82%;position:absolute;left:18%;top:0%;margin-left:0%;" class="container-fluid"> 
        <br>
        <br>
        
        <div id="boxAssessment" >
            <br>
            <h1 class="text-center">Avaliando a esperança</h1>
            <br>
            <br>
            <table>
                <tr>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                    <th>8</th>
                </tr>
                <tr>
                    <td>Definitivamente falso</td>
                    <td>Em grande parte falso</td>
                    <td>Em parte falso</td>
                    <td>ligeramente falso</td>
                    <td>ligeramente verdadeiro</td>
                    <td>Em parte verdadeiro</td>
                    <td>Em grande parte verdadeiro</td>
                    <td>Definitivamente verdadeiro</td>

                </tr>
            </table>
            
            <br>
            <br>
            <h5>Consigo pensar em várias maneiras de sair de uma situação especifíca.</h5>
            <br>
            <input id="level1" class="inputLevel" type="range" oninput="getElementById('showLevel1').innerHTML = this.value;"  min="1" max="8" value="1" />
            <h1 id="showLevel1" class="numberLevel">1</h1>
            <br>
            <hr>
            <br>










            <h5>Persigo meus objetivos com toda a minha energia.</h5>
            <br>
            <input id="level2" class="inputLevel" type="range" oninput="getElementById('showLevel2').innerHTML = this.value;"  min="1" max="8" value="1" />
            <h1 id="showLevel2" class="numberLevel">1</h1>
            <br>
            <hr>
            <br>

            <h5>Sinto-me cansado na maior parte do tempo.</h5>
            <br>
            <input id="level3" class="inputLevel" type="range" oninput="getElementById('showLevel3').innerHTML = this.value;"  min="1" max="8" value="1" />
            <h1 id="showLevel3" class="numberLevel">1</h1>
            <br>
            <hr>
            <br>

            <h5>Há várias maneiras de solucionar um problema.</h5>
            <br>
            <input id="level4" class="inputLevel" type="range" oninput="getElementById('showLevel4').innerHTML = this.value;"  min="1" max="8" value="1" />
            <h1 id="showLevel4" class="numberLevel">1</h1>
            <br>
            <hr>
            <br>

            <h5>Sou facilmente vencido em discussões.</h5>
            <br>
            <input id="level5" class="inputLevel" type="range" oninput="getElementById('showLevel5').innerHTML = this.value;"  min="1" max="8" value="1" />
            <h1 id="showLevel5" class="numberLevel">1</h1>
            <br>
            <hr>
            <br>

            <h5>Consigo pensar em várias maneiras de obter o que é importante para min.</h5>
            <br>
            <input id="level6" class="inputLevel" type="range" oninput="getElementById('showLevel6').innerHTML = this.value;"  min="1" max="8" value="1" />
            <h1 id="showLevel6" class="numberLevel">1</h1>
            <br>
            <hr>
            <br>

            <h5>Ando sempre nervoso.</h5>
            <br>
            <input id="level7" class="inputLevel" type="range" oninput="getElementById('showLevel7').innerHTML = this.value;"  min="1" max="8" value="1" />
            <h1 id="showLevel7" class="numberLevel">1</h1>
            <br>
            <hr>
            <br>

            <h5>Mesmo quando os outros se sentem desencorajados, eu sei que posso encontrar uma maneira de resolver um problema.</h5>
            <br>
            <input id="level8" class="inputLevel" type="range" oninput="getElementById('showLevel8').innerHTML = this.value;"  min="1" max="8" value="1" />
            <h1 id="showLevel8" class="numberLevel">1</h1>
            <br>
            <hr>
            <br>
            
            <h5>Minhas experiências passadas meu preparem muito bem para meu futuro.</h5>
            <br>
            <input id="level9" class="inputLevel" type="range" oninput="getElementById('showLevel9').innerHTML = this.value;"  min="1" max="8" value="1" />
            <h1 id="showLevel9" class="numberLevel">1</h1>
            <br>
            <hr>
            <br>

            <h5>Tenho sido bem sucedido na vida.</h5>
            <br>
            <input id="level10" class="inputLevel" type="range" oninput="getElementById('showLevel10').innerHTML = this.value;"  min="1" max="8" value="1" />
            <h1 id="showLevel10" class="numberLevel">1</h1>
            <br>
            <hr>
            <br>

            <h5>Estou sempre preocupado com alguma coisa.</h5>
            <br>
            <input id="level11" class="inputLevel" type="range" oninput="getElementById('showLevel11').innerHTML = this.value;"  min="1" max="8" value="1" />
            <h1 id="showLevel11" class="numberLevel">1</h1>
            <br>
            <hr>
            <br>

            <h5>Eu alcanço os objetivos que estabeleci para mim</h5>
            <input id="level12" class="inputLevel" type="range" oninput="getElementById('showLevel12').innerHTML = this.value;"  min="1" max="8" value="1" />
            <h1 id="showLevel12" class="numberLevel">1</h1>
            <br>
            <hr>
            <br>
            
            <br>
            <h5>Comentários e anotações</h5>
            
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <div style="display: flex; justify-content: center;"> 
                  <button class="btn" >Salvar resultados</button>
            </div>
            <br>
            <br>
        
        </div>   
    </div>
</template>
<script>
export default {
    methods:
    {
        
        
        
    },
    data() {
        return {
            
            nivel1:4,
            nivel2:4,
            nivel3:4,
            nivel4:4,
            nivel5:4,
            nivel6:4,
            nivel7:4,
            nivel8:4,
            nivel9:4,
            nivel10:4,
            nivel11:4,
            nivel12:4,
            opcoes: [
               {escala: 'Definitivamente falso'},
               {escala: 'Em grande parte falso'},
               {escala: 'Em parte falso'},
               {escala: 'Ligeramente falso'},
               {escala: 'Ligeramente verdadeiro'},
               {escala: 'Em parte verdadeiro'},
               {escala: 'Em grande parte verdadeiro'},
               {escala: 'Definitivamente verdadeiro'},
               
               
            ],
            gender:[
                {sexo: 'masculino'},
                {sexo: 'feminino'}
            ],
            
        }
    }
}
</script>

<style scoped>
.numberLevel
{
    text-align:center;
    color:#983e7b;
}
.inputLevel
{
    width:100%;
}
input[type='range'] {
  cursor: pointer;
}

input[type='range'],
input[type='range']::-webkit-slider-runnable-track,
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}

/** 
 * Use o pseudo-seletor "::-webkit-slider-runnable-track" para customizar o "trilho" do campo.
 */
input[type='range']::-webkit-slider-runnable-track {
  border-radius: 13px;
  height: 10px;
  background: #983e7b;
  
}

/** 
 * Use o pseudo-seletor "::-webkit-slider-thumb" para customizar o "seletor" do campo.
 */
input[type='range']::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  margin-top: -4.5px;
  background-color: #fec961;
  border-radius: 50px;
}
input{
    background-color: white;
    border-color:#983e7b;
    height:50px;
    border-radius:10px;
}
h2
{
    text-align:justify;
}    

h5
{
    text-align:justify;    
}
p
{
    text-align: justify;
}
table tr td
{
    text-align: center;
    width:150px;
    border-bottom-color:#D3D3D3;
    border-bottom-style: solid;
     
    border-width: 1px;
}
table tr th
{
    border-top-color:#D3D3D3;
    border-top-style: solid;
     
    border-width: 1px;
    text-align: center;
    width:150px;
}
*
{
    font-family: roboto;
}
#boxAssessment
{
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    
    color: black;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
a:hover{
    color:#fec961;
}
</style>

