<template>
  <div>
    <Navbar></Navbar>
    <div id="privacy-policy">
      <h1 style="text-align: center">Política de privacidade</h1>
      <br />
      <p>

        Um dos principais objetivos da Plataforma web ou mobile da FLORESCER DESENVOLVIMENTO
        (doravante denominada simplesmente “Plataforma”, independentemente de sua versão),
        desenvolvida e provida pela FLORESCER DESENVOLVIMENTO E TREINAMENTOS LTDA - ME (“Provedora”),
        inscrita no CNPJ/MF sob nº 27.917.185/0001-50 com sede na Av. Francisco de Andrade Ribeiro,
        N, 543- Sala 11– CEP: 37.540-000 – na Cidade de Santa Rita do Sapucaí, Estado de Minas Gerais,
        é criar um ambiente de Gestão, de Acompanhamentos mentoria, consultoria e atendimentos de modo seguro para
        Psicólogos ,
        Paciente/ Clientes/ Colaboradores em conjunto com Gestores de Empresas (RH´s e Líderes de modo que seja
        garantida a privacidade e o sigilo de suas comunicações. Dessa forma, a presente Política de Privacidade
        (“Política”) foi desenvolvida com o intuito de informar ao Usuário como se dará a coleta, uso, compartilhamento
        e armazenamento de suas informações.
      </p>
      <p>
        A aceitação a esta Política ocorrerá no ato do clique do Usuário no botão de aceite, no momento de seu cadastro,
        de forma que este manifesta seu consentimento livre, expresso e informado às práticas de tratamento de suas
        informações pela Plataforma. Caso o Usuário não concorde com as disposições deste documento, deverá se abster de
        utilizar a Plataforma.
      </p>
      <p>
        Para que o Usuário possa consultar o inteiro teor desta Política, a qualquer momento, ela está disponível para
        acesso por meio da página painel.florescerdesenvolvimento.com.br/politica-privacidade Ainda, aplicam-se a esta
        Política todas as
        disposições constantes nos Termos e Condições de Uso da Plataforma, disponíveis para acesso na página
        painel.florescerdesenvolvimento.com.br/termos-uso
      </p>
      <p>
        Em caso de qualquer dúvida com relação ao conteúdo desta Política, indicamos que o Usuário consulte a página de
        Perguntas Frequentes da página www.florescerdesenvolvimento.com.br ou entre em contato com a Provedora por meio
        dos canais disponibilizados no site contato@florescerdesenvolvimento.com
      </p>
      <p>
        Esta Política não se aplica às práticas de empresas que não pertencem nem são controladas por nós, incluindo seu
        empregador, ou qualquer usuário (pacientes, parceiros e clientes).
      </p>
      <p>
        Ao aceitar a presente Política, o Usuário se declara ciente das informações coletadas quando de sua utilização
        da Plataforma, e manifesta seu consentimento livre, expresso e informado com relação à coleta de tais
        informações.

      </p>
      <br />
      <h5>1. Informações Coletadas</h5>
      <br />
      <p>
        1.1. A Provedora celebra contratos com empregadores e soluções de Gestão
        da Saúde Emocional para oferecer aos colaboradores elegíveis (e
        familiares) a oportunidade de acessar a FLORESCER DESENVOLVIMENTO com
        condições comerciais diferenciadas. O plano FLORESCER DESENVOLVIMENTO
        permite o acesso aos serviços de medições de humor, emoções, sentimentos
        e uso de ferramentas especialistas da Psicologia positiva por nossas
        Psicólogas parceiras.
      </p>
      <p>
        1.2. Todas as informações pessoais relativas aos assinantes e usuários
        que utilizem o site da A FLORESCER DESENVOLVIMENTO serão tratadas em
        concordância com a Lei da Proteção de Dados Pessoais de 26 de outubro de
        1998 (Lei n.º 67/98). A informação pessoal recolhida pode incluir o seu
        nome, e-mail, número de telefone, endereço de moradia, data de
        nascimento, entre outros.
      </p>
      <p>
        1.3. A Provedora coleta dois tipos de informação por meio da Plataforma:
        (i) Fornecidas ativamente pelo usuário na plataforma e (ii) Coletadas
        automaticamente durante o acesso à plataforma.
      </p>
      <p>
        1.4. A Provedora usa seus Dados Pessoais para prestar a você os serviços
        da Plataforma, confirmar a elegibilidade e administrar seu cadastro,
        responder às suas perguntas, entregar uma experiência mais relevante por
        meio de nossos Serviços e Parceiros, e para atender outras finalidades
        de negócio. Podemos usar seus Dados Pessoais para as seguintes
        finalidades, expressas no quadro-resumo abaixo:
      </p>
      <table>
        <tr>
          <th>Classificação</th>
          <th>Dados</th>
          <th>Finalidade e base legal</th>
        </tr>
        <tr>
          <td>Dados cadastrais do Usuário Paciente/cliente</td>
          <td>
            Nome, data de nascimento, CPF, endereço de e-mail, telefone e dados
            financeiros.
          </td>
          <td>
            Finalidade: Viabilizar a relação contratual da FLORESCER
            DESENVOLVIMENTO com o usuário, fornecer suporte administrativo e de
            uso, viabilizar o pagamento pelos nossos Serviços, comunicações
            promocionais e de marketing, confirmar o uso que você faz parte do
            programa de Gestão da Saúde Emocional ou plataforma de solução de
            bem-estar e saúde mental <br />Base legal: Execução de contrato.
          </td>
        </tr>
        <tr>
          <td>
            Dados cadastrais do usuário Especialistas (Psicólogo, Terapeuta)
          </td>
          <td>
            Nome, e-mail, data de nascimento, endereço, número de registro
            profissional, informações acadêmicas e de histórico profissional e
            área de especialidades.
          </td>
          <td>
            Finalidade: Seleção de especialistas, psicólogos para atendimento na
            plataforma e indicação aos colaboradores/ pacientes/clientes de
            acordo com o perfil e necessidade de desenvolvimento<br />Base
            legal: Execução de contrato.
          </td>
        </tr>
        <tr>
          <td>Informações de perfil empresa</td>
          <td>
            Empresa Dados cadastrais – Nome, Razão social, Nome fantasia, CNPJ,
            IE, Cep, rua/avenida, número, complemento, bairro, cidade, estado,
            país. Usuário (Gestor de RH, Líderes)– Nome do gestor, cargo, data
            de nascimento. telefone, e-mail
          </td>
          <td>
            Finalidade: Viabilizar a relação contratual da FLORESCER
            DESENVOLVIMENTO com o usuário, fornecer suporte administrativo e de
            uso, viabilizar o pagamento pelos nossos Serviços. Nas soluções de
            Gestão da Saúde Emocional- gestão integradas dos colaboradores, na
            modalidade empresarial, os dados de utilização da plataforma serão
            compartilhados com a empresa de forma agregada, em forma de
            relatórios estatísticos e no painel da Dashabord (evolução das
            competências, habilidades e humor/sentimentos) para fins de
            acompanhamento da evolução da equipe.<br />
            Base legal: Execução de contrato.
          </td>
        </tr>
        <tr>
          <td>Dados cadastrais do Usuário colaborador</td>
          <td>
            Nome, data de nascimento, CPF, endereço de e-mail, telefone e dados
            financeiros. Preenchimentos deAssessment de Avaliação de evolução:
            *Pré-competências e * pós competências. *avaliação de escala de
            humor (emoção/sentimentos) *Mapeamento de Perfil DISC *Mapeamento de
            Forças de Carater (VIA Institute)
          </td>
          <td>
            Finalidade: As informações são coletadas para que a FLORESCER
            DESENVOLVIMENTO possa fazer a análise inicial do perfil do Usuário,
            indicar o profissional mais adequado para o seu desenvolvimento. Em
            alguns casos, poderemos usar os dados para possibilitar a gestão
            integrada da saúde do Usuário em programas de saúde emocional,
            capacitações, treinamentos e gestão do bem-estar e saúde mental de
            seu empregador. Base legal: Execução de contrato.
          </td>
        </tr>
        <tr>
          <td>Dados automáticos</td>
          <td>
            Características do dispositivo de acesso, do navegador, IP com data
            e hora, origem do IP, cookies.
          </td>
          <td>
            Finalidade: Aprimoramento dos serviços prestados pela OrienteMe.Base
            legal: legítimo interesse. Finalidade: cumprimento de obrigações
            legais referentes à guarda de dados de acesso a aplicações. Base
            Legal: cumprimento de obrigações legais e da lei LGPD.
          </td>
        </tr>
      </table>
      <br />
      <br />

      <h5>2. Utilização e Tratamento das Informações</h5>
      <br />
      <p>
        2.1. A Provedora trata o conteúdo das comunicações entre Paciente/
        Cliente/colaborador e Terapeuta de forma totalmente sigilosa, uma vez
        que essas informações devem ser resguardadas, em decorrência do dever de
        sigilo profissional, não tendo qualquer acesso ao seu teor. Para
        garantir referido sigilo, a Provedora utiliza criptografia de ponta a
        ponta, de modo que somente Pacientes e Terapeutas participantes da
        conversa ou autoridades competentes (no caso de determinação legal)
        terão acesso ao conteúdo das mensagens.
      </p>
      <br />
      <p>
        2.1.1. Além disso, as informações inseridas pelos Usuários na
        Plataforma, por meio dos formulários disponibilizados, poderão ser
        utilizadas para fins estatísticos, desde que de forma agregada e
        anônima.
      </p>
      <p>
        2.2. O endereço de e-mail cadastrado pelo Usuário será utilizado pela
        Provedora para responder eventuais dúvidas apresentadas pelo Usuário ou
        mensagens recebidas, por meio das informações de contato
        disponibilizadas na Plataforma.
      </p>

      <p>
        2.3. Comunicações promocionais e de marketing: Podemos nos comunicar com
        você, se você nos fornecer os meios para fazê-lo. Por exemplo, podemos
        usar seus Dados Pessoais para apresentar ofertas especiais, promoções e
        informações sobre a Florescer e nossos Serviços. Ao nos informar seu
        número de telefone celular, endereço de e-mail ou credenciais de conta
        de mídia social, você confirma que a Florescer possa enviar a você,
        através desses canais, dados que acreditamos serem de seu interesse,
        como, entre outros, ofertas da Florescer promoções e atualizações a
        respeito de Parceiros da Florescer. Quando exigido pela lei aplicável,
        obteremos seu consentimento para lhe enviar comunicações de marketing.
        Se você não quiser receber nossas comunicações por e-mail, por favor,
        indique sua preferência clicando no link cancelar que aparecerá em todos
        os e- mails ou contate suporte@florescerdesenvolvimento.com. A Florescer
        fará todos os esforços razoáveis para executar seu pedido de cancelar a
        assinatura sem demora indevida, conforme exigido pela lei aplicável.
        Entretanto, observe que se você for um membro, mesmo que opte por ser
        excluído das comunicações de marketing, continuará a receber nossas
        comunicações administrativas e operacionais, tais como avisos sobre
        reservas ou faturamento.
      </p>
      <br />
      <br />
      <h5>COOKIES</h5>
      <p>
        2.4. Nós utilizamos cookies para otimizar sua experiência em nossa
        plataforma, para armazenar informações, tais como as suas preferências
        pessoais quando visita o nosso website. Isso poderá incluir um simples
        popup ou links em vários serviços que providenciamos, tais como fóruns,
        etc. O emprego de tecnologias de coleta automática de dados pode ser
        desativado, a qualquer momento, mediante a alteração de configurações no
        dispositivo de acesso do Usuário. No entanto, ressaltamos que, caso esta
        configuração seja implementada, algumas das funções oferecidas pela
        Plataforma podem deixar de funcionar corretamente.
      </p>
      <p>
        2.5. Durante a utilização da Plataforma, o Usuário poderá encontrar
        links capazes de conduzir o Usuário a outras páginas eletrônicas,
        disponibilizadas por terceiros, as quais poderão apresentar Política de
        Privacidade própria, com disposições distintas do aqui disposto. Desta
        forma, a Provedora se isenta de toda responsabilidade acerca da coleta,
        utilização, compartilhamento e armazenamento dos dados dos Usuários,
        realizados pelos responsáveis por tais páginas.
      </p>
      <p>
        2.5.1. Neste sentido, recomendamos que as Empresas, Terapeutas e
        Pacientes/clientes/colaboradores mantenham contato exclusivamente por
        meio da Plataforma, uma vez não ser possível à Provedora garantir que
        outros meios de contato contem com mecanismos de criptografia e outras
        medidas que visam garantir a privacidade dos Usuários e o sigilo
        profissional.
      </p>
      <br />
      <h5>3. Compartilhamento das Informações</h5>
      <p>
        3.1. A Provedora poderá compartilhar os dados coletados, nos termos da
        Cláusula 1.1 desta Política, por meio da Plataforma nas situações
        descritas abaixo:
        <br />a) Quando necessário às atividades comerciais da Provedora, por
        exemplo, com intermediadores de pagamento; <br />b) Para proteção dos
        interesses da Provedora em qualquer tipo de conflito, incluindo ações
        judiciais;

        <br />c) No caso de transações e/ou alterações societárias envolvendo a
        Provedora, hipótese em que a transferência das informações será
        necessária para a continuidade dos serviços; ou <br />d) Mediante ordem
        judicial ou pelo requerimento de autoridades administrativas com
        competência legal para tanto, referente aos dados coletados pela
        Provedora. <br />e) Nas soluções de Gestão da Saúde Emocional na
        modalidade empresarial, os dados de utilização da plataforma poderão ser
        compartilhados com a empresa de forma agregada, em forma de relatórios
        estatísticos e no painel da Dashabord para fins de acompanhamento da
        evolução da equipe. A confidencialidade entre o Terapeuta e
        Colaborador(paciente) será respeitada no plano empresarial, não havendo
        qualquer compartilhamento das informações abordadas durante os
        atendimentos. <br />f) Em algumas modalidades de serviços, dados
        fornecidos pelo Usuário poderão ser compartilhados com parceiros de
        negócios da Florescer, como gestoras de saúde, para a melhoria na
        prestação de serviços. <br />f.1) Nesses casos, o Usuário declara seu
        consentimento livre, inequívoco, informado e específico de que parceiros
        da Florescer podem ter acesso a dados pessoais sobre perfil e índice de
        risco, para fins de gestão integrada da saúde dos colaboradores. Os
        dados em questão não incluem quaisquer informações trocadas por meio de
        mensagens, videochamadas ou qualquer outro meio de comunicação entre o
        paciente e terapeuta, as quais serão sempre mantidas em sigilo. O
        Usuário declara ainda que possui conhecimento acerca da possibilidade de
        não consentimento e pedido expresso de revogação a qualquer momento,
        sendo que na hipótese da negativa do fornecimento de consentimento, a
        qualidade dos serviços prestados pode ser afetada.
      </p>
      <br />
      <h5>4. Armazenamento das Informações</h5>
      <p>
        4.1. Todas as informações relativas aos Usuários, coletadas pela
        Provedora, serão armazenadas por esta em servidores próprios ou de
        terceiros, por ela contratados. Dessa forma, a Provedora emprega todos
        os esforços razoáveis de mercado para garantir a segurança de seus
        sistemas na guarda de referidos dados, dentre eles a observância às
        diretrizes sobre padrões de segurança estabelecidas no Decreto n°
        8.771/2016, tais como: <br />a) Emprego de métodos padrões e de mercado
        para criptografar e anonimizar os dados coletados, a fim de assegurar
        sua inviolabilidade; <br />b) Utilização de softwares de alta tecnologia
        para proteção contra acesso não autorizado aos sistemas; <br />c) Acesso
        aos locais de armazenamento apenas e tão somente por pessoas previamente
        autorizadas, comprometidas a manter tais informações em sigilo absoluto,
        sob pena de responsabilização civil; <br />d) Aplicação de mecanismos de
        autenticação de acesso aos registros capazes de individualizar o
        responsável pelo tratamento destes; e <br />e) Manutenção de inventário
        indicando momento, duração, identidade do funcionário ou do responsável
        pelo acesso e o arquivo em referência, com base nos registros de conexão
        e de acesso a aplicações. <br />4.2. Por padrão, o histórico de
        mensagens de cada Paciente é excluído dos servidores da Plataforma a
        cada 30 dias, podendo ser alterado para 60 ou 90 dias a exclusivo
        critério do Paciente. O histórico também é excluído quando há troca do
        Terapeuta atendendo o Paciente. <br />4.3. Conforme aqui disposto, a
        Provedora emprega os melhores esforços a fim de garantir a segurança das
        informações por ela coletadas por meio da Plataforma. Todavia, em razão
        da própria natureza da Internet, não há como assegurar que terceiros não
        autorizados não tenham êxito em acessar indevidamente as informações
        armazenadas pela Provedora. Neste sentido, orientamos os Usuários a
        utilizarem suas senhas pessoais com cautela, certificando-se de que se
        conectem à Plataforma com dispositivos de acesso confiáveis.
      </p>
      <br />
      <h5>5. Direitos do sujeito de dados</h5>
      <p>
        1. Quando exigido pela lei aplicável, você tem os seguintes direitos com
        respeito a seus Dados Pessoais: <br />(i) Direito de acesso; direito de
        portabilidade de dados. Você tem o direito de receber uma cópia dos
        Dados Pessoais que temos a seu respeito e sobre como usamos estas
        informações. Você também tem o direito de receber seus Dados Pessoais em
        um formato estruturado e o direito de que os Dados Pessoais sejam
        transmitidos diretamente da Florescer a outro controlador de dados.
        <br />(ii) Direito de retificação. Você tem o direito de obter conosco,
        sem atrasos indevidos, a correção de Dados Pessoais imprecisos a seu
        respeito. <br />(iii) Direito de eliminação. Você tem o direito de pedir
        a eliminação de Dados Pessoais a seu respeito, a menos que seja
        necessário tratamento para exercer o direito de liberdade de expressão e
        informação, visando o cumprimento de uma obrigação legal, por razões de
        interesse público ou para estabelecer, exercer ou se defender de uma
        demanda judicial. <br />(iv) Direito de restringir ou se opor a
        tratamento. Você tem o direito de se opor ou restringir o tratamento de
        seus Dados Pessoais na medida em que você contestar a exatidão dos
        dados, o tratamento for ilegal, mas você se opuser à eliminação, ou você
        se opuser ao tratamento. Caso seus Dados Pessoais forem processados por
        nós para ações de marketing direto, você tem o direito de se opor, a
        qualquer momento, ao tratamento. <br />(v) Direito de retirar
        consentimento. Você pode retirar a qualquer momento seu consentimento
        com o tratamento de seus Dados Pessoais por nós.
        <br />
        <br />
        2. Você pode sempre optar por não divulgar dados para nós, mas tenha em
        mente que alguns dados podem ser necessários para fazer seu cadastro
        conosco ou para aproveitar alguns de nossos recursos. Através das
        configurações de sua conta, você pode acessar e atualizar os dados que
        nos forneceu. Se você desejar exercer qualquer um de seus direitos e
        essa opção ainda não estiver disponível através de suas configurações de
        conta, por favor, entre em contato conosco e trataremos de seu pedido.
        Observe, por favor, que somos proprietários e podemos usar dados
        agregados de forma anônima, derivados ou que incorporem seus Dados
        Pessoais depois que eles tiverem sido fornecidos a nós. Para contato com
        a equipe Florescer escreva para suporte@florescerdesenvolvimento.com
      </p>
      <br />
      <h5>6. Atualização desta Política</h5>
      <p>
        6.1. A Provedora poderá alterar a presente Política sempre que entender
        necessário, com o objetivo de garantir maior segurança ao Usuário, o que
        poderá se dar sem prévia notificação, salvo em caso de expressa vedação
        legal. Dessa forma, orientamos o Usuário a verificar este documento
        periodicamente, certificando-se que teve acesso à sua versão mais
        atualizada, conforme a data de modificação informada ao fim.
      </p>
      <br />
      <h5>7. Legislação e Foro</h5>
      <p>
        7.1. A presente relação jurídica é regida exclusivamente pelas leis
        brasileiras, inclusive eventuais ações decorrentes de violação dos seus
        termos e condições.
      </p>
      <p> 7.2. Fica eleito o Foro da Comarca de Santa Rita do
        Sapucaí-MG para dirimir quaisquer dúvidas, questões ou litígios
        decorrentes da presente Política, renunciando as partes a qualquer
        outro, por mais privilegiado que seja.<br />
      </p>
    </div>
    <br />
    <br />
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../components/navbar.vue";
import Footer from "../components/footer.vue";


export default {
  components: {
    Navbar,
    Footer,

  },
};
</script>

<style scoped>
p {
  text-align: justify;
}

table tr th {
  border-style: solid;
  border-color: black;
  text-align: justify;
}

table tr td {
  border-style: solid;
  border-color: black;
  text-align: justify;
}

#privacy-policy {
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin-top: 15%;
  padding: 30px;
  margin-left: 5%;
  margin-right: 5%;
}

a:link {
  text-decoration: none;
}

@media (min-width:0px) and (max-width:800px) {
  #privacy-policy {
    margin-top: 33%;
  }
}
</style>