import { render, staticRenderFns } from "./assessment-58.vue?vue&type=template&id=becb8164&scoped=true&"
import script from "./assessment-58.vue?vue&type=script&lang=js&"
export * from "./assessment-58.vue?vue&type=script&lang=js&"
import style0 from "./assessment-58.vue?vue&type=style&index=0&id=becb8164&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "becb8164",
  null
  
)

export default component.exports