<template>
  <div>
    <Navbar></Navbar>
    <br />
    <br />
    <br />
    <br />
    <text1></text1>
    <text2></text2>
    <text3></text3>
    <Footer></Footer>
  </div>
</template>
<script>
import text1 from "../components/text1-sobre-nos.vue";
import text2 from "../components/text2-sobre-nos.vue";
import text3 from "../components/text3-sobre-nos.vue";
import Navbar from "../components/navbar.vue";
import Footer from "../components/footer.vue";
export default {
  components: {
    text1,
    text2,
    text3,
    Navbar,
    Footer,
  },
};
</script>