<template>
    <div style="height:806px;width:82%;background-color:#f7f8fc;position:absolute;left:18%">
     <br>
        <br>
        <div class="row">
            <div class="col-sm">
                <h6 style="margin-left:10%"><b>Dashboard Especialista - Bem-vindo (a)</b></h6>
            </div>
            <div class="col-sm" style="margin-left:10%">
                <div class="row" style="margin-top:2px">
                    <div class="col">
                        <img style="width:20px;height:20px" src="../../imgs/notificacao.png">
                    </div>
                    <div class="col">
                        <div class="row" >
                            <h6 style="margin-left:50%">{{fisrtName}}</h6>
                            <img style="width:20px;height:20px;margin-left:20px" src="../../imgs/usuario-de-perfil.png">
                        </div>
                    </div>
                </div> 
            </div>
        </div>
        <br>
        <br>
        <br>
        <div class="row" style="margin-left:3%">
            <div class="col">
                <div class="box">
                    <p>Clientes em atendimento</p>
                    <h1>6</h1>
                </div>
            </div>
            <div class="col">
                <div class="box">
                    <p>Empresas em atendimento</p>
                     <h1>2</h1>
                </div>
            </div>
            <div class="col">
                <div class="box">
                    <p>Sessões Realizadas</p>
                    <br>
                    <h1>10</h1>
                </div>
            </div>
            <div class="col">
                <div class="box">
                    <p>Processos concluídos</p>
                    <br>
                    <h1>1</h1>
                </div>
            </div>


        </div>
        <br>
        <br>
        <div class="row">
            <div class="col">
                <div id="boxUser">
                    <div style="display: flex; justify-content: center;"> 
                        <img  src="../../imgs/foto_perfil.jpg" width="300px">
                          
                    </div>
                    <div style="display: flex; justify-content: center;"> 
                        <img  src="../../imgs/estrelas.png" width="300px">
                    </div>
                    <h2 style="text-align:center">{{userName}}</h2>
                    
                </div>
            </div>
            <div class="col">
                <div id="boxClient">
                    <br>
                    <table style="width:100%">
                        <tr>
                            <th>Clientes</th>
                            <th>Programa de desenvolvimento</th>
                            <th>Próxima sessão</th>
                        </tr>
                        <tr>
                            <td>Thiago</td>
                            <td>Pessoal</td>
                            <td>30/06/2021</td>
                            
                        </tr>
                        <tr>
                            <td>Matheus</td>
                            <td>Profissional</td>
                            <td>10/07/2021</td>
                            
                        </tr>
                        <tr>
                            <td>Ana</td>
                            <td>Relacional</td>
                            <td>20/07/2021</td>
                            
                        </tr>
                        <tr>
                            <td>Lucas</td>
                            <td>Relacional</td>
                            <td>20/07/2021</td>
                            
                        </tr>
                        <tr>
                            <td>Hyago</td>
                            <td>Relacional</td>
                            <td>20/07/2021</td>
                            
                        </tr>
                        <tr>
                            <td>Gabriel</td>
                            <td>Relacional</td>
                            <td>20/07/2021</td>
                            
                        </tr>
                        <tr>
                            <td>Igor</td>
                            <td>Relacional</td>
                            <td>20/07/2021</td>
                            
                        </tr>
                        <tr>
                            <td>Luanda</td>
                            <td>Relacional</td>
                            <td>20/07/2021</td>
                            
                        </tr>
                        <tr>
                            <td>Ruan</td>
                            <td>Pessoal</td>
                            <td>20/07/2021</td>
                            
                        </tr>
                        <tr>
                            <td>Felipe</td>
                            <td>Relacional</td>
                            <td>20/07/2021</td>
                            
                        </tr>
                        <tr>
                            <td>Luan</td>
                            <td>Relacional</td>
                            <td>20/07/2021</td>
                            
                        </tr>

                    </table>
                    <div style="display: flex; justify-content: center;"> 
                         <span id="set" style="font-size:30px;color:#D3D3D3">&#8249; &#8250;</span>
                    </div>
                </div>
            </div>
        </div>  
    </div>
</template>
<script>
export default{
    mounted()
    {
        this.userName = localStorage.getItem('userName');
        this.fisrtName = this.userName.split(' ',1);
        this.fisrtName = this.fisrtName[0];
        
    },
    data()
    {
        return{
            userName:"",
            fisrtName:""
        }
    }
}


</script>
<style scoped>
#set
{
    cursor: pointer;
}
table tr th
{
    font-size:12px;
    text-align:center;
}
table tr td
{
    text-align:center;
}
#boxClient
{
    border-style: solid;
    background-color: white;
    border-radius: 15px;
    border-color: #D3D3D3;
    margin-right:5%;
    height:400px;
    border-width: 1px;
}
#boxUser
{
    border-style: solid;
    background-color: white;
    border-radius: 15px;
    border-color: #D3D3D3;
    margin-left:10%;
    height:400px;
    border-width: 1px;
}

.box
{
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
    height:120px;
    width:200px;
    border-radius: 15px;
    text-align: center;
    color: black;
}

</style>