<template>
  <div>
    <br />
    <br />
    <br />
    <br />
    <div id="boxAvaliacao" class="ml-5 mr-5 mt-3">
      <h1 style="color: #983e7b" class="text-center">
        Avaliação da atuação e competências do profissional
      </h1>
      <br />
      <h4 class="bold">
        De 1 a 5 - onde 1 é totalmente insatisfeito e 5 é totalmente satisfeito
        como você avalia a atuação do profissional referente a sua competencia,
        habilidades e princípios durante a sessão:
      </h4>
      <br />
      <p class="subtitle">
        Comunicação: o profissional demonstrou habilidade de ouvir, perguntar,
        estabelecer empatia e gerar novas opções e entedimentos
      </p>
      <div class="row text-center">
        <br />
        <br />
        <div class="col">
          <input type="radio" name="p1" value="beatles" />
          <br />
          1
        </div>
        <div class="col">
          <input type="radio" name="p1" value="beatles" />
          <br />
          2
        </div>
        <div class="col">
          <input type="radio" name="p1" value="beatles" />
          <br />
          3
        </div>
        <div class="col">
          <input type="radio" name="p1" value="beatles" />
          <br />
          4
        </div>
        <div class="col">
          <input type="radio" name="p1" value="beatles" />
          <br />
          5
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <p class="subtitle">
        Motivação: o profissional soube motivar, apoiam entusiasmar, dar suporte
        e aumentar os níveis de autoconfiança e autoestima
      </p>
      <div class="row text-center">
        <br />
        <br />
        <div class="col">
          <input type="radio" name="p2" value="beatles" />
          <br />
          1
        </div>
        <div class="col">
          <input type="radio" name="p2" value="beatles" />
          <br />
          2
        </div>
        <div class="col">
          <input type="radio" name="p2" value="beatles" />
          <br />
          3
        </div>
        <div class="col">
          <input type="radio" name="p2" value="beatles" />
          <br />
          4
        </div>
        <div class="col">
          <input type="radio" name="p2" value="beatles" />
          <br />
          5
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />

      <p class="subtitle">
        Plenejamento: o profissional soube gerar foco, planejar, segmentar
        sonhos e objetivos, eslarecer o propósito e missão, identificar crenças
        e valores
      </p>
      <div class="row text-center">
        <br />
        <br />
        <div class="col">
          <input type="radio" name="p3" value="beatles" />
          <br />
          1
        </div>
        <div class="col">
          <input type="radio" name="p3" value="beatles" />
          <br />
          2
        </div>
        <div class="col">
          <input type="radio" name="p3" value="beatles" />
          <br />
          3
        </div>
        <div class="col">
          <input type="radio" name="p3" value="beatles" />
          <br />
          4
        </div>
        <div class="col">
          <input type="radio" name="p3" value="beatles" />
          <br />
          5
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <p class="subtitle">
        transformação: o profissional promover melhoria continua, mudanças e
        transformações pessoais
      </p>
      <div class="row text-center">
        <br />
        <br />
        <div class="col">
          <input type="radio" name="p4" value="beatles" />
          <br />
          1
        </div>
        <div class="col">
          <input type="radio" name="p4" value="beatles" />
          <br />
          2
        </div>
        <div class="col">
          <input type="radio" name="p4" value="beatles" />
          <br />
          3
        </div>
        <div class="col">
          <input type="radio" name="p4" value="beatles" />
          <br />
          4
        </div>
        <div class="col">
          <input type="radio" name="p4" value="beatles" />
          <br />
          5
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <p class="subtitle">
        Visão sistêmica: o profissional soube entender os processos e conseguiu
        contribuir com a estruturação de planos, passos e etapas para gerar
        resultados continuamente
      </p>
      <div class="row text-center">
        <br />
        <br />
        <div class="col">
          <input type="radio" name="p5" value="beatles" />
          <br />
          1
        </div>
        <div class="col">
          <input type="radio" name="p5" value="beatles" />
          <br />
          2
        </div>
        <div class="col">
          <input type="radio" name="p5" value="beatles" />
          <br />
          3
        </div>
        <div class="col">
          <input type="radio" name="p5" value="beatles" />
          <br />
          4
        </div>
        <div class="col">
          <input type="radio" name="p5" value="beatles" />
          <br />
          5
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />

      <p class="subtitle">
        Ética & caráter: O profissional demonstrou ética e caráter durante as
        sessões
      </p>
      <div class="row text-center">
        <br />
        <br />
        <div class="col">
          <input type="radio" name="p6" value="beatles" />
          <br />
          1
        </div>
        <div class="col">
          <input type="radio" name="p6" value="beatles" />
          <br />
          2
        </div>
        <div class="col">
          <input type="radio" name="p6" value="beatles" />
          <br />
          3
        </div>
        <div class="col">
          <input type="radio" name="p6" value="beatles" />
          <br />
          4
        </div>
        <div class="col">
          <input type="radio" name="p6" value="beatles" />
          <br />
          5
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />

      <p class="subtitle">
        Não julgamento: O profissional soube manter-se neutro, livre de
        julgamentos e preconceitos durante todo o processo
      </p>
      <div class="row text-center">
        <br />
        <br />
        <div class="col">
          <input type="radio" name="p7" value="beatles" />
          <br />
          1
        </div>
        <div class="col">
          <input type="radio" name="p7" value="beatles" />
          <br />
          2
        </div>
        <div class="col">
          <input type="radio" name="p7" value="beatles" />
          <br />
          3
        </div>
        <div class="col">
          <input type="radio" name="p7" value="beatles" />
          <br />
          4
        </div>
        <div class="col">
          <input type="radio" name="p7" value="beatles" />
          <br />
          5
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />

      <p class="subtitle">
        Foco no futuro: O profissional gerou foco no futuro e nos resultados
        desejados e definidos pelo cliente.
      </p>
      <div class="row text-center">
        <br />
        <br />
        <div class="col">
          <input type="radio" name="p8" value="beatles" />
          <br />
          1
        </div>
        <div class="col">
          <input type="radio" name="p8" value="beatles" />
          <br />
          2
        </div>
        <div class="col">
          <input type="radio" name="p8" value="beatles" />
          <br />
          3
        </div>
        <div class="col">
          <input type="radio" name="p8" value="beatles" />
          <br />
          4
        </div>
        <div class="col">
          <input type="radio" name="p8" value="beatles" />
          <br />
          5
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />

      <p class="subtitle">
        Ação: O profissional promoveu fortemente a ação do cliente
      </p>
      <div class="row text-center">
        <br />
        <br />
        <div class="col">
          <input type="radio" name="p9" value="beatles" />
          <br />
          1
        </div>
        <div class="col">
          <input type="radio" name="p9" value="beatles" />
          <br />
          2
        </div>
        <div class="col">
          <input type="radio" name="p9" value="beatles" />
          <br />
          3
        </div>
        <div class="col">
          <input type="radio" name="p9" value="beatles" />
          <br />
          4
        </div>
        <div class="col">
          <input type="radio" name="p9" value="beatles" />
          <br />
          5
        </div>
      </div>
      <br />
      <br />
      <br />
      <h4>Comentários sobre a sessão</h4>

      <textarea
        required="required"
        style="resize: vertical; width: 100%"
      ></textarea>
      <br />
      <br />
      <div style="display: flex; justify-content: center">
        <button class="btn">Salvar avaliação</button>
      </div>
    </div>
    <br />
    <br />
  </div>
</template>

<script>
export default {
  methods: {
    changeFigure: function (nivel, question) {
      var i;

      for (i = 1; i <= nivel; i++) {
        document.getElementById(i + question).src =
          "../../imgs/estrela_transparente.png";
      }
      for (i = 5; i > nivel; i--) {
        document.getElementById(i + question).src =
          "../../imgs/estrela_transparente.png";
      }
    },
  },
};
</script>

<style scoped>
#boxAvaliacao {
  border-radius: 2%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin: 0 auto;
  padding: 30px;
}
.btn {
  background-color: #983e7b;
  color: white;
  border: none;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}
a:link {
  text-decoration: none;
}
</style>