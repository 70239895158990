<template>
  <div style="margin-left: 10%; margin-right: 10%">
    <br />
    <br />

    <div>
      <p id="textStory">
        A empresa Florescer nasceu da Ideologia do florescimento humano para
        um<br />
        despertar dos talentos e da capacidade do uso das forças e virtudes
        humanas.<br /><br />
        E Para atingir o Florescimento, serão necessários passar por algumas
        etapas,<br />
        na qual podemos comparar com um jardim em construção.<br />
        <br /><br />Vamos pensar em três principais ingredientes:
      </p>
      <h1
        style="
          text-align: center;
          font-size: 40px;
          font-family: monotype corsiva;
          color: #983e7b;
        "
      >
        <b>a semente, a terra, e o jardineiro.</b>
      </h1>
      <div style="display: flex; justify-content: center">
        <img id="img" src="../../imgs/img_historia.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style scoped>
#textStory {
  text-align: center;
}
#img {
  width: 94%;
  margin-left: 3%;
  margin-right: 3%;
}
</style>
