<template>
    <div class="container-fluid"> 
        <br>
        <br>
        <br>
        <div id="boxAssessment" class="m-3">
            <h1 class="text-center">Ensaio mental</h1>
            <br>
            <h5>[Desenvolvido a partir do trabalho de Manz & Neck]</h5>
            <br>
            <h3 class="bold">Passo 1 – Visualize o que você quer mudar ou melhorar (como é hoje)</h3>
            <br>
            <h5>Imagine que você é um diretor de cinema e vai revisar uma cena para melhorá-la.
Veja-se numa grande tela mental, exibindo o comportamento que você quer mudar ou melhorar
(lembre-se da última vez em que você exibiu o comportamento a ser melhorado).</h5>
            <br>
            <br>

            <h3 class="bold">Passo 2 – Decida o que você quer mudar ou melhorar</h3>
            <br>
            <h5>Após visualizar a si mesmo, identifique e decida o que você quer mudar ou melhorar. Se ne-
cessário, busque modelos externos (o que alguém que você admira muito faria no seu lugar?).</h5>
            <br>
            <br>

            <h3 class="bold">Passo 3 – Edite o filme alterando tudo o que você decidiu alterar</h3>
            <br>
            <h5>Veja-se novamente no filme, mas, desta vez, fazendo diferente. Crie suas falas mentalmente,
vendo-se na tela o tempo todo.</h5>
            <br>
            <br>

            <h3 class="bold">Passo 4 – Entre no filme e transforme-se nessa pessoa</h3>
            <br>
            <h5>Entre no filme mental como se o fato estivesse acontecendo agora e veja tudo o que você veria
através dos seus olhos, ouça tudo e, principalmente, sinta dentro de você todas as sensações
que você quer sentir quando estiver fazendo de forma diferente o que você deseja.</h5>
            <br>
            <br>

            <h3 class="bold">Passo 5 – Crie uma conexão com o futuro</h3>
            <br>
            <h5>Pense no seu futuro próximo, quando você quer passar a exibir esse novo comportamento ou
essa nova atitude, e imagine esse momento.</h5>
            <br>
            <br>
        
            <h5>Comentários e anotações</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <div style="display: flex; justify-content: center;"> 
                    <a href="/nova-sessao"><button class="btn mr-3" >Voltar a pagina do cliente</button></a>
                    <button class="btn" >Salvar resultados</button>
            </div>


        </div>
    </div>
</template>

<style scoped>
*
{
    font-family: roboto;
}s
#boxAssessment
{
  border-radius: 2%;  
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  margin: 0 auto;
  padding: 30px;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
</style>

<script>
export default {
     data() {
        return {
            
           
            opcoes: [
               {n: '0'},
               {n: '1'},
               {n: '2'},
               {n: '3'},
               {n: '4'},
               {n: '5'},
               {n: '6'},
               {n: '7'},
               {n: '8'},
               {n: '9'},
               {n: '10'},
            ]
        }
     }
   
}
</script>