<template>
    <div style="width:82%;position:absolute;left:18%;top:0%;margin-left:0%;"> 
        <br>
        <br>
       
        <div id="boxAssessment" > 
            <br>
            <h1 class="text-center">Escala de Florescimento</h1>
            <br>
            <br>
           
           
            <h5>A Escala Flourishing constitui uma maneira rápida de medir seu nível de bem-estar sob a perspectiva do florescimento humano, ou Flourishing. Florescer equivale a viver em um nível otimizado de funcionamento, o que estimula o crescimento, o bem-estar e a realização. É uma combinação de sentir-se bem e funcionar  com eficácia no ambiente que o cerca. Para descobrir quanto você esta florescendo, siga as orientações a seguir</h5>
            <br>
            <br>
            <table>
                <tr>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                </tr>
                <tr>
                    <td>Discordo totalmente</td>
                    <td>Discordo</td>
                    <td>Discordo parcialmente</td>
                    <td>Não concordo, nem discordo</td>
                    <td>Concordo parcialmente</td>
                    <td>Concordo</td>
                    <td>Concordo totalmente</td>
                </tr>
            </table>
             
            <br>
            <br>
            <h5>Minha vida tem propósito e significado</h5>
            <br>
            <input id="level1" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel1').innerHTML = this.value;"  min="1" max="7" value="4" />
            <h1 id="showLevel1" class="numberLevel">4</h1>
            <br>
            <hr>
            <br>

            <h5>Meus relacionamentos sociais são uma fonte de apoio e gratificação</h5>
            <br>
            <input id="level2" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel2').innerHTML = this.value;"  min="1" max="7" value="4" />
            <h1 id="showLevel2" class="numberLevel">4</h1>
            <br>
            <hr>
            <br>
            <h5>Sou uma pessoa interessada e engajada em minhas atividades diárias</h5>
            <br>
            <input id="level3" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel3').innerHTML = this.value;"  min="1" max="7" value="4" />
            <h1 id="showLevel3" class="numberLevel">4</h1>
            <br>
            <hr>
            <br>
            <h5>Contribuo ativamente para a felicidade e o bem-estar dos outros</h5>
           <br>
            <input id="level4" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel4').innerHTML = this.value;"  min="1" max="7" value="4" />
            <h1 id="showLevel4" class="numberLevel">4</h1>
            <br>
            <hr>
            <br>

            <h5>Sou competente e capaz no que diz respeito ás atividades importantes para mim</h5>
            <br>
            <input id="level5" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel5').innerHTML = this.value;"  min="1" max="7" value="4" />
            <h1 id="showLevel5" class="numberLevel">4</h1>
            <br>
            <hr>
            <br>

            <h5>Sou uma boa pessoa e tenho uma boa vida</h5>
            <br>
            <input id="level6" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel6').innerHTML = this.value;"  min="1" max="7" value="4" />
            <h1 id="showLevel6" class="numberLevel">4</h1>
            <br>
            <hr>
            <br>
            <h5>Sinto-me otimista em relação ao meu futuro</h5>
            <input id="level7" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel7').innerHTML = this.value;"  min="1" max="7" value="4" />
            <h1 id="showLevel7" class="numberLevel">4</h1>
            <br>
            <hr>
            <br>

            <h5>As pessoas me respeitam</h5>
            <input id="level8" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel8').innerHTML = this.value;"  min="1" max="7" value="4" />
            <h1 id="showLevel8" class="numberLevel">4</h1>
            <br>
            <hr>
            <br>
            
            <h5>Comentários e anotações</h5>
            
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <div style="display: flex; justify-content: center;"> 
                 <button class="btn" >Salvar resultados</button>
            </div>
            <br>
            <br>
        </div>   
    </div>
</template>
<script>
export default {
    
    data(){

        return{
            nivel1:4,
            nivel2:4,
            nivel3:4,
            nivel4:4,
            nivel5:4,
            nivel6:4,
            nivel7:4,
            nivel8:4,
        }
    
    },
    methods:
    {
        alterValue: function(id)
        {
           
            if(id == 1)
            {
                 this.nivel1 = parseInt(document.getElementById("inputRangeAss"+id).value);
            }
            else
                if(id == 2)
                {
                    this.nivel2 = parseInt(document.getElementById("inputRangeAss"+id).value);
                }
                else
                    if(id == 3)
                    {
                        this.nivel3 = parseInt(document.getElementById("inputRangeAss"+id).value);
                    }
                    else
                        if(id == 4)
                        {
                            this.nivel4 = parseInt(document.getElementById("inputRangeAss"+id).value);
                        }
                        else
                            if(id == 5)
                            {
                                this.nivel5 = parseInt(document.getElementById("inputRangeAss"+id).value);
                                
                            }
                            else
                                if(id == 6)
                                {
                                    this.nivel6 = parseInt(document.getElementById("inputRangeAss"+id).value);
                                    
                                }
                                else
                                    if(id == 7)
                                    {
                                        this.nivel7 = parseInt(document.getElementById("inputRangeAss"+id).value);
                                        
                                    }
                                    else
                                        if(id == 8)
                                        {
                                            this.nivel8 = parseInt(document.getElementById("inputRangeAss"+id).value);
                                            
                                        }
        }
    }
}
</script>

<style scoped>
.numberLevel
{
    text-align:center;
    color:#983e7b;
}
.inputLevel
{
    width:100%;
}
table tr td
{
    text-align: center;
    width:150px;
    border-bottom-color:#D3D3D3;
    border-bottom-style: solid;
     
    border-width: 1px;
}
table tr th
{
    border-top-color:#D3D3D3;
    border-top-style: solid;
     
    border-width: 1px;
    text-align: center;
    width:150px;
}

h2
{
    text-align:justify;
}    

h5
{
    text-align:justify;    
}
p
{
    text-align: justify;
}
*
{
    font-family: roboto;
}
#boxAssessment
{
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    
    color: black;
}
*
{
    
    font-family: roboto;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
a:hover{
    color:#fec961;
}

input[type='range'] {
  cursor: pointer;
}

input[type='range'],
input[type='range']::-webkit-slider-runnable-track,
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}

/** 
 * Use o pseudo-seletor "::-webkit-slider-runnable-track" para customizar o "trilho" do campo.
 */
input[type='range']::-webkit-slider-runnable-track {
  
  height: 10px;
  background: #983e7b;
  border-radius:13px;
  
}

/** 
 * Use o pseudo-seletor "::-webkit-slider-thumb" para customizar o "seletor" do campo.
 */
input[type='range']::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  margin-top: -4.5px;
  background-color: #fec961;
  border-radius: 20px;
}
input{
    background-color:white;
    border-color:#983e7b;
    height:50px;
    border-radius:10px;
}
</style>