<template>
  <div id="rowText">
    <br />
    <br />

    <h1 style="text-align: center; font-size: 28px; color: white">
      <b>IMPACTOS POSITIVOS NAS VIDAS E NAS ORGANIZAÇÕES</b>
    </h1>
    <br />

    <div class="row" style="width: 80%; margin-left: 10%; margin-right: 10%">
      <div class="col" style="height: 100px; border-left: 5px solid #e5b259">
        <p style="font-size: 50px">
          <b
            >+<span id="value1">{{ value1 }}</span></b
          >
        </p>
        <p style="font-size: 15px; margin-top: -20px">
          pessoas já passaram por treinamento em grupo
        </p>
      </div>

      <div class="col" style="height: 100px; border-left: 5px solid #e5b259">
        <p style="font-size: 50px">
          <b
            >+<span id="value2">{{ value2 }}</span
            >h</b
          >
        </p>
        <p style="font-size: 15px; margin-top: -20px">
          horas de dedicação em atendimentos individuais, com foco na psicologia
          positiva.
        </p>
      </div>

      <div class="col" style="height: 100px; border-left: 5px solid #e5b259">
        <p style="font-size: 50px">
          <b
            >+<span id="value3">{{ value3 }}</span>
          </b>
        </p>
        <p style="font-size: 15px; margin-top: -20px">
          Empresas impactadas pela consultoria de Gestão de Pessoas
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    setInterval(() => {
      this.nextValue();
    }, 100);
  },
  data() {
    return {
      value1: 0,
      value2: 0,
      value3: 0,
    };
  },
  methods: {
    nextValue: function () {
      if (window.scrollY > 1400) {
        if (this.value1 < 4500) {
          var value1L = document.getElementById("value1");
          value1L.innerText = this.value1 + 300;
          this.value1 = parseInt(value1L.innerText);
        }
        if (this.value2 < 3500) {
          var value2L = document.getElementById("value2");
          value2L.innerText = this.value2 + 175;
          this.value2 = parseInt(value2L.innerText);
        }
        if (this.value3 < 150) {
          var value3L = document.getElementById("value3");
          value3L.innerText = this.value3 + 10;
          this.value3 = parseInt(value3L.innerText);
        }
      }
    },
    nextValue2: function () {},
  },
};
</script>
<style scoped>
#rowText {
  background-image: linear-gradient(180deg, #fd5392, #f86f64);
  width: 100%;
  height: 300px;
  color: white;
  box-shadow: inset 0 0 1em #fd5392, 0 0 1em #f86f64;
  margin-top: 15px;
}
* {
  margin: 0;
}
</style>
