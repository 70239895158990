<template>
  <div style="margin-left: 10%; margin-right: 10%">
    <br />
    <br />
    <br />
    <br />
    <h1 style="text-align: center; color: #bb6081"><b>Nossa História</b></h1>

    <div
      style="
        width: 10%;
        margin-left: 45%;
        margin-right: 45%;
        border-bottom: solid #f6c56f;
      "
    ></div>
    <br />
    <br />
    <div class="row">
      <div id="divText" class="col">
        <p id="textStory">
          A Florescer Desenvolvimento foi criada em 2017 quando a Fundadora
          Giliani Souza, teve um propósito muito forte: ajudar as pessoas,
          através da escuta e do direcionamento.<br /><br />
          Nos últimos anos, isso foi ficando cada vez mais INTENSO e seu
          propósito foi mergulhado cada vez mais em trazer uma solução de
          democratizar o acesso ao atendimento psicológico, aproximando
          pacientes e profissionais por meio da internet.<br /><br />
          Desde então, o sonho se torna cada dia mais real, com mais pessoas no
          time fazendo o sonho acontecer e percebemos que estamos a caminho de
          proporcionar algo a mais grandioso na vida das pessoas.<br /><br />
          Por meio das nossas soluções tecnológicas, pessoas que buscam
          conectarem com si mesmas, e encontrarem respostas em que vem buscando
          sobre suas potencialidades, poderão ser atendidas por profissionais
          altamente capacitados, através do uso do dispositivo com internet de
          modo seguro e prático, criando conexões prospera e realizando mudanças
          transformadoras em todas as dimensões da vida.
        </p>
      </div>
      <div id="divImg" class="col">
        <img id="img" src="../../imgs/giliani.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style scoped>
@media (min-width: 1000px) {
  #textStory {
    font-size: 13.5px;
  }
}
@media (min-width: 770px) and (max-width: 999px) {
  #img {
    width: 600px;
  }
  #divImg {
    display: flex;
    justify-content: center;
    order: 2;
  }
  #divText {
    flex-direction: row;
    width: 100vw;
    order: 1;
  }
  #textStory {
    font-size: 13.5px;
  }
}
@media (min-width: 605px) and (max-width: 769px) {
  #divImg {
    width: 100vw;
    display: flex;
    justify-content: center;
    order: 2;
  }
  #divText {
    flex-direction: row;
    width: 100vw;
    order: 1;
  }
  #textStory {
    font-size: 13.5px;
  }
}
@media (min-width: 420px) and (max-width: 604px) {
  #img {
    width: 400px;
  }
  #divImg {
    width: 100vw;
    display: flex;
    justify-content: center;
    order: 2;
  }
  #divText {
    flex-direction: row;
    width: 100vw;
    order: 1;
  }
  #textStory {
    font-size: 13.5px;
  }
}
@media (min-width: 0px) and (max-width: 419px) {
  #img {
    width: 300px;
  }
  #divImg {
    width: 100vw;
    display: flex;
    justify-content: center;
    order: 2;
  }
  #divText {
    flex-direction: row;
    width: 100vw;
    order: 1;
  }
  #textStory {
    font-size: 13.5px;
  }
}
</style>