<template>
    <div class="wrapper">
    
 
    <nav id="sidebar">
        <div class="sidebar-header">
          <img width="200px" src="../../imgs/logo_branco.jpg">
        </div>
       
            <ul  class="list-unstyled components">
                
            
            
                <li>
                    <a><img class="link" width="24px" style="margin-right:14px" src="../../imgs/dashboard.png">Dashboard</a>
                </li>
                <li>
                    <a><img class="link" width="24px" style="margin-right:14px" src="../../imgs/profissional.png">Meu perfil</a>
                </li>
                <li>
                    <a  href="#pageSubmenu2" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle"><img width="24px" class="link" style="margin-right:24px" src="../../imgs/clientes2.png">Clientes</a>
                    <ul style="text-align:justify;" class="collapse list-unstyled" id="pageSubmenu2">
                        <li >
                            <a href="#"><img width="24px"  class="sublink" style="margin-right:14px" src="../../imgs/pessoa-lendo.png">Pacientes</a>
                        </li>
                        <li>
                            <a href="#"><img width="22px" class="sublink" style="margin-right:14px" src="../../imgs/empresario.png">Funcionários</a>
                        </li>
                        <li>
                            <a href="#"><img width="22px"  class="sublink" style="margin-right:14px" src="../../imgs/companhia.png">Empresas</a>
                        </li>
                        
                        
                    </ul>
                </li>
                <li>
                    <a><img width="22px" class="link" style="margin-right:14px"  src="../../imgs/agenda.png">Minha agenda</a>
                </li>
                <li v-on:click="changePage()">
                    <a ><img style="margin-right:10px" class="link" src="../../imgs/assessment.png">Ferramentas</a>
                </li>
                <li>
                    <a ><img style="margin-right:10px" class="link" src="../../imgs/tutorial.png">Tutorial</a>
                </li>
                <li>
                    <a ><img  style="margin-right:10px" class="link" src="../../imgs/roda.png">Configurações</a>
                </li>
                 <li v-on:click="back()">
                    <a ><img  style="margin-right:10px" class="link" src="../../imgs/sair.png">Sair</a>
                </li>
            </ul>
            
    </nav>

</div> 

</template>
<script>
export default {
    methods:
    {
        changePage: function()
        {
           this.$router.push('pagina-ferramentas');
        },
        back: function()
        {
            localStorage.clear();
            localStorage.setItem('popup', 1);
            this.$router.push('login-cliente');
        }
    }
}
</script>



<style scoped>

.link
{
    margin-left:50px;
}
.sublink
{
    margin-left:30px;
    
}
.wrapper {
    display: flex;
    width: 100%;
}

#sidebar {
    width: 250px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 999;
    background: #7386D5;
    color: #fff;
    transition: all 0.9s;
}

#sidebar.active {
    margin-left: -250px;
}
a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}
p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}

a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.2s;
    
}

#sidebar {
    
    background: #983e7b;
    color: #fff;
    transition: all 0.9s;
}
#sidebar {
    min-width: 250px;
    max-width: 250px;
    min-height: 100vh;
}
#sidebar .sidebar-header {
    padding: 20px;
    background: #983e7b;
}

#sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #983e7b;
    
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 0.7em;
    display: block;
    
}
#sidebar ul li a:hover {
    color: #fff;
    background: rgb(255, 255, 255,0.1);
    cursor:pointer;
}

#sidebar ul li.active > a, a[aria-expanded="true"] {
    color: #fff;
    background: #e5b259;
}
ul ul a {
    font-size: 0.6em !important;
    padding-left: 30px !important;
    background: #aa468a;
}
</style>