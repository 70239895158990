<template>
    <div style="width:82%;position:absolute;left:18%;top:0%;margin-left:0%;"> 
        <br>
        <br>
        <br>
        <div id="boxAssessment" >
            <br>
            <h1 class="text-center">Dreamlist</h1>
            <br>
            <div class="row">
                <div class="col" style="width:33%;margin-top:0.2%;">
                    <h6 class="text-center"><b>Lista de Sonhos/Desejos/Metas/Objetivos/Planos</b></h6>
                    <input type="text" class="mt-2" style="width:100%">
                    <input type="text" class="mt-2" style="width:100%">
                    <input type="text" class="mt-2" style="width:100%">
                    <input type="text" class="mt-2" style="width:100%">
                    <input type="text" class="mt-2" style="width:100%">
                    <input type="text" class="mt-2" style="width:100%">
                    <input type="text" class="mt-2" style="width:100%">
                    <input type="text" class="mt-2" style="width:100%">
                    <input type="text" class="mt-2" style="width:100%">
                    <input type="text" class="mt-2" style="width:100%">
                </div>
                <div class="col" style="width:33%;margin-top:2%;">
                     <h6 class="text-center"><b>Categoria</b></h6>
                     <input type="text" class="mt-2" style="width:100%">
                     <input type="text" class="mt-2" style="width:100%">
                     <input type="text" class="mt-2" style="width:100%">
                     <input type="text" class="mt-2" style="width:100%">
                     <input type="text" class="mt-2" style="width:100%">
                     <input type="text" class="mt-2" style="width:100%">
                     <input type="text" class="mt-2" style="width:100%">
                     <input type="text" class="mt-2" style="width:100%">
                     <input type="text" class="mt-2" style="width:100%">
                     <input type="text" class="mt-2" style="width:100%">
                </div>
                <div class="col" style="width:33%;margin-top:2%;">
                    <h6 class="text-center"><b>Prazo</b></h6>
                    
                    <input type="date" class="mt-2" style="width:100%">
                    <input type="date" class="mt-2" style="width:100%">
                    <input type="date" class="mt-2" style="width:100%">
                    <input type="date" class="mt-2" style="width:100%">
                    <input type="date" class="mt-2" style="width:100%">
                    <input type="date" class="mt-2" style="width:100%">
                    <input type="date" class="mt-2" style="width:100%">
                    <input type="date" class="mt-2"  style="width:100%">
                    <input type="date" class="mt-2" style="width:100%">
                    <input type="date" class="mt-2" style="width:100%">
                </div>
            </div>
            <br>
            <br>

            
            <div style="display: flex; justify-content: center;"> 
                     <button class="btn" >Salvar resultados</button>
            </div>
            <br>
        </div>
    </div>
</template>

<style scoped>
*
{
    font-family: roboto;
}
.numberLevel
{
    text-align:center;
    color:#983e7b;
}
.inputLevel
{
    width:100%;
}
h5
{
    text-align:justify;    
}
p
{
    text-align:justify;
}
#boxAssessment
{
    
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    
    color: black;
}
*
{
    
    font-family: roboto;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
a:hover{
    color:#fec961;
}

</style>

<script>
export default {
     data() {
        return {
            
           
            opcoes: [
               {n: '0'},
               {n: '1'},
               {n: '2'},
               {n: '3'},
               {n: '4'},
               {n: '5'},
               {n: '6'},
               {n: '7'},
               {n: '8'},
               {n: '9'},
               {n: '10'},
            ]
        }
     }
   
}
</script>