<template>
    <div>
    <Navbar></Navbar>
    <div id="rowSpecialist" class="row">
        <div class="col-sm" id="message">
            <br>
            <br>
            <div style="display: flex; justify-content: center;"> 
                <img id="imgLogo" src="../../imgs/florescer_desenvolvimento.png" >
            </div>
            <br>
            <h2 >Faça parte da empresa que promoverá o florescimento na vida das pessoas!</h2>
            
            <div style="display: flex; justify-content: center;"> 
                <img id="imgSpecialist" src="../../imgs/cadastro-especialista.png">
            </div>
        </div>

        <div class="col-sm" id="formSpecialist">
            <br>
            <br>
            
            <div v-if="part==1" id="part1">
                        <br>
                        <h1>Deixe-nos saber a sua especialidade</h1>
                        <br>
                        <br>
                        <div id="inputSpecialty">
                            <div class="radio">
                                <input type="radio" id="psicologo" name="specialty" value="psicologo"
                                        checked>
                                <label class="labelSpecialty" for="psicologo">Psicólogo(a)</label>
                            </div>

                            <div class="radio">
                                <input type="radio" id="psicologoOrganizacional" name="specialty" value="psicologoOrganizacional">
                                <label class="labelSpecialty" for="psicologoOrganizacional">Psicólogo(a) Organizacional</label>    
                            </div>
                            
                            <div class="radio">
                                <input type="radio" id="masterCoach" name="specialty" value="masterCoach">
                                <label class="labelSpecialty" for="masterCoach">Master Coach</label>
                            </div>

                            <div class="radio">
                                <input type="radio" id="outros" name="specialty" value="outros">
                                <label class="labelSpecialty" for="outros">Outros</label>
                            </div>
                            <br>
                            <br>
                        </div>
                        <div style="display: flex; justify-content: center;"> 
                            <button v-on:click="saveSpecialty()">Salvar e continuar</button>
                        </div>
                        
            </div>
                       
            <div v-if="part==2" id="part2">
                <p  id="msg1" style="color:red;">{{msgError}}</p>
                <form>
                    
                    <div>
                        <label for="name">Nome: *</label>
                        <br>
                        <input   class="form-control input1" type="text" id="name" />
                        
                    </div>
                    <br>

                    <div class="row">
                        <div  class="col">
                            <label for="email">E-mail: *</label>
                            <br>
                            <input class="form-control input2" type="email" id="email" />
                        </div>
                        <div  class="col">
                            <label for="tel">Telefone/Celular: *</label>
                            <br>
                            <input class="form-control input2" v-on:blur="editTel()" maxlength="15" type="text" id="tel" />
                            
                        </div>
                    </div>
                    <br>

                    <div class="row">
                        <div  class="col">
                            <label for="linkedin">Link do linkedin:</label>
                            <br>
                            <input class="form-control input2" type="text" id="linkedin" />
                        </div>
                        <div  class="col">
                            <label for="date">Data de nascimento: *</label>
                            <br>
                            <input class="form-control input2" type="date" id="date" />
                        </div>
                    </div>
                    
                    <br>
                    <div class="row">
                        <div  class="col">
                            <label  for="cpf">CPF: *</label>
                            <br>
                            <input autocomplete="off" v-on:keyup="putMaskCpf($event)" maxlength="14" class="form-control input2" type="text" id="cpf" />
                        </div>
                        <div  class="col" v-if="this.specialty == 'psicologo' || this.specialty == 'psicologoOrganizacional'">
                            <label for="crp">CRP: *</label>
                            <br>
                            <input autocomplete="off" v-on:keyup="putMaskCrp($event)" maxlength="11" class="form-control input2" type="text" id="crp" />
                        </div>
                    </div>

                    <br>
                    <br>
                    <div style="display: flex; justify-content: center;"> 
                        <button v-on:click="goBack($event)" style="margin-right:15px" >Voltar</button>
                        <button v-on:click="verifyPart2($event)" >Salvar e continuar</button>
                    </div>
                </form>

                      
            </div>

            <div v-if="part==3" id="part3">
                <p  id="msg2" style="color:red;">{{msgError2}}</p>
                <form>
                   

                    <div class="row">
                        <div  class="col">
                            <label for="cep *">CEP: *</label>
                            <br>
                            <input key="1" v-on:keyup="putMaskCep($event)" v-on:blur="completeAddress()" maxlength="10" class="form-control input2" type="text" id="cep" />
                        </div>
                        <div  class="col">
                            <label for="state">Estado: *</label>
                            <br>
                            <select class="form-control input2" id="state" style="color:gray;" required="required">
                                <option  style="color:gray;" v-for="op in states" :key="op.uf">
                                    {{ op.uf }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div  class="col">
                            <label for="city">Cidade: *</label>
                            <br>
                            <input class="form-control input2" type="text" id="city" />
                        </div>
                        <div  class="col">
                            <label for="district">Bairro: *</label>
                            <br>
                            <input class="form-control input2" type="text" id="district" />
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div  class="col">
                            <label for="street">Rua: *</label>
                            <br>
                            <input class="form-control input2" type="text" id="street" />
                        </div>
                        <div  class="col">
                            <label for="number">Número: *</label>
                            <br>
                            <input class="form-control input2" type="number" id="number" />
                        </div>
                    </div>
                    <br>
                    <div>
                        <label for="complement">Complemento:</label>
                        <br>
                        <input   class="form-control input1" type="text" id="complement" />
                        
                    </div>
                    

                    <br>
                    <br>
                    <div style="display: flex; justify-content: center;"> 
                        <button v-on:click="goBack($event)" style="margin-right:15px" >Voltar</button>
                        <button v-on:click="verifyPart3($event)" >Salvar e continuar</button>
                    </div>
                </form>
               

                      
            </div>

            <div v-if="part==4" id="part4">
                <p  id="msg2" style="color:red;">{{msgError3}}</p>
                
                <form>
                    <div class="row" v-if="qtdFormations >= 1">
                        <div  class="col-4">
                            <label class="labelFormation" for="formation">Formação:</label>
                            <br>
                            <input class="input2 formations" type="text" id="formation1" />
                        </div>
                        <div  class="col-3">
                            <label class="labelFormation" for="level">Tipo:</label>
                            <br>
                            <input type="text" disabled  placeholder="Graduação" value="Graduação" class="input2 types">
                        </div>
                        <div  class="col-3">
                            <label class="labelFormation" for="crp">instituição:</label>
                            <br>
                            <input class="input2 institutions" id="institution1" type="text" />
                        </div>
                         <div  class="col-2">
                            <label class="labelFormation" for="crp">Concluiu em:</label>
                            <br>
                            <input class="input2 yearsConclusion" type="number" value="2021" id="year1"  />
                        </div>
                    </div>
                    <br>
                    <div class="row" v-if="qtdFormations >= 2">
                        <div  class="col-4">
                            <label class="labelFormation" for="formation">Formação:</label>
                            <br>
                            <input class="input2 formations" type="text"  />
                        </div>
                        <div  class="col-3">
                            <label class="labelFormation" for="level">Tipo:</label>
                            <br>
                            <select class="input2 types" style="color:gray;"  required="required">
                                <option  style="color:gray;" v-for="l in levels" :key="l.level">
                                    {{ l.level }}
                                </option>
                            </select>

                        </div>
                        <div  class="col-3">
                            <label class="labelFormation" for="crp">instituição:</label>
                            <br>
                            <input class="input2 institutions" type="text" />
                        </div>
                         <div  class="col-2">
                            <label class="labelFormation" for="crp">Concluiu em:</label>
                            <br>
                            <input class="input2 yearsConclusion" type="number" value="2021"  />
                        </div>
                    </div>
                    <br>
                    <div class="row" v-if="qtdFormations >= 3">
                        <div  class="col-4">
                            <label class="labelFormation" for="formation">Formação:</label>
                            <br>
                            <input class="input2 formations" type="text"  />
                        </div>
                        <div  class="col-3">
                            <label class="labelFormation" for="level">Tipo:</label>
                            <br>
                            <select class="input2 types"  style="color:gray;"  required="required">
                                <option  style="color:gray;" v-for="l in levels" :key="l.level">
                                    {{ l.level }}
                                </option>
                            </select>
                        </div>
                        <div  class="col-3">
                            <label class="labelFormation" for="crp">instituição:</label>
                            <br>
                            <input class="input2 institutions" type="text" />
                        </div>
                         <div  class="col-2">
                            <label class="labelFormation" for="crp">Concluiu em:</label>
                            <br>
                            <input class="input2 yearsConclusion" type="number" value="2021"  />
                        </div>
                    </div>
                    <br>
                     <div class="row" v-if="qtdFormations >= 4">
                        <div  class="col-4">
                            <label class="labelFormation" for="formation">Formação:</label>
                            <br>
                            <input class="input2 formations" type="text"  />
                        </div>
                        <div  class="col-3">
                            <label class="labelFormation" for="level">Tipo:</label>
                            <br>
                            <select class="input2 types"  style="color:gray;"  required="required">
                                <option  style="color:gray;" v-for="l in levels" :key="l.level">
                                    {{ l.level }}
                                </option>
                            </select>
                        </div>
                        <div  class="col-3">
                            <label class="labelFormation" for="crp">instituição:</label>
                            <br>
                            <input class="input2 institutions" type="text" />
                        </div>
                         <div  class="col-2">
                            <label class="labelFormation" for="crp">Concluiu em:</label>
                            <br>
                            <input class="input2 yearsConclusion" type="number" value="2021"  />
                        </div>
                    </div>
                    <br>
                     <div class="row" v-if="qtdFormations >= 5">
                        <div  class="col-4">
                            <label class="labelFormation" for="formation">Formação:</label>
                            <br>
                            <input class="input2 formations" type="text"  />
                        </div>
                        <div  class="col-3">
                            <label class="labelFormation" for="level">Tipo:</label>
                            <br>
                            <select class="input2 types" id="level" style="color:gray;"  required="required">
                                <option  style="color:gray;" v-for="l in levels" :key="l.level">
                                    {{ l.level }}
                                </option>
                            </select>
                        </div>
                        <div  class="col-3">
                            <label class="labelFormation" for="crp">instituição:</label>
                            <br>
                            <input class="input2 institutions" type="text" id="text" />
                        </div>
                         <div  class="col-2">
                            <label class="labelFormation" for="crp">Concluiu em:</label>
                            <br>
                            <input class="input2 yearsConclusion" type="number" value="2021" />
                        </div>
                    </div>
                    <br>
                     <div class="row" v-if="qtdFormations >= 6">
                        <div  class="col-4">
                            <label class="labelFormation" for="formation">Formação:</label>
                            <br>
                            <input class="input2 formations" type="text" id="formation" />
                        </div>
                        <div  class="col-3">
                            <label class="labelFormation" for="level">Tipo:</label>
                            <br>
                            <select class="input2 types" id="level" style="color:gray;"  required="required">
                                <option  style="color:gray;" v-for="l in levels" :key="l.level">
                                    {{ l.level }}
                                </option>
                            </select>
                        </div>
                        <div  class="col-3">
                            <label class="labelFormation" for="crp">instituição:</label>
                            <br>
                            <input class="input2 institutions" type="text"  />
                        </div>
                         <div  class="col-2">
                            <label class="labelFormation" for="crp">Concluiu em:</label>
                            <br>
                            <input class="input2 yearsConclusion" type="number" value="2021"  />
                        </div>
                    </div>
                   
                    <br>
                     <div class="row" v-if="qtdFormations >= 7">
                        <div  class="col-4">
                            <label class="labelFormation" for="formation">Formação:</label>
                            <br>
                            <input class="input2 formations" type="text" />
                        </div>
                        <div  class="col-3">
                            <label class="labelFormation" for="level">Tipo:</label>
                            <br>
                            <select class="input2 types"  style="color:gray;"  required="required">
                                <option  style="color:gray;" v-for="l in levels" :key="l.level">
                                    {{ l.level }}
                                </option>
                            </select>
                        </div>
                        <div  class="col-3">
                            <label class="labelFormation" for="crp">instituição:</label>
                            <br>
                            <input class="input2 institutions" type="text"  />
                        </div>
                         <div  class="col-2">
                            <label class="labelFormation" for="crp">Concluiu em:</label>
                            <br>
                            <input class="input2 yearsConclusion" type="number" value="2021"  />
                        </div>
                    </div>
                
                    <br>
                    <div class="row" v-if="qtdFormations >= 8">
                        <div  class="col-4">
                            <label class="labelFormation" for="formation">Formação:</label>
                            <br>
                            <input class="input2 formations" type="text"  />
                        </div>
                        <div  class="col-3">
                            <label class="labelFormation" for="level">Tipo:</label>
                            <br>
                            <select class="input2 types" style="color:gray;"  required="required">
                                <option  style="color:gray;" v-for="l in levels" :key="l.level">
                                    {{ l.level }}
                                </option>
                            </select>
                        </div>
                        <div  class="col-3">
                            <label class="labelFormation" for="crp">instituição:</label>
                            <br>
                            <input class="input2 institutions" type="text" />
                        </div>
                         <div  class="col-2">
                            <label class="labelFormation" for="crp">Concluiu em:</label>
                            <br>
                            <input class="input2 yearsConclusion" type="number" value="2021"  />
                        </div>
                    </div>
                    <br>
                    
                     <div class="row" v-if="qtdFormations >= 9">
                        <div  class="col-4">
                            <label class="labelFormation" for="formation">Formação:</label>
                            <br>
                            <input class="input2 formations" type="text"  />
                        </div>
                        <div  class="col-3">
                            <label class="labelFormation" for="level">Tipo:</label>
                            <br>
                            <select class="input2 types" style="color:gray;"  required="required">
                                <option  style="color:gray;" v-for="l in levels" :key="l.level">
                                    {{ l.level }}
                                </option>
                            </select>
                        </div>
                        <div  class="col-3">
                            <label class="labelFormation" for="crp">instituição:</label>
                            <br>
                            <input class="input2 institutions" type="text"  />
                        </div>
                         <div  class="col-2">
                            <label class="labelFormation" for="crp">Concluiu em:</label>
                            <br>
                            <input class="input2 yearsConclusion" type="number" value="2021"  />
                        </div>
                    </div>
                    <br>
                    
                     <div class="row" v-if="qtdFormations >= 10">
                        <div  class="col-4">
                            <label class="labelFormation" for="formation">Formação:</label>
                            <br>
                            <input class="input2 formations" type="text"  />
                        </div>
                        <div  class="col-3">
                            <label class="labelFormation" for="level">Tipo:</label>
                            <br>
                            <select class="input2 types"  style="color:gray;"  required="required">
                                <option  style="color:gray;" v-for="l in levels" :key="l.level">
                                    {{ l.level }}
                                </option>
                            </select>
                        </div>
                        <div  class="col-3">
                            <label class="labelFormation" for="crp">instituição:</label>
                            <br>
                            <input class="input2 institutions" type="text"  />
                        </div>
                         <div  class="col-2">
                            <label class="labelFormation" for="crp">Concluiu em:</label>
                            <br>
                            <input class="input2 yearsConclusion" type="number" value="2021" />
                        </div>
                    </div>
                    <br>
                    <div style="margin-top:-12.5px">
                        <div style="margin-left:10px" class="row">
                            <div class="button" id="plus" v-on:click="plusFormation()" >+</div>
                            <div class="button" id="less" style="margin-left:10px" v-on:click="lessFormation()" >-</div>
                        </div>
                        <div style="display: flex; justify-content: center;"> 
                            <button v-on:click="goBack($event)" style="margin-right:15px" >Voltar</button>
                            <button v-on:click="verifyPart4($event)" >Salvar e continuar</button>
                        </div>
                    </div>
                </form>

                      
            </div>
            <div v-if="part==5" id="part5">
                <p  id="msg3" style="color:red;">{{msgError4}}</p>
                <form>
                    <div v-if="specialty == 'psicologo'">
                        <p>Selecione as áreas que você possui maior conhecimento e deseja atuar? *</p>
                        <br>
                        <div class="row">
                            <div class="col">
                            <legend>Pessoal:</legend>
                                <div>
                                    <input type = "checkbox" id = "inteligenciaEmocional" name = "workThemesP" value = "Inteligência Emocional">
                                    <label class="labelArea" for = "inteligenciaEmocional">Inteligência Emocional</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "autoconfiancaAutoestima" name = "workThemesP" value = "Autoconfiança e autoestima">
                                    <label class="labelArea" for = "autoconfiancaAutoestima">Autoconfiança e autoestima</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "ansiedade" name = "workThemesP" value = "Ansiedade">
                                    <label class="labelArea" for = "ansiedade">Ansiedade</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "depressao" name = "workThemesP" value = "Depressao">
                                    <label class="labelArea" for = "depressao">Depressão</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "depressao" name = "workThemesP" value = "Adolescência">
                                    <label class="labelArea" for = "depressao">Adolescência</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "depressao" name = "workThemesP" value = "Saúde Mental">
                                    <label class="labelArea" for = "depressao">Saúde mental</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "emagrecimento" name = "workThemesP" value = "Emagrecimento">
                                    <label  class="labelArea" for = "emagrecimento">Emagrecimento</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "bemestar" name = "workThemesP" value = "Bem-estar">
                                    <label class="labelArea" for = "bemestar">Bem-estar</label>
                                </div>
                            </div>

                            <div class="col">
                                <legend>Relacional:</legend>
                                <div>
                                    <input type = "checkbox" id = "familia" name = "workThemesP" value = "Família">
                                    <label class="labelArea" for = "familia">Família</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "amigos" name = "workThemesP" value = "Amigos">
                                    <label class="labelArea" for = "amigos">Amigos</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "amoroso" name = "workThemesP" value = "Amoroso">
                                    <label class="labelArea" for = "amoroso">Amoroso</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "trabalho" name = "workThemesP" value = "Trabalho">
                                    <label class="labelArea" for = "trabalho">Trabalho</label>
                                </div>
                               
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col">
                            <legend>Profissional:</legend>
                                <div>
                                    <input type = "checkbox" id = "carreira" name = "workThemesP" value = "Carreira">
                                    <label class="labelArea" for = "carreira">Carreira</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "liderança" name = "workThemesP" value = "Lideranca">
                                    <label class="labelArea" for = "liderança">Liderança</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "motivacao" name = "workThemesP" value = "Motivacao">
                                    <label class="labelArea" for = "motivacao">Motivação</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "financeiro" name = "workThemesP" value = "Financeiro">
                                    <label class="labelArea" for = "financeiro">Financeiro</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "planejamentoOrganicao" name = "workThemesP" value = "Planejamento e organizaçao">
                                    <label class="labelArea" for = "planejamentoOrganicao">Planejamento e organização</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "gestaoDoTempo" name = "workThemesP" value = "Gestão do tempo">
                                    <label class="labelArea" for = "gestaoDoTempo">Gestão do tempo</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "estresse" name = "workThemesP" value = "Estresse">
                                    <label class="labelArea" for = "estresse">Estresse</label>
                                </div>
                            </div>

                            <div class="col">
                                <legend>Emocional/Espiritual:</legend>
                                <div>
                                    <input type = "checkbox" id = "qualidadeVida" name = "workThemesP" value = "Qualidade de vida">
                                    <label class="labelArea" for = "qualidadeVida">Qualidade de vida</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "significadoPropositoVida" name = "workThemesP" value = "Significado e propósito de vida">
                                    <label class="labelArea" for = "significadoPropositoVida">Significado e propósito de vida</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "conexaoConsigoMesmo" name = "workThemesP" value = "Conexão consigo mesmo">
                                    <label class="labelArea" for = "conexaoConsigoMesmo">Conexão consigo mesmo</label>
                                </div>

                               
                            </div>
                        </div>
                        <br>
                       
                    </div>

                    <div v-if="specialty == 'psicologoOrganizacional'">
                        <p>Selecione as áreas que você possui maior conhecimento e deseja atuar? *</p>
                        <br>
                        <div >
                            
                        
                                <div>
                                    <input type = "checkbox" id = "inteligenciaEmocional" name = "workThemesPO" value = "Gestão de pessoas">
                                    <label class="labelArea" for = "inteligenciaEmocional">Gestão de pessoas</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "autoconfiancaAutoestima" name = "workThemesPO" value = "Avaliação psicológica">
                                    <label class="labelArea" for = "autoconfiancaAutoestima">Avaliação psicológica</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "ansiedade" name = "workThemesPO" value = "Consultoria empresarial">
                                    <label class="labelArea" for = "ansiedade">Consultoria empresarial</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "emagrecimento" name = "workThemesPO" value = "Mentoria em recursos humanos">
                                    <label class="labelArea" for = "emagrecimento">Mentoria em recursos humanos</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "bemestar" name = "workThemesPO" value = "Treinamento e desenvolvimento">
                                    <label class="labelArea" for = "bemestar">Treinamento e desenvolvimento</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "bemestar" name = "workThemesPO" value = "Desenvolvimento de competências profissionais">
                                    <label class="labelArea" for = "bemestar">Desenvolvimento de competências profissionais</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "bemestar" name = "workThemesPO" value = "Desenvolvimento pessoal e profissional">
                                    <label class="labelArea" for = "bemestar">Desenvolvimento pessoal e profissional</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "bemestar" name = "workThemesPO" value = "Orientação profissional">
                                    <label class="labelArea" for = "bemestar">Orientação profissional</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "bemestar" name = "workThemesPO" value = "Saúde mental">
                                    <label class="labelArea" for = "bemestar">Saúde mental</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "bemestar" name = "workThemesPO" value = "Treinamento empresarial">
                                    <label class="labelArea" for = "bemestar">Treinamento empresarial</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "bemestar" name = "workThemesPO" value = "Recrutamento e seleção">
                                    <label class="labelArea" for = "bemestar">Recrutamento e seleção</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "bemestar" name = "workThemesPO" value = "Avaliação">
                                    <label class="labelArea" for = "bemestar">Avaliação</label>
                                </div>

                            

                              
                        </div>
                    </div>

                    <div v-if="specialty == 'masterCoach'">
                            <p>Selecione as áreas que você possui maior conhecimento e deseja atuar? *</p>
                            <br>
                            <div >
                            
                        
                                <div>
                                    <input type = "checkbox" id = "mentoria" name = "workThemesMC" value = "Mentoria">
                                    <label class="labelArea" for = "mentoria">Mentoria</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "consultoriaEmpresarial" name = "workThemesMC" value = "Consultoria empresarial">
                                    <label class="labelArea" for = "consultoriaEmpresarial">Consultoria empresarial</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "consultoriaEmpresarial" name = "workThemesMC" value = "Consultoria pessoal">
                                    <label class="labelArea" for = "consultoriaPessoal">Consultoria pessoal</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "financas" name = "workThemesMC" value = "Finanças">
                                    <label class="labelArea" for = "financas">Finanças</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "emagrecimento" name = "workThemesMC" value = "Emagrecimento">
                                    <label class="labelArea" for = "emagrecimento">Emagrecimento</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "carreira" name = "workThemesMC" value = "Carreira">
                                    <label class="labelArea" for = "carreira">Carreira</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "lideranca" name = "workThemesMC" value = "Liderança">
                                    <label class="labelArea" for = "lideranca">Liderança</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "pessoal" name = "workThemesMC" value = "Pessoal">
                                    <label class="labelArea" for = "pessoal">Pessoal</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "profissional" name = "workThemesMC" value = "Profissional">
                                    <label class="labelArea" for = "profissional">Profissional</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "relacionamento" name = "workThemesMC" value = "Relacionamento">
                                    <label class="labelArea" for = "relacionamento">Relacionamento</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "espiritual" name = "workThemesMC" value = "Espiritual">
                                    <label class="labelArea" for = "espiritual">Espiritual</label>
                                </div>
                            </div>
                    </div>

                    <div v-if="specialty == 'outros'">
                            <p>Selecione as áreas que você possui maior conhecimento e deseja atuar? *</p>
                            <br>
                            <div >
                            
                        
                                <div>
                                    <input type = "checkbox" id = "consultoriaPessoal" name = "workThemesO" value = "Consultoria Pessoal">
                                    <label class="labelArea" for = "consultoriaPessoal">Consultoria Pessoal</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "consultoriaFamiliar" name = "workThemesO" value = "Consultoria Familiar">
                                    <label class="labelArea" for = "consultoriaFamiliar">Consultoria Familiar</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "relacionamentoAmoroso" name = "workThemesO" value = "Relacionamento amoroso">
                                    <label class="labelArea" for = "relacionamentoAmoroso">Relacionamento amoroso</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "saudeMental" name = "workThemesO" value = "Saúde mental">
                                    <label class="labelArea" for = "saudeMental">Saúde mental</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "depressaoAnsiedade" name = "workThemesO" value = "Depressão/Ansiedade">
                                    <label class="labelArea" for = "depressaoAnsiedade">Depressão/Ansiedade</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "carreira" name = "workThemesO" value = "Carreira">
                                    <label class="labelArea" for = "carreira">Carreira</label>
                                </div>
                                <div>
                                    <input type = "checkbox" id = "adolescencia" name = "workThemesO" value = "Adolescência">
                                    <label class="labelArea" for = "adolescencia">Adolescência</label>
                                </div>
                            </div>
                    </div>
                    <br>
                    <div style="display: flex; justify-content: center;"> 
                        <button v-on:click="goBack($event)" style="margin-right:15px" >Voltar</button>
                        <button v-on:click="verifyPart5($event)" >Salvar e continuar</button>
                    </div>
                    
                </form>
            </div>
            <div v-if="part==6" id="part6">
                
                <form>
                    <div>
                        <label id="msg4" for="resume">Conte-nos mais sobre você: *</label>
                        <br>
                        <textarea placeholder="Escreva aqui um breve descritivo profissional/pessoal seu." type="text" maxlength="600" id="resume" ></textarea>
                    </div>
                    <br>
                    
                    <br>
                    <div style="display: flex; justify-content: center;"> 
                        <button v-on:click="goBack($event)" style="margin-right:15px" >Voltar</button>
                        <button v-on:click="verifyPart6($event)" >Salvar e continuar</button>
                    </div>
                </form>
            </div>
            <br>
            <div v-if="part==7" id="part7">
                <p  id="msg6" style="color:red;">{{msgError6}}</p>
                <form>
                    <div>
                        <label for="howFind">Como nos encontrou? *</label>
                        <br>
                        <select v-on:click="showTextBox()" class="custom-select input1" id="howFind" style="color:gray;"  required="required">
                            <option  style="color:gray;" v-for="op in howFind" :key="op.midia">
                                {{ op.midia }}
                            </option>
                        </select>
                    </div>
                    <br>
                    <div v-if="boxOthers==true">
                        <label for="name">Em qual outro meio nos encontrou? *</label>
                        <br>
                        <input   class="form-control input1" type="text" id="other" />
                        <br>
                        <br>
                    </div>
                    <div>
                        <label for="name">Link para o Currículo Lattes: </label>
                        <br>
                        <input   class="form-control input1" type="text" id="lattes" />    
                    </div>
                    <br>
                    <div>
                        <label for="name">Currículo: *</label>
                        <br>
                        <input   type="file" id="file" />    
                    </div>
                    <br>
                    <div style="display: flex; justify-content: center;"> 
                        <button v-on:click="goBack($event)" style="margin-right:15px" >Voltar</button>
                        <button v-on:click="verifyPart7($event)" >Salvar e continuar</button>
                    </div>
                </form>
            </div>
            <br>
            <div v-if="part==8" id="part8">
                <p  id="msg5" style="color:red;">{{msgError5}}</p>
                <form>
                  
                   
                    
                   
                    <div>
                        <label for="password">Senha: *</label>
                        <br>
                        <input maxlength="10" class="form-control input1" v-on:click="showMsgPassword()" type="password" id="password" />
                    </div>
                    <br>
                    <div>
                        <label for="confirmPassword">Confirme sua senha: *</label>
                        <br>
                        <input maxlength="10" class="form-control input1" v-on:click="showMsgPassword()" type="password" id="confirmPassword" />
                    </div>
                    <br>
                    <br>
                    <div style="display: flex; justify-content: center;"> 
                        <input id="agreeTerms" type="checkbox" >
                        <label>Ao registrar você concorda com nossos termos uso</label>
                    </div>
                    <br>
                    <div style="display: flex; justify-content: center;"> 
                        <button v-on:click="goBack($event)" style="margin-right:15px" >Voltar</button>
                        <button v-on:click="verifyPart8($event)" >Registrar-se</button>
                    </div>
                </form>
            </div>
            <div v-if="part==9" id="part9">
                <h2 style="text-align:justify;margin-left:10%;margin-right:10%;">Seu cadastro foi concluido e será verificado por um administrador, em breve entraremos em contato no seu email!</h2>
                <div style="display: flex; justify-content: center;">
                    <img width="350px" src="../../imgs/email.png">
                </div>
            </div>
        </div>
        
    </div>
    <Footer></Footer>
    </div>
</template>
<script>
import axios from 'axios';
import api from "../services/api.js"; 
import Navbar from "../components/navbar.vue";
import Footer from "../components/footer.vue";
export default
{
    components:
    {
        Navbar, Footer
    },
    mounted()
    {
        document.body.addEventListener('keypress', function (event) {
            const code = event.keyCode;
            
            if(code == 13)
            {
                event.preventDefault();
            }
        });
    },
    updated()
    {
        
        
        
        if(this.qtdFormations == 1)
        {
                document.getElementById("less").style.backgroundColor="#D3D3D3";
        }
        if(this.qtdFormations > 1)
        {
                document.getElementById("less").style.backgroundColor="#983e7b";
        }
        
        if(this.qtdFormations < 10)
        {
                document.getElementById("plus").style.backgroundColor="#983e7b";
        }
        if(this.qtdFormations == 10)
        {
                document.getElementById("plus").style.backgroundColor="#D3D3D3";
        }

       
        
    },
    
    methods:
    {
        
        showTextBox: function()
        {
           var howFind = document.getElementById("howFind").value;
           
           if(howFind == "Outros")
           {
                this.boxOthers=true;  
           } 
           else
           {
               this.boxOthers=false;
           }
        },
        showMsgPassword: function()
        {
            this.msgError5 = "A senha deve conter pelo menos 6 caracteres, com letras e números";
        
        },
        verifyPart7: function(event)
        {
            event.preventDefault();
            this.file = document.getElementById("file").value;
            this.howFindSelected = document.getElementById("howFind").value;
            this.lattes = document.getElementById("lattes").value;

            if(this.boxOthers == true)
            {
                var otherMedia = document.getElementById("other").value;
            }

            if(this.howFind == "")
            {
                this.msgError6 = "Preencha todos os campos obrigatórios!";
            }
            else
                if(!this.file)
                {
                    this.msgError6 = "Faça upload de seu currículo";
                }
                else
                    if(this.boxOthers== true && !otherMedia)
                    {
                        this.msgError6 = "Preencha todos os dados obrigatórios!";
                    }
                    else
                    {
                        this.changeText();
                    }
            
            
        },
        cleanMsgs: function()
        {
            this.msgError="";
            this.msgError2="";
            this.msgError3="";
            this.msgError4="";
            this.msgError5="";
        },
        goBack: function(event)
        {
            event.preventDefault();
            const back = confirm("Se fizer isso você irá perder todos os dados preenchidos nessa parte do formulário, deseja realmente voltar?");
            if(back == true)
            {
                event.preventDefault();
                this.part=this.part-1;
                this.cleanMsgs();
            }
        },
        completeAddress: function()
        {
            const cep = document.getElementById("cep").value;
            const url = `https://viacep.com.br/ws/${cep}/json/`;
            
            if(cep)
            {
                
                axios
                    .get(url)
                    .then(response => {
                        console.log(response);
                        if(response.data.localidade)
                        {
                            this.currentCity =  response.data.localidade;
                            document.getElementById("city").value = this.currentCity;
                        }

                        if(response.data.uf)
                        {
                            this.currentState = response.data.uf;
                            document.getElementById("state").value = this.currentState;
                        }
                        
                        if(response.data.logradouro)
                        {
                            this.currentStreet = response.data.logradouro;
                            document.getElementById("street").value = this.currentStreet;
                        }

                        if(response.data.bairro)
                        {
                            this.currentDistrict = response.data.bairro;
                            document.getElementById("district").value = this.currentDistrict;
                        }
                        
                        
                })
                .catch(error => {
                    console.log(error)
                    this.errored = true
                })
                
            }
        },
        verifyPassword: function(password)
        {
            
           
            if(password.length >= 6)
            {
                if(/[0-9]/.test(password))
                {
                    if((/[a-z]/.test(password)) ||  (/[A-Z]/.test(password)))
                    {
                        return true;
                    }
                    else
                    {
                        return false;
                    }
                }
                else
                {
                    return false;
                }
            }
            else
            {
                
                return false;
            }
            

            
        },
        verifyPart8: function(event)
        {
            event.preventDefault();
            this.password = document.getElementById("password").value;
            const confirmPassword = document.getElementById("confirmPassword").value;
            const agreeTerms = document.getElementById("agreeTerms");
            const passwordValid = this.verifyPassword(this.password);
            
            if(!this.password)
            {
                this.msgError5 = "Crie sua senha";
            }
            else
                if(!confirmPassword)
                {   
                    this.msgError5 = "Confirme sua senha";
                }
                else
                    if(this.password !=confirmPassword)
                    {
                        this.msgError5 = "As senhas não correspondem";
                    }
                    else
                        if(passwordValid == false)
                        {
                            this.msgError5 = "A senha deve conter pelo menos 6 caracteres, com letras e números";
                        }
                        else
                            if(!agreeTerms.checked)
                            {
                                this.msgError5 = "Aceite os termos de uso para registrar-se";
                            }
                            else
                            {     
                                this.sendData();
                            }
        },
        sendData: async function()
        {   
            
           
            try
            {
                await api.post('http://api.florescer.b2ml.com.br:15200/api/register-specialist', {

                                        
                                        name: this.name,
                                        email: this.email,
                                        password: this.password,
                                        phoneNumber: this.tel,
                                        roleType:"Specialist",
                                        useTerms:true,
                                        formation:this.listFormations,
                                        formationType:this.listTypes,
                                        institution:this.listInstitutions,
                                        graduationYear: this.listYearsConclusion,
                                        linkedin:this.linkedin,
                                        birthDate:this.date,
                                        cpf:this.cpf,
                                        occupationArea:this.listOccupationAreaSelected,
                                        specialty: this.specialty,
                                        crp: this.crp,
                                        curriculum: this.file,
                                        about:this.resume,
                                        lattesCurriculum: this.lattes,
                                        howFindUs: this.howFindSelected,
                                        
                                        
                                    })
                                    .then((response) => {

                                        console.log(response);
                                        localStorage.setItem('specialistId',response.data.user.id);
                            
                                    }, (error) => {
                                        console.log(error);
                                        this.msgError5 = "Erro";
            });

            
            
                this.sendAddress();
            
            }
            catch(e)
            {
                console.log(e);
            }
            
                    
                    
                    

                    
            
        },
        sendAddress: async function()
        {
            const specialistId = localStorage.getItem('specialistId');
            
            await api.post('http://api.florescer.b2ml.com.br:15200/api/address', {

                                        userID: specialistId,
                                        address:this.street,
                                        houseNumber:this.number,
                                        city: this.city,
                                        state:this.state,
                                        zip:this.cep,
                                        neighborhood: this.district,
                                        complement: this.complement
                                        
                                    })
                                    .then((response) => {
                                        console.log(response);
                                        this.part=9;
                                        //const idFormation = response.data.user.id;
                                        
                                    }, (error) => {
                                        console.log(error);
            });
            
        },
        SaveOccupationArea: function()
        {
            
            this.listOccupationArea = [];
            this.listOccupationAreaSelected = [];
            if(this.specialty == 'psicologo')
            {
                this.listOccupationArea = document.getElementsByName("workThemesP");
            }
            else
                if(this.specialty == "psicologoOrganizacional")
                {
                    this.listOccupationArea = document.getElementsByName("workThemesPO");
                }
                else
                    if(this.specialty == "masterCoach")
                    {
                        this.listOccupationArea = document.getElementsByName("workThemesMC");
                    }
                    else
                        if(this.specialty == "outros")
                        {
                            this.listOccupationArea = document.getElementsByName("workThemesO");
                        }

            
            for(var i=0;i<this.listOccupationArea.length;i++)
            {
                if(this.listOccupationArea[i].checked)
                {
                    this.listOccupationAreaSelected.push(this.listOccupationArea[i].value);
                }
            }
            
           
            
        },
        saveFormation: function()
        {
            var arrayFormations=[], arrayInstituions=[],arrayTypes=[], arrayYearsConclusion=[];
            arrayFormations = document.querySelectorAll(".formations");
            
            arrayInstituions = document.querySelectorAll(".institutions");
            arrayTypes = document.querySelectorAll(".types");
            arrayYearsConclusion = document.querySelectorAll(".yearsConclusion");      
            for(var j=0;j<this.qtdFormations;j++)
            {
                this.listFormations[j] = arrayFormations[j].value;
                this.listInstitutions[j] = arrayInstituions[j].value;
                this.listTypes[j]=arrayTypes[j].value;
                this.listYearsConclusion[j] = arrayYearsConclusion[j].value;
                
            }
            
        },
        verifyPart6: function(event)
        {
            event.preventDefault();
            this.resume=document.getElementById("resume").value;
            if(!this.resume)
            {
                document.getElementById("msg4").style.color="red";
            }
            else
            {
                this.changeText();
            }
        },
        verifyPart3: function(event)
        {
            event.preventDefault();
            this.cep = document.getElementById("cep").value;
            this.city = document.getElementById("city").value;
            this.state = document.getElementById("state").value;
            this.street = document.getElementById("street").value;
            this.district = document.getElementById("district").value;
            this.number = document.getElementById("number").value;
            this.complement = document.getElementById("complement").value;
            
            if(!this.cep)
            {
                this.msgError2 = "Preencha todos os campos obrigatórios!";
            }
            else
                if(!this.city)
                {
                    this.msgError2 = "Preencha todos os campos obrigatórios!";
                }
                else
                    if(!this.street)
                    {
                         this.msgError2 = "Preencha todos os campos obrigatórios!";
                    }
                    
                        else
                            if(!this.district)
                            {
                                this.msgError2 = "Preencha todos os campos obrigatórios!";
                            }
                            else
                                if(!this.number)
                                {
                                    this.msgError2 = "Preencha todos os campos obrigatórios!";
                                }
                                else
                                {
                                    this.changeText();
                                }

        },
        verifyPart4: function(event)
        {
            event.preventDefault();
            var formation = document.getElementById("formation1").value;
            var institution = document.getElementById("institution1").value;
            var year = document.getElementById("year1").value;
            
            
            if(!formation)
            {
                this.msgError3 = "É necessário possuir pelo menos uma graduação!";
            }
            else
                if(!institution)
                {
                    this.msgError3 = "É necessário possuir pelo menos uma graduação!";
                }
                else
                    if(!year)
                    {
                         this.msgError3 = "É necessário possuir pelo menos uma graduação!";
                    }
                    else
                    {
                        this.saveFormation();
                        this.changeText();
                    }
            

        },
        verifyPart5: function(event)
        {
            event.preventDefault();
            var workThemes;
            if(this.specialty == 'psicologo')
            {
                workThemes = document.getElementsByName('workThemesP');
            }
                else
                    if(this.specialty == 'psicologoOrganizacional')
                    {
                        workThemes = document.getElementsByName('workThemesPO');
                    }
                    else
                        if(this.specialty == 'masterCoach')
                        {
                            workThemes = document.getElementsByName('workThemesMC');
                        }
                        else
                            if(this.specialty == 'outros')
                            {
                                workThemes = document.getElementsByName('workThemesO');
                            }
                            

            var ok=false;
            for (var i = 0; i < workThemes.length; i++)
            {
                if ( workThemes[i].checked ) {
                    ok = true;
                    break;
                }
                
            }
            if(ok == true)
            {
                this.SaveOccupationArea();
                this.changeText();
            }
            else
            {
                this.msgError4="Selecione no mínimo uma área que deseja atuar!";
            }

        },
        putMaskCpf: function(event)
        {
            if(event.keyCode != 8)
            {
                const cpf = document.getElementById("cpf").value;
                if(cpf.length==3)
                {
            
                    document.getElementById("cpf").value=cpf+'.';
                }
                else
                    if(cpf.length==7)
                    {
                        document.getElementById("cpf").value=cpf+'.';
                    }
                    else
                        if(cpf.length==11)
                        {
                            document.getElementById("cpf").value=cpf+'-';
                        }
            }
        },
        putMaskCep: function(event)
        {
            if(event.keyCode != 8)
            {
                const cep = document.getElementById("cep").value;
                if(cep.length==5)
                {
            
                    document.getElementById("cep").value=cep+'-';
                }
                
            }
        },
        putMaskCrp: function(event)
        {
            if(event.keyCode != 8)
            {
                const crp = document.getElementById("crp").value;
                if(crp.length==2)
                {
            
                    document.getElementById("crp").value=crp+'/';
                }
               
            }
        },
        testCpf: function(strCPF)
        {
            
            var sum;
            var rest;
            sum = 0;
            if (strCPF == "00000000000") return false;

            for (var i=1; i<=9; i++)    
                sum = sum + parseInt(strCPF.substring(i-1, i)) * (11 - i);
            
            rest = (sum * 10) % 11;

            if ((rest == 10) || (rest == 11))  
                rest = 0;

            if (rest != parseInt(strCPF.substring(9, 10)) ) 
                return false;

            sum = 0;
            
            for (i = 1; i <= 10; i++) 
                sum = sum + parseInt(strCPF.substring(i-1, i)) * (12 - i);

            rest = (sum * 10) % 11;

            if ((rest == 10) || (rest == 11)) 
                 rest = 0;

            if (rest != parseInt(strCPF.substring(10, 11) ) ) 
                return false;

            return true;
        },
        editTel: function()
        {
           var tel = document.getElementById("tel").value;
           
           if(tel.length >= 11)
           {
               
               const ddd = tel.slice(0,2);
               const part1 = tel.slice(2,7);
               const part2 = tel.slice(7,11);
               if(isNaN(ddd) == true || isNaN(part1) == true || isNaN(part2) == true )
               {
                   document.getElementById("tel").value = "(11)99999-9999";
               }
               else
               {
                   tel = `(${ddd})${part1}-${part2}`;
                   document.getElementById("tel").value = tel;
               }
               
           }
           else
                if(tel.length == 10)
                {
                    const ddd = tel.slice(0,2);
                    const part1 = tel.slice(2,6);
                    const part2 = tel.slice(6,10);
                    if(isNaN(ddd) == true || isNaN(part1) == true || isNaN(part2) == true )
                    {
                        document.getElementById("tel").value = "(11)99999-9999";
                    }
                    else
                    {
                        tel = `(${ddd})${part1}-${part2}`;
                        document.getElementById("tel").value = tel;
                    }
                }
        },
        calculateAge: function(date)
        {
           
         
            var partDate = date.split("-");
            var year = parseInt(partDate[0]);
            var month = parseInt(partDate[1]);
            var day = parseInt(partDate[2]);
            var currentYear = parseInt(new Date().getFullYear());
            var currentMonth = parseInt(new Date().getMonth()+1);
            var currentDay = parseInt(new Date().getDate());
            this.date=`${year}-${month}-${day}`;
            var age = currentYear - year;
            var remainingMonths = currentMonth - month;
            var remainingDays = currentDay - day;
            if(remainingMonths < 0)
            {
                age= age -1;
            }
            else
                if(remainingDays < 0)
                {
                    age = age-1;
                }
            
            
            return age;
          
        },
        getCpf: function(cpf)
        {
           
                var part1= cpf.slice(0,3);
                var part2= cpf.slice(4,7);
                var part3 = cpf.slice(8,11);
                var part4 = cpf.slice(12,14);
            
                cpf = `${part1}${part2}${part3}${part4}`;
                
                return cpf;
            
        },
        verifyPart2: function(event)
        {
            
            event.preventDefault();
            
            this.name = document.getElementById("name").value;
            this.linkedin = document.getElementById("linkedin").value;
            this.email = document.getElementById("email").value;
            this.tel=document.getElementById("tel").value;
           
            this.date = document.getElementById("date").value;
            
            const age = this.calculateAge(this.date);
            const cpf = document.getElementById("cpf").value;
            if(cpf)
            {
                this.cpf = this.getCpf(cpf);
            }
            
            const resultTestCPF = this.testCpf(this.cpf);
            if(this.specialty == "psicologo" || this.specialty == "psicologoOrganizacional")
            {
               this.crp = document.getElementById("crp").value;
               if(!this.name)
               {
                   this.msgError = "Preencha todos os campos obrigatórios!";
               }
               else
                    if(!this.email)
                    {
                        this.msgError = "Preencha todos os campos obrigatórios!";
                    }
                    else
                        if(!this.tel)
                        {
                            this.msgError = "Preencha todos os campos obrigatórios!";
                        }
                        else
                            if(!this.date)
                            {
                                this.msgError = "Preencha todos os campos obrigatórios!";
                            }
                            else
                                if(!this.cpf)
                                {
                                    this.msgError = "Preencha todos os campos obrigatórios!";
                                }
                                else
                                    if(!this.crp)
                                    {
                                       this.msgError = "Preencha todos os campos obrigatórios!"; 
                                    }
                                    else
                                        if(age<18 && age>0)
                                        {
                                            this.msgError = "Somente maiores de 18 anos podem se cadastrar como especialista!";
                                        }
                                        else
                                            if(age<0)
                                            {
                                                this.msgError = "Data de nascimento inválida!";
                                            }
                                            else
                                                if(age>100)
                                                {
                                                    this.msgError = "Data de nascimento inválida!";
                                                }
                                                else
                                                    if(resultTestCPF == false)
                                                    {
                                                        this.msgError = "CPF inválido!";
                                                    }
                                                    else
                                                    {
                                                        
                                                        this.changeText();
                                                    }
            }
            else
            {
               if(!this.name)
               {
                   this.msgError = "Preencha todos os campos obrigatórios!";
               }
               else
                    if(!this.email)
                    {
                        this.msgError = "Preencha todos os campos obrigatórios!";
                    }
                    else
                        if(!this.tel)
                        {
                            this.msgError = "Preencha todos os campos obrigatórios!";
                        }
                        else
                            if(!this.date)
                            {
                                this.msgError = "Preencha todos os campos obrigatórios!";
                            }
                            else
                                if(!this.cpf)
                                {
                                    this.msgError = "Preencha todos os campos obrigatórios!";
                                }
                                else
                                        if(age<18 && age>0)
                                        {
                                            this.msgError = "Somente maiores de 18 anos podem se cadastrar como especialista!";
                                        }
                                        else
                                            if(age<0)
                                            {
                                                this.msgError = "Data de nascimento inválida!";
                                            }
                                            else
                                                if(age>100)
                                                {
                                                    this.msgError = "Data de nascimento inválida!";
                                                }
                                                else
                                                    if(resultTestCPF == false)
                                                    {
                                                        this.msgError = "CPF inválido!";
                                                    }
                                                    else
                                                    {
                                                        
                                                        this.changeText();
                                                    }
            }           
        },
        saveSpecialty: function()
        {
            this.specialty =  document.querySelector('input[name="specialty"]:checked').value;
            this.part=this.part+1;
        },
        
        plusFormation: function()
        {
            if(this.qtdFormations<10)
            {
                this.qtdFormations=this.qtdFormations+1;
            }
            if(this.qtdFormations >= 5)
            {
              this.increaseSize();  
            }
            
        },
        increaseSize: function()
        {
            
            document.getElementById("rowSpecialist").style.height = "1300px";
           
        },
        decreaseSize: function()
        {
            document.getElementById("rowSpecialist").style.height = "850px";
           
        },
        lessFormation: function()
        {
            if(this.qtdFormations>1)
            {
                this.qtdFormations=this.qtdFormations-1;
            }   
        },
        changeText: function()
        {
            
            this.part=this.part+1;
            if(this.part == 5)
            {
                this.decreaseSize();
            }
        }
    },
    data()
    {
        return{
            idUser:"",
            idFormation:"",
            idAddress:"",
            howFindSelected:"",
            file:"",
            lattes: "",
            jsonOccupationArea:[],
            listOccupationAreaSelected:[],
            listOccupationArea:[],
            JsonFormations:{},
            listFormations:[],
            listTypes:[],
            listInstitutions:[],
            listYearsConclusion:[],
            name:"",
            password:"",
            email:"",
            tel:"",
            state:"",
            date:"",
            cpf:"",
            crp:"",
            cep:"",
            city:"",
            street:"",
            district:"",
            number:"",
            resume:"",
            complement:"",
            boxOthers:false,
            currentDistrict:"",
            currentStreet:"",
            currentState:"",
            currentCity:"",
            msgError:"",
            msgError2:"",
            msgError3:"",
            msgError4:"",
            msgError5:"",
            msgError6:"",
            empty:false,
            specialty:"",
            qtdFormations:1,
            part:1,
            levels: [
               {level: 'Curso/Especialização'},
               {level: 'Graduação'},
               {level: 'Pós-Graduação'},
               {level: 'MBA'},
               {level: 'Mestrado'},
               {level: 'Doutorado'}
            ],
            howFind: [
               {midia: ''},
               {midia: 'Facebook'},
               {midia: 'Twitter'},
               {midia: 'Google'},
               {midia: 'Instagram'},
               {midia: 'Youtube'},
               {midia: 'Noticias'},
               {midia: 'Indicação de amigo'},
               {midia: 'LinkedIn'},
               {midia: 'Outros'}
            ],
            states: [
              {uf: 'SP'},
              {uf: 'RJ'},
              {uf: 'ES'},
              {uf: 'MG'},
              {uf: 'BA'},
              {uf: 'SE'},
              {uf: 'PE'},
              {uf: 'AL'},
              {uf: 'PB'},
              {uf: 'RN'},
              {uf: 'CE'},
              {uf: 'PI'},
              {uf: 'MA'},
              {uf: 'PA'},
              {uf: 'AP'},
              {uf: 'AM'},
              {uf: 'RR'},
              {uf: 'AC'},
              {uf: 'DF'},
              {uf: 'GO'},
              {uf: 'RO'},
              {uf: 'TO'},
              {uf: 'MT'},
              {uf: 'MS'},
              {uf: 'PR'},
              {uf: 'SC'},
              {uf: 'RS'},
              
            ]
        }
    }
}

</script>
<style scoped>
#inputSpecialty
{
    margin-left:35%;
}
.labelFormation
{
    font-size:10px;
}
legend
{
    font-size:15px;
    font-weight: bold;
}
label
{
    
    font-size:14px;
}
input[type="checkbox"] 
{
        
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    background-clip: content-box;
    background-color: #D3D3D3;
    border-radius: 50%;
    margin-right:10px;
    margin-top:2px;
    
    
}

input[type="checkbox"]:checked 
{

    background-color: #983e7b;
   
    
}

.labelArea
{
    position:relative;
    bottom:2px;
}
.button
{
    background-color:#983e7b;
    color:white;
    border-radius:10px;
    border-color:transparent;
    text-align: center;
    width:40px;
    cursor:pointer;
}
#resume
{
    resize:none;
    border-color:#983e7b;
    height:377px;
    border-radius:10px;
    width:100%;
}
.input2
{
    border-width: 1px;
    border-color:#983e7b;
    height:50px;
    border-radius:10px;
    width:100%;
}
.input1
{
    border-width: 1px;
    border-color:#983e7b;
    height:50px;
    border-radius:10px;
    width:100%;
}
#part9
{
    margin-left:5%;
    margin-right:5%;
}
#part8
{
    margin-left:5%;
    margin-right:5%;
}
#part7
{
    margin-left:5%;
    margin-right:5%;
}
#part6
{
    margin-left:5%;
    margin-right:5%;
}
#part5
{
    margin-left:5%;
    margin-right:5%;
}
#part4
{
    margin-left:5%;
    margin-right:5%;
}
#part3
{
    margin-left:5%;
    margin-right:5%;
}
#part2
{
    margin-left:5%;
    margin-right:5%;
}
button
{
    background-color:#983e7b;
    color:white;
    border-radius:10px;
    border-color:transparent;
    padding-left:30px;
    padding-right:30px;
    padding-top:10px;
    padding-bottom:10px;
}
#formSpecialist
{
        width:65%;
        background-color:#983e7b17;
        height:100%;
}
#message
{
        width:35%;
        background-color:white;
        height:100%;
}
#rowSpecialist
{
    width:100%;
    margin:0px;
    height:750px;
}


input[type="radio"]
{
   -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    background-clip: content-box;
    background-color: #D3D3D3;
    border-radius: 50%;
    margin-right:10px;
    margin-top:5px;


}

input[type="radio"]:checked {
  background-color: #983e7b;
  
}
 
.labelSpecialty
{
    position:relative;
    bottom:2px;
}
#part1 h1
{
    text-align:center;
}
#textDescription
{
    color:grey;
    text-align:center;
}
h2
{
    color:black;
    text-align:center;
}
#imgLogo
{
    width:320px;
    height:120px;
}
#imgSpecialist
{
    width:400px;
}
</style>