<template>
    <div style="height:100%;width:82%;position:absolute;left:18%;top:0%;margin-left:0%;"> 
        <br>
        <br>
        
        <div id="boxAssessment">
            <br>
            <h1 class="text-center">O Crítico Interno</h1>
            <br>
            <h5>[Desenvolvido a partir do trabalho de S. Skiffington]</h5>
            <br>
            
            <br>
        
            <ul>
                <li>Solicite ao cliente que nomeie a voz interna crítica ou o diálogo interno negativo.</li>
                <li>Como tarefa, solicite que o cliente monitore a frequência, intensidade e palavras exatas
de cada autocrítica.</li>
                <li>Junto com o cliente, escolha um ou dois padrões negativos para focar. Por exemplo: Nunca
vou alcançar minhas metas; ou Não consigo ser organizado; ou Nunca serei bem-sucedido.</li>
                <li>Junto com o cliente, identifique ou crie algumas sentenças contrárias que irão neutralizar o
diálogo interno negativo. Exemplos: Mentira; Já obtive sucesso no passado; Consigo qualquer
coisa, desde que eu queira.</li>
                <li>Solicite ao cliente que pratique as sentenças neutralizadoras e monitore a frequência, a
intensidade e as palavras exatas de cada autocrítica e de cada neutralização.</li>
                <li>Solicite ao cliente que continue com o exercício até a voz crítica desaparecer comple-
tamente. Regularmente, verifique o progresso do seu cliente.</li>
            </ul>
            <br>
             <br>
            
            <h5>Como interromper padrões de pensamentos negativos:</h5>
            
           <ul>
                <li>Solicite ao cliente que defina o pensamento negativo ou crítico.</li>
                <li>Junto com o cliente, escolha ou crie um padrão de interrupção. Exemplos: gritar “Pare!”;
ou usar um elástico no pulso e esticá-lo e soltá-lo toda vez que quiser interromper o padrão.</li>
                <li>Solicite ao cliente que substitua o antigo pensamento por um novo pensamento.</li>
                <li>Solicite ao cliente que monitore e pratique o exercício de interrupção de pensamentos.</li>
                <ul>    
                    <li>Os pensamentos estão ocorrendo com menor frequência?</li>
                    <li>Estão se tornando menos intensos?</li>
                    <li>Como a troca de pensamentos tem sido útil?</li>
                </ul>

                <li>Encoraje o cliente a continuar a prática da técnica de interrupção de padrões até que
novos pensamentos substituam os antigos.</li>

            </ul>
            <br>
            <br>
             
            <h5>Comentários e anotações</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <div style="display: flex; justify-content: center;"> 
                  <button class="btn" >Salvar resultados</button>
            </div>
            <br>

        </div>
    </div>
</template>

<style scoped>
*
{
    font-family: roboto;
}
#boxAssessment
{
    
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    
    color: black;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
</style>

<script>
export default {
     data() {
        return {
            
           
            opcoes: [
               {n: '0'},
               {n: '1'},
               {n: '2'},
               {n: '3'},
               {n: '4'},
               {n: '5'},
               {n: '6'},
               {n: '7'},
               {n: '8'},
               {n: '9'},
               {n: '10'},
            ]
        }
     }
   
}
</script>