<template>
  <div id="torne">
    <div id="text">
      <h1>Torne-se um<br /><span>ESPECIALISTA</span></h1>
      <p>
        Alie-se a uma plataforma tecnológica disruptiva para<br />agregar em sua
        carreira e flexibilizar seus atendimentos!
      </p>
      <div>
        <a :href="VUE_APP_URL_ESPECIALISTA + '/cadastro-especialista'"><button
            class="btn">Inscrever-se</button></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      VUE_APP_URL_ESPECIALISTA: process.env.VUE_APP_URL_ESPECIALISTA
    }
  }
};
</script>

<style scoped>
.btn:hover {
  background-color: #fff051;
  color: #692b55;
}

button {
  background-color: #fabe0a;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 1.458;
  color: #692b55;
}

p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1.458vw;
}

span {
  color: #fabe0a;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Open Sans;
  font-size: 5.81vw;
}

#torne {
  color: white;
  margin-top: 5%;
  background-image: url("../../imgs/header_especialista.png");
  height: 699px;
}

#text {
  text-align: left;
  margin-left: 7.8%;
  position: absolute;
  top: 217px;
}

h1 {
  font-style: normal;
  color: white;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 3.508125vw;
}

@media (max-width: 800px) {
  p {
    font-size: 2.458vw;
  }
}
</style>
