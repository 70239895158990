<template>
  <div id="pageConsumer">
    <dashboard></dashboard>
    <barraLateral></barraLateral>
    <!--<modal v-if="completed == 'false'"></modal>-->
  </div>
</template>
<script>
//import modal  from "../components/modal-completar-perfil-cliente.vue";
import barraLateral from "../components/barra-lateral-cliente.vue";
import dashboard from "../components/dashboard-cliente.vue";
export default {
  components: {
    barraLateral,
    dashboard,
    //modal
  },
  data() {},
  mounted() {
    //this.completed = localStorage.getItem('completed');
  },
};
</script>
<style scoped>
#pageConsumer {
  width: 100%;
  height: 800px;
}
* {
  margin-top: -1%;
}
</style>