<template>
  <div>
    <div style="background-color: #bb6081">
      <img style="margin-top: -30px" src="../../imgs/ondulatoria2.png" />
      <br />
      <br />
      <br />
      <br />
      <h1 style="color: white; text-align: center">
        No que a Florescer acredita
      </h1>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div class="row">
        <div
          style="
            background-color: white;
            margin-left: 15%;
            width: 30%;
            margin-right: 10%;
            height: 320px;
          "
          class="col"
        >
          <div style="display: flex; justify-content: center">
            <img style="margin-top: -35px" src="../../imgs/alvo.png" />
          </div>
          <br />

          <h1 style="color: #bb6081; text-align: center; font-size: 30px">
            <b>Nosso propósito</b>
          </h1>
          <p style="font-size: 15px; text-align: justify">
            Promover vitalidade, saúde emocional, engajamento e felicidade
            através da conexão de uma solução tecnológica entre pessoas/
            empresas a especialistas da psicologia positiva.
          </p>
        </div>
        <div
          style="background-color: white; width: 30%; margin-right: 15%"
          class="col"
        >
          <div style="display: flex; justify-content: center">
            <img style="margin-top: -35px" src="../../imgs/foguete.png" />
          </div>
          <br />
          <h1 style="color: #bb6081; text-align: center; font-size: 30px">
            <b>Missão</b>
          </h1>
          <p style="font-size: 15px; text-align: justify">
            Despertar e Promover o florescimento de pessoas, elevando a
            satisfação e a maximização dos resultados e do potencial humano,
            através do autoconhecimentoe do Gestão do comportamento humano.
          </p>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div class="row">
        <div
          style="
            background-color: white;
            margin-left: 15%;
            width: 30%;
            margin-right: 10%;
            height: 320px;
          "
          class="col"
        >
          <div style="display: flex; justify-content: center">
            <img
              style="margin-top: -35px; height: 100%"
              src="../../imgs/aviaozinho.png"
            />
          </div>
          <br />

          <h1 style="color: #bb6081; text-align: center; font-size: 30px">
            <b>Visão</b>
          </h1>
          <p style="font-size: 15px; text-align: justify">
            Conectar especialistas a pessoas de uma forma exponencial através de
            uma tecnologia disruptiva se tornando referência mundial em cuidado
            com a saúde emocional das pessoas.
          </p>
        </div>
        <div
          style="
            background-color: white;
            width: 30%;
            margin-right: 15%;
            height: 320px;
          "
          class="col"
        >
          <div style="display: flex; justify-content: center">
            <img style="margin-top: -35px" src="../../imgs/medalha.png" />
          </div>
          <br />
          <h1 style="color: #bb6081; text-align: center; font-size: 30px">
            <b>Valores</b>
          </h1>
          <p style="font-size: 15px; text-align: justify">
            Honestidade, respeito, empatia, comprometimento, escuta ativa, ética
            e amor ao próximo
          </p>
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
    </div>
    <div style="background-color: #f2f2f2; height: 300px">
      <br />
      <br />
      <br />
      <br />
      <p
        style="
          font-size: 25px;
          text-align: center;
          color: #bb6081;
          font-weight: bold;
        "
      >
        DEDICANDO-SE HÁ MAIS DE 15 ANOS NA RELAÇÃO DO COMPORTAMENTO HUMANIZADO E
        A PROMOÇÃO DO FLORESCIMENTO NA VIDA DAS PESSOAS E TIMES
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style scoped>
</style>
