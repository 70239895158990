<template>
  <div id="ferramentas">
    <barraLateral @change-component="changeComponent"></barraLateral>

    <component id="comp" :is="activeComponent" />
  </div>
</template>

<script>
import textAssessment from "../components/texto-pagina-ferramentas.vue";
import barraLateral from "../components/barra-lateral-assessments.vue";
import avaliacaoPre from "./avaliacao-pre.vue";
import avaliacaoPos from "./avaliacao-pos.vue";
import assessment64 from "./assessment/assessment-64.vue";
import assessment62 from "./assessment/assessment-62.vue";
import assessment43 from "./assessment/assessment-43.vue";
import assessment10 from "./assessment/assessment-10.vue";
import assessment14 from "./assessment/assessment-14.vue";
import assessment44 from "./assessment/assessment-44.vue";
import assessment45 from "./assessment/assessment-45.vue";
import assessment46 from "./assessment/assessment-46.vue";
import assessment47 from "./assessment/assessment-47.vue";
import assessment49 from "./assessment/assessment-49.vue";
import assessment48 from "./assessment/assessment-48.vue";
import assessment59 from "./assessment/assessment-59.vue";
import assessment51 from "./assessment/assessment-51.vue";
import assessment52 from "./assessment/assessment-52.vue";
import assessment50 from "./assessment/assessment-50.vue";
import assessment60 from "./assessment/assessment-60.vue";
import assessment42 from "./assessment/assessment-42.vue";
import assessment58 from "./assessment/assessment-58.vue";
import assessment53 from "./assessment/assessment-53.vue";
import assessment54 from "./assessment/assessment-54.vue";
import assessment20 from "./assessment/assessment-20.vue";
import assessment39 from "./assessment/assessment-39.vue";
import assessment41 from "./assessment/assessment-41.vue";
import assessment61 from "./assessment/assessment-61.vue";
import assessment6 from "./assessment/assessment-6.vue";
import assessment7 from "./assessment/assessment-7.vue";
import assessment9 from "./assessment/assessment-9.vue";
import assessment4 from "./assessment/assessment-4.vue";
import assessment3 from "./assessment/assessment-3.vue";
import assessment2 from "./assessment/assessment-2.vue";
import assessment1 from "./assessment/assessment-1.vue";
import assessment13 from "./assessment/assessment-13.vue";
import assessment18 from "./assessment/assessment-18.vue";
import assessment17 from "./assessment/assessment-17.vue";
import assessment16 from "./assessment/assessment-16.vue";
import assessment25 from "./assessment/assessment-25.vue";
import assessment27 from "./assessment/assessment-27.vue";
import assessment55 from "./assessment/assessment-55.vue";
import assessment56 from "./assessment/assessment-56.vue";
import assessment57 from "./assessment/assessment-57.vue";
import assessment21 from "./assessment/assessment-21.vue";
import assessment40 from "./assessment/assessment-40.vue";
import assessment23 from "./assessment/assessment-23.vue";
import assessment22 from "./assessment/assessment-22.vue";
import assessment24 from "./assessment/assessment-24.vue";
import assessment28 from "./assessment/assessment-28.vue";
import assessment29 from "./assessment/assessment-29.vue";
import assessment30 from "./assessment/assessment-30.vue";
import assessment31 from "./assessment/assessment-31.vue";
import assessment63 from "./assessment/assessment-63.vue";
import assessment32 from "./assessment/assessment-32.vue";
import assessment33 from "./assessment/assessment-33.vue";
import assessment34 from "./assessment/assessment-34.vue";
import assessment35 from "./assessment/assessment-35.vue";
import assessment36 from "./assessment/assessment-36.vue";
import assessment37 from "./assessment/assessment-37.vue";
import assessment38 from "./assessment/assessment-38.vue";
import assessment11 from "./assessment/assessment-11.vue";
import assessment8 from "./assessment/assessment-8.vue";
import assessment65 from "./assessment/assessment-65.vue";
import assessment66 from "./assessment/assessment-66.vue";
import assessment67 from "./assessment/assessment-67.vue";
import assessment68 from "./assessment/assessment-68.vue";
import assessment69 from "./assessment/assessment-69.vue";
import assessment70 from "./assessment/assessment-70.vue";

export default {
  components: {
    barraLateral,
    assessment8,
    textAssessment,
    avaliacaoPos,
    assessment11,
    assessment69,
    assessment68,
    assessment65,
    assessment64,
    assessment30,
    assessment37,
    assessment38,
    assessment35,
    assessment36,
    assessment34,
    assessment33,
    assessment32,
    assessment31,
    assessment28,
    assessment29,
    assessment24,
    assessment22,
    assessment21,
    assessment23,
    assessment40,
    assessment63,
    assessment56,
    assessment67,
    assessment62,
    assessment57,
    assessment55,
    assessment27,
    assessment25,
    assessment16,
    assessment18,
    assessment17,
    assessment13,
    assessment6,
    assessment58,
    assessment61,
    assessment41,
    assessment20,
    assessment39,
    assessment53,
    assessment54,
    assessment52,
    assessment7,
    assessment42,
    assessment2,
    assessment3,
    assessment9,
    avaliacaoPre,
    assessment10,
    assessment14,
    assessment60,
    assessment4,
    assessment43,
    assessment50,
    assessment51,
    assessment48,
    assessment1,
    assessment44,
    assessment47,
    assessment45,
    assessment46,
    assessment59,
    assessment66,
    assessment49,
    assessment70,
  },
  data() {
    return {
      page: 1,
      activeComponent: "textAssessment",
      component: "",
    };
  },
  updated() {
    if (this.component == "avaliacaoPos") {
      document.getElementById("ferramentas").style.height = "9100px";
    } else if (this.component == "textAssessment") {
      document.getElementById("ferramentas").style.height = "800px";
    } else if (this.component == "avaliacaoPre") {
      document.getElementById("ferramentas").style.height = "9200px";
    } else if (this.component == "assessment1") {
      document.getElementById("ferramentas").style.height = "3500px";
    } else if (this.component == "assessment59") {
      document.getElementById("ferramentas").style.height = "2000px";
    } else if (this.component == "assessment45") {
      document.getElementById("ferramentas").style.height = "2000px";
    } else if (this.component == "assessment49") {
      document.getElementById("ferramentas").style.height = "2000px";
    } else if (this.component == "assessment44") {
      document.getElementById("ferramentas").style.height = "5000px";
    } else if (this.component == "assessment46") {
      document.getElementById("ferramentas").style.height = "2200px";
    } else if (this.component == "assessment43") {
      document.getElementById("ferramentas").style.height = "3600px";
    } else if (this.component == "assessment47") {
      document.getElementById("ferramentas").style.height = "2200px";
    } else if (this.component == "assessment48") {
      document.getElementById("ferramentas").style.height = "2700px";
    } else if (this.component == "assessment4") {
      document.getElementById("ferramentas").style.height = "800px";
    } else if (this.component == "assessment51") {
      document.getElementById("ferramentas").style.height = "1600px";
    } else if (this.component == "assessment50") {
      document.getElementById("ferramentas").style.height = "5100px";
    } else if (this.component == "assessment60") {
      document.getElementById("ferramentas").style.height = "2100px";
    } else if (this.component == "assessment2") {
      document.getElementById("ferramentas").style.height = "2000px";
    } else if (this.component == "assessment10") {
      document.getElementById("ferramentas").style.height = "1500px";
    } else if (this.component == "assessment14") {
      document.getElementById("ferramentas").style.height = "800px";
    } else if (this.component == "assessment9") {
      document.getElementById("ferramentas").style.height = "1900px";
    } else if (this.component == "assessment3") {
      document.getElementById("ferramentas").style.height = "2400px";
    } else if (this.component == "assessment7") {
      document.getElementById("ferramentas").style.height = "1100px";
    } else if (this.component == "assessment42") {
      document.getElementById("ferramentas").style.height = "5100px";
    } else if (this.component == "assessment58") {
      document.getElementById("ferramentas").style.height = "3000px";
    } else if (this.component == "assessment52") {
      document.getElementById("ferramentas").style.height = "2900px";
    } else if (this.component == "assessment53") {
      document.getElementById("ferramentas").style.height = "1600px";
    } else if (this.component == "assessment54") {
      document.getElementById("ferramentas").style.height = "2300px";
    } else if (this.component == "assessment20") {
      document.getElementById("ferramentas").style.height = "1800px";
    } else if (this.component == "assessment49") {
      document.getElementById("ferramentas").style.height = "1800px";
    } else if (this.component == "assessment39") {
      document.getElementById("ferramentas").style.height = "3500px";
    } else if (this.component == "assessment41") {
      document.getElementById("ferramentas").style.height = "2300px";
    } else if (this.component == "assessment6") {
      document.getElementById("ferramentas").style.height = "3400px";
    } else if (this.component == "assessment13") {
      document.getElementById("ferramentas").style.height = "3500px";
    } else if (this.component == "assessment18") {
      document.getElementById("ferramentas").style.height = "2900px";
    } else if (this.component == "assessment17") {
      document.getElementById("ferramentas").style.height = "1700px";
    } else if (this.component == "assessment16") {
      document.getElementById("ferramentas").style.height = "2100px";
    } else if (this.component == "assessment25") {
      document.getElementById("ferramentas").style.height = "1600px";
    } else if (this.component == "assessment27") {
      document.getElementById("ferramentas").style.height = "1300px";
    } else if (this.component == "assessment55") {
      document.getElementById("ferramentas").style.height = "2600px";
    } else if (this.component == "assessment56") {
      document.getElementById("ferramentas").style.height = "1400px";
    } else if (this.component == "assessment57") {
      document.getElementById("ferramentas").style.height = "2100px";
    } else if (this.component == "assessment21") {
      document.getElementById("ferramentas").style.height = "3000px";
    } else if (this.component == "assessment40") {
      document.getElementById("ferramentas").style.height = "4700px";
    } else if (this.component == "assessment23") {
      document.getElementById("ferramentas").style.height = "1100px";
    } else if (this.component == "assessment22") {
      document.getElementById("ferramentas").style.height = "1200px";
    } else if (this.component == "assessment24") {
      document.getElementById("ferramentas").style.height = "800px";
    } else if (this.component == "assessment28") {
      document.getElementById("ferramentas").style.height = "2500px";
    } else if (this.component == "assessment29") {
      document.getElementById("ferramentas").style.height = "4100px";
    } else if (this.component == "assessment30") {
      document.getElementById("ferramentas").style.height = "1400px";
    } else if (this.component == "assessment31") {
      document.getElementById("ferramentas").style.height = "1100px";
    } else if (this.component == "assessment32") {
      document.getElementById("ferramentas").style.height = "6200px";
    } else if (this.component == "assessment33") {
      document.getElementById("ferramentas").style.height = "3000px";
    } else if (this.component == "assessment34") {
      document.getElementById("ferramentas").style.height = "3600px";
    } else if (this.component == "assessment35") {
      document.getElementById("ferramentas").style.height = "2500px";
    } else if (this.component == "assessment36") {
      document.getElementById("ferramentas").style.height = "2200px";
    } else if (this.component == "assessment37") {
      document.getElementById("ferramentas").style.height = "1700px";
    } else if (this.component == "assessment38") {
      document.getElementById("ferramentas").style.height = "1600px";
    } else if (this.component == "assessment11") {
      document.getElementById("ferramentas").style.height = "700px";
    } else if (this.component == "assessment8") {
      document.getElementById("ferramentas").style.height = "3000px";
    } else if (this.component == "assessment62") {
      document.getElementById("ferramentas").style.height = "8600px";
    } else if (this.component == "assessment63") {
      document.getElementById("ferramentas").style.height = "8500px";
    } else if (this.component == "assessment64") {
      document.getElementById("ferramentas").style.height = "1650px";
    } else if (this.component == "assessment65") {
      document.getElementById("ferramentas").style.height = "1100px";
    } else if (this.component == "assessment66") {
      document.getElementById("ferramentas").style.height = "2700px";
    } else if (this.component == "assessment67") {
      document.getElementById("ferramentas").style.height = "1600px";
    } else if (this.component == "assessment68") {
      document.getElementById("ferramentas").style.height = "4400px";
    } else if (this.component == "assessment69") {
      document.getElementById("ferramentas").style.height = "2700px";
    } else if (this.component == "assessment70") {
      document.getElementById("ferramentas").style.height = "2900px";
    }
  },
  mounted() {
    document.getElementById("ferramentas").style.height = "700px";
  },
  methods: {
    changeComponent(componentName) {
      this.activeComponent = componentName;
      this.component = componentName;
    },
  },
};
</script>
<style>
#ferramentas {
  width: 100%;
  background-color: #f7f8fc;
  overflow-y: hidden;
}
</style>