<template>
  <div id="pageSpecialist">
    <dashboard></dashboard>
    <barraLateral></barraLateral>
    <modal v-if="completed == 'false'"></modal>
  </div>
</template>
<script>
import modal from "../components/modal-completar-perfil-empresa.vue";
import barraLateral from "../components/barra-lateral-empresa.vue";
import dashboard from "../components/dashboard-empresa.vue";
export default {
  components: {
    barraLateral,
    dashboard,
    modal,
  },
  data() {
    return {
      completed: "",
    };
  },
  mounted() {
    this.completed = localStorage.getItem("completed");
  },
};
</script>
<style scoped>
#pageSpecialist {
  width: 100%;
  height: 1000px;
}
* {
  margin-top: -1%;
}
</style>