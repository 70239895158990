<template>
  <div href="#" id="text3" class="container-fluid">
    <div style="margin-top: 8.712vw">
      <h1>Por qual <span>florescimento</span> você procura?</h1>
      <br />
      <br />
      <div id="line" class="row">
        <div class="colImg">
          <img src="../../imgs/florescimento_pessoal.png" />
          <p>Busco o meu florescimento</p>
          <div class="divBtn">
            <router-link to="/para-voce" class="router"><button class="btn">Florescer Pessoal</button></router-link>
          </div>
        </div>
        <div class="colImg">
          <img src="../../imgs/organizacional.png" />
          <p>Busco o florescimento da minha empresa</p>
          <div class="divBtn">
            <router-link to="/para-sua-empresa" class="router"><button class="btn">
                Florescer Organizacional
              </button></router-link>
          </div>
        </div>
        <div class="colImg">
          <img src="../../imgs/ocupacional.png" />
          <p>Quero contribuir com o florescimento</p>
          <div class="divBtn">
            <router-link to="/torne-se-um-especialista" class="router"><button class="btn">Torne-se um
                especialista</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      VUE_APP_URL_ESPECIALISTA: process.env.VUE_APP_URL_ESPECIALISTA
    }
  }
};
</script>

<style scoped>
.colImg {
  margin-left: 1%;
  margin-right: 1%;
}

.router {
  width: 100%;
}

.btn {
  background-color: #fabe0a;
  width: 28.111vw;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  color: #692b55;
  font-size: 1.537vw;
}

.btn:hover {
  background-color: #fff051;
}

p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1.171vw;
  text-align: center;
  color: #9c9c9c;
}

img {
  width: 28.111vw;
  height: 21.083vw;
}

#line {
  display: flex;
  justify-content: center;
}

h1 {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 3.514vw;
  text-align: center;
  color: #692b55;
}

#text3 {
  background-color: white;
  height: 600px;
}

span {
  color: #de4eaf;
}

@media (min-width: 860px) and (max-width: 1040px) {
  #text3 {
    height: 500px;
  }
}

@media (min-width: 721px) and (max-width: 859px) {
  #text3 {
    height: 500px;
  }
}

@media (min-width: 650px) and (max-width: 720px) {
  #text3 {
    height: 200.8vh;
  }

  img {
    width: 64.111vw;
    height: 50.083vw;
    display: flex;
    justify-content: center;
    margin-left: 1%;
  }

  p {
    text-align: center;
    font-size: 1.971vw;
  }

  .divBtn {
    margin-bottom: 8%;
    width: 44%;
    margin-left: 28%;
  }
}

@media (min-width: 559px) and (max-width: 649px) {
  #text3 {
    height: 178.8vh;
  }

  img {
    width: 64.111vw;
    height: 50.083vw;
    display: flex;
    justify-content: center;
    margin-left: 1%;
  }

  p {
    text-align: center;
    font-size: 1.971vw;
  }

  .divBtn {
    margin-bottom: 8%;
    width: 44%;
    margin-left: 28%;
  }
}

@media (min-width: 430px) and (max-width: 559px) {
  #text3 {
    height: 150.8vh;
  }

  img {
    width: 64.111vw;
    height: 50.083vw;
    display: flex;
    justify-content: center;
    margin-left: 1%;
  }

  p {
    text-align: center;
    font-size: 1.971vw;
  }

  .divBtn {
    margin-bottom: 8%;
    width: 44%;
    margin-left: 28%;
  }
}

@media (min-width: 375px) and (max-width: 429px) {
  #text3 {
    height: 128.8vh;
  }

  img {
    width: 64.111vw;
    height: 50.083vw;
    display: flex;
    justify-content: center;
    margin-left: 1%;
  }

  p {
    text-align: center;
    font-size: 2.271vw;
  }

  .divBtn {
    margin-bottom: 8%;
    width: 44%;
    margin-left: 28%;
  }
}

@media (min-width: 0px) and (max-width: 375px) {
  #text3 {
    height: 118.8vh;
  }

  img {
    width: 64.111vw;
    height: 50.083vw;
    display: flex;
    justify-content: center;
    margin-left: 1%;
  }

  p {
    text-align: center;
    font-size: 2.371vw;
  }

  .divBtn {
    margin-bottom: 8%;
    width: 44%;
    margin-left: 28%;
  }

  .btn {
    font-size: 2.071vw;
  }
}
</style>
