<template>
  <div id="text">
    <h1 class="text-center">Em que a <span>Florescer</span> acredita?</h1>

    <div id="rectangle1" class="row rectangle">
      <div class="col-lg-3 col-sm-5 col-3">
        <div id="circle1" class="circle">
          <img id="vision" src="../../imgs/visao.png" />
        </div>
      </div>
      <div class="col-lg-9 col-sm-7 col-9">
        <div class="text">
          <h2>Visão</h2>
          <p>
            Conectar especialistas a pessoas de uma forma exponencial através de
            uma tecnologia disruptiva se tornando referência mundial em cuidado
            com a saúde emocional das pessoas.
          </p>
        </div>
      </div>
    </div>

    <div id="rectangle2" class="row rectangle">
      <div class="col-lg-9 col-sm-7 col-9">
        <div class="text">
          <h2>Missão</h2>
          <p>
            Despertar e promover o florescimento de pessoas, elevando a
            satisfação e a maximização dos resultados e do potencial humano,
            através do autoconhecimento e da gestão do comportamento .
          </p>
        </div>
      </div>
      <div class="col-lg-3 col-sm-5 col-3">
        <div id="circle2" class="circle">
          <img id="mission" src="../../imgs/missao.png" />
        </div>
      </div>
    </div>

    <div id="rectangle3" class="row rectangle">
      <div class="col-lg-3 col-sm-5 col-3">
        <div id="circle3" class="circle">
          <img id="values" src="../../imgs/valores.png" />
        </div>
      </div>

      <div class="col-lg-9 col-sm-7 col-9">
        <div class="text">
          <h2>Valores</h2>
          <p>
            Honestidade, respeito, empatia, comprometimento, escuta ativa, ética
            e amor ao próximo
          </p>
        </div>
      </div>
    </div>

    <div style="display: flex; justify-content: center">
      <button class="btn" v-on:click="changePage()">Quero Florescer</button>
    </div>
    <img src="../../imgs/onda_3.png" alt="" id="curve3" />

    <div id="divLastText">
      <br />
      <p id="lastText">
        DEDICANDO-SE HÁ MAIS DE 15 ANOS NA RELAÇÃO DO COMPORTAMENTO HUMANIZADO E
        A PROMOÇÃO DO FLORESCIMENTO NA VIDA DAS PESSOAS E TIMES
      </p>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    changePage: function () {
      sessionStorage.setItem("aboutUs", "true");
      this.$router.push("/#/");
    },
  },
};
</script>
<style scoped>
.btn:hover {
  background-color: #fff051;
  color: #692b55;
}
#divLastText {
  background-color: white;
  height: 120px;
}
#lastText {
  margin-right: 7.8%;
  margin-left: 7.8%;
  text-align: center;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 2.2vw;
  color: #ab3585;
  margin-top: -60px;
}
button {
  background-color: #fabe0a;
  border-radius: 6px;
  border-color: transparent;
  width: 29.16vw;
  height: 52px;
  position: relative;
  top: 218px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  color: #692b55;
}
#curve3 {
  background-repeat: no-repeat;
  display: block;
  background-size: cover;
  margin-top: 186px;
}
.text {
  margin-top: 20px;
}
h2 {
  color: #ab3585;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
}
p {
  color: #ab3585;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}
#mission {
  margin-top: 27px;
  width: 112px;
  height: 112px;
}
#values {
  margin-top: 34px;
  width: 96px;
  height: 96px;
}
#vision {
  margin-top: 47px;
  width: 115px;
  height: 72px;
}
#circle1 {
  margin-left: -18px;
}
#circle2 {
  margin-left: 59px;
}
#circle3 {
  margin-left: -18px;
}
.circle {
  display: flex;
  justify-content: center;
  border-radius: 100%;
  width: 166px;
  height: 166px;
  background-color: #ab3585;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.75);
}
#rectangle3 {
  border-radius: 0px 12px 12px 0px;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  margin-top: 56px;
  margin-left: 7.8%;
}
#rectangle2 {
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  margin-top: 56px;
  margin-left: 22.29%;
}
#rectangle1 {
  border-radius: 0px 12px 12px 0px;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  margin-top: 75px;
  margin-left: 7.8%;
}
.rectangle {
  width: 63.8vw;
  background-color: white;
  height: 166px;
}
#text {
  background-color: #ab3585;
  height: 1350px;
  margin-top: -4px;
}
h1 {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  color: white;
}
span {
  color: #fabe0a;
}
@media (min-width: 1101px) and (max-width: 1200px) {
  #circle2 {
    margin-left: 10px;
  }
}
@media (min-width: 992px) and (max-width: 1100px) {
  #circle2 {
    margin-left: 0px;
  }
  p {
    font-size: 1.5vw;
  }
}

@media (min-width: 790px) and (max-width: 991px) {
  #circle2 {
    margin-left: 9.2vw;
  }
  p {
    font-size: 1.3vw;
  }
}
@media (min-width: 694px) and (max-width: 789px) {
  button {
    font-size: 14px;
    position: relative;
    top: 250px;
  }
  #circle2 {
    margin-left: 30px;
  }
  p {
    font-size: 1vw;
  }
}
@media (min-width: 565px) and (max-width: 693px) {
  button {
    font-size: 14px;
    position: relative;
    top: 250px;
  }
  .rectangle {
    width: 72%;
  }
  p {
    font-size: 1.3vw;
  }
  #circle2 {
    margin-left: 20px;
  }
}
@media (min-width: 350px) and (max-width: 564px) {
  #curve3 {
    margin-top: -1px;
  }
  button {
    font-size: 14px;
    position: relative;
    top: 70px;
  }
  #lastText {
    margin-top: -100px;
  }
  .text {
    margin-top: -55px;
  }
  #text {
    height: 860px;
  }
  .circle {
    width: 11.527777777777779vw;
    height: 11.527777777777779vw;
  }
  .rectangle {
    width: 72%;
    height: 11.527777777777779vw;
  }
  p {
    font-size: 1.3vw;
  }
  #circle2 {
    margin-left: 19px;
  }
  h2 {
    font-size: 2.2222222222222223vw;
  }
  #mission {
    margin-top: 8px;
    width: 7.777777777777778vw;
    height: 7.777777777777778vw;
  }
  #values {
    margin-top: 10px;
    width: 6.666666666666667vw;
    height: 6.666666666666667vw;
  }
  #vision {
    margin-top: 15px;
    width: 7.986111111111111vw;
    height: 5vw;
  }
  .text {
    margin-top: 2px;
  }
}
@media (min-width: 0px) and (max-width: 350px) {
  #text {
    height: 1050px;
  }
  button {
    font-size: 14px;
    position: relative;
    top: 260px;
  }
  #lastText {
    margin-top: 0px;
  }
  .circle {
    width: 11.527777777777779vw;
    height: 11.527777777777779vw;
  }
  .rectangle {
    width: 72%;
    height: 11.527777777777779vw;
  }
  p {
    font-size: 1.3vw;
  }
  #circle2 {
    margin-left: 14px;
  }
  h2 {
    font-size: 2.2222222222222223vw;
  }
  #mission {
    margin-top: 6px;
    width: 7.777777777777778vw;
    height: 7.777777777777778vw;
  }
  #values {
    margin-top: 8px;
    width: 6.666666666666667vw;
    height: 6.666666666666667vw;
  }
  #vision {
    margin-top: 10px;
    width: 7.986111111111111vw;
    height: 5vw;
  }
  .text {
    margin-top: 2px;
  }
}
</style>