<template>
    <div style="width:82%;position:absolute;left:18%;top:0%;margin-left:0%;"> 
        <br>
        <br>
        
        <div id="boxAssessment">
            <br>
            <h1 class="text-center">Modelo AF</h1>
            <br>
            <h5>[Desenvolvido a partir do trabalho de S. Skiffington]</h5>
            <br>
            
            <h3><b>Activating (ativando o evento ou situação)</b></h3>
            <br>
            <h5>Descreva o evento ou situação problemática ou difícil. (Exemplo: Fazer uma apresentação na frente dos colegas.)</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
             <br>
            <br>
            <br>
            <h3><b>Belief (crença limitante sobre a situação)</b></h3>
            <br>
            <h5>Detecte as ideias limitantes, o diálogo interno ou os comportamentos que estão gerando
a situação difícil. (Exemplo: Eu preciso atuar muito bem, senão meus colegas irão pensar
que eu sou estúpido).</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
             <br>
            <br>
            <h3><b>Consequences (consequências emocionais e comportamentais dessa crença)</b></h3>
            <br>
            <h5>Quais foram as emoções indesejadas que você sentiu? (Exemplos: ansiedade, baixo grau de concentração.)</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Quais foram os comportamentos indesejados que você teve?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
        

            <h3><b>Disputing (disputando a crença limitante)</b></h3>
            <br>
            <h5>Esta crença é lógica?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Existe alguma evidência disto?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Quão realista é esta crença?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Quanto ter esta crença ajuda você?</h5>
            <p>(Exemplos: Só porque você quer atuar muito bem não implica logicamente que você vai? Você
está sendo realista? Se você não atuar bem, será que seus colegas pensarão que você é estúpido?
Que utilidade tem esta ideia? Como isto está ajudando você?).</p>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>

          
           
            <h3><b>Effective (nova crença eficaz)</b></h3>
            <p>(Exemplos: Embora eu atue muito bem, isto não significa que eu deva. Não existe nenhuma
evidência que meus colegas pensam ou irão pensar que eu sou estúpido se eu não atuar muito
bem. Ter esta ideia me deixa ansioso e mais propício a atuar mal. Eu posso me preocupar, mas
não ficar ansioso em relação à apresentação).</p>
             
            <br>
            <h5>Qual seria uma nova crença eficaz?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <h5>Esta crença é verdadeira?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <h5>Quão realista é esta crença?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <h5>Quanto ter esta nova crença ajuda você?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h3><b>Feelings (novos sentimentos e emoções)</b></h3>
            <p>(Exemplo: Estou mais confiante, sou capaz de considerar a apresentação um desafio em vez
de uma experiência penosa ou difícil).</p>
             
            <br>
            <h5>Solicite ao cliente que desafie a nova crença, gerando uma oportunidade para si mesmo
de enfrentar novamente a situação ou evento antes problemático ou difícil.</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <h5>Como você se sentiu quando o evento aconteceu?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <h5>O que você estava falando para si mesmo que lhe trouxe este sentimento?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <h5>Como você desafiou sua própria crença limitante (caso tenha se repetido)?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <h5>Como a sua nova crença está mais lógica, realista e o quanto ela está ajudando você?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <div style="display: flex; justify-content: center;"> 
                  <button class="btn" >Salvar resultados</button>
            </div>
            <br>

        </div>
    </div>
</template>

<style scoped>
*
{
    font-family: roboto;
}
#boxAssessment
{
    
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    
    color: black;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
</style>

<script>
export default {
     data() {
        return {
            
           
            opcoes: [
               {n: '0'},
               {n: '1'},
               {n: '2'},
               {n: '3'},
               {n: '4'},
               {n: '5'},
               {n: '6'},
               {n: '7'},
               {n: '8'},
               {n: '9'},
               {n: '10'},
            ]
        }
     }
   
}
</script>