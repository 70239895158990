import { render, staticRenderFns } from "./barra-lateral-empresa.vue?vue&type=template&id=43fc57b3&scoped=true&"
import script from "./barra-lateral-empresa.vue?vue&type=script&lang=js&"
export * from "./barra-lateral-empresa.vue?vue&type=script&lang=js&"
import style0 from "./barra-lateral-empresa.vue?vue&type=style&index=0&id=43fc57b3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43fc57b3",
  null
  
)

export default component.exports