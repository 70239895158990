<template>
    <div  style="height:100%;width:82%;position:absolute;left:25%;margin-right:25%;top:6%;margin-left:0%;">
        
        
        
        <div id="formRegister">
                <h6><b>Cadastre os colaboradores da empresa que vão participar do treinamento</b></h6>
        
                <br>  
                <p  id="msg1" style="color:red;">{{msgError}}</p>
                  
                <div >
                        <label for="name">Nome: *</label>
                        <br>
                        <input   class="input1 form-control" type="text" id="name" />
                        
                </div>
                <br>

                <div class="row" >
                        <div  class="col">
                            <label for="email">E-mail: *</label>
                            <br>
                            <input class="form-control input2" type="email" id="email" />
                        </div>
                        <div  class="col">
                            <label for="tel">Telefone/Celular: *</label>
                            <br>
                            <input class="form-control input2" v-on:blur="editTel()" maxlength="15" type="text" id="tel" />
                            
                        </div>
                </div>
                <br>

                <div class="row" >
                        <div  class="col">
                            <label for="date">Data de nascimento: *</label>
                            <br>
                            <input class="form-control input2" type="date" id="date" />
                        </div>
                        <div  class="col">
                            <label for="tel">Tempo de casa: *</label>
                            <br>
                            <input class="form-control input2"  type="text" id="houseTime" />
                            
                        </div>
                </div>
                
                
                <br>
                <div >
                        <label for="time">Cargo atual: *</label>
                        <br>
                        <input   class="input1 form-control" type="text" id="role" />
                        
                </div>
                
                <br>
                <div class="row">

                        <div class="col">
                                
                                <p>Ele passou por algum programa de capacitação, terapia ou acompanhamento individual? </p>
                                                
                                                <div id="inputTraining">
                                                        <div class="radio" >
                                                                <input type="radio" v-on:click="showInput('yes')" id="yesTraining" name="training" value="Sim">
                                                                <label  for="yesTraining">Sim</label>
                                                        </div>

                                                        <div class="radio">
                                                                <input type="radio" v-on:click="showInput('no')" id="noTraining" name="training" value="Não" checked>
                                                                <label for="noTraining">Não</label>    
                                                        </div>
                                                        
                                                        
                                                </div>
                                        <div v-if="training==true" >
                                                <br>
                                                <label for="name">Quais? *</label>
                                                <br>
                                                <input   class="input1 form-control" type="text" id="which" />
                                                <br>
                                                <label for="name">Por quanto tempo? *</label>
                                                <br>
                                                <input   class="input1 form-control" type="text" id="howTime" />
                                                <br>
                                        </div>
                        </div>
                        <div class="col">
                                
                                <br>
                                <br>
                                <button v-on:click="verifyForm()" class="btn" >Adicionar mais um colaborador</button>
                                <br>
                                <router-link to="/visualizar-colaboradores"><button class="btn" >Ver colaboradores já cadastrados</button></router-link>
                                                          
                        </div>
                        <br>
                
                </div>
        </div>
         
    </div>
</template>
<script>
import api from '../services/api.js';

import emailjs from 'emailjs-com';
export default {
        data()
        {
                return{
                        training:false,
                        msgError:"",
                        inputResponsible:false,
                        name:"",
                        email:"",
                        tel:"",
                        date:"",
                        houseTime:"",
                        role:"",
                        password:"",
                        idCollaborator:""
                }
        },
        methods:
        { 
          verifyForm: function()
          {
                this.name = document.getElementById("name").value;
                this.email = document.getElementById("email").value;
                this.tel = document.getElementById("tel").value;
                this.houseTime = document.getElementById("houseTime").value;
                this.role = document.getElementById("role").value;
                const age = this.calculateAge();
                if(this.training)
                {
                        this.which= document.getElementById("which").value;
                        this.howTime = document.getElementById("howTime").value;
                }
                if(this.training == true && !this.which)
                {
                        this.msgError = "Preencha todos os dados obrigatórios!";
                }
                else
                     if(this.training == true && !this.howTime)
                     {
                             this.msgError = "Preencha todos os dados obrigatórios!";
                     }
                     else
                        if(!this.name)
                        {
                               this.msgError = "Preencha todos os dados obrigatórios!";
                        }
                        else
                                if(!this.email)
                                {
                                        this.msgError = "Preencha todos os dados obrigatórios!";
                                }
                                else
                                        if(!this.tel)
                                        {
                                                this.msgError = "Preencha todos os dados obrigatórios!";
                                        }
                                        else
                                        if(!this.date)
                                        {
                                                this.msgError = "Preencha todos os dados obrigatórios!";
                                        }
                                        else
                                                if(!this.houseTime)
                                                {
                                                this.msgError = "Preencha todos os dados obrigatórios!";
                                                }
                                                else
                                                if(!this.role)
                                                {
                                                        this.msgError = "Preencha todos os dados obrigatórios!";
                                                }
                                                else
                                                        if(age < 14)
                                                        {
                                                                this.msgError = "Idade inválida!";
                                                        }       
                                                        else
                                                                if(age>100)
                                                                {
                                                                        this.msgError = "Idade inválida!";    
                                                                }
                                                                else
                                                                {
                                                                        this.sendData();
                                                                }
                                                
                                    

          },
          generatePassword: function()
          {
                this.password= Math.random().toString(36).slice(-8);
                
          },
          sendEmail: function()
          {
               const templateParams= {

                        toName:this.name,
                        message:this.password,
                        recipient:this.email
               };

                emailjs.send('service_1zxdfqe', 'template_rzxxkln', templateParams, 'user_mLAxVXxCu2DchVaAZ0cjU')
                .then(function(response) {
                        console.log('SUCCESS!', response.status, response.text);
                }, function(error) {
                        console.log('FAILED...', error);
                });
                
          },
          sendData: async function()
          {
                this.generatePassword();
        
                const idUser = localStorage.getItem('userId');
                const idCompany = localStorage.getItem('companyId');
                
                await api.post('/register-collaborator-by-company', {
                                       
                                        userID: idUser,
                                        id: idCompany,
                                        name: this.name,
                                        email: this.email,
                                        password: this.password,
                                        phone: this.tel,
                                        role: this.role,
                                        houseTime: this.houseTime,
                                        birthDate: this.birthDate,
                                        roleType: "Collaborator",
                                        howFindUs: "Cadastro Empresa",
                                        useTerms: true
    
                                    })
                                    .then((response) => {
                                        
                                        console.log(response);
                                        this.idCollaborator =  response.data.collaborator.id;
                                       
                                        this.registrationCompleted();
                                    }, (error) => {
                                        this.msgError="Erro";
                                        document.getElementById("msg1").style.color="red";
                                        console.log(error);
                                        
                });
                
                if(this.training)
                {
                        
                        await api.post('/collaborator-training', {
                                        id: this.idCollaborator,
                                        userID: idUser,
                                        trainingName:this.which,
                                        trainingTime:this.howTime
                                        
                                    })
                                    .then((response) => {
                                        
                                        console.log(response);
                                       
                                    }, (error) => {
                                        
                                        console.log(error);
                                        
                        });
                }
          },
          registrationCompleted: function()
          {
                this.sendEmail();
                document.getElementById("msg1").style.color="green";
                this.msgError="Cadastro Concluido!";
          },
          calculateAge: function()
          {
                this.date = document.getElementById("date").value;
                var partDate = this.date.split("-");
                var year = parseInt(partDate[0]);
                var month = parseInt(partDate[1]);
                var day = parseInt(partDate[2]);
                var currentYear = parseInt(new Date().getFullYear());
                var currentMonth = parseInt(new Date().getMonth()+1);
                var currentDay = parseInt(new Date().getDate());
                this.date=`${year}-${month}-${day}`;
                var age = currentYear - year;
                var remainingMonths = currentMonth - month;
                var remainingDays = currentDay - day;
                if(remainingMonths < 0)
                {
                        age= age -1;
                }
                else
                        if(remainingDays < 0)
                        {
                                age = age-1;
                        }

               
                return age;        
                
                
                
          
          },
          showInput: function(answer)
          {
                  if(answer == 'yes')
                  {
                          this.training = true;
                          window.scrollTo(0, 210);
                          document.getElementById("formRegister").style.height="830px";
                  }
                  else
                  {
                          this.training = false;
                          document.getElementById("formRegister").style.height="670px";
                  }
          },
          editTel: function()
          {
                var tel = document.getElementById("tel").value;
                
                if(tel.length >= 11)
                {
                
                const ddd = tel.slice(0,2);
                const part1 = tel.slice(2,7);
                const part2 = tel.slice(7,11);
                if(isNaN(ddd) == true || isNaN(part1) == true || isNaN(part2) == true )
                {
                        document.getElementById("tel").value = "(11)99999-9999";
                }
                else
                {
                        tel = `(${ddd})${part1}-${part2}`;
                        document.getElementById("tel").value = tel;
                }
                
                }
                else
                        if(tel.length == 10)
                        {
                                const ddd = tel.slice(0,2);
                                const part1 = tel.slice(2,6);
                                const part2 = tel.slice(6,10);
                        if(isNaN(ddd) == true || isNaN(part1) == true || isNaN(part2) == true )
                        {
                                document.getElementById("tel").value = "(11)99999-9999";
                        }
                        else
                        {
                                tel = `(${ddd})${part1}-${part2}`;
                                document.getElementById("tel").value = tel;
                        }
                }
            },
        }
}
</script>
<style scoped>
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height:30px;
}
.input2
{
    border-color:#983e7b;
    height:30px;
    border-radius:10px;
    width:100%;
    border-width: 1px;

}
.input1
{
    border-width: 1px;
    border-color:#983e7b;
    height:30px;
    border-radius:10px;
    width:100%;
}
#formRegister
{
       width:80%;
       height:670px;
       padding-top:4%;
       padding-left:4%;
       padding-right:4%;
       margin-right:4%;
       margin-left:4%;
       border-style: solid;
       border-width: 1px;
       border-color: #D3D3D3;
       background-color:white;
       border-radius: 15px;
       color: black;
}

input[type="radio"] 
{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    background-clip: content-box;
    background-color: #D3D3D3;
    border-radius: 50%;
    margin-right:10px;
    margin-top:2px;
}

input[type="radio"]:checked 
{
    background-color: #983e7b;   
}
</style>