<template>
    <div style="height:806px;width:82%;background-color:#f7f8fc;position:absolute;left:18%">
        <br>
        <br>
        <div class="row">
            <div class="col-sm">
                <h6 style="margin-left:10%"><b>Dashboard do Cliente - Bem-vindo (a)</b></h6>
            </div>
            <div class="col-sm" style="margin-left:10%">
                
                    <img style="width:20px;height:20px" src="../../imgs/notificacao.png">
                    <div class="row" style="margin-top:-25px;">
                        <h6 style="margin-left:50%">Rafaela Santos</h6>
                        <img style="width:20px;height:20px;margin-left:20px" src="../../imgs/usuario-de-perfil.png">
                    </div>
                
                
                
                   
            </div>
        </div>
        <br>
        <br>
        <div v-on:click="changePage()" style="background-color:#983e7b;color:#D3D3D3" class="box">
            
            <h3 style="margin-top:5%">Dimensões da vida</h3>

        </div>
        <br>
        <br>
        <div class="box">
            
            <h3 style="margin-top:5%">Teste 2</h3>

        </div>
        <br>
        <br>
        <div class="box">
            
            <h3 style="margin-top:5%">Teste 3</h3>

        </div>
        <br>
        <br>
        <div class="box">
            
            <h3 style="margin-top:5%">Teste 4</h3>

        </div>
    </div>
</template>
<script>
export default {
    methods:
    {
        changePage: function()
        {
            window.location.href = "http://localhost:8080/dimensoes-da-vida";
        }
    }
}
</script>
<style scoped>
#set
{
    cursor: pointer;
}
table tr th
{
    font-size:12px;
    text-align:center;
}
table tr td
{
    text-align:center;
}
#boxClient
{
    border-style: solid;
    background-color: white;
    border-radius: 15px;
    border-color: #D3D3D3;
    margin-right:5%;
    height:400px;
    border-width: 1px;
}
#boxUser
{
    border-style: solid;
    background-color: white;
    border-radius: 15px;
    border-color: #D3D3D3;
    margin-left:10%;
    height:400px;
    border-width: 1px;
}

.box
{
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
    height:120px;
    width:800px;
    border-radius: 15px;
    margin-left:10%;
    text-align: center;
    color: black;
    cursor: pointer;
}

</style>