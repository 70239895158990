<template>
    <div class="modal fade" id="modalCompany">
        
            <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Complete seu perfil</h5>
                
                </div>

                 <div class="modal-body">
                    <span>{{msgError}}</span>
                    <form>
                        <div v-if="part==1">
                                <div class="row">
                                    <div class="col form-group">
                                        <label for="recipient-name" class="col-form-label">Razão social:</label>
                                        <input  type="text" class="form-control" id="companyName">
                                    </div>
                                
                                    <div class="col form-group">
                                        <label for="message-text" class="col-form-label">CNPJ:</label>
                                        <input type="text" autocomplete="off" maxlength="20" v-on:keyup="putMaskCnpj($event)" class="form-control" id="cnpj">
                                    </div>
                                </div>

                                
                                <div class="form-group">
                                        <label for="recipient-name" class="col-form-label">Inscrição estadual:</label>
                                        <input type="text" class="form-control" id="stateRegistration">
                                </div>
                                
                                    
                                

                                <div class="row">
                                    <div class="col form-group">
                                        <label for="recipient-name" class="col-form-label">CEP:</label>
                                        <input type="text" v-on:keyup="putMaskCep($event)" v-on:blur="completeAddress()" class="form-control" id="cep">
                                    </div>
                                
                                    <div class="col form-group">
                                        <label for="message-text" class="col-form-label">Cidade:</label>
                                        <input type="text" class="form-control" id="city">
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col form-group">
                                        <label for="recipient-name" class="col-form-label">Estado:</label>
                                        <select class="custom-select input2" id="state" style="color:gray;" required="required">
                                                    <option  style="color:gray;" v-for="op in states" :key="op.uf">
                                                        {{ op.uf }}
                                                    </option>
                                        </select>   
                                    </div>
                                
                                    <div class="col form-group">
                                        <label for="message-text" class="col-form-label">Rua:</label>
                                        <input type="text" class="form-control" id="street">
                                    </div>
                                </div>
                        </div>

                        <div v-if="part==2">
                            <div class="row">
                                    <div class="col-6 form-group">
                                        <label for="recipient-name" class="col-form-label">Bairro:</label>
                                        <input type="text" class="form-control" id="district">
                                    </div>
                                    
                                    <div class="col-2 form-group">
                                        <label for="recipient-name" class="col-form-label">Número:</label>
                                        <input type="text" class="form-control" id="number">
                                    </div>

                                    <div class="col-4 form-group">
                                        <label for="message-text" class="col-form-label">Complemento:</label>
                                        <input type="text" class="form-control" id="complement">
                                    </div>
                            </div>

                            <div class="row">
                                <div class="col form-group">
                                    <label for="recipient-name" class="col-form-label">Tempo de atuação no mercado:</label>
                                    <input type="text" class="form-control" id="timeMarket">
                                </div>
                            
                                <div class="col form-group">
                                    <label for="message-text" class="col-form-label">Número de colaboradores:</label>
                                    <input type="text" class="form-control" id="numberEmployees">    
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="message-text" class="col-form-label">Perfil do linkedin:</label>
                                <input type="text" class="form-control" id="linkedin">
                            </div>

                        </div>
                        <div v-if="part==3">
                            <p style="font-size:10px;">Escolha a logo da sua empresa</p>
                            <div style="width:20px">
                                <input  type="file" id="filePic" accept="image/*" v-on:change="previewImage($event)">
                            </div>
                            
                            
                            <div style="margin-top:10px;display: flex; justify-content: center;"> 
                                <img height="400px" width="500px"  src="" id="output_image"/>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="modal-footer">
                    <button type="button" v-on:click="verifyForm()" id="submit" class="btn">{{msg}}</button>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossorigin="anonymous"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js" integrity="sha384-ZMP7rVo3mIykV+2+9J3UJ46jBk0WLaUAdn689aCwoqbBJiSnjAK/l8WvCWPIPm49" crossorigin="anonymous"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/js/bootstrap.min.js" integrity="sha384-ChfqqxuZUCnJSK3+MXmPNIyE6ZbWh2IMqE241rYiqJxyMiZ6OW/JmZQ5stwEULTy" crossorigin="anonymous"></script>
  
<script>
import api from '../services/api.js';
import axios from 'axios';
export default {
    data()
    {
        return{
            userId:"",
            companyId:"",
            file:false,
            stateSelected:"",
            linkedin:"",
            stateRegistration:"",
            complement:"",
            companyName:"",
            cnpj:"", 
            cep:"",
            city:"",
            state:"",
            street:"",
            district:"",
            number:"",
            timeMarket:"",
            numberEmployees:"",
            msg:"Continuar",
            part:1,
            date:"",
            age:"18",
            msgError:"",
            maritalStatus:[
                {status: 'Solteiro(a)'},
                {status: 'Casado(a)'},
                {status: 'Divorciado(a)'},
                {status: 'União Estável'},
                {status: 'Viuvo(a)'}
             ],
             states: [
              {uf: 'SP'},
              {uf: 'RJ'},
              {uf: 'ES'},
              {uf: 'MG'},
              {uf: 'BA'},
              {uf: 'SE'},
              {uf: 'PE'},
              {uf: 'AL'},
              {uf: 'PB'},
              {uf: 'RN'},
              {uf: 'CE'},
              {uf: 'PI'},
              {uf: 'MA'},
              {uf: 'PA'},
              {uf: 'AP'},
              {uf: 'AM'},
              {uf: 'RR'},
              {uf: 'AC'},
              {uf: 'DF'},
              {uf: 'GO'},
              {uf: 'RO'},
              {uf: 'TO'},
              {uf: 'MT'},
              {uf: 'MS'},
              {uf: 'PR'},
              {uf: 'SC'},
              {uf: 'RS'},
              
            ],
            formation:[
                {level: 'Ensino fundamental incompleto'},
                {level: 'Ensino fundamental completo'},
                {level: 'Ensino médio incompleto'},
                {level: 'Ensino médio completo'},
                {level: 'Ensino superior incompleto'},
                {level: 'Ensino superior completo'},
             ],
        }
    },
    mounted()
    {
        this.openModal();
    },
    methods:
    {
        previewImage: function(event)
        {
            this.file=true;
            if(event.target.files.length > 0)
            {
                var src = URL.createObjectURL(event.target.files[0]);
                var preview = document.getElementById("output_image");
                preview.src = src;
                preview.style.display = "block";
            }
        },
        openModal: function()
        {
            $('#modalCompany').modal('show');
        },
        closeModal: function()
        {
            $('#modalCompany').modal('hide');
        },
        sendData: function()
        {
            this.userId = localStorage.getItem('userId');
            this.companyId = localStorage.getItem('companyId');
            
            
            
            api.put('/edit-company',{
                                        userID: this.userId,
                                        companyID:this.companyId,
                                        nameFantasy:this.companyName,
                                        cnpj:this.cnpj,
                                        corporateName:this.companyName,
                                        stateRegistration:this.stateRegistration,
                                        actingTime:this.timeMarket,
                                        logoPic:"",
                                        linkedin:this.linkedin,
                                        numbersContrubutors:this.numberEmployees
                                          
                                    })
                                    .then((response) => {
                                        
                                        console.log(response);
                                        this.nextPart();
                                    }, (error) => {
                                        
                                        console.log(error.response);

                                        
                                        
            });
        },
        sendPic: function()
        {
        
            const filePic = document.getElementById("filePic").value;
            
            api.put('/company-logopic',{
                                        userID: this.userId,
                                        companyID:this.companyId,
                                        logoPic:filePic
                                    })
                                    .then((response) => {
                                        
                                        console.log(response);
                                        localStorage.setItem('completed', true);
                                        //document.location.reload(true);
                                        this.closeModal();
                                    }, (error) => {
                                        
                                        console.log(error.response);

                                        
                                        
            });
        },
        verifyForm: function()
        {
            
            if(this.part==1)
            {
                this.verifyPart1();
            }
            else
                if(this.part==2)
                {
                    this.verifyPart2();
                }
                else 
                    if(this.part==3)
                    {
                        this.sendPic();
                    }
        },
        nextPart: function()
        {
            this.part= this.part+1;
            this.msgError="";

            if(this.part==3)
                this.msg="Confirmar";
            
        },
        verifyPart1: function()
        {
            this.stateRegistration = document.getElementById("stateRegistration").value;
            this.companyName = document.getElementById("companyName").value;
            this.cnpj = document.getElementById("cnpj").value;
            this.cep = document.getElementById("cep").value;
            this.city = document.getElementById("city").value;
            this.stateSelected = document.getElementById("state").value;
            this.street = document.getElementById("street").value;
            
            
            if(!this.companyName)
            {
                    this.msgError ="Preencha todos os dados obrigatórios!";
            }
            else
                if(!this.cnpj)
                {
                    this.msgError ="Preencha todos os dados obrigatórios!";
                }
                else
                        if(!this.cep)
                        {
                            this.msgError ="Preencha todos os dados obrigatórios!";
                        }
                        else
                            if(!this.city)
                            {
                                this.msgError ="Preencha todos os dados obrigatórios!";
                            }
                            else
                                    if(!this.street) 
                                    {
                                        this.msgError ="Preencha todos os dados obrigatórios!";
                                    }
                                    else
                                    {
                                                
                                        this.nextPart(); 
                                    }
        },
        verifyPart2: function()
        {
            this.complement = document.getElementById("complement").value; 
            this.district = document.getElementById("district").value;
            this.number = document.getElementById("number").value;
            this.timeMarket  = document.getElementById("timeMarket").value;
            this.numberEmployees = document.getElementById("numberEmployees").value;
            this.linkedin = document.getElementById("linkedin").value;

            if(!this.district)
            {
                this.msgError ="Preencha todos os dados obrigatórios!";
            }
            else
                if(!this.number)
                {
                    this.msgError ="Preencha todos os dados obrigatórios!";
                }
                else
                   if(!this.timeMarket)
                   {
                       this.msgError ="Preencha todos os dados obrigatórios!";
                   }
                   else
                        if(!this.numberEmployees)
                        {
                            this.msgError ="Preencha todos os dados obrigatórios!";
                        }
                        else
                        {
                                this.sendData();
                        }
        },
        putMaskCep: function(event)
        {
            if(event.keyCode != 8)
            {
                const cep = document.getElementById("cep").value;
                if(cep.length==5)
                {
            
                    document.getElementById("cep").value=cep+'-';
                }
                
            }
        },
        calculateAge: function()
        {
            
                this.date = document.getElementById("date").value;
                var partDate = this.date.split("-");
                var year = parseInt(partDate[0]);
                var month = parseInt(partDate[1]);
                var day = parseInt(partDate[2]);
                var currentYear = parseInt(new Date().getFullYear());
                var currentMonth = parseInt(new Date().getMonth()+1);
                var currentDay = parseInt(new Date().getDate());
                this.date=`${year}-${month}-${day}`;
                this.age = currentYear - year;
                var remainingMonths = currentMonth - month;
                var remainingDays = currentDay - day;
                if(remainingMonths < 0)
                {
                        this.age= this.age -1;
                }
                else
                        if(remainingDays < 0)
                        {
                            this.age = this.age-1;
                        }
        },
        completeAddress: function()
        {
            
            const cep = document.getElementById("cep").value;
            const url = `https://viacep.com.br/ws/${cep}/json/`;
            if(cep)
            {
                
                axios
                    .get(url)
                    .then(response => {
                        console.log(response);
                        if(response.data.localidade)
                        {
                            this.currentCity =  response.data.localidade;
                            document.getElementById("city").value = this.currentCity;
                        }

                        if(response.data.uf)
                        {
                            this.currentState = response.data.uf;
                            document.getElementById("state").value = this.currentState;
                        }
                        
                        if(response.data.logradouro)
                        {
                            this.currentStreet = response.data.logradouro;
                            document.getElementById("street").value = this.currentStreet;
                        }

                        if(response.data.bairro)
                        {
                            this.currentDistrict = response.data.bairro;
                            document.getElementById("district").value = this.currentDistrict;
                           

                        }
                        
                        
                })
                .catch(error => {
                    console.log(error)
                    this.errored = true
                })
                
            }
        },
        putMaskCnpj: function(event)
        {
            if(event.keyCode != 8)
            {
                const cnpj = document.getElementById("cnpj").value;
                if(cnpj.length==2)
                {
                    document.getElementById("cnpj").value=cnpj+'.';
                }
                else
                    if(cnpj.length==6)
                    {
                        document.getElementById("cnpj").value=cnpj+'.';
                    }
                    else
                        if(cnpj.length==10)
                        {
                            document.getElementById("cnpj").value=cnpj+'/';
                        }
                        else
                            if(cnpj.length == 15)
                            {
                                document.getElementById("cnpj").value=cnpj+'-';
                            }
            }
        },
    }
}
</script>
<style scoped>
img
{
    display:none;
}
#submit
{
    background-color:#983e7b;
    color:white;
}
#modalCompany
{
    overflow-y: hidden;
}
span
{
    font-size:10px;
    color:red;
}

</style>