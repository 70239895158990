<template>
    <div style="height:1006px;width:82%;background-color:#f7f8fc;position:absolute;left:18%">
       <br>
        <br>
        <div class="row">
            <div class="col-sm">
                <h6 style="margin-left:10%"><b>Dashboard Empresa - Bem-vindo (a)</b></h6>
            </div>
            <div class="col-sm" style="margin-left:4%">
                <div class="row" style="margin-top:2px">
                    <div class="col">
                        <img style="width:20px;height:20px" src="../../imgs/notificacao.png">
                    </div>
                    <div class="col">
                        <div class="row" >
                            <h6 style="margin-left:50%">{{fisrtName}}</h6>
                            <img style="width:20px;height:20px;margin-left:10px" src="../../imgs/usuario-de-perfil.png">
                        </div>
                    </div>
                </div>
                   
            </div>
        </div>
        <br>
        <br>
        <br>
        <div class="row" style="margin-left:3%">
            <div class="col">
                <div class="box">
                    <p>Colaboradores</p>
                    <br>
                    <h1>{{numberCollaborators}}</h1>
                </div>
            </div>
            
            <div class="col">
                <div class="box">
                    <p>Concluíram Cadastro</p>
                    <br>
                    <h1>{{registeredCollaborator}}</h1>
                </div>
            </div>
            <div class="col">
                <div class="box">
                    <p>Atividades concluidas pelos colaboradores</p>
                    
                    <h1>0</h1>
                </div>
            </div>
            
            

        </div>
        <br>
        <br>
       <div id="graph">
                <img  width="900px" src="../../imgs/grafico_empresa.jpg">
        </div>
         <br>
        <div style="margin-left:4%" class="row">
           
            <div class="col">
                <div class="lastBox">
                    <h5>Overview dos Colaboradores</h5>
                </div>
            </div>
            <div style="margin-left:5%" class="col">
                <div class="lastBox">
                    <h5>Tarefas</h5>
                </div>
            </div>
        </div>
    
    
    </div>
</template>
<script>
import api from '../services/api.js';
export default {
    mounted()
    {
        
        this.userName = localStorage.getItem('nameCompany');
        this.fisrtName = this.userName.split(' ',1);
        this.fisrtName = this.fisrtName[0];
        this.getNumbersEmployees();
           
        
        
    },
    methods:
    {
        getNumbersEmployees: function()
        {
            //const idUser = localStorage.getItem('userId');
            const idCompany = localStorage.getItem('companyId');
            
           
            api.get(`/show-collaborator-by-company/${idCompany}`,{
                           
                                                
                            }).then((response) => {
                                    for(let i=0; i<response.data.collaborators.length;i++)
                                    {
                                        if(response.data.collaborators[i].active == true)
                                        {
                                            this.registeredCollaborator=this.registeredCollaborator+1;
                                        }
                                    }
                                        
                                        this.numberCollaborators = response.data.numberOfCollaborators;
                                        console.log(response);
                                       
                                    }, (error) => {
                                        
                                        console.log(error.response.data);
                                        
                                        
            });
        }
    },
    data()
    {
        return{
            numberCollaborators:0,
            user:"",
            userName:"",
            fisrtName:"",
            userId:"",
            companyId:"",
            registeredCollaborator:0
        }
    }
}
</script>
<style scoped>
.lastBox
{

    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
    height:120px;
    width:420px;
    border-radius: 15px;
    text-align: center;
    color: black;
}
#graph
{
    padding:2%;
    width:1000px;
    margin-left:58px;
    border-style: solid;
    background-color: white;
    border-radius: 15px;
    border-color: #D3D3D3;
    border-width: 1px;
}
#set
{
    cursor: pointer;
}
table tr th
{
    font-size:12px;
    text-align:center;
}
table tr td
{
    text-align:center;
}
#boxClient
{
    border-style: solid;
    background-color: white;
    border-radius: 15px;
    border-color: #D3D3D3;
    margin-right:5%;
    height:400px;
    border-width: 1px;
}
#boxUser
{
    border-style: solid;
    background-color: white;
    border-radius: 15px;
    border-color: #D3D3D3;
    margin-left:10%;
    height:400px;
    border-width: 1px;
}

.box
{
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
    height:120px;
    width:280px;
    border-radius: 15px;
    text-align: center;
    color: black;
}

</style>