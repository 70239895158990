import Vue from 'vue';
import Router from 'vue-router';

import Home from '@/views/Home';
import termosUso from '@/views/termos-de-uso.vue';
import visualizarColaboradores from '@/views/visualizar-colaboradores.vue';
import mapeamentoNecessidades from '@/views/mapeamento-necessidades.vue';
import loginCliente from '@/views/login-cliente.vue';
import loginColaborador from '@/views/login-colaborador.vue';
import loginEspecialista from '@/views/login-especialista.vue';
import loginEmpresa from '@/views/login-empresa.vue';
import Cadastre from '@/views/cadastre.vue';
import cadastroEmpresa from '@/views/cadastro-empresa.vue';
import cadastroEspecialista from '@/views/cadastro-especialista.vue';
import torneSeUmEspecialista from '@/views/torne-se-um-especialista';
import cadastroCliente from '@/views/cadastro-cliente.vue';
import cadastroColaboradores from '@/views/cadastro_colaboradores.vue';
import perfilEmpresa from '@/views/perfil-empresa.vue';
import perfilCliente from '@/views/perfil-cliente.vue';
import clientesAtendimento from '@/views/clientes-em-atendimento.vue';
import paginaInicialEspecialista from '@/views/pagina-inicial-especialista.vue';
import paginaInicialColaborador from '@/views/pagina-inicial-colaborador.vue';
import visualizarAgenda from '@/views/visualizar-agenda.vue';
import editarAgenda from '@/views/editar-agenda.vue';
import avaliacao from '@/views/avaliacao.vue';
import paginaInicialCliente from '@/views/pagina-inicial-cliente.vue';
import esqueciMinhaSenha from '@/views/esqueci-minha-senha.vue';
import contato from '@/views/contato.vue';
import assessment1 from '@/views/assessment/assessment-1.vue';
import assessment2 from '@/views/assessment/assessment-2.vue';
import assessment3 from '@/views/assessment/assessment-3.vue';
import assessment4 from '@/views/assessment/assessment-4.vue';
import assessment5 from '@/views/assessment/assessment-5.vue';
import assessment6 from '@/views/assessment/assessment-6.vue';
import assessment7 from '@/views/assessment/assessment-7.vue';
import assessment8 from '@/views/assessment/assessment-8.vue';
import assessment9 from '@/views/assessment/assessment-9.vue';
import assessment10 from '@/views/assessment/assessment-10.vue';
import assessment11 from '@/views/assessment/assessment-11.vue';
import assessment12 from '@/views/assessment/assessment-12.vue';
import assessment13 from '@/views/assessment/assessment-13.vue';
import assessment14 from '@/views/assessment/assessment-14.vue';
import assessment15 from '@/views/assessment/assessment-15.vue';
import assessment16 from '@/views/assessment/assessment-16.vue';
import assessment17 from '@/views/assessment/assessment-17.vue';
import assessment18 from '@/views/assessment/assessment-18.vue';
import assessment19 from '@/views/assessment/assessment-19.vue';
import assessment20 from '@/views/assessment/assessment-20.vue';
import assessment21 from '@/views/assessment/assessment-21.vue';
import assessment22 from '@/views/assessment/assessment-22.vue';
import assessment23 from '@/views/assessment/assessment-23.vue';
import assessment24 from '@/views/assessment/assessment-24.vue';
import assessment25 from '@/views/assessment/assessment-25.vue';
import assessment26 from '@/views/assessment/assessment-26.vue';
import assessment27 from '@/views/assessment/assessment-27.vue';
import assessment28 from '@/views/assessment/assessment-28.vue';
import assessment29 from '@/views/assessment/assessment-29.vue';
import assessment30 from '@/views/assessment/assessment-30.vue';
import assessment31 from '@/views/assessment/assessment-31.vue';
import assessment32 from '@/views/assessment/assessment-32.vue';
import assessment33 from '@/views/assessment/assessment-33.vue';
import assessment34 from '@/views/assessment/assessment-34.vue';
import assessment35 from '@/views/assessment/assessment-35.vue';
import assessment36 from '@/views/assessment/assessment-36.vue';
import assessment37 from '@/views/assessment/assessment-37.vue';
import assessment38 from '@/views/assessment/assessment-38.vue';
import assessment39 from '@/views/assessment/assessment-39.vue';
import assessment40 from '@/views/assessment/assessment-40.vue';
import assessment41 from '@/views/assessment/assessment-41.vue';
import assessment42 from '@/views/assessment/assessment-42.vue';
import assessment43 from '@/views/assessment/assessment-43.vue';
import assessment44 from '@/views/assessment/assessment-44.vue';
import assessment45 from '@/views/assessment/assessment-45.vue';
import assessment46 from '@/views/assessment/assessment-46.vue';
import assessment47 from '@/views/assessment/assessment-47.vue';
import assessment48 from '@/views/assessment/assessment-48.vue';
import assessment49 from '@/views/assessment/assessment-49.vue';
import assessment50 from '@/views/assessment/assessment-50.vue';
import assessment51 from '@/views/assessment/assessment-51.vue';
import assessment52 from '@/views/assessment/assessment-52.vue';
import assessment53 from '@/views/assessment/assessment-53.vue';
import assessment54 from '@/views/assessment/assessment-54.vue';
import assessment55 from '@/views/assessment/assessment-55.vue';
import assessment56 from '@/views/assessment/assessment-56.vue';
import assessment57 from '@/views/assessment/assessment-57.vue';
import assessment58 from '@/views/assessment/assessment-58.vue';
import assessment59 from '@/views/assessment/assessment-59.vue';
import assessment60 from '@/views/assessment/assessment-60.vue';
import assessment61 from '@/views/assessment/assessment-61.vue';
import assessment62 from '@/views/assessment/assessment-62.vue';
import assessment63 from '@/views/assessment/assessment-63.vue';
import assessment64 from '@/views/assessment/assessment-64.vue';
import assessment65 from '@/views/assessment/assessment-65.vue';
import assessment66 from '@/views/assessment/assessment-66.vue';
import assessment67 from '@/views/assessment/assessment-67.vue';
import assessment68 from '@/views/assessment/assessment-68.vue';
import assessment69 from '@/views/assessment/assessment-69.vue';
import assessment70 from '@/views/assessment/assessment-70.vue';
import testes from '@/views/testes.vue';
import historicoCliente from '@/views/historico-cliente.vue';
import novaSessao from '@/views/nova-sessao.vue';
import paginaInicialEmpresa from '@/views/pagina-inicial-empresa.vue';
import paginaInicialAdm from '@/views/pagina-inicial-adm.vue';
import avaliacaoPre from '@/views/avaliacao-pre.vue';
import avaliacaoPos from '@/views/avaliacao-pos.vue';
import nossoEndereco from '@/views/nosso-endereco.vue';
import modulos from '@/views/modulos.vue';
import conhecaFlorescer from '@/views/conheca-florescer.vue';
import paraSuaEmpresa from '@/views/para-sua-empresa.vue';
import politicaPrivacidade from '@/views/politica-privacidade.vue';
import textPageAssessments from '@/components/texto-pagina-ferramentas.vue';
import paginaFerramentas from '@/views/pagina-ferramentas.vue';
import sobreNos from '@/views/sobre_nos.vue';
import paraVoce from '@/views/para-voce.vue';


Vue.use(Router);

const routes = [
    {
        name: 'termos-uso',
        path: '/termos-uso',
        component: termosUso
    },
    {
        name: 'para-voce',
        path: '/para-voce',
        component: paraVoce
    },
    {
        name: 'sobre-nos',
        path: '/sobre-nos',
        component: sobreNos
    },
    {
        name: 'mapeamento-necessidades',
        path: '/mapeamento-necessidades',
        component: mapeamentoNecessidades
    },
    {
        name: 'testes',
        path: '/testes',
        component: testes
    },
    {
        name: 'texto-ferramentas',
        path: '/texto-ferramentas',
        component: textPageAssessments
    },
    {
        name: 'pagina-ferramentas',
        path: '/pagina-ferramentas',
        component: paginaFerramentas
    },
    {
        name: 'politica-privacidade',
        path: '/politica-privacidade',
        component: politicaPrivacidade
    },
    {
        name: 'para-sua-empresa',
        path: '/para-sua-empresa',
        component: paraSuaEmpresa
    },
    {
        name: 'esqueci-minha-senha',
        path: '/esqueci-minha-senha',
        component: esqueciMinhaSenha
    },
    {
        name: 'conheca-florescer',
        path: '/conheca-florescer',
        component: conhecaFlorescer
    },
    {
        name: 'modulos',
        path: '/modulos',
        component: modulos
    },
    {
        name: 'home',
        path: '/',
        component: Home,
    },
    {
        name: 'login-colaborador',
        path: '/login-colaborador',
        component: loginColaborador
    },
    {
        name: 'login-especialista',
        path: '/login-especialista',
        component: loginEspecialista
    },
    {
        name: 'login-empresa',
        path: '/login-empresa',
        component: loginEmpresa
    },
    {
        name: 'login-cliente',
        path: '/login-cliente',
        component: loginCliente
    },
    {
        name: 'cadastro-colaboradores',
        path: '/cadastro-colaboradores',
        component: cadastroColaboradores
    },
    {
        name: 'cadastro-empresa',
        path: '/cadastro-empresa',
        component: cadastroEmpresa
    },
    {
        name: 'cadastre',
        path: '/cadastre',
        component: Cadastre
    },
    {
        name: 'cadastro-especialista',
        path: '/cadastro-especialista',
        component: cadastroEspecialista
    },
    {
        name: 'torne-se-um-especialista',
        path: '/torne-se-um-especialista',
        component: torneSeUmEspecialista
    },
    {
        name: 'cadastro-cliente',
        path: '/cadastro-cliente',
        component: cadastroCliente
    },
    {
        name: 'perfil-empresa',
        path: '/perfil-empresa',
        component: perfilEmpresa
    },
    {
        name: 'perfil-cliente',
        path: '/perfil-cliente',
        component: perfilCliente
    },
    {
        name: 'clientes-atendimento',
        path: '/clientes-atendimento',
        component: clientesAtendimento
    },
    {
        name: 'pagina-inicial-especialista',
        path: '/pagina-inicial-especialista',
        component: paginaInicialEspecialista
    },
    {
        name: 'visualizar-agenda',
        path: '/visualizar-agenda',
        component: visualizarAgenda
    },
    {
        name: 'editar-agenda',
        path: '/editar-agenda',
        component: editarAgenda
    },
    {
        name: 'avaliacao',
        path: '/avaliacao',
        component: avaliacao
    },
    {
        name: 'pagina-inicial-empresa',
        path: '/pagina-inicial-empresa',
        component: paginaInicialEmpresa
    },
    {
        name: 'pagina-inicial-cliente',
        path: '/pagina-inicial-cliente',
        component: paginaInicialCliente
    },
    {
        name: 'pagina-inicial-colaborador',
        path: '/pagina-inicial-colaborador',
        component: paginaInicialColaborador
    },
    {
        name: 'contato',
        path: '/contato',
        component: contato
    },
    {
        name: 'assessment-69',
        path: '/assessment/assessment-69',
        component: assessment69
    },
    {
        name: 'assessment-68',
        path: '/assessment/assessment-68',
        component: assessment68
    },
    {
        name: 'assessment-67',
        path: '/assessment/assessment-67',
        component: assessment67
    },
    {
        name: 'assessment-66',
        path: '/assessment/assessment-66',
        component: assessment66
    },
    {
        name: 'assessment-65',
        path: '/assessment/assessment-65',
        component: assessment65
    },
    {
        name: 'assessment-64',
        path: '/assessment/assessment-64',
        component: assessment64
    },
    {
        name: 'assessment-63',
        path: '/assessment/assessment-63',
        component: assessment63
    },
    {
        name: 'assessment-62',
        path: '/assessment/assessment-62',
        component: assessment62
    },
    {
        name: 'assessment-61',
        path: '/assessment/assessment-61',
        component: assessment61
    },
    {
        name: 'assessment-59',
        path: '/assessment/assessment-59',
        component: assessment59
    },
    {
        name: 'assessment-58',
        path: '/assessment/assessment-58',
        component: assessment58
    },
    {
        name: 'assessment-57',
        path: '/assessment/assessment-57',
        component: assessment57
    },
    {
        name: 'assessment-56',
        path: '/assessment/assessment-56',
        component: assessment56
    },
    {
        name: 'assessment-55',
        path: '/assessment/assessment-55',
        component: assessment55
    },
    {
        name: 'assessment-53',
        path: '/assessment/assessment-53',
        component: assessment53
    },
    {
        name: 'assessment-54',
        path: '/assessment/assessment-54',
        component: assessment54
    },
    {
        name: 'assessment-52',
        path: '/assessment/assessment-52',
        component: assessment52
    },
    {
        name: 'assessment-51',
        path: '/assessment/assessment-51',
        component: assessment51
    },
    {
        name: 'assessment-60',
        path: '/assessment/assessment-60',
        component: assessment60
    },
    {
        name: 'assessment-50',
        path: '/assessment/assessment-50',
        component: assessment50
    },
    {
        name: 'assessment-49',
        path: '/assessment/assessment-49',
        component: assessment49
    },
    {
        name: 'assessment-48',
        path: '/assessment/assessment-48',
        component: assessment48
    },
    {
        name: 'assessment-47',
        path: '/assessment/assessment-47',
        component: assessment47
    },
    {
        name: 'assessment-46',
        path: '/assessment/assessment-46',
        component: assessment46
    },
    {
        name: 'assessment-45',
        path: '/assessment/assessment-45',
        component: assessment45
    },
    {
        name: 'assessment-44',
        path: '/assessment/assessment-44',
        component: assessment44
    },
    {
        name: 'assessment-43',
        path: '/assessment/assessment-43',
        component: assessment43
    },
    {
        name: 'assessment-42',
        path: '/assessment/assessment-42',
        component: assessment42
    },
    {
        name: 'assessment-41',
        path: '/assessment/assessment-41',
        component: assessment41
    },
    {
        name: 'assessment-40',
        path: '/assessment/assessment-40',
        component: assessment40
    },
    {
        name: 'assessment-39',
        path: '/assessment/assessment-39',
        component: assessment39
    },
    {
        name: 'assessment-1',
        path: '/assessment/assessment-1',
        component: assessment1
    },
    {
        name: 'assessment-2',
        path: '/assessment/assessment-2',
        component: assessment2
    },
    {
        name: 'assessment-3',
        path: '/assessment/assessment-3',
        component: assessment3
    },
    {
        name: 'assessment-4',
        path: '/assessment/assessment-4',
        component: assessment4
    },
    {
        name: 'assessment-5',
        path: '/assessment/assessment-5',
        component: assessment5
    },
    {
        name: 'assessment-6',
        path: '/assessment/assessment-6',
        component: assessment6
    },
    {
        name: 'assessment-7',
        path: '/assessment/assessment-7',
        component: assessment7
    },
    {
        name: 'assessment-8',
        path: '/assessment/assessment-8',
        component: assessment8
    },
    {
        name: 'assessment-9',
        path: '/assessment/assessment-9',
        component: assessment9
    },
    {
        name: 'assessment-10',
        path: '/assessment/assessment-10',
        component: assessment10
    },
    {
        name: 'assessment-11',
        path: '/assessment/assessment-11',
        component: assessment11
    },
    {
        name: 'assessment-12',
        path: '/assessment/assessment-12',
        component: assessment12
    },
    {
        name: 'assessment-13',
        path: '/assessment/assessment-13',
        component: assessment13
    },
    {
        name: 'assessment-14',
        path: '/assessment/assessment-14',
        component: assessment14
    },
    {
        name: 'assessment-15',
        path: '/assessment/assessment-15',
        component: assessment15
    },
    {
        name: 'assessment-16',
        path: '/assessment/assessment-16',
        component: assessment16
    },
    {
        name: 'assessment-17',
        path: '/assessment/assessment-17',
        component: assessment17
    },
    {
        name: 'assessment-18',
        path: '/assessment/assessment-18',
        component: assessment18
    },
    {
        name: 'assessment-19',
        path: '/assessment/assessment-19',
        component: assessment19
    },
    {
        name: 'assessment-20',
        path: '/assessment/assessment-20',
        component: assessment20
    },
    {
        name: 'assessment-21',
        path: '/assessment/assessment-21',
        component: assessment21
    },
    {
        name: 'assessment-22',
        path: '/assessment/assessment-22',
        component: assessment22
    },
    {
        name: 'assessment-23',
        path: '/assessment/assessment-23',
        component: assessment23
    },
    {
        name: 'assessment-70',
        path: '/assessment/assessment-70',
        component: assessment70
    },

    {
        name: 'assessment-24',
        path: '/assessment/assessment-24',
        component: assessment24
    },
    {
        name: 'assessment-25',
        path: '/assessment/assessment-25',
        component: assessment25
    },
    {
        name: 'assessment-26',
        path: '/assessment/assessment-26',
        component: assessment26
    },
    {
        name: 'assessment-27',
        path: '/assessment/assessment-27',
        component: assessment27
    },
    {
        name: 'assessment-28',
        path: '/assessment/assessment-28',
        component: assessment28
    },
    {
        name: 'assessment-29',
        path: '/assessment/assessment-29',
        component: assessment29
    },
    {
        name: 'assessment-30',
        path: '/assessment/assessment-30',
        component: assessment30
    },
    {
        name: 'assessment-31',
        path: '/assessment/assessment-31',
        component: assessment31
    },
    {
        name: 'assessment-32',
        path: '/assessment/assessment-32',
        component: assessment32
    },
    {
        name: 'assessment-33',
        path: '/assessment/assessment-33',
        component: assessment33
    },
    {
        name: 'assessment-34',
        path: '/assessment/assessment-34',
        component: assessment34
    },
    {
        name: 'assessment-35',
        path: '/assessment/assessment-35',
        component: assessment35
    },
    {
        name: 'assessment-36',
        path: '/assessment/assessment-36',
        component: assessment36
    },
    {
        name: 'assessment-37',
        path: '/assessment/assessment-37',
        component: assessment37
    },
    {
        name: 'assessment-38',
        path: '/assessment/assessment-38',
        component: assessment38
    },
    {
        name: 'historico-cliente',
        path: '/historico-cliente',
        component: historicoCliente
    },
    {
        name: 'nova-sessao',
        path: '/nova-sessao',
        component: novaSessao
    },
    {
        name: 'pagina-inicial-adm',
        path: '/pagina-inicial-adm',
        component: paginaInicialAdm
    },
    {
        name: 'avaliacao-pre',
        path: '/avaliacao-pre',
        component: avaliacaoPre
    },
    {
        name: 'avaliacao-pos',
        path: '/avaliacao-pos',
        component: avaliacaoPos
    },
    {
        name: 'nosso-endereco',
        path: '/nosso-endereco',
        component: nossoEndereco
    },
    {
        name: 'visualizar-colaboradores',
        path: '/visualizar-colaboradores',
        component: visualizarColaboradores
    }
]

const router = new Router({
    mode: 'history',
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes
})

export default router
