<template>
  <div>
    <Navbar></Navbar>
    <div id="terms">
      <h1 style="text-align: center">FLORESCER - TERMOS E CONDIÇÕES DE USO</h1>
      <br />
      <p>
        Caso pretenda utilizar a Plataforma, na qualidade de USUÁRIO, deverá aceitar integralmente as cláusulas e
        condições estabelecidas nos presentes Termos e Condições de Uso (“TCU”) estabelecidos por seu proprietário
        FLORESCER , pessoa jurídica de direito privado, inscrita no CNPJ sob o n° 27.917.185/0001-50, com sede na Av.
        Francisco de Andrade Ribeiro, nº 543, 2º andar, Sala 11, Bairro: Família Andrade, CEP 37.540-000, no município
        de Santa Rita do Sapucaí , Estado de Minas Gerais (“Florescer Desenvolvimento”), comprometendo-se a cumprir
        todas as disposições aqui contidas. Em caso de discordância com as normas aqui apresentadas, a utilização da
        Plataforma deverá ser imediatamente interrompida
      </p>
      <p>DEFINIÇÕES:</p>
      <p>
        Os termos utilizados neste instrumento deverão ser interpretados e usados conforme definições abaixo, seja no
        singular ou plural, sem prejuízo de outras definições trazidas neste TCU:
      </p>
      <p>
        I. Plataforma: Software as a Service de titularidade da FLORESCER, disponibilizado em forma de plataforma web
        (hospedado no domínio “www.florescerdesenvolvimento.com.br ”), que funciona como um portal de ferramentas e um
        ambiente de Gestão, de acompanhamentos mentoria, consultoria e atendimentos de modo seguro para Psicólogos ,
        Paciente/ Clientes/ Colaboradores em conjunto com Gestores de Empresas (RH´s e Líderes ao que visa ampliar e
        facilitar o acompanhamento, mentoria e atendimento por videoconferência através da Internet em ambiente
        completamente seguro e dentro dos parâmetros exigidos pelo Conselho Federal de Psicologia.
      </p>
      <p>
        II. EMPRESAS: de pequeno, médio ou grande porte que possui um conselho, uma presidência ou direção, além de
        colaboradores atuantes, e uma gestão de Líderes e Recursos humanos, onde desejam passar por uma mentoria
        especializada em Gestão de Pessoas e/ou atendimento psicológico com especialistas da plataforma florescer.
      </p>
      <p>
        III. Esta Política não se aplica às práticas de empresas que não pertencem nem são controladas por nós,
        incluindo seu
        empregador, ou qualquer usuário (pacientes, parceiros e clientes).
      </p>
      <p>
        IV. PSICÓLOGO: Profissional graduado e registrado no Conselho Regional de Psicologia (CRP) de sua região
        (integrante
        do Sistema Conselhos do Conselho Federal de Psicologia - CFP), com cadastro específico para prestação de
        serviços psicológicos por meio de tecnologias de informação e comunicação (TIC's) - e-Psi - aprovado,
        devidamente cadastrado na Plataforma, com o objetivo de atender PACIENTES/Empresas

      </p>
      <p>
        V. USUÁRIO: COLABORADOR/ GESTOR DE RH/ LIDERES/ DIRETORES/ CEOS/PACIENTE/CLIIENTE PSICÓLOGO/ MENTORES/
        CONSULTORES/
        ESPECIALISTAS que utilize a Plataforma, mediante aceitação do TCU.
      </p>
      <p>
        VI. TCU: Termos e Condições de Uso estabelecidos pela Florescer que deverão ser aceitos por qualquer um
        que
        pretenda utilizar a Plataforma, mesmo que a título de teste. O USUÁRIO estará integralmente vinculado às regras
        estabelecidas no TCU e, em caso de discordância, a utilização da Plataforma deverá ser imediatamente
        interrompida.

      </p>
      <p>
        ACEITE DOS TERMOS E CONDIÇÕES DE USO 1. Os presentes Termos e Condições de Uso podem ser alterados a qualquer
        momento, a critério exclusivo do FLORESCER, sendo o USUÁRIO sempre informado caso isso aconteça.
      </p>
      <p>
        Caso o USUÁRIO não concorde com alguma mudança realizada nos TCU, recomendamos que este encerre sua Conta e
        interrompa a utilização da Plataforma. Caso o USUÁRIO não encerre sua Conta na Plataforma, considerar-se-ão
        aceitos os novos TCU.
      </p>
      <p>
        1. CADASTRO . O acesso à Plataforma será realizado pelo USUÁRIO através de uma conta, com login e senha, sendo a
        conta criada de uso pessoal e intransferível.
      </p>
      <p>
        2. O cadastro e a utilização da Plataforma deverão ser realizados com informações, declarações e documentos
        válidos, completos e atualizados, responsabilizando-se os USUÁRIOS, civil e criminalmente, pela veracidade e
        precisão das informações, isentando o FLORESCER e os parceiros deste por qualquer dano ou prejuízo que a falta
        de informações corretas causar.
      </p>
      <p>
        3. O USUÁRIO sempre será integralmente responsável pela utilização de sua conta na Plataforma, devendo adotar as
        medidas de segurança necessárias para impedir o uso por terceiros não autorizados.
      </p>
      <p>
        O USUÁRIO não poderá divulgar a terceiros seus dados de acesso, nem permitir o uso de tais informações por
        terceiros, responsabilizando-se pelas consequências do uso de seu cadastro, uma vez que estes são pessoais e
        intransferíveis. Caso suspeite que um terceiro esteja utilizando indevidamente sua conta na Plataforma, deverá
        efetuar a imediata troca de sua senha pessoal e comunicar tal fato FLORESCER.
      </p>
      <p>
        4. O uso da Plataforma de forma indevida, irregular, ilegal ou em desacordo às condições previstas neste TCU
        implicará a exclusão do cadastro e a rescisão imediata do acesso do USUÁRIO à Plataforma, sujeito ainda às
        cominações legais. O USUÁRIO deverá indenizar o FLORESCER, seus sócios, colaboradores, empregados, clientes e/ou
        terceiros prejudicados por qualquer perda, reclamação ou demanda, bem como por todos e quaisquer prejuízos
        resultantes da utilização indevida da Plataforma em discordância com o disposto no presente instrumento e na
        legislação aplicável.
      </p>
      <p>
        5. INFORMAÇÕES GERAIS DO AMBIENTE . Acesso à Internet. O acesso à Internet é de responsabilidade única e
        exclusiva dos USUÁRIOS, ficando FLORESCER isento de qualquer responsabilidade quanto à indisponibilidade de
        conexão entre as partes.
      </p>
      <p>
        6. Conexões e Velocidade da Internet. Para que a sessão seja realizada sem problemas, é importante que uma boa
        velocidade de internet e estabilidade de sinal. Os valores mínimos para uso são: Upload= 1.0 Mbps.
      </p>
      <p>
        7. Manutenção do Sistema: FLORESCER se reserva ao direito de efetuar eventuais manutenções em seus sistemas,
        visando melhoria na qualidade do serviço prestado. Caso haja a necessidade de suspensão de serviços de forma
        programada, a FLORESCER avisará com antecedência os clientes, empresas, pacientes, colaboradores, psicólogos e
        especialistas. Sempre que possível, as manutenções serão realizadas no horário entre 00h e 05h. 10. Utilização
        de Câmera e Fone de Ouvido. Para a realização dos atendimentos é necessária a utilização de câmera e fone de
        ouvido, que são de total responsabilidade do PSICÓLOGO e dos PACIENTES. Para evitar problemas durante o
        atendimento, aconselhamos que o USUÁRIO realize testes previamente para verificar se os equipamentos estão
        funcionando de maneira satisfatória.
      </p>
      <p>
        8. Utilização da Plataforma em computadores, Tablets e Celular. O procedimento é o mesmo para o ambiente Web.
        DISPONIBILIDADE DA PLATAFORMA
      </p>
      <p>
        9. A FLORESCER irá empreender seus melhores esforços para garantir a maior disponibilidade possível da
        Plataforma. Contudo, o acesso à Plataforma pode ser interrompido, suspenso ou ficar intermitente
        temporariamente, sem qualquer aviso prévio, em caso de falha de sistema ou servidor, manutenção, alteração de
        sistemas, ou por qualquer motivo que escape ao controle, sem que se faça devida qualquer indenização e/ou
        reparação por parte desta.
      </p>
      <p>
        10. Para que as ocorrências acima não causem dano, prejuízo ou perda aos USUÁRIOS, recomendamos fortemente que
        os USUÁRIOS, por segurança e cautela, mantenham registros próprios dos teleatendimentos e/ou backups dos
        relatórios confeccionados por meio da Plataforma , conforme o caso, sendo certo que não será devida qualquer
        reparação ou indenização por parte da FLORESCER, isentando-o de responsabilidade.
      </p>
      <p>
        11. CONTEÚDO - a FLORESCER irá se esforçar para manter o conteúdo produzido pela Plataforma atualizado e
        completo, livre de quaisquer defeitos ou vírus, não se responsabilizando, contudo, por esses e quaisquer outros
        possíveis problemas que não sejam causadas diretamente e exclusivamente. Fica desde já acordado que a FLORESCER
        poderá alterar o conteúdo da Plataforma a qualquer momento, sem prévio aviso.
      </p>
      <p>
        12. Parte das informações disponíveis na Plataforma são geradas e transmitidas ao FLORESCER por terceiros, sem o
        envolvimento ou responsabilidade deste. A FLORESCER nunca irá alterar o conteúdo que for produzido por
        terceiros, sem prévia solicitação e/ou autorização destes.
      </p>
      <p>13. A FLORESCER não se responsabiliza pelo conteúdo e forma dos atendimentos online, sendo apenas um veículo
        para
        que os mesmos ocorram de forma segura.</p>
      <p>14. É expressamente vedado aos USUÁRIOS o direito de gravar e/ou veicular qualquer atendimento ou sessão
        realizada por meio da Plataforma, seja por meio de gravação onde apareçam imagens, sons, textos, ou outra
        linguagem criptografada ou não, sob pena de incorrer em crime de uso indevido da imagem e voz. </p>
      <p>15. PROPRIEDADE INTELECTUAL. O USUÁRIO não adquire, pela utilização da Plataforma, qualquer direito de
        propriedade intelectual ou outros direitos exclusivos, incluindo patentes, desenhos, marcas, direitos autorais
        ou quaisquer direitos sobre informações confidenciais ou segredos de negócio, bem como todo o conteúdo
        disponibilizado na Plataforma, incluindo, mas não se limitando a textos, gráficos, imagens, logotipos, ícones,
        fotografias, conteúdo editorial e qualquer outro material relacionado à Plataforma.</p>
      <p>16. Todo o conteúdo disponibilizado na Plataforma, incluindo, mas não se limitando a, textos, gráficos,
        imagens, logos, ícones, fotografias, conteúdo editorial, notificações, softwares e qualquer outro material é de
        propriedade exclusiva da FLORESCER ou está devidamente licenciado por este, não podendo ser copiado, plagiado,
        reproduzido ou publicado em qualquer meio (físico ou virtual).
      </p>
      <p>17. É vedado ao USUÁRIO modificar, copiar, distribuir, transmitir, exibir, realizar, reproduzir, publicar,
        disponibilizar, licenciar ou criar obras derivadas a partir das informações coletadas na Plataforma, bem como
        transferir ou utilizar para fins comerciais tais informações, softwares, produtos ou serviços, sob pena de
        violação deste instrumento e caracterização de infração legal.
      </p>
      <p> 18. É expressamente proibida a utilização de ferramentas, scripts, softwares, robôs ou meios que contenham
        qualquer vírus, worms, malwares, trojans e/ou outros programas de computador que possam causar danos à
        Plataforma. </p>
      <p>19. É proibida ainda a coleta de quaisquer informações de identificação pessoal e Cadastro de outros USUÁRIOS
        da Plataforma para quaisquer fins.
      </p>
      <p>20. POLÍTICA DE PRIVACIDADE . A coleta de alguns Dados Pessoais é requisito necessário à utilização da
        Plataforma,
        de modo que, o USUÁRIO que deseje que os seus Dados Pessoais não sejam coletados, não deverá dar prosseguimento
        à
        navegação ou a utilização da Plataforma.</p>
      <p>21. Esta cláusula trata das atividades de coleta de dados online pela FLORESCER, bem como a forma de
        proteção
        destes dados, aplicando-se aos dados que identificam os USUÁRIOS individualmente (Dados Pessoais) e demais dados
        fornecidos pelo USUÁRIO ou coletados durante a utilização da Plataforma.
      </p>
      <p>22. Os dados e os registros de atividades coletados serão armazenados em ambiente seguro e controlado,
        tendo a
        FLORESCER o compromisso de preservar a estabilidade, segurança e funcionalidade da rede, por meio de medidas
        técnicas compatíveis com os padrões internacionais e pelo estímulo ao uso de boas práticas.
      </p>
      <p>23. De acordo com as leis aplicáveis, a FLORESCER usará os Dados Pessoais por quanto tempo for necessário
        para
        satisfazer as finalidades para as quais seus Dados Pessoais foram coletados ou para cumprir com os requerimentos
        legais aplicáveis. Pode, ainda, para fins de auditoria, segurança, controle de fraudes e preservação de
        direitos,
        permanecer, mesmo após o encerramento da Conta do USUÁRIO na Plataforma, com o seu histórico de registro dos
        dados
        por 5 (cinco) anos ou prazo maior nas hipóteses que a lei ou norma regulatória assim estabelecer ou para
        preservação de direitos. Contudo, tem a faculdade de excluí-los definitivamente segundo sua conveniência em
        prazo
        menor.
      </p>
      <p>
        24. A FLORESCER utiliza medidas adequadas para manter os Dados Pessoais do USUÁRIO confidenciais e seguros.
        Contudo, estas proteções não se aplicam a informações que o USUÁRIO tenha escolhido compartilhar em áreas
        públicas, como redes sociais de terceiros.
      </p>
      <p>25. Os Dados Pessoais serão processados pelos colaboradores ou agentes autorizados do FLORESCER, desde que
        estes
        precisem ter acesso a tais informações, dependendo dos propósitos específicos para os quais seus Dados Pessoais
        tenham sido coletados (ex.: colaboradores encarregados de assuntos relacionados ao atendimento ao usuário).
      </p>
      <p>
        26. Por ser a FLORESCER tão somente uma plataforma web que facilita a conexão entre os USUÁRIOS, estes
        reconhecem
        que serão os únicos e exclusivos responsáveis pela confidencialidade das informações que informarem a outros
        USUÁRIOS e/ou a terceiros, inclusive se tal divulgação se der por meio da Plataforma.
      </p>
      <p>27. Todas as sessões serão realizadas em sigilo profissional conforme o Código de Ética Profissional do
        Psicólogo.
        As informações do USUÀRIO são recebidas somente pelo ESPECIALISTA e nunca são compartilhadas com outras pessoas
        ou
        distribuídas na Internet. Nenhuma sessão será gravada ou armazenada sem prévia e expressa autorização entre as
        Partes. O conteúdo das sessões será registrado, organizado e mantido atualizado pelo PSICÓLOGO em forma de
        registro documental eletrônico no prontuário disponível na Plataforma, o qual será preservado em sigilo
        conjuntamente pela FLORESCER DESENVOLVIMENTO e pelo ESPECIALISTA e poderá ser disponibilizado ao USUARIO, bem
        como
        ao Conselho de Psicologia, quando/se solicitado, nos termos da Resolução CFP nº 001/2009.
      </p>
      <p>28. Os serviços mediados por computadores não podem ser considerados como totalmente sigilosos e seguros, pois
        são
        vulneráveis a ameaças digitais. Aconselha-se a não usar computadores públicos e apagar os históricos de
        conversações sempre após as sessões. É também importante proteger o seu computador com um programa de antivírus
        e
        firewall. LIMITAÇÃO DE RESPONSABILIDADE DA FLORESCER.</p>
      <p>29. As Partes reconhecem que não há qualquer relação comercial entre os PSICÓLOGOS, PACIENTES/CLIENTES ou as
        INSTITUIÇÕES e A FLORESCER , não podendo a relação havida entre estes e a FLORESCER considerada, em hipótese
        alguma, como relação de trabalho empregador/empregado, sociedade, associação ou representação.</p>
      <p>30. A FLORESCER não se responsabilizará, em hipótese alguma, perante qualquer USUÁRIO devido a danos e
        perda
        econômica, financeira ou de negócio ou por qualquer perda indireta ou consequente, como perda de reputação,
        negociação perdida ou perda de oportunidades, decorrentes da utilização da Plataforma, exceto no caso de fraude,
        ou ocultação dolosa por parte do PSICOLOGIA VIVA.
      </p>
      <p>31. A FLORESCER adota as medidas de segurança adequadas de acordo com os padrões de mercado para a proteção das
        informações armazenadas na Plataforma. Contudo, os USUÁRIOS e as INSTITUIÇÕES reconhecem que nenhum sistema,
        servidor ou software está absolutamente imune a ataques e/ou invasões de hackers e outros agentes maliciosos,
        não
        sendo a FLORESCER responsável por qualquer exclusão, obtenção, utilização ou divulgação não autorizada de
        informações resultantes de ataques que a FLORESCER não poderia razoavelmente evitar por meio dos referidos
        padrões
        de segurança.</p>
      <p>32. O USUÁRIO poderá entrar em contato com a FLORESCER por meio de envio de e-mail para o seguinte canal de
        atendimento da FLORESCER: suporte@florescerdesenvolvimento.com</p>
      <p>33. Os USUÁRIOS gozam do direito de realizar o cancelamento de seu Cadastro a qualquer tempo. Para efetuá-lo,
        deverá entrar em contato com a FLORESCER por meio do e-mail suporte@florescerdesenvolvimento.com devendo, para
        tanto, informar seu nome completo, CPF, data de nascimento e e-mail que utiliza para fazer o login.
        33. O USUÁRIO declara sua ciência de que uma vez cancelado seu cadastro, suas informações serão também apagadas,
        e
        estas não poderão mais ser recuperadas, ficando a FLORESCER isento de qualquer responsabilidade por quaisquer
        perdas ou danos decorrentes deste procedimento solicitado pelo USUÁRIO.
      </p>
      <p>34. Todas as solicitações dos USUÁRIOS devem ser sempre enviadas ao e-mail
        suporte@florescerdesenvolvimento.com,
        acompanhadas das informações de contato do solicitante, para que a FLORESCER possa finalizar o atendimento. Em
        todos os casos, inclusive solicitações de cancelamento, os USUÁRIOS receberão confirmação de recebimento do
        contato e, a partir da confirmação de recebimento, a FLORESCER terá até 10 (dez) dias para responder o
        contato/solicitação.</p>
      <p>35. A FLORESCER ainda poderá, a seu exclusivo critério, suspender, modificar ou encerrar as atividades da
        Plataforma, mediante comunicação prévia por escrito ao USUÁRIO, com antecedência mínima de 30 (trinta) dias,
        disponibilizando formas e alternativas de extrair da Plataforma as informações de titularidade do USUÁRIO nele
        constante, salvo nas hipóteses de caso fortuito ou força maior.
      </p>
      <p>36. Todas as questões envolvendo a FLORESCER, a Plataforma e/ou o USUÁRIO serão regidas pelas leis da República
        Federativa do Brasil, sendo eleito o Foro da Comarca Santa Rita do Sapucaí/MG como o único competente para
        dirimir
        quaisquer litígios e/ou demandas que venham a envolver as Partes em relação aos serviços prestados. Na qualidade
        de USUÁRIO cadastrado na Plataforma, declaro que li e entendi as informações e condições aqui descritas e aceito
        integralmente os termos do presente TCU por meio da confirmação eletrônica abaixo, mediante o registro do meu
        número de IP, data e hora vinculado ao meu cadastro.</p>

    </div>
    <br />
    <br />
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../components/navbar.vue";
import Footer from "../components/footer.vue";


export default {
  components: {
    Navbar,
    Footer,

  },
};
</script>

<style scoped>
p {
  text-align: justify;
}

table tr th {
  border-style: solid;
  border-color: black;
  text-align: justify;
}

table tr td {
  border-style: solid;
  border-color: black;
  text-align: justify;
}

#terms {
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin-top: 15%;
  padding: 30px;
  margin-left: 5%;
  margin-right: 5%;
}

a:link {
  text-decoration: none;
}

@media (min-width:0px) and (max-width:800px) {
  #privacy-policy {
    margin-top: 33%;
  }
}
</style>