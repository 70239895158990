<template>
  <div id="text4" class="container-fluid">
    <div class="row" id="block">
      <div class="col-12 col-md-6">
        <div id="text">
          <h3>Quer saber mais?</h3>
          <h1>Entre em contato</h1>
          <br />
          <p>florescer.relacionamentos@gmail.com</p>
          <p>(35) 9 9941-1101</p>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div id="form">
          <br />
          <br />
          <label>Nome completo:</label>
          <br />
          <input placeholder=" Digite seu nome completo" id="name" type="text" />
          <br />
          <br />
          <label>Email:</label>
          <br />
          <input placeholder=" email@exemplo.com" id="email" type="text" />
          <br />
          <br />
          <label>Assunto:</label>
          <br />
          <input placeholder=" Qual o assunto?" id="subject" type="text" />
          <br />
          <br />
          <label>Mensagem:</label>
          <br />
          <textarea id="message" placeholder=" Escreva sua mensagem aqui"></textarea>
          <br />
          <br />
          <div style="display: flex; justify-content: center">
            <button v-on:click="sendEmail()" class="btn">Enviar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import emailjs from 'emailjs-com';
export default
  {
    data() {
      return {
        key: false
      }
    },
    methods:
    {
      sendEmail() {
        if (this.key == false) {

          let name = document.getElementById("name").value;
          let email = document.getElementById("email").value;
          let subject = document.getElementById("subject").value;
          let message = document.getElementById("message").value;

          if (name && email && subject && message) {
            emailjs.send("service_f4svv7j", "template_xor8mlr", {
              to_name: "Equipe Florescer",
              from_name: name,
              subject: subject,
              mensagem: message,
              reply_to: email,
            }, "B9cRseNK9Z3JC08Gd").then(r => {
              alert("Mensagem enviada com sucesso!");
              console.log(r);
              this.key = true;
            });
          }
          else {
            alert("Preencha todos os campos para enviar a mensagem!");
          }

        }
        else {
          alert("Mensagem já enviada!");
        }
      }
    }
  }
</script>
<style scoped>
#block {
  width: 100vw;
}

label {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #6a6a6a;
}

.btn {
  background-color: #fabe0a;
  width: 80%;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #692b55;
}

.btn:hover {
  background-color: #fff051;
}

textarea {
  border-radius: 6px;
  border: 1px solid #cccccc;
  resize: none;
  height: 150px;
  margin-left: 10%;
  width: 80%;
}

label {
  margin-left: 10%;
  font-family: Open Sans;
}

input {
  margin-left: 10%;
  width: 80%;
  height: 40px;
  border-radius: 6px;
  border: 1px solid #cccccc;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

#form {
  border-radius: 6px;
  margin-top: 2.778vw;
  margin-bottom: 2.222vw;
  background-color: white;
  height: 612px;
  width: 33.889vw;
  margin-right: 15vw;
}

#text4 {
  height: 684px;
  background-image: url("../../imgs/fundo_contato.png");
  background-repeat: no-repeat;
}

#text {
  position: relative;
  top: 33%;
  left: 7.8%;
}

h1 {
  text-align: justify;
  color: white;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 3.514vw;
}

h3 {
  text-align: justify;
  color: white;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 2.343vw;
}

p {
  text-align: justify;
  color: white;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1.537vw;
}

@media (min-width: 620px) and (max-width: 768px) {
  textarea {
    height: 50px;
  }

  #form {
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
    height: 500px;
  }

  #text {
    top: 10%;
    width: 32.26%;
    position: relative;
    left: 33.9%;
  }

  #text4 {
    background-color: yellow;
  }

  .btn {
    height: 36px;
  }
}

@media (min-width: 0px) and (max-width: 619px) {
  h1 {
    font-size: 3.914vw;
  }

  h3 {
    font-size: 2.943vw;
  }

  p {
    font-size: 2.237vw;
  }

  textarea {
    height: 50px;
  }

  #form {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    height: 500px;
  }

  #text {
    top: 10%;
    width: 32.26%;
    position: relative;
    left: 33.9%;
  }

  #text4 {
    background-color: yellow;
  }

  .btn {
    height: 36px;
  }
}
</style>