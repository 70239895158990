<template>
    <div class="container-fluid"> 
        <br>
        <br>
        <br>
        <div id="boxAssessment" class="m-3">
            <h1 class="text-center">Modelo Seis Segundos</h1>
            <br>
            <h5>[Desenvolvido a partir do trabalho de S. Skiffington]</h5>
            <br>
            
            <h3><b>Passo 1 – Determine o problema</b></h3>
            <br>
            <h5>Estado atual: Qual é o estado atual?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Estado desejado: Qual é o estado desejado?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
        

            <h3><b>Passo 2 – Determine as emoções negativas</b></h3>
            <br>
            <h5>Quais são as emoções negativas que limitam sua performance?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
           
            <h3><b>Passo 3 – Determine o gatilho</b></h3>
            <br>
            <h5>Que evento dispara as emoções?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>É algo que você pensa, vê, ouve ou sente?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3><b>Lidar com emoções limitantes:</b></h3>
            <br>
            <h3><b>Passo 4 – Seis segundos</b></h3>
            <p>Estabelecer uma pausa de seis segundos entre o estímulo e a reação (emoção negativa). Esse
processo permite que o cliente aja de forma mais racional, em vez de simplesmente reagir ao
estímulo.</p>
            <br>
            <h5>O que você pode pensar após o gatilho (Passo 3) para ter uma pausa de pensamento?</h5>
            <p>Exemplos: Contar até seis em um novo idioma; Enunciar os nomes dos sete anões; Dizer os
nomes de seis capitais; Visualizar os detalhes de seis lugares maravilhosos; Descrever seis coi-
sas positivas etc.</p>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3><b>Passo 5 – Ensaio mental</b></h3>
            <p>Juntos, cliente e profissional trabalham para ampliar as opções de atuação do cliente por meio de
ensaio mental, simulando mentalmente um desafio futuro.</p>
            <br>
            <br>
             <br>
            <br>
            
            <h5>Comentários e anotações</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <div style="display: flex; justify-content: center;"> 
                    <a href="/nova-sessao"><button class="btn mr-3" >Voltar a pagina do cliente</button></a>
                    <button class="btn" >Salvar resultados</button>
            </div>


        </div>
    </div>
</template>

<style scoped>
*
{
    font-family: roboto;
}
#boxAssessment
{
  border-radius: 2%;  
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  margin: 0 auto;
  padding: 30px;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
</style>

<script>
export default {
     data() {
        return {
            
           
            opcoes: [
               {n: '0'},
               {n: '1'},
               {n: '2'},
               {n: '3'},
               {n: '4'},
               {n: '5'},
               {n: '6'},
               {n: '7'},
               {n: '8'},
               {n: '9'},
               {n: '10'},
            ]
        }
     }
   
}
</script>