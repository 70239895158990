<template>
  <div>
    <Navbar></Navbar>
    <br />
    <br />
    <br />
    <Text1></Text1>
    <Text2></Text2>
    <Footer></Footer>
  </div>
</template>

<script>
import Text1 from "../components/text1-para-voce";
import Text2 from "../components/text2-para-voce";
import Navbar from "../components/navbar.vue";
import Footer from "../components/footer.vue";

export default {
  components: {
    Text1,
    Text2,
    Navbar,
    Footer,
  },
};
</script>