 <template>
  <div class="container-fluid" id="text6">
    <br />
    <br />
    <br />
    <div id="carrousel">
      <div v-if="numberImg == 0">
        <div class="picture">
          <img src="../../imgs/ana_carolina.png" />
        </div>
        <br />

        <p class="textDepositions" style="text-align: justify">
          Só tenho a agradecer pelo apoio profissional que ajudou a me ver de
          forma diferente e me ensinou a traçar planos de ações e realizá-los
          até alcançar a meta desejada. Para você que quer dar um UP em sua vida
          tanto profissional como também pessoal, vale muito à pena realizar
          esse processo.
        </p>
        <br />
        <h2 style="text-align: center; color: #aa0073">
          Ana Carolina - Mãe e Profissional aspirante
        </h2>
      </div>

      <div v-if="numberImg == 1">
        <div class="picture">
          <img src="../../imgs/ceo_girassol.png" />
        </div>
        <br />

        <p class="textDepositions">
          A Giliani foi recomendada por um consultor jurídico que acreditava que
          a Girassol precisava de um amadurecimento dos sócios, e
          desenvolvimento de gestão de pessoas, e essa evolução iniciou com a
          chegada da Florescer. O trabalho foi iniciado com sessões individuais
          para os sócios, após isso, treinamento individuais para os vendedores,
          hoje, todos da equipe de vendas se sentem melhores e dizem que tudo o
          que aprendeu com a Giliani irão levar de aprendizado para o resto da
          vida. Logo enxergamos os excelentes resultados que a Florescer trazia
          para empresa e sendo assim, aplicamos também treinamento de liderança
          para os coordenadores de cada setor, eles receberam orientações da
          Giliani, que se tornou a nossa intermediária entre os CEOs e os
          coordenadores, trazendo para a diretoria todas as dores que os setores
          tinham e orientando na melhora de cada um deles. Só tenho a agradecer
          pela Giliani e seus serviços,pois ela além de enxergar as pessoas
          pelos seus cargos ela as enxerga com empatia e a parceria com a
          Florescer é algo que iremos levar pela eternidade com a Girassol, só
          tenho a agradecer por tudo que a Giliani fez para a minha empresa e
          para mim.
        </p>

        <h2 style="text-align: center; color: #aa0073">
          Yan Samuel Ferreira<span style="text-align: center; color: #983e7b"
            ><br /><b>CEO - Girassol Brasil</b></span
          >
        </h2>
      </div>

      <div v-if="numberImg == 2">
        <div class="picture">
          <img src="../../imgs/helton.png" />
        </div>
        <br />

        <p class="textDepositions">
          Realizar o desenvolvimento com a Giliani foi uma experiência incrível,
          um processo de transformação intenso. Cada sessão me levou a uma
          reflexão profunda sobre todos os acontecimentos do dia a dia, tanto no
          âmbito profissional como pessoal. Adquirir o autoconhecimento, fazer
          novas descobertas, foram etapas importantes desse processo. Os
          desafios e metas se tornaram metas mais claras de desenvolvimento.
          Gostaria de agradecer imensamente a Giliani pelo profissionalismo,
          dedicação, respeito, empatia, comprometimento e pelo amor que ela têm
          pela profissão. Pois transforma isso em motivação para todos.
        </p>
        <br />
        <h2 style="text-align: center; color: #aa0073">
          Helton C. Santos<span style="text-align: center; color: #983e7b"
            ><br /><b>Gerente Geral - R&M</b></span
          >
        </h2>
      </div>

      <div v-if="numberImg == 3">
        <div class="picture">
          <img src="../../imgs/janaina.png" />
        </div>
        <br />

        <p class="textDepositions">
          Tive a oportunidade de se desenvolver com os especialistas da
          Florescer, através do método Florescer em 2019, numa trajetória de 40
          horas. Estava passando por alguns desafios na minha carreira
          profissional e buscar ferramentas de gestão fez toda diferença para o
          sucesso nesse período. O método utilizado me fez perceber que é
          possível produzir mais e melhor trazendo eficácia para vários
          contextos da minha vida, tanto profissional quanto pessoal. Foram
          inúmeras ferramentas utilizadas nesse método onde o grande diferencial
          para mim era o plano de ação, onde de fato eu pude vivenciar toda
          parte teórica e todas as técnicas utilizadas como chaves para
          alavancar minha vida na prática. Assumir o comando da nossa vida é
          tudo de bom que podemos de fato fazer por nós! E entender o estilo de
          personalidade de cada pessoa que convivemos e respeitar a sua
          história, são visões que começam a nos dar os primeiros passos para
          construirmos uma equipe vencedora. Infelizmente temos a falsa ilusão
          que conseguimos progredir sozinhos, apenas utilizando nossos
          instintos, materiais de internet e resiliência...isso é um grande
          equívoco. Procurar uma profissional dedicada, estudiosa, acolhedora
          com um método reconhecido mundialmente fez toda a diferença para
          construção de uma vida mais feliz. O maior investimento feito é aquela
          que nos leva para um próximo nível.
        </p>

        <h2 style="text-align: center; color: #aa0073">
          Janaína Siécola Villela - Gestora comercial
        </h2>
      </div>

      <div v-if="numberImg == 4">
        <div class="picture">
          <img src="../../imgs/angelica.png" />
        </div>
        <br />

        <p class="textDepositions">
          Ter passado pelo desenvolvimento com a Giliani, foi maravilhoso, me
          ajudou muito, em todas as áreas de minha vida, tanto pessoal e
          profissional. Nossa vida é um desafio diário, e com o processo aprendi
          que a diferença está em como reagimos e encaramos esses desafios, a
          partir do momento em que aprendi a parar e pensar, ficou muito mais
          fácil enxergar outras possibilidades de agir, me trouxe a consciência
          de que meus atos, e a forma como eu lido com certas situações, podem
          ser muito mais positivos, do que quando agia no impulso, e apenas na
          minha razão. Estou me redescobrindo, acreditando mais em mim e no meu
          potencial, hoje consigo enxergar que sempre posso ir além e ser cada
          dia melhor não só pra mim, mas para quem estiver a minha volta. Estou
          muitoooo feliz!! Obrigada por acreditar em mim, e me ajudar a trilhar
          esse novo caminho!!
        </p>
        <br />
        <h2 style="text-align: center; color: #aa0073">
          Angélica<br /><span style="text-align: center; color: #983e7b"
            ><b>Comercial - Pedrão Imóveis</b></span
          >
        </h2>
      </div>

      <div v-if="numberImg == 5">
        <div class="picture">
          <img src="../../imgs/simone.png" />
        </div>
        <br />

        <p class="textDepositions">
          O trabalho dentro da Florescer está sendo essencial no desenvolvimento
          profissional da equipe do Pedrão Imóveis. Em cada trabalho realizado
          aprendemos a importância de se conhecer e respeitar uns aos outros,
          buscando sempre atingir o nosso melhor. Temos muita gratidão por seus
          ensinamentos e sabemos da importância de seu trabalho em nossa
          empresa, para melhoria dos processos e da construção e fortalecimento
          do nosso eu e que é essencial aos líderes e seus liderados. E cada vez
          mais buscarem juntos este crescimento constante.
        </p>
        <br />
        <h2 style="text-align: center; color: #aa0073">
          Simone Ribeiro Costa<br /><span
            style="text-align: center; color: #983e7b"
            ><b>CEO - Pedrão Imóveis</b></span
          >
        </h2>
      </div>
    </div>
    <br />
    <br />
    <br />
    <br />

    <div style="margin-top: 60px; display: flex; justify-content: center">
      <img
        style="cursor: pointer"
        class="mr-5 arrow"
        v-on:click="previow()"
        src="../../imgs/seta_esquerda.png"
      />
      <img
        style="cursor: pointer"
        class="ml-5 arrow"
        v-on:click="next()"
        src="../../imgs/seta_direita.png"
      />
    </div>

    <br />
    <br />
  </div>
</template>

<script>
export default {
  mounted() {
    setInterval(() => {
      this.next();
    }, 30000);
    setInterval(() => {
      this.changeLogos();
    }, 7000);
  },
  data() {
    return {
      numberImg: 0,
      showLogos: 0,
    };
  },
  methods: {
    changeLogos: function () {
      if (this.showLogos == 0) {
        this.showLogos = 1;
      } else if (this.showLogos < 2) {
        this.showLogos = this.showLogos + 1;
      } else {
        this.showLogos = 0;
      }
    },
    next: function () {
      if (this.numberImg < 5) this.numberImg = this.numberImg + 1;
      else this.numberImg = 0;
    },
    previow: function () {
      if (this.numberImg > 0) this.numberImg = this.numberImg - 1;
      else this.numberImg = 4;
    },
  },
};
</script>
<style scoped>
.picture {
  display: flex;
  justify-content: center;
}
h2 {
  font-size: 15px;
}
.arrow {
  width: 3.66vw;
  height: 3.66vw;
}
.textDepositions {
  color: #545454;
  text-align: justify;
  font-size: 1.078vw;
}
#carrousel {
  background-color: #fad7ef;
  height: 400px;
  width: 70%;
  color: black;
  border-radius: 2%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-top: -50px;
  padding: 30px;
  margin-left: 15%;
  margin-right: 15%;
}
#text6 {
  width: 100%;
}
img {
  visibility: visible;
  transition-property: visibility;
  margin-left: 37.5%;
  margin-right: 37.5%;
  margin-top: -140px;
}
.boxText {
  background-image: linear-gradient(180deg, #fd5392, #f86f64);
  color: white;
  border-radius: 10px;
  padding: 20px;
  text-align: justify;
  font-size: 15px;
  box-shadow: inset 0 0 1em #fd5392, 0 0 1em #f86f64;
}
@media (min-width: 1025px) {
  .picture {
    padding-top: 30px;
  }
  img {
    width: 120px;
    height: 120px;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  #carrousel {
    height: 400px;
  }
  img {
    margin-top: -95px;
    width: 12.639vw;
    height: 12.639vw;
  }
}
@media (min-width: 565px) and (max-width: 768px) {
  #carrousel {
    height: 390px;
  }
  img {
    margin-top: -70px;
    width: 12.639vw;
    height: 12.639vw;
  }
  .textDepositions {
    font-size: 1.478vw;
  }
}
@media (min-width: 400px) and (max-width: 564px) {
  #carrousel {
    height: 410px;
  }
  img {
    margin-top: -70px;
    width: 12.639vw;
    height: 12.639vw;
  }
  .textDepositions {
    font-size: 1.7vw;
  }
  .arrow {
    width: 3.96vw;
    height: 3.96vw;
  }
}
@media (min-width: 0px) and (max-width: 399px) {
  #carrousel {
    height: 500px;
  }
  img {
    margin-top: -60px;
    width: 12.639vw;
    height: 12.639vw;
  }
  .textDepositions {
    font-size: 2vw;
  }
  .arrow {
    width: 3.96vw;
    height: 3.96vw;
  }
}
</style>