<template>
    <div class="container-fluid"> 
        <br>
        <br>
        <br>
        <div id="boxAssessment" class="m-3">
            <h1 class="text-center">Medição em Quatro Dimensões</h1>
            <br>
            <br>
            <h3 ><b>DIMENSÃO I – PROFISSIONAL</b></h3>
            <br>
            <div class="row">
                <h5>As sessões foram prazerosas e gratificantes</h5>
                
                <select class="input ml-3" required="required">
                            <option v-for="opcao in opcoes" :key="opcao.n">
                                {{ opcao.n}}
                            </option>
                        
                </select>
            </div>
            <br>
            <div class="row">
                <h5>As sessões atenderam ou superaram as minhas expectativas e foram úteis para os meus objetivos.</h5>
                
                <select class="input ml-3" required="required">
                            <option v-for="opcao in opcoes" :key="opcao.n">
                                {{ opcao.n}}
                            </option>
                        
                </select>
            </div>
            <br>
            <div class="row">
                <h5>As sessões de coaching foram motivadoras e me estimularam a agir.</h5>
                
                <select class="input ml-3" required="required">
                            <option v-for="opcao in opcoes" :key="opcao.n">
                                {{ opcao.n}}
                            </option>
                        
                </select>
            </div>
            <br>
            <div class="row">
                <h5>As sessões de coaching me ajudaram a superar barreiras e limitações internas.</h5>
                
                <select class="input ml-3" required="required">
                            <option v-for="opcao in opcoes" :key="opcao.n">
                                {{ opcao.n}}
                            </option>
                        
                </select>
            </div>
            <br>
            <div class="row">
                <h5>As sessões de coaching contribuíram decisivamente para meu crescimento pes-
soal e profissional.</h5>
                
                <select class="input ml-3" required="required">
                            <option v-for="opcao in opcoes" :key="opcao.n">
                                {{ opcao.n}}
                            </option>
                        
                </select>
            </div>
            <br>
            <div class="row">
                <h5>O quanto você está inclinado a indicar o coaching a um amigo.</h5>
                
                <select class="input ml-3" required="required">
                            <option v-for="opcao in opcoes" :key="opcao.n">
                                {{ opcao.n}}
                            </option>
                        
                </select>
            </div>
            <br>
            <br>
            <h3 ><b>DIMENSÃO II – APRENDIZADO</b></h3>
            <br>
            <div class="row">
                <h5>Fazer as perguntas certas para direcionar a busca de soluções.</h5>
                
                <select class="input ml-3" required="required">
                            <option v-for="opcao in opcoes" :key="opcao.n">
                                {{ opcao.n}}
                            </option>
                        
                </select>
            </div>
            <br>
            <div class="row">
                <h5>Formular corretamente objetivos e estabelecer um plano de ação.</h5>
                
                <select class="input ml-3" required="required">
                            <option v-for="opcao in opcoes" :key="opcao.n">
                                {{ opcao.n}}
                            </option>
                        
                </select>
            </div>
            <br>
            <div class="row">
                <h5>Analisar ganhos e perdas, motivadores e sabotadores para entrar em ação de
forma efetiva.</h5>
                
                <select class="input ml-3" required="required">
                            <option v-for="opcao in opcoes" :key="opcao.n">
                                {{ opcao.n}}
                            </option>
                        
                </select>
            </div>
            <br>
            <div class="row">
                <h5>Administrar as ações no tempo de modo mais eficaz.</h5>
                
                <select class="input ml-3" required="required">
                            <option v-for="opcao in opcoes" :key="opcao.n">
                                {{ opcao.n}}
                            </option>
                        
                </select>
            </div>
            <br>
            <div class="row">
                <h5>Analisar e solucionar problemas de modo mais eficaz.</h5>
                
                <select class="input ml-3" required="required">
                            <option v-for="opcao in opcoes" :key="opcao.n">
                                {{ opcao.n}}
                            </option>
                        
                </select>
            </div>
            <br>
            <div class="row">
                <h5>Controlar estados emocionais.</h5>
                
                <select class="input ml-3" required="required">
                            <option v-for="opcao in opcoes" :key="opcao.n">
                                {{ opcao.n}}
                            </option>
                        
                </select>
            </div>
            <br>
             <div class="row">
                <h5>Desenvolver novos comportamentos e competências.</h5>
                
                <select class="input ml-3" required="required">
                            <option v-for="opcao in opcoes" :key="opcao.n">
                                {{ opcao.n}}
                            </option>
                        
                </select>
            </div>
            <br>
            <div class="row">
                <h5>Desenvolver uma visão sistêmica.</h5>
                
                <select class="input ml-3" required="required">
                            <option v-for="opcao in opcoes" :key="opcao.n">
                                {{ opcao.n}}
                            </option>
                        
                </select>
            </div>
            <br>
            <br>


            <h3 ><b>DIMENSÃO III – AUMENTO DE PERFORMANCE</b></h3>
            <br>
            <div class="row">
                <h5>Melhoria na definição de direção, no planejamento, e na especificaçãode objetivos.</h5>
                
                <select class="input ml-3" required="required">
                            <option v-for="opcao in opcoes" :key="opcao.n">
                                {{ opcao.n}}
                            </option>
                        
                </select>
            </div>
            <br>
            <div class="row">
                <h5>Melhoria no entendimento, na formulação e no foco em missão e propósito.</h5>
                
                <select class="input ml-3" required="required">
                            <option v-for="opcao in opcoes" :key="opcao.n">
                                {{ opcao.n}}
                            </option>
                        
                </select>
            </div>
            <br>
            <div class="row">
                <h5>Melhoria na motivação e continuidade de ação em direção aos objetivos desejados.</h5>
                
                <select class="input ml-3" required="required">
                            <option v-for="opcao in opcoes" :key="opcao.n">
                                {{ opcao.n}}
                            </option>
                        
                </select>
            </div>
            <br>
            <div class="row">
                <h5>Melhoria no autoconhecimento e na utilização das forças, virtudes e talentos.</h5>
                
                <select class="input ml-3" required="required">
                            <option v-for="opcao in opcoes" :key="opcao.n">
                                {{ opcao.n}}
                            </option>
                        
                </select>
            </div>
            <br>
            <div class="row">
                <h5>Melhoria na autoestima, autoconfiança e automotivação.</h5>
                
                <select class="input ml-3" required="required">
                            <option v-for="opcao in opcoes" :key="opcao.n">
                                {{ opcao.n}}
                            </option>
                        
                </select>
            </div>
            <br>
            <div class="row">
                <h5>Melhoria nos comportamentos e desenvolvimento de competências desejadas.</h5>
                
                <select class="input ml-3" required="required">
                            <option v-for="opcao in opcoes" :key="opcao.n">
                                {{ opcao.n}}
                            </option>
                        
                </select>
            </div>
            <br>
             <div class="row">
                <h5>Melhoria na congruência e motivação interna por meio do entendimento de va-
lores e crenças.</h5>
                
                <select class="input ml-3" required="required">
                            <option v-for="opcao in opcoes" :key="opcao.n">
                                {{ opcao.n}}
                            </option>
                        
                </select>
            </div>
            <br>
            <div class="row">
                <h5>Melhoria no entendimento da importância da mudança e do aprendizado para
obtenção de melhores resultados.</h5>
                
                <select class="input ml-3" required="required">
                            <option v-for="opcao in opcoes" :key="opcao.n">
                                {{ opcao.n}}
                            </option>
                        
                </select>
            </div>
            <br>
            <br>
             <h3 ><b>DIMENSÃO IV – RESULTADOS E MELHORIA CONTÍNUA</b></h3>
            <br>
            <div class="row">
                <h5>Melhoria nos resultados da vida pessoal.</h5>
                
                <select class="input ml-3" required="required">
                            <option v-for="opcao in opcoes" :key="opcao.n">
                                {{ opcao.n}}
                            </option>
                        
                </select>
            </div>
            <br>
            <div class="row">
                <h5>Melhoria nos resultados da vida profissional.</h5>
                
                <select class="input ml-3" required="required">
                            <option v-for="opcao in opcoes" :key="opcao.n">
                                {{ opcao.n}}
                            </option>
                        
                </select>
            </div>
            <br>
            <div class="row">
                <h5>Melhoria nos relacionamentos.</h5>
                
                <select class="input ml-3" required="required">
                            <option v-for="opcao in opcoes" :key="opcao.n">
                                {{ opcao.n}}
                            </option>
                        
                </select>
            </div>
            <br>
            <div class="row">
                <h5>Melhoria na qualidade de vida.</h5>
                
                <select class="input ml-3" required="required">
                            <option v-for="opcao in opcoes" :key="opcao.n">
                                {{ opcao.n}}
                            </option>
                        
                </select>
            </div>
            <br>
            <div class="row">
                <h5>Melhoria na sua capacidade de aprender com os eventos/experiências e obter
conclusões efetivas.</h5>
                
                <select class="input ml-3" required="required">
                            <option v-for="opcao in opcoes" :key="opcao.n">
                                {{ opcao.n}}
                            </option>
                        
                </select>
            </div>
            <br>
            

            <div style="display: flex; justify-content: center;"> 
                    <a href="/nova-sessao"><button class="btn mr-3" >Voltar a pagina do cliente</button></a>
                    <button class="btn" >Salvar resultados</button>
            </div>


        </div>
    </div>
</template>

<style scoped>
*
{
    font-family: roboto;
}
#boxAssessment
{
  border-radius: 2%;  
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  margin: 0 auto;
  padding: 30px;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
</style>

<script>
export default {
     data() {
        return {
            
           
            opcoes: [
               {n: '0'},
               {n: '1'},
               {n: '2'},
               {n: '3'},
               {n: '4'},
               {n: '5'},
               {n: '6'},
               {n: '7'},
               {n: '8'},
               {n: '9'},
               {n: '10'},
            ]
        }
     }
   
}
</script>