<template>
  <div id="header" href="#" class="container-fluid">
    <div id="screenDesktop" class="row">
      <div class="col-9" id="text">
        <br />
        <h1>
          <b>Despertando talentos<br />e promovendo o seu
            <span>FLORESCIMENTO</span></b>
        </h1>
        <br />

        <h5>
          Gestão da saúde emocional e bem-estar para pessoas<br />e corporações
          com o foco na performance e ciência do<br />
          Florescimento Humano.
        </h5>

        <br />

        <div>
          <button v-on:click="showPage()" class="btn">
            Conheça o nosso trabalho
          </button>
        </div>
      </div>
      <div class="col-3" id="image">
        <img id="flower" src="../../imgs/flor_transparente.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    showPage: function () {
      window.scrollTo(0, 2550);
    },
  },
};
</script>

<style scoped>
#flower {
  z-index: 0;
}

#image {
  display: flex;
  justify-content: flex-end;
  top: 10%;
  position: relative;
}

#text {
  position: relative;
  top: 35%;
  height: 380px;
}

#screenDesktop {
  justify-content: normal;
  color: white;
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0%;
}

#header {
  background-image: url("../../imgs/imagem-hero.png");
  height: 730px;
  background-repeat: no-repeat;
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100vw;
}

span {
  color: #fabe0a;
  font-weight: bold;
  font-family: Source Sans Pro;
  font-style: normal;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

* {
  margin: 0;
  padding: 0;
}

h1 {
  margin-left: 7.778vw;
}

h5 {
  margin-left: 7.778vw;
}

.btn {
  position: absolute;
  z-index: 20;
  margin-left: 7.778vw;
}

.btn:hover {
  background-color: #fff051;
}

@media (min-width: 1400px) {
  #flower {
    width: 499px;
    height: 693px;
  }

  h1 {
    color: white;
    font-weight: bold;
    font-size: 56px;
    font-family: Source Sans Pro;
  }

  h5 {
    color: white;
    font-size: 21px;
    font-family: Open Sans;
  }

  .btn {
    background-color: #fabe0a;
    padding: 10px;
    border-radius: 6px;
    width: 278px;
    height: 52px;
    font-family: Source Sans Pro;
    color: #692b55;
    font-weight: bold;
    font-size: 1.537vw;
  }

  .btn:hover {
    background-color: #fff051;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  #flower {
    width: 499px;
    height: 693px;
  }

  h1 {
    color: white;
    font-weight: bold;
    font-size: 3.9vw;
    font-family: Source Sans Pro;
  }

  h5 {
    color: white;
    font-size: 1.537vw;
    font-family: Open Sans;
  }

  span {
    color: #fabe0a;
    font-weight: bold;
    font-family: Source Sans Pro;
    font-style: normal;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .btn {
    background-color: #fabe0a;
    padding: 10px;
    border-radius: 6px;
    width: 20.351vw;
    height: 52px;
    font-family: Source Sans Pro;
    color: #692b55;
    font-weight: bold;
    font-size: 1.537vw;
  }

  * {
    margin: 0;
    padding: 0;
  }
}

@media (min-width: 993px) and (max-width: 1199px) {
  #flower {
    width: 499px;
    height: 693px;
  }

  h1 {
    color: white;
    font-weight: bold;
    font-size: 4vw;
    font-family: Source Sans Pro;
  }

  h5 {
    color: white;
    font-size: 1.537vw;
    font-family: Open Sans;
  }

  span {
    color: #fabe0a;
    font-weight: bold;
    font-family: Source Sans Pro;
    font-style: normal;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .btn {
    background-color: #fabe0a;
    padding: 10px;
    border-radius: 6px;
    width: 20.351vw;
    height: 52px;
    font-family: Source Sans Pro;
    color: #692b55;
    font-weight: bold;
    font-size: 1.537vw;
  }

  * {
    margin: 0;
    padding: 0;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  #flower {
    width: 499px;
    height: 693px;
  }

  h1 {
    color: white;
    font-weight: bold;
    font-size: 4vw;
    font-family: Source Sans Pro;
  }

  h5 {
    color: white;
    font-size: 2vw;
    font-family: Open Sans;
  }

  span {
    color: #fabe0a;
    font-weight: bold;
    font-family: Source Sans Pro;
    font-style: normal;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .btn {
    background-color: #fabe0a;
    padding: 10px;
    border-radius: 6px;
    width: 20.351vw;
    height: 52px;
    font-family: Source Sans Pro;
    color: #692b55;
    font-weight: bold;
    font-size: 1.537vw;
  }

  * {
    margin: 0;
    padding: 0;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  #flower {
    width: 499px;
    height: 693px;
  }

  h1 {
    color: white;
    font-weight: bold;
    font-size: 4.5vw;
    font-family: Source Sans Pro;
  }

  h5 {
    color: white;
    font-size: 2.4vw;
    font-family: Open Sans;
  }

  span {
    color: #fabe0a;
    font-weight: bold;
    font-family: Source Sans Pro;
    font-style: normal;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .btn {
    background-color: #fabe0a;
    padding: 10px;
    border-radius: 6px;
    width: 30.351vw;
    height: 52px;
    font-family: Source Sans Pro;
    color: #692b55;
    font-weight: bold;
    font-size: 1.837vw;
    z-index: 4;
  }

  .btn:hover {
    background-color: #fff051;
  }
}

@media (min-width: 376px) and (max-width: 576px) {
  #flower {
    width: 499px;
    height: 693px;
    z-index: unset;
  }

  h1 {
    color: white;
    font-weight: bold;
    font-size: 6vw;
    font-family: Source Sans Pro;
    z-index: 1;
  }

  h5 {
    color: white;
    font-size: 2.61vw;
    font-family: Open Sans;
    z-index: 1;
  }

  span {
    color: #fabe0a;
    font-weight: bold;
    font-family: Source Sans Pro;
    font-style: normal;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 1;
  }

  .btn {
    background-color: #fabe0a;
    padding: 10px;
    border-radius: 6px;
    width: 35vw;
    height: 52px;
    font-family: Source Sans Pro;
    color: #692b55;
    font-weight: bold;
    font-size: 2.5vw;

  }
}

@media (min-width: 0px) and (max-width: 375px) {
  #flower {
    width: 499px;
    height: 693px;
    z-index: 0;
  }

  h1 {
    color: white;
    font-weight: bold;
    font-size: 6vw;
    font-family: Source Sans Pro;
  }

  #header {
    background-position: center;
  }

  h5 {
    color: white;
    font-size: 2.6vw;
    font-family: Open Sans;
  }

  span {
    color: #fabe0a;
    font-weight: bold;
    font-family: Source Sans Pro;
    font-style: normal;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .btn {
    background-color: #fabe0a;
    padding: 10px;
    border-radius: 6px;
    width: 55vw;
    height: 52px;
    font-family: Source Sans Pro;
    color: #692b55;
    font-weight: bold;
    font-size: 3.1vw;
    z-index: 4;
  }
}
</style>

