<template>
    <div  style="background-color:#f7f8fc;;height:100%;width:82%;position:absolute;left:18%;top:0%;margin-left:0%;"> 
        <br>
        <br>
       
        <div id="boxAssessment" >
            <br>
            <h1 class="text-center">DRE</h1>
            <br>
            <br>
            <p style="text-align:center">Receita</p>
            <scroll-container>
            <table  id="tableRevenue">
                <tr>
                    <th colspan="27"  ></th>
                </tr>
               
                <tr>
                    <td>Ano <input type="number" value="2021" ></td>
                    <td>Janeiro</td>
                    <td class="percent">%</td>
                    <td>Fevereiro</td>
                    <td class="percent">%</td>
                    <td>Março</td>
                    <td class="percent">%</td>
                    <td>Abril</td>
                    <td class="percent">%</td>
                    <td>Maio</td>
                    <td class="percent">%</td>
                    <td>Junho</td>
                    <td class="percent">%</td>
                    <td>Julho</td>
                    <td class="percent">%</td>
                    <td>Agosto</td>
                    <td class="percent">%</td>
                    <td>Setembro</td>
                    <td class="percent">%</td>
                    <td>Outubro</td>
                    <td class="percent">%</td>
                    <td>Novembro</td>
                    <td class="percent">%</td>
                    <td>Dezembro</td>
                    <td class="percent">%</td>
                    <td>Total</td>
                    <td>Media</td>
                </tr>
                <tr>
                    <td><input type="text" ></td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td><input type="text"></td>
                </tr>

            </table>
            </scroll-container>
            <br>
            <button v-on:click="addLine" class="btn">+ Adicionar receita</button>
            <br>
             <scroll-container>
            <table  id="tableRevenue">
                <tr>
                     
                    <th>Total de receitas</th>
                    <th>0,00</th>
                    <th class="percent">%</th>
                    <th>0,00</th>
                    <th class="percent">%</th>
                    <th>0,00</th>
                    <th class="percent">%</th>
                    <th>0,00</th>
                    <th class="percent">%</th>
                    <th>0,00</th>
                    <th class="percent">%</th>
                    <th>0,00</th>
                    <th class="percent">%</th>
                    <th>0,00</th>
                    <th class="percent">%</th>
                    <th>0,00</th>
                    <th class="percent">%</th>
                    <th>0,00</th>
                    <th class="percent">%</th>
                    <th>0,00</th>
                    <th class="percent">%</th>
                    <th>0,00</th>
                    <th class="percent">%</th>
                    <th>0,00</th>
                    <th class="percent">%</th>
                    <th>Total</th>
                    <th>Media</th>
                
                </tr>
                
                <tr>
                    <td>Impostos</td>
                    <td>0,00</td>
                    <td class="percent">%</td>
                    <td>0,00</td>
                    <td class="percent">%</td>
                    <td>Março</td>
                    <td class="percent">%</td>
                    <td>Abril</td>
                    <td class="percent">%</td>
                    <td>Maio</td>
                    <td class="percent">%</td>
                    <td>Junho</td>
                    <td class="percent">%</td>
                    <td>Julho</td>
                    <td class="percent">%</td>
                    <td>Agosto</td>
                    <td class="percent">%</td>
                    <td>Setembro</td>
                    <td class="percent">%</td>
                    <td>Outubro</td>
                    <td class="percent">%</td>
                    <td>Novembro</td>
                    <td class="percent">%</td>
                    <td>Dezembro</td>
                    <td class="percent">%</td>
                    <td>Total</td>
                    <td>Media</td>
                </tr>
                <tr>
                    <td><input type="text" ></td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td><input type="text"></td>
                </tr>

            </table>
            </scroll-container>

            <br>
             
            <button v-on:click="addLine" class="btn">+ Adicionar Impostos</button>
            
            <br>
             <scroll-container>
            <table  id="tableRevenue">
                <tr>
                     
                    <th>Faturamente líquido</th>
                    <th>0,00</th>
                    <th class="percent">%</th>
                    <th>0,00</th>
                    <th class="percent">%</th>
                    <th>0,00</th>
                    <th class="percent">%</th>
                    <th>0,00</th>
                    <th class="percent">%</th>
                    <th>0,00</th>
                    <th class="percent">%</th>
                    <th>0,00</th>
                    <th class="percent">%</th>
                    <th>0,00</th>
                    <th class="percent">%</th>
                    <th>0,00</th>
                    <th class="percent">%</th>
                    <th>0,00</th>
                    <th class="percent">%</th>
                    <th>0,00</th>
                    <th class="percent">%</th>
                    <th>0,00</th>
                    <th class="percent">%</th>
                    <th>0,00</th>
                    <th class="percent">%</th>
                    <th>Total</th>
                    <th>Media</th>
                
                </tr>
                
                <tr>
                    <td>Impostos</td>
                    <td>0,00</td>
                    <td class="percent">%</td>
                    <td>0,00</td>
                    <td class="percent">%</td>
                    <td>Março</td>
                    <td class="percent">%</td>
                    <td>Abril</td>
                    <td class="percent">%</td>
                    <td>Maio</td>
                    <td class="percent">%</td>
                    <td>Junho</td>
                    <td class="percent">%</td>
                    <td>Julho</td>
                    <td class="percent">%</td>
                    <td>Agosto</td>
                    <td class="percent">%</td>
                    <td>Setembro</td>
                    <td class="percent">%</td>
                    <td>Outubro</td>
                    <td class="percent">%</td>
                    <td>Novembro</td>
                    <td class="percent">%</td>
                    <td>Dezembro</td>
                    <td class="percent">%</td>
                    <td>Total</td>
                    <td>Media</td>
                </tr>
                <tr>
                    <td><input type="text" ></td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td class="percent">%</td>
                    <td><input type="text"></td>
                    <td><input type="text"></td>
                </tr>

            </table>
            </scroll-container>
            <br>
            <br>
             
             
            <button v-on:click="addLine" class="btn">+ Adicionar Custos</button>
            <br>
            <button v-on:click="addLine" class="btn">+ Adicionar Desespesas</button>
            <br>
            <br>
            <scroll-container style="height:40px">
                <table  id="tableRevenue">
                    <tr>
                        
                        <th>Total</th>
                        <th>0,00</th>
                        <th class="percent">%</th>
                        <th>0,00</th>
                        <th class="percent">%</th>
                        <th>0,00</th>
                        <th class="percent">%</th>
                        <th>0,00</th>
                        <th class="percent">%</th>
                        <th>0,00</th>
                        <th class="percent">%</th>
                        <th>0,00</th>
                        <th class="percent">%</th>
                        <th>0,00</th>
                        <th class="percent">%</th>
                        <th>0,00</th>
                        <th class="percent">%</th>
                        <th>0,00</th>
                        <th class="percent">%</th>
                        <th>0,00</th>
                        <th class="percent">%</th>
                        <th>0,00</th>
                        <th class="percent">%</th>
                        <th>0,00</th>
                        <th class="percent">%</th>
                        <th>Total</th>
                        <th>Media</th>
                    
                    </tr>
                </table>
            </scroll-container>
            <br>
            <br>
            <div style="display: flex; justify-content: center;"> 
                   
                    <button class="btn" >Salvar resultados</button>

            </div>
            <br>
            <br>
        </div>   
    </div>
</template>
<script>
export default {
    methods:
    {
        addLine: function()
        {
           /*var table = document.getElementById("tableRevenue");
           let newRow = table.insertRow(-1);

            // Insert a cell in the row at index 0
            let newCell = newRow.insertCell(0);
           

            // Append a text node to the cell
            var inp = document.createElement("input");
            inp.type="text";
            newCell.appendChild(inp);

            newCell = newRow.insertCell(0);
           

            // Append a text node to the cell
            inp = document.createElement("input");
            inp.type="text";
            newCell.appendChild(inp);
            
            newCell = newRow.insertCell(0);
           

            // Append a text node to the cell
            inp = document.createElement("p");
            inp.value="dd";
            newCell.appendChild(inp);
           */
        }
    }
    
}
</script>

<style scoped>

scroll-container {
  display: block;
  margin: 0 auto;
  text-align: center;
}
scroll-container {
  display: block;
  width: 100%;
  height: 100px;
  overflow-y: scroll;
  overflow-x: scroll;
  scroll-behavior: smooth;
}
.percent
{
    background-color: #c0c0c0;
    width:30px;
}
table
{
    width:1800px;
}
table tr td
{
    text-align: center;
   
    border-color:#D3D3D3;
    border-style: solid;
    font-size:12px; 
    border-width: 1px;
}
table tr th
{
    
    font-size:12px;
    background-color: #D3D3D3;
    border-width: 1px;
    text-align: center;
    
}

textarea
{
    width:100%;
}
h5
{
    text-align:justify;

}
p
{
    text-align: justify;
}

#boxAssessment
{
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
    border-radius: 15px;
    text-align: center;
    color: black;
}
*
{  
    font-family: roboto;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
a:hover{
    color:#fec961;
}
input
{
    width:100%;
}
ul li
{
    text-align:justify;
}
</style>

