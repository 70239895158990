<template>
    <div style="width:82%;position:absolute;left:18%;top:0%;margin-left:0%;"> 
        <br>
        <br>
       
        <div id="boxAssessment" >
            <br>
            <h1 class="text-center">Assessment para mudança de carreira</h1>
            <br>
            <h3><b>Parte 1 – Peça para o cliente responder se ele concorda ou Não com as seguintes afirmações:</b></h3>
            <br>
            <h5>Há poucas oportunidades de crescimento em minha área.</h5>
            <input type="radio" name="p1" value="Sim" />
            <label class="ml-2">Sim</label>
            <br>
            <input type="radio" name="p1" value="Não" />
            <label class="ml-2">Não</label>
            <br>
            
            <br>
            <br>
            <hr>
            <h5>Quero ganhar mais dinheiro do que eu poderia se continuasse em minha carreira.</h5>
            <input type="radio" name="p2" value="Sim" />
            <label class="ml-2">Sim</label>
            <br>
            <input type="radio" name="p2" value="Não" />
            <label class="ml-2">Não</label>
            <br>
            <br>
            <hr>
            <h5>Preciso me desenvolver mais para crescer em minha área, mas Não estou suficientemente motivado com minha carreira para empreender esse esforço.</h5>
            <input type="radio" name="p3" value="Sim" />
            <label class="ml-2">Sim</label>
            <br>
            <input type="radio" name="p3" value="Não" />
            <label class="ml-2">Não</label>
            <br>
         
            <br>
            <hr>
            <h5>Estou decepcionado com minha carreira. Meu trabalho Não corresponde ao
que eu esperava quando me formei.</h5>
             <input type="radio" name="p4" value="Sim" />
            <label class="ml-2">Sim</label>
            <br>
            <input type="radio" name="p4" value="Não" />
            <label class="ml-2">Não</label>
            <br>
            
            <br>
            <br>
            <hr>
            <h5>Sinto que Não estou vivendo minha vocação.</h5>
            <input type="radio" name="p5" value="Sim" />
            <label class="ml-2">Sim</label>
            <br>
            <input type="radio" name="p5" value="Não" />
            <label class="ml-2">Não</label>
            <br>
            <br>
            <hr>
            <h5>Minhas expectativas mudaram e eu Não consigo satisfazer essas
expectativas em minha carreira atual.</h5>
            <input type="radio" name="p6" value="Sim" />
            <label class="ml-2">Sim</label>
            <br>
            <input type="radio" name="p6" value="Não" />
            <label class="ml-2">Não</label>
            <br>
            
            <br>
            <br>
            <hr>
            <h5>Nem mesmo a expectativa de uma promoção seria capaz de me animar.</h5>
            <input type="radio" name="p7" value="Sim" />
            <label class="ml-2">Sim</label>
            <br>
            <input type="radio" name="p7" value="Não" />
            <label class="ml-2">Não</label>
            <br>

            <br>
            <br>
            <hr>
            <h5>Não consigo expressar meus talentos nem perseguir meus interesses em minha carreira atual.</h5>
            <input type="radio" name="p8" value="Sim" />
            <label class="ml-2">Sim</label>
            <br>
            <input type="radio" name="p8" value="Não" />
            <label class="ml-2">Não</label>
            <br>

            <br>
            <br>
            <hr>
            <h5>Minha carreira atual é incompatível com meus valores.</h5>
            <input type="radio" name="p9" value="Sim" />
            <label class="ml-2">Sim</label>
            <br>
            <input type="radio" name="p9" value="Não" />
            <label class="ml-2">Não</label>
            <br>
             <br>
            <br>
            <hr>
            <h5>Estou interessado em uma profissão que Não existia ou que eu Não conhecia antes.</h5>
            <input type="radio" name="p10" value="Sim" />
            <label class="ml-2">Sim</label>
            <br>
            <input type="radio" name="p10" value="Não" />
            <label class="ml-2">Não</label>
            <br>
             <br>
            <br>
            <hr>
            <h5>Prefiro enfrentar os riscos de mudar de carreira a continuar na carreira atual.</h5>
            <input type="radio" name="p11" value="Sim" />
            <label class="ml-2">Sim</label>
            <br>
            <input type="radio" name="p11" value="Não" />
            <label class="ml-2">Não</label>
            <br>
            <br>
            <hr>
            <h5>Experimento um alto nível de frustração, insatisfação e desgaste em minha carreira atual.</h5>
            <input type="radio" name="p12" value="Sim" />
            <label class="ml-2">Sim</label>
            <br>
            <input type="radio" name="p12" value="Não" />
            <label class="ml-2">Não</label>
            <br>
            <hr>
            <h5>Sinto que deixei meus sonhos em segundo plano, e Não vejo como realizar
esses sonhos em minha carreira atual.</h5>
            <input type="radio" name="p13" value="Sim" />
            <label class="ml-2">Sim</label>
            <br>
            <input type="radio" name="p13" value="Não" />
            <label class="ml-2">Não</label>
            <br>
            <hr>
            <h5>Não tenho mais paixão nem motivação em minha carreira atual.</h5>
            <input type="radio" name="p14" value="Sim" />
            <label class="ml-2">Sim</label>
            <br>
            <input type="radio" name="p14" value="Não" />
            <label class="ml-2">Não</label>
            <br>
            <hr>
            <h5>Tenho permanecido em minha carreira atual por causa do status e da segurança, mas isso já Não é suficiente para mim.</h5>
            <input type="radio" name="p15" value="Sim" />
            <label class="ml-2">Sim</label>
            <br>
            <input type="radio" name="p15" value="Não" />
            <label class="ml-2">Não</label>
            <br>
            <hr>
            <h5>Sinto que posso contribuir mais e crescer mais em outra carreira.</h5>
            <input type="radio" name="p16" value="Sim" />
            <label class="ml-2">Sim</label>
            <br>
            <input type="radio" name="p16" value="Não" />
            <label class="ml-2">Não</label>
            <br>
             <hr>
            <h5>Minha carreira é incapaz de satisfazer minhas necessidades atuais.</h5>
            <input type="radio" name="p17" value="Sim" />
            <label class="ml-2">Sim</label>
            <br>
            <input type="radio" name="p17" value="Não" />
            <label class="ml-2">Não</label>
            <br>
            <hr>
            <h5>As compensações de minha carreira atual Não são compatíveis com o
esforço e o stress a que sou submetido.</h5>
            <input type="radio" name="p18" value="Sim" />
            <label class="ml-2">Sim</label>
            <br>
            <input type="radio" name="p18" value="Não" />
            <label class="ml-2">Não</label>
            <br>
            <br>
            <br>
            <h5>Parte 2 – Converse com o cliente sobre suas respostas. Quanto maior for o número de respostas
“Sim”, maior é sua propensão/motivação para mudar de carreira.</h5>
            <br>
            <br>
            <h5>Parte 3 – Questione cada resposta “Sim”. Faça perguntas como: “Você já tentou mudar essa situ-
ação?”; “O que você fez para tentar mudar essa situação?”; “Você já explorou outras alternativas?
Quais?”. Faça essas e outras perguntas que você achar necessárias, de acordo com a afirmação
que está sendo questionada.</h5>
            <br>
            <br>
            <h5>Parte 4 – É importante que você conduza o cliente a uma profunda autorreflexão. O que realmente está por trás da decisão de mudar de carreira? O que está por trás da desmotivação? Será

que o problema está de fato na carreira? E quanto à forma como ele tem conduzido sua carreira

e definido seus objetivos? Até que ponto suas atitudes estão contribuindo para aumentar sua frustração e falta de perspectivas? Lembre-se de que seu objetivo como coach é ajudá-lo a analisar a
situação sob diferentes ângulos, enxergar possibilidades que ele Não via antes, assumir responsabilidades e pesar vantagens e desvantagens a fim de tomar a melhor decisão. A decisão final, porém, é sempre do cliente.</h5>
            
            
            
        <br>
        <div style="display: flex; justify-content: center;"> 
                  <button class="btn" >Salvar resultados</button>
        </div>
        <br> 
    </div> 
    </div>
</template>

<style scoped>
input
{
    margin-bottom: -2px;
}
input[type="radio"] {

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  
 
  width: 15px;
  height: 15px;
  

  background-clip: content-box;
  
  background-color: #D3D3D3;
  border-radius: 50%;
}


input[type="radio"]:checked {
  background-color: #983e7b;
  
}
*
{
    font-family: roboto;
}
#boxAssessment
{
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    
    color: black;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
</style>

<script>
export default {
    
   
}
</script>