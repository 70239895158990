<template>
  <div id="rowText">
    <div id="text">
      <h1><b>IMPACTOS POSITIVOS NAS VIDAS E NAS ORGANIZAÇÕES</b></h1>
      <br />

      <div class="row" style="width: 80%; margin-left: 10%; margin-right: 10%">
        <div class="col-sm colNumber">
          <p class="pNumber">
            <b
              >+<span id="value1">{{ value1 }}</span></b
            >
          </p>
          <p>pessoas já passaram por treinamento em grupo</p>
        </div>

        <div class="col-sm colNumber">
          <p class="pNumber">
            <b
              >+<span id="value2">{{ value2 }}</span
              >h</b
            >
          </p>
          <p>
            horas de dedicação em atendimentos individuais, com foco na
            psicologia positiva.
          </p>
        </div>

        <div class="col-sm colNumber">
          <p class="pNumber">
            <b
              >+<span id="value3">{{ value3 }}</span>
            </b>
          </p>
          <p>Empresas impactadas pela consultoria de Gestão de Pessoas</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    setInterval(() => {
      this.nextValue();
    }, 100);
  },
  data() {
    return {
      value1: 0,
      value2: 0,
      value3: 0,
    };
  },
  methods: {
    nextValue: function () {
      if (window.scrollY > 3500) {
        if (this.value1 < 4500) {
          var value1L = document.getElementById("value1");
          value1L.innerText = this.value1 + 300;
          this.value1 = parseInt(value1L.innerText);
        }
        if (this.value2 < 3500) {
          var value2L = document.getElementById("value2");
          value2L.innerText = this.value2 + 175;
          this.value2 = parseInt(value2L.innerText);
        }
        if (this.value3 < 150) {
          var value3L = document.getElementById("value3");
          value3L.innerText = this.value3 + 10;
          this.value3 = parseInt(value3L.innerText);
        }
      }
    },
  },
};
</script>
<style scoped>
#text {
  position: relative;
  top: 19%;
}
h1 {
  text-align: center;
  font-size: 2.222vw;
}
.pNumber {
  font-size: 3.889vw;
  color: #fabe0a;
}
.colNumber {
  height: 100%;
  border-left: 5px solid #de4eaf;
}
p {
  font-size: 1.111vw;
}
#rowText {
  background-image: linear-gradient(156.32deg, #802863 15.24%, #ab3585 84.76%);
  width: 100%;
  height: 337px;
  color: white;
  margin-top: -40px;
}
* {
  margin: 0;
}
@media (min-width: 576px) and (max-width: 720px) {
  #rowText {
    height: 250px;
  }
}
@media (min-width: 350px) and (max-width: 575px) {
  p {
    font-size: 2.111vw;
  }
  #rowText {
    height: 400px;
  }
  .colNumber {
    margin-bottom: 5%;
  }
}

@media (min-width: 0px) and (max-width: 375px) {
  p {
    font-size: 3.111vw;
  }
  #rowText {
    height: 400px;
  }
  .colNumber {
    margin-bottom: 5%;
  }
}
</style>
