<template>
    <div style="height:100%;width:82%;position:absolute;left:18%;top:0%;margin-left:0%;"> 
        
        <br>
        <br>
        <div id="boxAssessment" >
            <br>
            
            <br>
            <h1 class="text-center">Mapeamento de necessidades</h1>
            <br>
            <span style="color:red;">{{msgError}}</span>
            <br>
            <br>
            <h3 ><b>Passo 1 - Objetivos do processo</b></h3>
            <br>
            
            <h5>Quais os objetivos a serem alcançados com o programa?</h5> 
            <textarea class="questions" id="question1" required="required" style="resize: none;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>Quais as necessidades especificas?<br>
                Existe algum problema que o programa deve ajudar a resolver?<br>
                Existe alguma oportunidade que o programa deve ajudar a alcançar?<br>
                A forma como a empresa, o setor ou colaborador vem crescendo nos ultimos anos está satisfatória?<br>
                A produtividade/lucratividade da empresa, setor ou colaborador esta satisfatória?<br>
                Os indicadores de resultados vem sendo atingidos<br>
                Os indicadores de performance estão sendo satisfatória e monitorados?<br>
                Quais são os indicadores de aprendizado?</h5> 
            <textarea class="questions" id="question2" required="required" style="resize: none;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>Qual o foco esperado?<br>
                Desenvolvimento de liderança?<br>
                Desenvolvimento de habilidades especificas?<br>
                Aumento de performance individual ou em equipe?<br>
                Preparação para mudança de posição corporativa?<br>
                Descarrilamento? Resolução de problemas?<br>


            </h5> 
            <textarea class="questions" id="question3" required="required" style="resize: none;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>Quais os resultados específicos que o processo deve trazer?<br>
                Como iremos medir esse resultado?<br>
                Quais os indicadores de resultado?<br>
                Quais as competências precisam ser desenvolvidas para atingir esse resultados?<br>
                Usar roda de competências<br>
                Conquistado essas competências precisam ser desenvolvidas para atingir esses resultados?<br>
                Usar roda de competências<br>
                Conquistado essas competências, quais seriam os indicadores de desempenho?<br>

                </h5>
            <textarea class="questions" id="question4" required="required" style="resize: none;width:100%" ></textarea>
            <br>
            <br> 
            <br>
            <br>
            <h5>Qual a importância do programa a ser desenvolvido?<br>
                Atingindo a todos esses objetivos quais seriam os ganhos para empresa, para o colaborador e para a sociedade?</h5> 
            <textarea class="questions" id="question5" required="required" style="resize: none;width:100%" ></textarea>
            <br>
            <br>
            
            <br>
            <br>
            
            
            <h5>As respostas acima refletem o pensamento de quem? RH, CEO, Financeiro?<br>
                Seria interessante entrevistar os demais envolvidos para ter uma visão sistêmica?<br>
                Quem? Quando?</h5> 
            <textarea class="questions" id="question6" required="required" style="resize: none;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3 ><b>Passo 2 - Histórico</b></h3>
            <br>
            <h5>A empresa ou cliente já passaram por algum programa de desenvolvimento anteriormente?<br>
                Quais foram os resultados?</h5> 
            <textarea class="questions" id="question7" required="required" style="resize: none;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>A necessidade citada já foi abordada de outra forma? (ex: treinamentos, consultoria, mentoria)<br>
                Quais foram os resultados?</h5> 
            <textarea class="questions" id="question8" required="required" style="resize: none;width:100%" ></textarea>
            <br>
            <br>
           
            <br>
            <br>
            
            
            <h5>A quanto tempo esse problema ou oportunidade existe?</h5> 
            <textarea class="questions" id="question9" required="required" style="resize: none;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>Houve algum evento organizacional importante que deva ser considerado?<br>
             (mudança de presidencia, desafios, estilo de liderança, resultado do negócio)</h5> 
            <textarea class="questions" id="question10" required="required" style="resize: none;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3 ><b>Passo 3 - Contexto</b></h3>
            <br>
            <h5>Qual o contexto atual da sua empresa?<br>
                (clima organizacional, desafios, estilo de liderança, resultado de negócio)</h5> 
            <textarea class="questions" id="question11" required="required" style="resize: none;width:100%" ></textarea>
            <br>
            <br>
            
            <br>
            <br>
            
            <h5>Quantas pessoas entrariam para o programa?</h5> 
            <textarea class="questions" id="question12" required="required" style="resize: none;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>Os pares ou superiores desses participantes serão apoiadores do programa?</h5> 
            <textarea class="questions" id="question13" required="required" style="resize: none;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>Quais os motivadores para a participação no programa a ser implantado?</h5> 
            <textarea class="questions" id="question14" required="required" style="resize: none;width:100%" ></textarea>
            <br>
            <br>
            
            <br>
            <br>
            <h5>Como a empresa reconhece o aumento de desempenho de seus colaboradores?</h5> 
            <textarea class="questions" id="question15" required="required" style="resize: none;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>Existe algum risco na implementação do programa na organização?</h5> 
            <textarea class="questions" id="question16" required="required" style="resize: none;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>Existem variáveis que possam impedir o sucesso do programa?<br>
                (tempo, comprometimento, falta de conhecimento)</h5> 
            <textarea class="questions" id="question17" required="required" style="resize: none;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3><b>Passo 4 - Escopo</b></h3>
            <br>
            <h5>Tendo em vista tudo o que conversamos que irá participar do programa?</h5> 
            <textarea class="questions" id="question18" required="required" style="resize: none;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>Quais os cargos e funções?</h5> 
            <textarea class="questions" id="question19" required="required" style="resize: none;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>Qual a posição hierárquica? A quem esse colaborador se reporta?<br>
                Existem quantos altos níveis de crescimento acima dele?</h5> 
            <textarea class="questions" id="question20" required="required" style="resize: none;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>Quantos colaboradores estão sob a liderança desse participante?</h5> 
            <textarea class="questions" id="question21" required="required" style="resize: none;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>Quais as principais responsabilidades e desafios?</h5> 
            <textarea class="questions" id="question22" required="required" style="resize: none;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>Quais os indicadores de resultado, performance e aprendizado para cada participante?</h5> 
            <textarea class="questions" id="question23" required="required" style="resize: none;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            

            <div style="display: flex; justify-content: center;"> 
                    
                    <button class="btn" v-on:click="verifyForm()" >Salvar resultados</button>
            </div>
            <br>
            <br>
        </div>
    </div>
</template>
<script>
import api from '../services/api.js';
export default {
    mounted(){
         api.get('/show-questions-by-form/0460b3af-788c-4b74-ab1e-d6fc4b574f52',{
                                       
                                    })
                                    .then((response) => {
                                        
                                        for(var i; i<=23;i++)
                                        {
                                            
                                            this.arrayQuestion[i] = response.data.question[i].id;
                                            
                                        }
                                        
                                    }, (error) => {
                                        
                                        console.log(error.response);

                                        
                                        
        });
    },
    data()
    {
        return{
            msgError:"",
            arrayQuestion:[]
        }
        
    },
    methods:
    {
        verifyForm()
        {
            
            var q = document.getElementsByClassName("questions");
            var error=false;
            for(var i=0;i<q.length;i++)
            {
                if(!q[i].value)
                {
                    this.msgError="Responda todas as perguntas!";
                    window.scrollTo(0, 0);
                    error=true;
                    break;
                }
            }
            if(error == false)
            {
                this.sendData();

            }
            

        },
        sendData: function()
        {

        }
    }
}
</script>
<style scoped>

input[type='range'] {
  cursor: pointer;
}

input[type='range'],
input[type='range']::-webkit-slider-runnable-track,
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}

/** 
 * Use o pseudo-seletor "::-webkit-slider-runnable-track" para customizar o "trilho" do campo.
 */
input[type='range']::-webkit-slider-runnable-track {
  
  height: 10px;
  background: #983e7b;
  border-radius: 13px;
}

/** 
 * Use o pseudo-seletor "::-webkit-slider-thumb" para customizar o "seletor" do campo.
 */
input[type='range']::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  margin-top: -4.5px;
  background-color: #fec961;
  border-radius: 20px;
}
input{
    background-color: white;
    border-color:#983e7b;
    height:50px;
    border-radius:10px;
}

*
{
    font-family: roboto;
}
.numberLevel
{
    text-align:center;
    color:#983e7b;
}
.inputLevel
{
    width:100%;
}
h5
{
    text-align:justify;    
}
p
{
    text-align:justify;
}
#boxAssessment
{
    
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    
    color: black;
}
*
{
    
    font-family: roboto;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
a:hover{
    color:#fec961;
}
textarea
{
    height:100px;
}
</style>

