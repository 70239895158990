<template>
    <div style="height:100%;width:82%;position:absolute;left:23%;top:10%;">
      <h3>Colaboradores cadastrados</h3>
      <br>
      <div v-for="element in data" :key="element.name" id="list"> 
        {{element.name}} / {{element.email}}
      </div>
      <br>
      <br>

    </div>   
</template>
<script>
import axios from 'axios';
export default {
    data()
    {
        return{
            
            data:[]
        }
    },
    mounted()
    {
      
                axios
                  .get('http://api.florescer.b2ml.com.br:15200/api/show-collaborator')
                  .then(response => {
                    console.log(response.data);
                    this.data = response.data;
                       
                })
        
                .catch(error => {
                    console.log(error)
                    this.errored = true
                })

               
    }
    
}
</script>
<style scoped>

#list{
    padding:10px;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
    margin-top:10px;
    border-radius: 15px;
   
    color: black;
    width:80%;
}
</style>