var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"profileConsumer"}},[_c('br'),_c('br'),_c('div',{attrs:{"id":"boxAssessment"}},[_c('br'),_c('h1',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.userName))]),_c('br'),_vm._m(0),_c('br'),_vm._m(1),_c('br'),_c('br'),_vm._m(2),_c('br'),_c('br'),_vm._m(3),_c('br'),_c('br'),_vm._m(4),_c('br'),_c('br'),_c('div',{staticClass:"row"},[_vm._m(5),_c('div',{staticClass:"col-sm"},[_c('label',{attrs:{"for":"tel"}},[_vm._v("Estado: *")]),_c('br'),_c('select',{staticClass:"custom-select input2",staticStyle:{"color":"gray"},attrs:{"id":"state","required":"required"}},_vm._l((_vm.states),function(op){return _c('option',{key:op.uf,staticStyle:{"color":"gray"}},[_vm._v(" "+_vm._s(op.uf)+" ")])}),0)])]),_c('br'),_c('br'),_vm._m(6),_c('br'),_c('br'),_vm._m(7),_c('br'),_c('br'),_vm._m(8),_c('br'),_c('br'),_vm._m(9),_c('br'),_c('br'),_c('br'),_c('br'),_vm._m(10),_c('br')])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('img',{staticClass:"link",staticStyle:{"margin-right":"10px"},attrs:{"width":"240px","src":require("../../imgs/perfil.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Razão Social: *")]),_c('br'),_c('input',{staticClass:"form-control input2",attrs:{"type":"text","id":"corporateName"}})]),_c('div',{staticClass:"col-sm"},[_c('label',{attrs:{"for":"tel"}},[_vm._v("Nome Fantasia: *")]),_c('br'),_c('input',{staticClass:"form-control input2",attrs:{"type":"text","id":"name"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Nome do responsável: *")]),_c('br'),_c('input',{staticClass:"form-control input2",attrs:{"type":"text","id":"name"}})]),_c('div',{staticClass:"col-sm"},[_c('label',{attrs:{"for":"tel"}},[_vm._v("Email do responsável: *")]),_c('br'),_c('input',{staticClass:"form-control input2",attrs:{"type":"text","id":"email"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Telefone/Celular do responsável: *")]),_c('br'),_c('input',{staticClass:"form-control input2",attrs:{"type":"text","id":"tel"}})]),_c('div',{staticClass:"col-sm"},[_c('label',{attrs:{"for":"tel"}},[_vm._v("Senha: *")]),_c('br'),_c('input',{staticClass:"form-control input2",attrs:{"type":"password","id":"password"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm"},[_c('label',{attrs:{"for":"email"}},[_vm._v("CNPJ: *")]),_c('br'),_c('input',{staticClass:"form-control input2",attrs:{"type":"text","id":"tel"}})]),_c('div',{staticClass:"col-sm"},[_c('label',{attrs:{"for":"tel"}},[_vm._v("Inscrição Estadual: *")]),_c('br'),_c('input',{staticClass:"form-control input2",attrs:{"type":"password","id":"password"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm"},[_c('label',{attrs:{"for":"email"}},[_vm._v("CEP: *")]),_c('br'),_c('input',{staticClass:"form-control input2",attrs:{"type":"text","id":"cep"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Cidade: *")]),_c('br'),_c('input',{staticClass:"form-control input2",attrs:{"type":"text","id":"city"}})]),_c('div',{staticClass:"col-sm"},[_c('label',{attrs:{"for":"tel"}},[_vm._v("Rua: *")]),_c('br'),_c('input',{staticClass:"form-control input2",attrs:{"type":"text","id":"street"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Bairro: *")]),_c('br'),_c('input',{staticClass:"form-control input2",attrs:{"type":"text","id":"district"}})]),_c('div',{staticClass:"col-sm"},[_c('label',{attrs:{"for":"tel"}},[_vm._v("Número: *")]),_c('br'),_c('input',{staticClass:"form-control input2",attrs:{"type":"number","id":"number"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Complemento")]),_c('br'),_c('input',{staticClass:"form-control input2",attrs:{"type":"text","id":"complement"}})]),_c('div',{staticClass:"col-sm"},[_c('label',{attrs:{"for":"tel"}},[_vm._v("Tempo de atuação no mercado:")]),_c('br'),_c('input',{staticClass:"form-control input2",attrs:{"type":"text","id":"timeMarket"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Número de colaboradores: *")]),_c('br'),_c('input',{staticClass:"form-control input2",attrs:{"type":"text","id":"numbersEmployes"}})]),_c('div',{staticClass:"col-sm"},[_c('label',{attrs:{"for":"tel"}},[_vm._v("Perfil do linkedin: *")]),_c('br'),_c('input',{staticClass:"form-control input2",attrs:{"type":"text","id":"linkedin"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('button',[_vm._v("Salvar alterações")])])
}]

export { render, staticRenderFns }