<template>
  <div id="forYou">
    <br />
    <br />
    <div id="text">
      <h1>AUTOCONHECIMENTO,<br />SUPERAÇÃO E<br />AUTO-DESENVOLVIMENTO</h1>
      <br />
      <h3>
        São tópicos chaves de como você atingirá seu<br />Florescimento e
        potencial pleno
      </h3>
      <a :href="VUE_APP_URL_ESPECIALISTA + '/dimensoes-da-vida'"><button class="btn">Avaliação
          Gratuita</button></a>
    </div>
    <img src="../../imgs/onda_para_voce.png" />
  </div>
</template>
<script>
export default {
  data(){
    return {
      VUE_APP_URL_ESPECIALISTA: process.env.VUE_APP_URL_ESPECIALISTA
    }
  }
};
</script>
<style scoped>
.btn:hover {
  background-color: #fff051;
  color: #692b55;
}

img {
  height: 270px;
  width: 100%;
  margin-top: 78px;
}

#text {
  margin-top: 70px;
}

#forYou {
  background-size: cover;
  background-position: center center;
  max-height: 708px;
  background-image: url("../../imgs/capa_para_voce.png");
}

h1 {
  color: #fabe0a;
  margin-left: 7.8%;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 3.508125vw;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

h3 {
  font-size: 1.4811111111111vw;
  margin-left: 7.8%;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  color: white;
}

button {
  border-color: transparent;
  margin-left: 7.8%;
  margin-top: 24px;
  background-color: #fabe0a;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  width: 211px;
  height: 52px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  color: #692b55;
}

span {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #fabe0a;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 900;
}

@media (max-width: 700px) {
  h1 {
    font-size: 4.508125vw;
  }

  h3 {
    font-size: 2.4811111111111vw;
  }
}
</style>
