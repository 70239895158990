<template>
    <div style="width:82%;position:absolute;left:18%;top:0%;margin-left:0%;"> 
        <br>
        <br>
        
        <div id="boxAssessment">
            <br>
            <h1 class="text-center">Missão & Propósito</h1>
            <br>
            <br>
            <h3 ><b>Passo 1 – Identificar cinco principais características ou talentos</b></h3>
            <br>
            
            <h5>Quais são seus cinco maiores talentos?</h5> 
            <input style="width:100%;" placeholder="Primeiro">
            <br>
            <br>
            <input style="width:100%;" placeholder="Segundo">
            <br>
            <br>
            <input style="width:100%;" placeholder="Terceiro">
            <br>
            <br>
            <input style="width:100%;" placeholder="Quarto">
            <br>
            <br>
            <input style="width:100%;" placeholder="Quinto">
            <br>
            <br>
            <br>
            <br>
            <h5>Quando você pensa em si, quais são suas principais características?</h5> 
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3 ><b>Passo 2 – Descrever os comportamentos que indicam os talentos ou as características</b></h3>
            <br>
            
            <h5>Quais comportamentos evidenciam a característica/o talento?</h5> 
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>Quais são suas ações que comprovam esta sua característica/talento?</h5> 
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>

            <h3 ><b>Passo 3 – Identifique os cinco principais objetivos pessoais/profissionais a serem
atingidos no período de um ano, a partir de hoje</b></h3>
            <br>
            
            <h5>Quais são seus principais objetivos pessoais ou profissionais a serem atingidos
daqui a um ano?</h5> 
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>

            <h3 ><b>Passo 4 – Identifique o objetivo financeiro a ser atingido no período de um ano, a
partir de hoje</b></h3>
            <br>
            
            <h5>Qual é seu objetivo financeiro para daqui a um ano?</h5> 
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>

            <h3 ><b>Passo 5 – Selecione três principais características/talentos e os comportamentos
que os evidenciam</b></h3>
            <br>
            
            <h5>Quais são seus três principais talentos/características?</h5> 
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
           
            <h3 ><b>Passo 6 – Elabore sua missão</b></h3>
            <br>
            <textarea required="required" style="resize: vertical;width:100%" placeholder="Minha missão é: Ser... (talentos/características), por meio de... (comportamentos), para conquistar... (objetivos gerais/financeiros)"></textarea>
            <br>
            <br>
            
            <div style="display: flex; justify-content: center;"> 
                  <button class="btn" >Salvar resultados</button>
            </div>
            <br>
            <br>
        </div>
    </div>
</template>

<style scoped>
h5
{
    text-align:justify;    
}
p
{
    text-align:justify;
}
#boxAssessment
{
    
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    
    color: black;
}
*
{
    
    font-family: roboto;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
a:hover{
    color:#fec961;
}

</style>

<script>
export default {
     data() {
        return {
            
           
            opcoes: [
               {n: '0'},
               {n: '1'},
               {n: '2'},
               {n: '3'},
               {n: '4'},
               {n: '5'},
               {n: '6'},
               {n: '7'},
               {n: '8'},
               {n: '9'},
               {n: '10'},
            ]
        }
     }
   
}
</script>