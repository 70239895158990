<template>
  <div>
    <Navbar></Navbar>
    <Text1></Text1>
    <br />
    <br />
    <Text2></Text2>

    <Text4></Text4>
    <Text5></Text5>
    <br />
    <br />
    <Text3></Text3>
    <Text8></Text8>
    <Text6></Text6>
    <br />
    <br />
    <br />
    <br />
    <Text7></Text7>
    <Text4Home></Text4Home>
    <Footer></Footer>
  </div>
</template>
<script>
import Text1 from "../components/text1-para-sua-empresa";
import Text2 from "../components/text2-para-sua-empresa";
import Text3 from "../components/text3-para-sua-empresa";
import Text4 from "../components/text4-para-sua-empresa";
import Text5 from "../components/text5-para-sua-empresa";

import Text6 from "../components/text6-para-sua-empresa";
import Text7 from "../components/text7-para-sua-empresa";
import Text8 from "../components/text-8-para-sua-empresa";
import Text4Home from "../components/text4-home.vue";
import Navbar from "../components/navbar.vue";
import Footer from "../components/footer.vue";

export default {
  components: {
    Text4Home,
    Text1,
    Text2,
    Text3,
    Text4,
    Text7,
    Text6,
    Navbar,
    Footer,
    Text5,
    Text8,
  },
};
</script>
