<template>
  <div class="wrapper">
    <nav id="sidebar">
      <div class="sidebar-header">
        <img width="200px" src="../../imgs/logo_branco.jpg" />
      </div>
      <scroll-container>
        <ul class="list-unstyled components">
          <li>
            <a
              id="preF"
              href="#pageSubmenu1"
              data-toggle="collapse"
              aria-expanded="false"
              class="dropdown-toggle"
              ><img
                width="19px"
                style="margin-right: 10px; margin-bottom: 3px"
                src="../../imgs/sementes.png"
              />Pré-Florescimento</a
            >
            <ul class="collapse list-unstyled" id="pageSubmenu1">
              <li>
                <a v-on:click="changePage(1)" href="#" id="1"
                  >&#10004; Avaliação inicial</a
                >
              </li>

              <li>
                <a v-on:click="changePage(74)" href="#" id="74"
                  >&#10004; Avaliando a felicidade</a
                >
              </li>

              <li>
                <a v-on:click="changePage(10)" id="10"
                  >&#10004; Especificação de objetivos</a
                >
              </li>
              <li>
                <a v-on:click="changePage(3)" href="#" id="3"
                  >&#10004; Definição de Resultados esperados</a
                >
              </li>
              <li>
                <a v-on:click="changePage(5)" href="#" id="5"
                  >&#10004; Escala de satisfação com a vida</a
                >
              </li>
              <li>
                <a v-on:click="changePage(7)" href="#" id="7"
                  >&#10004; Escala de realização</a
                >
              </li>
              <li>
                <a v-on:click="changePage(4)" href="#" id="4"
                  >&#10004; Escala de avaliação de humor</a
                >
              </li>
              <li>
                <a v-on:click="changePage(2)" href="#" id="2"
                  >&#10004; Avaliação do nível de esperança</a
                >
              </li>
              <li>
                <a v-on:click="changePage(6)" href="#" id="6"
                  >&#10004; Avaliação do nível de motivação</a
                >
              </li>
              <li>
                <a v-on:click="changePage(8)" href="#" id="8"
                  >&#10004; Avaliação do funcionamento otimizado</a
                >
              </li>
              <li>
                <a v-on:click="changePage(9)" href="#" id="9"
                  >&#10004; Escala de florescimento</a
                >
              </li>
            </ul>
          </li>
          <li>
            <a
              href="#pageSubmenu2"
              data-toggle="collapse"
              style="text-align: center; height: 80px"
              aria-expanded="false"
              class="dropdown-toggle"
              ><span
                ><b>S</b> - Ser humano, mapeamento<br />
                Talentos e entendendo suas<br />
                Forças e Virtudes</span
              ></a
            >
            <ul class="collapse list-unstyled" id="pageSubmenu2">
              <li>
                <a href="#" id="op12"
                  >&#10004; Mapeamento DISC comportamental</a
                >
              </li>
              <li>
                <a href="#" id="op13">&#10004; Via Institute Carater</a>
              </li>
              <li>
                <a href="#" v-on:click="changePage(11)" id="11"
                  >&#10004; Meu eu ideal</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(13)" id="13"
                  >&#10004; Dreamlist</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(12)" id="12"
                  >&#10004; Helicopter view</a
                >
              </li>
            </ul>
          </li>
          <li>
            <a
              href="#pageSubmenu3"
              data-toggle="collapse"
              style="text-align: center; height: 80px"
              aria-expanded="false"
              class="dropdown-toggle"
              ><b>E</b> - Engajamento, Estado Flow,<br />
              desenvolvimento de Foco,<br />metas e Objetivos</a
            >
            <ul class="collapse list-unstyled" id="pageSubmenu3">
              <li>
                <a href="#" v-on:click="changePage(14)" id="14"
                  >&#10004; Flow no trabalho</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(15)" id="15"
                  >&#10004; Ganhos e Perdas</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(16)" id="16"
                  >&#10004; Levanta Astral</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(17)" id="17"
                  >&#10004; Projeção de objetivos na linha do tempo</a
                >
              </li>
            </ul>
          </li>

          <li>
            <a
              href="#pageSubmenu4"
              data-toggle="collapse"
              style="text-align: center; height: 50px"
              aria-expanded="false"
              class="dropdown-toggle"
              ><b>M</b> - Missão propósito e<br />significado</a
            >
            <ul class="collapse list-unstyled" id="pageSubmenu4">
              <li>
                <a href="#" v-on:click="changePage(18)" id="18"
                  >&#10004; Swot Pessoal</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(19)" id="19"
                  >&#10004; Missão e propósito</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(20)" id="20"
                  >&#10004; Identificação de Valores</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(21)" id="21"
                  >&#10004; Alinhamento de Valores</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(22)" id="22"
                  >&#10004; Teoria da Autoeficácia</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(65)" id="65"
                  >&#10004; Grande sonho</a
                >
              </li>
            </ul>
          </li>

          <li>
            <a
              href="#pageSubmenu5"
              data-toggle="collapse"
              style="text-align: center; height: 60px"
              aria-expanded="false"
              class="dropdown-toggle"
              ><b>E</b> - Energia, emoções positivas<br />e Qualidade de Vida</a
            >
            <ul class="collapse list-unstyled" id="pageSubmenu5">
              <li>
                <a href="#" v-on:click="changePage(23)" id="23"
                  >&#10004; Ative emoções positivas</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(24)" id="24"
                  >&#10004; Mova-se</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(25)" id="25"
                  >&#10004; Pratique Mindfulness</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(26)" id="26"
                  >&#10004; Ancora</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(27)" id="27"
                  >&#10004; 6 segundos</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(28)" id="28"
                  >&#10004; Congelamento de imagem</a
                >
              </li>
            </ul>
          </li>

          <li>
            <a
              href="#pageSubmenu6"
              data-toggle="collapse"
              aria-expanded="false"
              class="dropdown-toggle"
              ><b>A</b> - Alvos , Metas e Objetivos</a
            >
            <ul class="collapse list-unstyled" id="pageSubmenu6">
              <li>
                <a href="#" v-on:click="changePage(29)" id="29"
                  >&#10004; Administração de Tempo</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(30)" id="30">&#10004; DRE</a>
              </li>
              <li>
                <a href="#" v-on:click="changePage(31)" id="31"
                  >&#10004; Roadmap</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(32)" id="32"
                  >&#10004; Modelo ideal</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(33)" id="33"
                  >&#10004; Análise de Campo de Força</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(34)" id="34"
                  >&#10004; Swot estratégico</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(35)" id="35"
                  >&#10004; Ensaio mental</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(36)" id="36"
                  >&#10004; GROW</a
                >
              </li>
              <li>
                <a href="#" id="op41">&#10004; Modelo Skilled-Helper</a>
              </li>
            </ul>
          </li>

          <li>
            <a
              href="#pageSubmenu7"
              data-toggle="collapse"
              aria-expanded="false"
              style="text-align: center; height: 60px"
              class="dropdown-toggle"
              ><b>R</b> - Relacionamentos e<br />interações</a
            >
            <ul class="collapse list-unstyled" id="pageSubmenu7">
              <li>
                <a href="#" v-on:click="changePage(37)" id="37"
                  >&#10004; Respostas fortalecedoras</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(38)" id="38"
                  >&#10004; Cavaleiro Apocalipse</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(39)" id="39"
                  >&#10004; 7 caminhos do casamento feliz</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(40)" id="40"
                  >&#10004; Modelo AF</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(41)" id="41"
                  >&#10004; House of change</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(42)" id="42"
                  >&#10004; Critico interno</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(43)" id="43"
                  >&#10004; Ideias fixas</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(44)" id="44"
                  >&#10004; Ensaio dramático</a
                >
              </li>
            </ul>
          </li>

          <li>
            <a
              href="#pageSubmenu8"
              data-toggle="collapse"
              style="text-align: center; height: 60px"
              aria-expanded="false"
              class="dropdown-toggle"
              ><img
                width="19px"
                style="margin-right: 10px; margin-top: 5px"
                src="../../imgs/maleta.png"
              />Construindo uma carreira<br />de sucesso</a
            >
            <ul class="collapse list-unstyled" id="pageSubmenu8">
              <li>
                <a href="#" v-on:click="changePage(45)" id="45"
                  >&#10004; Iniciando uma carreira</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(46)" id="46"
                  >&#10004; Identificação e avaliação de competências</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(47)" id="47"
                  >&#10004; Entrevista para o primeiro emprego</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(48)" id="48"
                  >&#10004; Plano de carreira para iniciantes</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(49)" id="49"
                  >&#10004; Inventário de atitudes para inicío de carreiras</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(50)" id="50"
                  >&#10004; Medindo a autoeficácia</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(51)" id="51"
                  >&#10004; Assessment para mudança de carreira</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(52)" id="52"
                  >&#10004; Plano de desenvolvimento de carreira</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(53)" id="53"
                  >&#10004; Roteiro para mudança de carreira</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(54)" id="54"
                  >&#10004; Roteiro para trabalhar com cliente desempregado</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(55)" id="55"
                  >&#10004; Resourceful Coach</a
                >
              </li>
            </ul>
          </li>

          <li>
            <a
              href="#pageSubmenu9"
              data-toggle="collapse"
              style="text-align: center; height: 60px"
              aria-expanded="false"
              class="dropdown-toggle"
              ><img
                width="19px"
                style="margin-right: 10px; margin-top: 5px"
                src="../../imgs/performance.png"
              />Desenvolvimento de<br />
              performance</a
            >
            <ul class="collapse list-unstyled" id="pageSubmenu9">
              <li>
                <a href="#" v-on:click="changePage(73)" id="73"
                  >&#10004; Pré-sessão</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(66)" id="66"
                  >&#10004; Perfis de liderança</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(67)" id="67"
                  >&#10004; Aprendizado e defensividade</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(68)" id="68"
                  >&#10004; Feedback</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(69)" id="69"
                  >&#10004; Roteiro de Feedback</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(70)" id="70"
                  >&#10004; Minifeedback 360 graus</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(71)" id="71"
                  >&#10004; Modelo 6Ws</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(72)" id="72"
                  >&#10004; Plano de metas 5x5</a
                >
              </li>
            </ul>
          </li>

          <li>
            <a
              href="#pageSubmenu10"
              data-toggle="collapse"
              aria-expanded="false"
              class="dropdown-toggle"
              ><img
                width="26px"
                style="margin-right: 10px"
                src="../../imgs/semente-crescente.png"
              />Pós-Florescimento</a
            >
            <ul class="collapse list-unstyled" id="pageSubmenu10">
              <li>
                <a href="#" v-on:click="changePage(56)" id="56"
                  >&#10004; Avaliação final</a
                >
              </li>

              <li>
                <a v-on:click="changePage(75)" href="#" id="75"
                  >&#10004; Avaliando a felicidade</a
                >
              </li>
              <li>
                <a href="#" id="op62"
                  >&#10004; Especificação de objetivos e Resultados
                  Alcançados</a
                >
              </li>

              <li>
                <a href="#" v-on:click="changePage(58)" id="58"
                  >&#10004; Escala de satisfação com a vida</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(59)" id="59"
                  >&#10004; Escala de realização</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(60)" id="60"
                  >&#10004; Escala de avaliação de humor</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(61)" id="61"
                  >&#10004; Avaliação do nível de esperança</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(62)" id="62"
                  >&#10004; Avaliação do nível de motivação</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(63)" id="63"
                  >&#10004; Avaliação do funcionamento otimizado</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(64)" id="64"
                  >&#10004; Escala de florescimento</a
                >
              </li>
              <li>
                <a href="#" v-on:click="changePage(57)" id="57"
                  >&#10004; Avaliação de Resultados do desenvolvimento</a
                >
              </li>
            </ul>
          </li>
        </ul>
      </scroll-container>
      <h1 v-on:click="changePageSpecialist()" id="arrow">&#8249;</h1>
    </nav>
    -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      previousId: 0,
    };
  },
  methods: {
    changePageSpecialist: function () {
      this.$router.push("pagina-inicial-especialista");
    },
    underline: function (id) {
      if (this.previousId != 0) {
        document.getElementById(this.previousId).style.textDecoration = "none";
      }
      document.getElementById(id).style.textDecoration = "underline";
      this.previousId = id;
    },
    changePage: function (page) {
      if (page == 1) {
        this.$emit("change-component", "avaliacaoPre");
      } else if (page == 2) {
        this.$emit("change-component", "assessment43");
      } else if (page == 3) {
        this.$emit("change-component", "assessment1");
      } else if (page == 4) {
        this.$emit("change-component", "assessment44");
      } else if (page == 5) {
        this.$emit("change-component", "assessment45");
      } else if (page == 6) {
        this.$emit("change-component", "assessment46");
      } else if (page == 7) {
        this.$emit("change-component", "assessment49");
      } else if (page == 8) {
        this.$emit("change-component", "assessment47");
      } else if (page == 9) {
        this.$emit("change-component", "assessment48");
      } else if (page == 10) {
        this.$emit("change-component", "assessment59");
      } else if (page == 11) {
        this.$emit("change-component", "assessment51");
      } else if (page == 12) {
        this.$emit("change-component", "assessment50");
      } else if (page == 13) {
        this.$emit("change-component", "assessment4");
      } else if (page == 14) {
        this.$emit("change-component", "assessment60");
      } else if (page == 15) {
        this.$emit("change-component", "assessment2");
      } else if (page == 16) {
        this.$emit("change-component", "assessment10");
      } else if (page == 17) {
        this.$emit("change-component", "assessment14");
      } else if (page == 18) {
        this.$emit("change-component", "assessment9");
      } else if (page == 19) {
        this.$emit("change-component", "assessment3");
      } else if (page == 20) {
        this.$emit("change-component", "assessment7");
      } else if (page == 21) {
        this.$emit("change-component", "assessment42");
      } else if (page == 22) {
        this.$emit("change-component", "assessment58");
      } else if (page == 23) {
        this.$emit("change-component", "assessment52");
      } else if (page == 24) {
        this.$emit("change-component", "assessment53");
      } else if (page == 25) {
        this.$emit("change-component", "assessment54");
      } else if (page == 26) {
        this.$emit("change-component", "assessment20");
      } else if (page == 27) {
        this.$emit("change-component", "assessment39");
      } else if (page == 28) {
        this.$emit("change-component", "assessment41");
      } else if (page == 29) {
        this.$emit("change-component", "assessment6");
      } else if (page == 30) {
        this.$emit("change-component", "assessment61");
      } else if (page == 31) {
        this.$emit("change-component", "assessment13");
      } else if (page == 32) {
        this.$emit("change-component", "assessment18");
      } else if (page == 33) {
        this.$emit("change-component", "assessment17");
      } else if (page == 34) {
        this.$emit("change-component", "assessment16");
      } else if (page == 35) {
        this.$emit("change-component", "assessment25");
      } else if (page == 36) {
        this.$emit("change-component", "assessment27");
      } else if (page == 37) {
        this.$emit("change-component", "assessment55");
      } else if (page == 38) {
        this.$emit("change-component", "assessment56");
      } else if (page == 39) {
        this.$emit("change-component", "assessment57");
      } else if (page == 40) {
        this.$emit("change-component", "assessment21");
      } else if (page == 41) {
        this.$emit("change-component", "assessment40");
      } else if (page == 42) {
        this.$emit("change-component", "assessment23");
      } else if (page == 43) {
        this.$emit("change-component", "assessment22");
      } else if (page == 44) {
        this.$emit("change-component", "assessment24");
      } else if (page == 45) {
        this.$emit("change-component", "assessment28");
      } else if (page == 46) {
        this.$emit("change-component", "assessment29");
      } else if (page == 47) {
        this.$emit("change-component", "assessment30");
      } else if (page == 48) {
        this.$emit("change-component", "assessment31");
      } else if (page == 49) {
        this.$emit("change-component", "assessment32");
      } else if (page == 50) {
        this.$emit("change-component", "assessment33");
      } else if (page == 51) {
        this.$emit("change-component", "assessment34");
      } else if (page == 52) {
        this.$emit("change-component", "assessment35");
      } else if (page == 53) {
        this.$emit("change-component", "assessment36");
      } else if (page == 54) {
        this.$emit("change-component", "assessment37");
      } else if (page == 55) {
        this.$emit("change-component", "assessment38");
      } else if (page == 56) {
        this.$emit("change-component", "avaliacaoPos");
      } else if (page == 57) {
        this.$emit("change-component", "assessment11");
      } else if (page == 58) {
        this.$emit("change-component", "assessment45");
      } else if (page == 59) {
        this.$emit("change-component", "assessment49");
      } else if (page == 60) {
        this.$emit("change-component", "assessment44");
      } else if (page == 61) {
        this.$emit("change-component", "assessment43");
      } else if (page == 62) {
        this.$emit("change-component", "assessment46");
      } else if (page == 63) {
        this.$emit("change-component", "assessment47");
      } else if (page == 64) {
        this.$emit("change-component", "assessment48");
      } else if (page == 65) {
        this.$emit("change-component", "assessment8");
      } else if (page == 66) {
        this.$emit("change-component", "assessment62");
      } else if (page == 67) {
        this.$emit("change-component", "assessment63");
      } else if (page == 68) {
        this.$emit("change-component", "assessment64");
      } else if (page == 69) {
        this.$emit("change-component", "assessment65");
      } else if (page == 70) {
        this.$emit("change-component", "assessment66");
      } else if (page == 71) {
        this.$emit("change-component", "assessment67");
      } else if (page == 72) {
        this.$emit("change-component", "assessment68");
      } else if (page == 73) {
        this.$emit("change-component", "assessment69");
      } else if (page == 74) {
        this.$emit("change-component", "assessment70");
      } else if (page == 75) {
        this.$emit("change-component", "assessment70");
      }

      this.underline(page);
    },
  },
};
</script>


<style scoped>
#arrow {
  margin-left: 200px;
}
#arrow:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  cursor: pointer;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar {
  width: 3.8px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #d3d3d3;
}
nav,
scroll-container {
  display: block;
  margin: 0 auto;
  text-align: center;
}
scroll-container {
  display: block;
  width: 100%;
  height: 500px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.wrapper {
  display: flex;
  width: 100%;
}

#sidebar {
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 999;
  background: #7386d5;
  color: #fff;
  transition: all 0.9s;
}

#sidebar.active {
  margin-left: -250px;
}
a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
p {
  font-family: Montserrat;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
}

#sidebar {
  /* don't forget to add all the previously mentioned styles here too */
  background: #983e7b;
  color: #fff;
  transition: all 0.9s;
}
#sidebar {
  min-width: 250px;
  max-width: 250px;
  min-height: 100vh;
}
#sidebar .sidebar-header {
  padding: 20px;
  background: #983e7b;
}

#sidebar ul.components {
  padding: 20px 0;
  border-bottom: 1px solid #983e7b;
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 0.75em;
  display: block;
}
#sidebar ul li a:hover {
  color: #fff;
  background: rgb(255, 255, 255, 0.1);
  cursor: pointer;
}

#sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: #fff;
  background: #e5b259;
}
ul ul a {
  font-size: 0.71em !important;
  padding-left: 30px !important;
  background: #aa468a;
}

* {
  font-family: Montserrat;
}
</style>
