<template>
  <div id="header2">
    <div class="row">
      <div id="colText" class="col">
        <h1 style="margin-top: 10%">
          <b
            >Entre em <span>contato</span> conosco e venha saber mais sobre
            nossa empresa!</b
          >
        </h1>
      </div>
      <div id="colImage" style="height: 50vh; z-index: 4" class="col">
        <img id="imgConheca" src="../../imgs/image_gestao.png" />
      </div>
    </div>
    <br /><br />
    <div style="width: 100%; z-index: 0; position: relative">
      <h1 id="sentence"><b>CONTATO</b></h1>
    </div>
    <div style="z-index: 1; position: relative">
      <img id="ondulatoria" src="../../imgs/ondulatoria.png" />
    </div>
    <div id="divBtn">
      <router-link to="/parceiro" class="nav-link"
        ><button class="btnTO">ENTRE EM CONTATO E SAIBA MAIS</button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
h1 {
  margin-left: 10%;
  color: #f6c56f;
  font-size: 40px;
  font-family: Montserrat;
}

h5 {
  margin-left: 10%;
  color: white;
  font-size: 20px;
}

#imgConheca {
  position: absolute;
  left: 20%;
  width: 80%;
  height: 400px;
}

#divBtn {
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: center;
  margin-top: -10%;
}

#ondulatoria {
  margin-top: -14%;
  width: 100%;
}

#sentence {
  color: white;
  width: 100vw;
  font-size: 250px;
  opacity: 0.2;
  margin-left: 2%;
  margin-top: -5%;
}

.btnTO {
  font-size: 15px;
  background-image: linear-gradient(180deg, #fd5392, #f86f64);
  color: white;
  border: solid transparent;
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 15px;
  font-family: Montserrat;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 1px;
  padding-right: 1px;
}

video {
  width: 100%;
}

#text {
  margin: 20px;
}

#header2 {
  width: 100%;
  height: 520px;
  background-color: #9648ba;
  font-size: 62.5%;
}

span {
  color: #f6c56f;
  font-weight: bold;
  font-size: 55px;
  font-family: Monotype Corsiva;
}

.cover {
  color: white;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 30%;
  left: 0;
}

.btnTO:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

#header {
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
}
</style>