<template>
    <div style="width:82%;position:absolute;left:18%;top:0%;margin-left:0%;"> 
        <br>
        <br>
        
        <div id="boxAssessment">
            <br>
            <h1 class="text-center">Ensaio Dramático</h1>
            <br>
            <ul>
                <li>Explique o propósito e os benefícios do ensaio (Role-Play) para o cliente.</li>
                <li>Estabeleça um acordo com o cliente se será gravado em vídeo ou não. (Opcional)</li>
                <li>Solicite ao cliente que defina claramente as habilidades desejadas.
Por exemplo: Gerenciar a raiva.</li>
                <li>Solicite ao cliente que descreva em detalhes um incidente prévio ao qual ele tenha
respondido inapropriadamente. Identifique o que o cliente estava pensando e sentindo
naquele momento. Solicite ao cliente que descreva em detalhes os comportamentos
das outras pessoas envolvidas no incidente.</li>
                
                <li>O profissional e o cliente simulam a situação. O cliente interpreta a si mesmo e o coach
interpreta o papel do “outro”.</li>
                <li>Juntos, profissional e cliente inventam um roteiro sobre uma situação potencial de conflito.</li>
                <li>O cliente é dirigido para interpretar uma posição diferente da que costuma tomar.
Por exemplo: Um papel polido e não agressivo.</li>
                
                <li>O profissional e o cliente continuam a inventar roteiros e praticar o novo comportamento do
cliente, variando as situações potenciais de conflito.</li>
                <li>O profissional cria um cenário surpresa com o qual o cliente poderá se deparar no futuro.
                    O cliente tem que interpretar suas respostas de forma a ensaiar situações potencialmente
difíceis que podem surgir.
                </li>
            </ul>
            <br>
            <br>
            <h5>Comentários e anotações</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <div style="display: flex; justify-content: center;"> 
                <button class="btn" >Salvar resultados</button>
            </div>
            <br>


        </div>
    </div>
</template>

<style scoped>
ul li
{
    text-align:justify;
}
*
{
    font-family: roboto;
}
#boxAssessment
{
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    text-align: center;
    color: black;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
</style>

<script>
export default {
     data() {
        return {
            
           
            opcoes: [
               {n: '0'},
               {n: '1'},
               {n: '2'},
               {n: '3'},
               {n: '4'},
               {n: '5'},
               {n: '6'},
               {n: '7'},
               {n: '8'},
               {n: '9'},
               {n: '10'},
            ]
        }
     }
   
}
</script>