var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"82%","position":"absolute","left":"18%","top":"0%","margin-left":"0%"}},[_c('br'),_c('br'),_c('div',{attrs:{"id":"boxAssessment"}},[_c('br'),_c('h1',{staticClass:"text-center"},[_vm._v("Eu ideal")]),_c('br'),_c('table',{key:"1",staticStyle:{"width":"100%"},attrs:{"id":"table1"}},[_vm._m(0),_vm._m(1),_vm._m(2)]),_c('br'),_c('br'),_c('br'),_c('h5',[_vm._v("Teoria da mudança intencional")]),_c('hr'),_c('table',{key:"2",staticStyle:{"width":"100%"},attrs:{"id":"table2"}},[_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)]),_c('br'),_c('br'),_vm._m(8),_c('br')])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{staticStyle:{"text-align":"center"}},[_vm._v("Atratores")]),_c('th',{staticStyle:{"text-align":"center"}},[_vm._v("Impacto no processo")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("Para estimular os atratores emocionais positivos (PEA), o profissionaldeve focar: "),_c('ul',[_c('li',[_vm._v("O eu ideal")]),_c('li',[_vm._v("A motivação intrínseca")]),_c('li',[_vm._v("As emoções positivas")]),_c('li',[_vm._v("Os estimulos ao Sistema Nervoso Parassimpático")])])]),_c('td',[_c('ul',[_c('li',[_vm._v("Ancorar a mudança em significado valores, paixão , futuro desejado e alavancagem de forças.")]),_c('li',[_vm._v("Fortalece a esperança, o otimismo, o esforço,a flexibildade, a persistência, a resiliência e a ampliação dos padrões mentais.")]),_c('li',[_vm._v("Facilita a performance cognitiva (integração de Informações, resolução criativa de problemas, , acessibilidade de informação na memória e aprendizado próativo), além da recepção ao feedback e a identificação de oportunidades. ")]),_c('li',[_vm._v("Aumentar a positividade e as possibilidades de ações eficazes, permite que o indivíduo se recupere de pensamentos, sentimentos e experiências estressantes e sustenta o aprendizado , o desenvolvimento e a mudança. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("O profissional deve entenderos estímulos aos atratores emocionais negativos (NEA) que ocorrem quando ele foca: "),_c('ul',[_c('li',[_vm._v("O eu real.")]),_c('li',[_vm._v("A motivação.")]),_c('li',[_vm._v("As emoções negativas.")]),_c('li',[_vm._v("Os estímulos ao Sistemas Nervoso Simpático")])])]),_c('td',[_c('ul',[_c('li',[_vm._v("Ancorar a mudança em exigências situacionais, na identificação de problemas e de ameaças e na minimização de perdas ")]),_c('li',[_vm._v("Fortalece a consciência quanto aos estressores, vulnerabilidade, fraquezas, ansiedades, medos e à necessidade de ações autoprotetoras.")]),_c('li',[_vm._v("Facilita a identificação de eventos que podem comprometer a eficácia e a segurança, mobiliza a energia psicológica para lidar com problemas e estressores situacionais.")]),_c('li',[_vm._v("Organiza respostas para enfatizar problemas, ameaças, ações, defensivas, vigilância, foco na autoproteção e em reações psicólogicos que podem levar ao esgotamento e aumento do estresse.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("Eu ideal/pilares do bem estar")]),_c('th',[_vm._v("Físico e psicológico")]),_c('th',[_vm._v("Socialmente")]),_c('th',[_vm._v("Comunidade")]),_c('th',[_vm._v("Carreira")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticStyle:{"text-align":"center"}},[_vm._v("1 - Desenhe seu Eu ideal/ a pessoa que quero ser")]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticStyle:{"text-align":"center"}},[_vm._v("2 - Avalie seu eu real")]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticStyle:{"text-align":"center"}},[_vm._v("3 - Planeje a mudança "),_c('br'),_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(" Quais as forças que já tenho e que podem me ajudar chegar lá? "),_c('br'),_vm._v(" O que preciso aprender para chegar lá? "),_c('br'),_vm._v(" Quais os comportamentos que preciso mudar para chegar lá? ")])]),_c('td',[_c('input',{staticStyle:{"height":"180px"},attrs:{"type":"text"}})]),_c('td',[_c('input',{staticStyle:{"height":"180px"},attrs:{"type":"text"}})]),_c('td',[_c('input',{staticStyle:{"height":"180px"},attrs:{"type":"text"}})]),_c('td',[_c('input',{staticStyle:{"height":"180px"},attrs:{"type":"text"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticStyle:{"text-align":"center"}},[_vm._v("4 - Use relacionamentos de apoio "),_c('br'),_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(" Quais as pessoas que te ajudarão nesse processo. ")])]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})]),_c('td',[_c('input',{attrs:{"type":"text"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('button',{staticClass:"btn"},[_vm._v("Salvar resultados")])])
}]

export { render, staticRenderFns }