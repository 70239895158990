<template>
    <div class="modal fade bd-example-modal-lg" id="modalConsumer" >
        
            <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Complete seu perfil</h5>
                    
                </div>

                 <div class="modal-body">
                    <span>{{msgError}}</span>
                    <form>
                        <div>
                            <p style="font-size:10px;">Escolha a sua foto de perfil</p>
                            <div style="width:20px">
                                <input  type="file" id="filePic" accept="image/*" v-on:change="previewImage($event)">
                            </div>
                            
                            
                            <div style="margin-top:10px;display: flex; justify-content: center;"> 
                                <img height="400px" width="500px"  src="" id="output_image"/>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="modal-footer">
                    <button type="button" v-on:click="sendPic()" id="submit" class="btn">{{msg}}</button>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossorigin="anonymous"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js" integrity="sha384-ZMP7rVo3mIykV+2+9J3UJ46jBk0WLaUAdn689aCwoqbBJiSnjAK/l8WvCWPIPm49" crossorigin="anonymous"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/js/bootstrap.min.js" integrity="sha384-ChfqqxuZUCnJSK3+MXmPNIyE6ZbWh2IMqE241rYiqJxyMiZ6OW/JmZQ5stwEULTy" crossorigin="anonymous"></script>
  
<script>
import api from '../services/api.js';

export default {
    data()
    {
        return{
            userId:"",
            complement:"",
            date:"",
            cpf:"",
            cep:"",
            city:"",
            stateSelected:"",
            street:"",
            district:"",
            number:"",
            nameResponsible:"",
            telResponsible:"",
            msgError:"",
            msg:"Concluir",
            part:1,
            date:"",
            age:"18",
            maritalStatusSelected:"",
            formationSelected:"",
            profession:"",
            motherName:"",
            goals:"",
            maritalStatus:[
                {status: 'Solteiro(a)'},
                {status: 'Casado(a)'},
                {status: 'Divorciado(a)'},
                {status: 'União Estável'},
                {status: 'Viuvo(a)'}
             ],
             states: [
              {uf: 'SP'},
              {uf: 'RJ'},
              {uf: 'ES'},
              {uf: 'MG'},
              {uf: 'BA'},
              {uf: 'SE'},
              {uf: 'PE'},
              {uf: 'AL'},
              {uf: 'PB'},
              {uf: 'RN'},
              {uf: 'CE'},
              {uf: 'PI'},
              {uf: 'MA'},
              {uf: 'PA'},
              {uf: 'AP'},
              {uf: 'AM'},
              {uf: 'RR'},
              {uf: 'AC'},
              {uf: 'DF'},
              {uf: 'GO'},
              {uf: 'RO'},
              {uf: 'TO'},
              {uf: 'MT'},
              {uf: 'MS'},
              {uf: 'PR'},
              {uf: 'SC'},
              {uf: 'RS'},
              
            ],
            formation:[
                {level: 'Ensino fundamental incompleto'},
                {level: 'Ensino fundamental completo'},
                {level: 'Ensino médio incompleto'},
                {level: 'Ensino médio completo'},
                {level: 'Ensino superior incompleto'},
                {level: 'Ensino superior completo'},
             ],
             specialistId:""
        }
    },
    mounted()
    {
        this.openModal();
        this.specialistId = localStorage.getItem('specialistId');
        
    },
    methods:
    {
        previewImage: function(event)
        {
            this.file=true;
            if(event.target.files.length > 0)
            {
                var src = URL.createObjectURL(event.target.files[0]);
                var preview = document.getElementById("output_image");
                preview.src = src;
                preview.style.display = "block";
            }
        },
        sendPic: function()
        {
        
            const filePic = document.getElementById("filePic").value;
            if(filePic)
            {
                api.patch('/specialist-profile-pic',{
                                        userID: this.specialistId,
                                        logoPic:filePic
                                    })
                                    .then((response) => {
                                        
                                        console.log(response);
                                        localStorage.setItem('completed', true);
                                        //document.location.reload(true);
                                        this.closeModal();
                                    }, (error) => {
                                        
                                        console.log(error.response);

                                        
                                        
                });
            }
            
        },
        openModal: function()
        {
            $('#modalConsumer').modal('show');
        },
        closeModal: function()
        {
            
            $('#modalConsumer').modal('hide');
        },
       
       
    }
}
</script>
<style scoped>
img
{
    display:none;
}
#submit
{
    background-color:#983e7b;
    color:white;
}
#modalConsumer
{
    overflow-y: hidden;
}
span
{
    font-size:10px;
    color:red;
}
.form-group
{
    height: 70px;
}
</style>