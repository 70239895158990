<template>
  <div id="pageSpecialist">
    <testes></testes>
    <barraLateral></barraLateral>
  </div>
</template>
<script>
import barraLateral from "../components/barra-lateral-cliente.vue";
import testes from "../components/pagina-testes.vue";
export default {
  components: {
    barraLateral,
    testes,
  },
};
</script>
<style scoped>
#pageSpecialist {
  width: 100%;
  height: 800px;
}
* {
  margin-top: -1%;
}
</style>