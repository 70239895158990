import { render, staticRenderFns } from "./assessment-12.vue?vue&type=template&id=75a30820&scoped=true&"
import script from "./assessment-12.vue?vue&type=script&lang=js&"
export * from "./assessment-12.vue?vue&type=script&lang=js&"
import style0 from "./assessment-12.vue?vue&type=style&index=0&id=75a30820&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75a30820",
  null
  
)

export default component.exports