import { render, staticRenderFns } from "./assessment-43.vue?vue&type=template&id=16561a9b&scoped=true&"
import script from "./assessment-43.vue?vue&type=script&lang=js&"
export * from "./assessment-43.vue?vue&type=script&lang=js&"
import style0 from "./assessment-43.vue?vue&type=style&index=0&id=16561a9b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16561a9b",
  null
  
)

export default component.exports