var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('br'),_c('br'),_c('div',{staticClass:"ml-5 mr-5 mt-3",attrs:{"id":"boxAtendimento"}},[_c('h1',{staticClass:"text-center"},[_vm._v("Clientes atendidos por João")]),_c('br'),_c('table',{staticClass:"table is-bordered is-hoverable",staticStyle:{"width":"100%"}},[_vm._m(0),_c('tr',{attrs:{"id":"line1"},on:{"mouseover":function($event){return _vm.changeColor('line1')}}},[_vm._m(1),_c('td',[_vm._v("Desenvolvimento pessoal")]),_c('td',[_vm._v("4.0")]),_vm._m(2)]),_c('tr',{attrs:{"id":"line2"},on:{"mouseover":function($event){return _vm.changeColor('line2')}}},[_c('td',[_vm._v("João")]),_c('td',[_vm._v("Desenvolvimento profissional")]),_c('td',[_vm._v("4.0")]),_vm._m(3)]),_c('tr',{attrs:{"id":"line3"},on:{"mouseover":function($event){return _vm.changeColor('line3')}}},[_c('td',[_vm._v("Fernanda")]),_c('td',[_vm._v("Desenvolvimento pessoal")]),_c('td',[_vm._v("4.0")]),_vm._m(4)]),_c('tr',{attrs:{"id":"line4"},on:{"mouseover":function($event){return _vm.changeColor('line4')},"click":function($event){return _vm.$root.pushState('teste-competencia-pessoal')}}},[_c('td',[_vm._v("Maria")]),_c('td',[_vm._v("Desenvolvimento pessoal")]),_c('td',[_vm._v("4.0")]),_vm._m(5)]),_c('tr',{attrs:{"id":"line5"},on:{"mouseover":function($event){return _vm.changeColor('line5')},"click":function($event){return _vm.$root.pushState('teste-competencia-pessoal')}}},[_c('td',[_vm._v("Luciano")]),_c('td',[_vm._v("Desenvolvimento pessoal")]),_c('td',[_vm._v("4.0")]),_vm._m(6)]),_c('tr',{attrs:{"id":"line6"},on:{"mouseover":function($event){return _vm.changeColor('line6')},"click":function($event){return _vm.$root.pushState('teste-competencia-pessoal')}}},[_c('td',[_vm._v("Pedro")]),_c('td',[_vm._v("Desenvolvimento pessoal")]),_c('td',[_vm._v("4.0")]),_vm._m(7)])]),_c('br')]),_c('br'),_c('br')])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticStyle:{"width":"25%","background-color":"#983e7b","color":"white"}},[_c('td',[_vm._v("Nome")]),_c('td',[_vm._v("Tema trabalhado")]),_c('td',[_vm._v("Média de notas")]),_c('td',[_vm._v("Sessões já realizadas")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('a',{attrs:{"href":"/historico-cliente"}},[_vm._v("Luan")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('button',{staticClass:"btn"},[_vm._v("1")]),_c('button',{staticClass:"btn ml-2"},[_vm._v("2")]),_c('button',{staticClass:"btn ml-2"},[_vm._v("3")]),_c('button',{staticClass:"btn ml-2"},[_vm._v("4")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('button',{staticClass:"btn"},[_vm._v("1")]),_c('button',{staticClass:"btn ml-2"},[_vm._v("2")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('button',{staticClass:"btn"},[_vm._v("1")]),_c('button',{staticClass:"btn ml-2"},[_vm._v("2")]),_c('button',{staticClass:"btn ml-2"},[_vm._v("3")]),_c('button',{staticClass:"btn ml-2"},[_vm._v("4")]),_c('button',{staticClass:"btn ml-2"},[_vm._v("5")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('button',{staticClass:"btn"},[_vm._v("1")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('button',{staticClass:"btn"},[_vm._v("1")]),_c('button',{staticClass:"btn ml-2"},[_vm._v("2")]),_c('button',{staticClass:"btn ml-2"},[_vm._v("3")]),_c('button',{staticClass:"btn ml-2"},[_vm._v("4")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('button',{staticClass:"btn",staticStyle:{"width":"50px"}},[_vm._v("1")]),_c('button',{staticClass:"btn ml-2",staticStyle:{"width":"50px"}},[_vm._v("2")]),_c('button',{staticClass:"btn ml-2"},[_vm._v("3")]),_c('button',{staticClass:"btn ml-2"},[_vm._v("4")]),_c('button',{staticClass:"btn ml-2"},[_vm._v("5")]),_c('button',{staticClass:"btn ml-2"},[_vm._v("6")]),_c('button',{staticClass:"btn ml-2"},[_vm._v("7")]),_c('button',{staticClass:"btn ml-2"},[_vm._v("8")]),_c('button',{staticClass:"btn ml-2"},[_vm._v("9")]),_c('button',{staticClass:"btn ml-2"},[_vm._v("10")])])
}]

export { render, staticRenderFns }