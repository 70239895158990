<template>
  <div class="container-fluid" style="z-index:1000000000">
    <!-- Navbar Desktop -->
    <nav id="navbarDesktop" class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
      <a class="navbar-brand" href="#">
        <img id="logo" src="../../imgs/logo_florescer.png" />
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse ml-5 mr-3" id="navbarSupportedContent">
        <ul class="nav navbar-nav ml-auto">
          <li class="nav-item active">
            <router-link to="/" class="list_nav">Home</router-link>
          </li>

          <li class="nav-item">
            <router-link to="sobre-nos" class="list_nav">Sobre nós</router-link>
          </li>

          <li class="nav-item">
            <router-link to="para-voce" class="list_nav">Para você</router-link>
          </li>

          <li class="nav-item">
            <router-link to="para-sua-empresa" class="list_nav">Para sua empresa</router-link>
          </li>

          <li class="nav-item">
            <router-link to="torne-se-um-especialista" class="list_nav">Torne-se um especialista</router-link>
          </li>
        </ul>
      </div>
      <a :href="VUE_APP_URL_PAINEL + '/login'" id="login" class="list_nav">Entrar</a>
    </nav>

    <!-- Navbar Mobile -->
    <nav id="navbarMobile" style="width: 100%" class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <a class="navbar-brand" href="#">
        <img id="logo" src="../../imgs/logo_florescer.png" />
      </a>

      <div class="collapse navbar-collapse ml-5 mr-3" id="navbarSupportedContent">
        <ul id="navList" class="nav navbar-nav ml-auto">
          <li class="nav-item active">
            <router-link to="/" class="list_nav">Home</router-link>
          </li>

          <li class="nav-item">
            <router-link to="sobre-nos" class="list_nav">Sobre nós</router-link>
          </li>

          <li class="nav-item">
            <router-link to="para-voce" class="list_nav">Para você</router-link>
          </li>

          <li class="nav-item">
            <router-link to="para-sua-empresa" class="list_nav">Para sua empresa</router-link>
          </li>

          <li class="nav-item">
            <router-link to="torne-se-um-especialista" class="list_nav">Torne-se um especialista</router-link>
          </li>
        </ul>
      </div>
      <a :href="VUE_APP_URL_PAINEL + '/login'" id="login" class="list_nav">Entrar</a>
    </nav>
  </div>
</template>

<script>
export default {
  data(){
    return {
      VUE_APP_URL_PAINEL: process.env.VUE_APP_URL_PAINEL
    }
  },
};
</script>

<style scoped>
#navbarSupportedContent {
  background-color: #f8f9fb;
}

.navbar-brand {
  margin-left: 7%;
}

#navbarDropdownMenuLink {
  color: #983e7b;
}

.dropdown-item:active {
  background-color: transparent;
  text-decoration: #e5b259;
}

.dropdown-item:hover {
  background-color: transparent;
  text-decoration: #e5b259;
}

.dropdown-item {
  color: #983e7b;
}

#login {
  font-family: Source Sans Pro;
  width: 63px;
  height: 36px;
  color: #983e7b;
  border-style: solid;
  border-color: #fabe0a;
  background-color: #fabe0a;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding-left: 8px;
  border-width: 2px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-right: 8.3%;
}

#login:hover {
  background-color: #fff051;
  border-color: #fff051;
}

#navbarMobile {
  display: none;
  visibility: hidden;
  width: 50px;
}

#navbarDesktop {
  display: flex;
  height: 120px;
}

nav {
  height: 96px;
}

.list_nav {
  color: #ab3585;
  cursor: pointer;
  margin-right: 16px;
  font-family: Open Sans;
  font-size: 16px;
}

.list_nav:hover {
  text-decoration: none;
  color: #de4eaf;
}

#logo {
  height: 87px;
  width: 175px;
}

@media (min-width: 345px) and (max-width: 993px) {
  #navbarDesktop {
    visibility: hidden;
    display: none;
  }

  #navbarMobile {
    visibility: visible;
    display: flex;
    height: 120px;
  }

  .navbar-brand {
    margin-left: 0%;
  }
}

@media (min-width: 0px) and (max-width: 390px) {
  #login {
    width: 100px;
  }

  #navbarDesktop {
    visibility: hidden;
  }

  #navbarMobile {
    visibility: visible;
    display: flex;
    height: 120px;
  }

  .navbar-brand {
    margin-left: 0%;
  }

  #logo {
    height: 65px;
    width: 130px;
  }

  #login {
    width: 18.612vw;
    height: 6.635vw;
    font-size: 12px;
    text-align: center;
    padding-bottom: 20px;
  }
}
</style>
