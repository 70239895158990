<template>
    <div style="width:82%;position:absolute;left:18%;top:0%;margin-left:0%;"> 
        <br>
        <br>
        
        <div id="boxAssessment">
            <br>
            <h1 class="text-center">Medindo a autoeficácia</h1>
            <br>
            <h5>[Desenvolvido a partir do trabalho de Albert Bandura]</h5>
            <br>
             <table>
                <tr>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                   
                   
                </tr>
                <tr>
                    <td>Raramente</td>
                    <td>Quase Sempre</td>
                    <td>Sempre</td>
                    
                </tr>
            </table>
            <br>
            <br>
            <br>
           
    
            
            <br>
            <h5>Você acredita que irá alcançar seus objetivos?</h5>
            <br>
            <input id="level1" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel1').innerHTML = this.value;"  min="1" max="3" value="2" />
            <h1 id="showLevel1" class="numberLevel">2</h1>
            <br>
            <br>
            <hr>

            <h5>Acredita que é capaz de encontrar os meios para realizar o que pretende?</h5>
            <br>
            <input id="level2" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel2').innerHTML = this.value;"  min="1" max="3" value="2" />
            <h1 id="showLevel2" class="numberLevel">2</h1>
           
            <br>
            <br>
            <hr>
            <h5>Compromete-se com seus objetivos a ponto de agir diariamente para alcançá-los?</h5>
            <br>
            <input id="level3" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel3').innerHTML = this.value;"  min="1" max="3" value="2" />
            <h1 id="showLevel3" class="numberLevel">2</h1>
            <br>
            <br>
            <hr>
            <h5>Quando pensa no que já fez, é capaz de identificar e de orgulhar-se de suas conquistas?</h5>
            <br>
            <input id="level4" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel4').innerHTML = this.value;"  min="1" max="3" value="2" />
            <h1 id="showLevel4" class="numberLevel">2</h1>
            <br>
            <br>
            <hr>
            <h5>Tem expectativas positivas em relação ao futuro?</h5>
            <br>
            <input id="level5" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel5').innerHTML = this.value;"  min="1" max="3" value="2" />
            <h1 id="showLevel5" class="numberLevel">2</h1>
            <br>
            <br>
            <hr>
            <h5>Sente-se bem com você mesmo?</h5>
            <br>
            <input id="level6" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel6').innerHTML = this.value;"  min="1" max="3" value="2" />
            <h1 id="showLevel6" class="numberLevel">2</h1>
            <br>
            <br>
            <hr>
            <h5>Confia em si mesmo quando tem de lidar com dificuldades inesperadas?</h5>
            <br>
            <input id="level7" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel7').innerHTML = this.value;"  min="1" max="3" value="2" />
            <h1 id="showLevel7" class="numberLevel">2</h1>
            <br>
            <br>
            <hr>
            <h5>Persiste diante dos obstáculos?</h5>
            <h5>Confia em si mesmo quando tem de lidar com dificuldades inesperadas?</h5>
            <br>
            <input id="level8" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel8').innerHTML = this.value;"  min="1" max="3" value="2" />
            <h1 id="showLevel8" class="numberLevel">2</h1>
            <br>
            <br>
            <hr>
            <h5>Consegue manter ou recuperar rapidamente a autoconfiança após receber uma crítica negativa?</h5>
            <h5>Confia em si mesmo quando tem de lidar com dificuldades inesperadas?</h5>
            <br>
            <input id="level9" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel9').innerHTML = this.value;"  min="1" max="3" value="2" />
            <h1 id="showLevel9" class="numberLevel">2</h1>
            <br>
            <br>
            <hr>
            <h5>Sente-se motivado para buscar novos desafios?</h5>
            <h5>Confia em si mesmo quando tem de lidar com dificuldades inesperadas?</h5>
            <br>
            <input id="level10" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel10').innerHTML = this.value;"  min="1" max="3" value="2" />
            <h1 id="showLevel10" class="numberLevel">2</h1>
            <br>
            <br>
            <hr>
            <h3>Orientações</h3>
            <br>
            <p>Passe esse questionário para o seu cliente e depois confira o resultado. Quanto maior for o número de respostas “A”, maior é o seu nível de autoeficácia.</p>
            <br>
            <br>
            
            <div style="display: flex; justify-content: center;"> 
                   <button class="btn" >Salvar resultados</button>
            </div>
            <br>
        </div>    
    </div>
</template>

<style scoped>
h5, p
{
    text-align:justify;
}
.numberLevel
{
    text-align:center;
    color:#983e7b;
}
.inputLevel
{
    width:100%;
}
h5
{
    text-align: justify;
}
table tr td
{
    text-align: center;
    width:400px;
    border-bottom-color:#D3D3D3;
    border-bottom-style: solid;
     
    border-width: 1px;
}
table tr th
{
    border-top-color:#D3D3D3;
    border-top-style: solid;
     
    border-width: 1px;
    text-align: center;
    width:400px;
}
input[type='range'] {
  cursor: pointer;
}

input[type='range'],
input[type='range']::-webkit-slider-runnable-track,
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}

/** 
 * Use o pseudo-seletor "::-webkit-slider-runnable-track" para customizar o "trilho" do campo.
 */
input[type='range']::-webkit-slider-runnable-track {
  
  height: 10px;
  background: #983e7b;
  border-radius: 13px;
}
input[type='range']::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  margin-top: -4.5px;
  background-color: #fec961;
  border-radius: 20px;
}
*
{
    font-family: roboto;
}
#boxAssessment
{
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    text-align: center;
    color: black;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
</style>

<script>
export default {
    
   
}
</script>