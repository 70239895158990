<template>
  <div class="container-fluid">
    <br />
    <br />
    <div id="boxAtendimento" class="ml-5 mr-5 mt-3">
      <h1 class="text-center">Clientes atendidos por João</h1>
      <br />
      <table class="table is-bordered is-hoverable" style="width: 100%">
        <tr style="width: 25%; background-color: #983e7b; color: white">
          <td>Nome</td>
          <td>Tema trabalhado</td>
          <td>Média de notas</td>
          <td>Sessões já realizadas</td>
        </tr>
        <tr id="line1" v-on:mouseover="changeColor('line1')">
          <td><a href="/historico-cliente">Luan</a></td>
          <td>Desenvolvimento pessoal</td>
          <td>4.0</td>
          <td>
            <!--
                      <button style="width:50px" class="primary m-2 has-text-centered" v-for="s in sessoes" v-bind:value="s">{{s}}</button>
                      <button v-on:click="teste()" style="width:50px" class="primary m-2 has-text-centered" >+</button>
                      -->

            <button class="btn">1</button>
            <button class="btn ml-2">2</button>
            <button class="btn ml-2">3</button>
            <button class="btn ml-2">4</button>
          </td>
        </tr>
        <tr id="line2" v-on:mouseover="changeColor('line2')">
          <td>João</td>
          <td>Desenvolvimento profissional</td>
          <td>4.0</td>
          <td>
            <!--
                      <button style="width:50px" class="primary m-2 has-text-centered" v-for="s in sessoes" v-bind:value="s">{{s}}</button>
                      <button v-on:click="teste()" style="width:50px" class="primary m-2 has-text-centered" >+</button>
                    -->
            <button class="btn">1</button>
            <button class="btn ml-2">2</button>
          </td>
        </tr>
        <tr id="line3" v-on:mouseover="changeColor('line3')">
          <td>Fernanda</td>
          <td>Desenvolvimento pessoal</td>
          <td>4.0</td>
          <td>
            <!--
                      <button style="width:50px" class="primary m-2 has-text-centered" v-for="s in sessoes" v-bind:value="s">{{s}}</button>
                      <button v-on:click="teste()" style="width:50px" class="primary m-2 has-text-centered" >+</button>
                    -->
            <button class="btn">1</button>
            <button class="btn ml-2">2</button>
            <button class="btn ml-2">3</button>
            <button class="btn ml-2">4</button>
            <button class="btn ml-2">5</button>
          </td>
        </tr>
        <tr
          id="line4"
          v-on:mouseover="changeColor('line4')"
          v-on:click="$root.pushState('teste-competencia-pessoal')"
        >
          <td>Maria</td>
          <td>Desenvolvimento pessoal</td>
          <td>4.0</td>
          <td>
            <!--
                      <button style="width:50px" class="primary m-2 has-text-centered" v-for="s in sessoes" v-bind:value="s">{{s}}</button>
                      <button v-on:click="teste()" style="width:50px" class="primary m-2 has-text-centered" >+</button>
                    -->
            <button class="btn">1</button>
          </td>
        </tr>
        <tr
          id="line5"
          v-on:mouseover="changeColor('line5')"
          v-on:click="$root.pushState('teste-competencia-pessoal')"
        >
          <td>Luciano</td>
          <td>Desenvolvimento pessoal</td>
          <td>4.0</td>
          <td>
            <!--
                      <button style="width:50px" class="primary m-2 has-text-centered" v-for="s in sessoes" v-bind:value="s">{{s}}</button>
                      <button v-on:click="teste()" style="width:50px" class="primary m-2 has-text-centered" >+</button>
                    -->
            <button class="btn">1</button>
            <button class="btn ml-2">2</button>
            <button class="btn ml-2">3</button>
            <button class="btn ml-2">4</button>
          </td>
        </tr>
        <tr
          id="line6"
          v-on:mouseover="changeColor('line6')"
          v-on:click="$root.pushState('teste-competencia-pessoal')"
        >
          <td>Pedro</td>
          <td>Desenvolvimento pessoal</td>
          <td>4.0</td>
          <td>
            <!--
                      <button style="width:50px" class="primary m-2 has-text-centered" v-for="s in sessoes" v-bind:value="s">{{s}}</button>
                      <button v-on:click="teste()" style="width:50px" class="primary m-2 has-text-centered" >+</button>
                    -->
            <button class="btn" style="width: 50px">1</button>
            <button class="btn ml-2" style="width: 50px">2</button>
            <button class="btn ml-2">3</button>
            <button class="btn ml-2">4</button>
            <button class="btn ml-2">5</button>
            <button class="btn ml-2">6</button>
            <button class="btn ml-2">7</button>
            <button class="btn ml-2">8</button>
            <button class="btn ml-2">9</button>
            <button class="btn ml-2">10</button>
          </td>
        </tr>
      </table>

      <br />
    </div>
    <br />
    <br />
  </div>
</template>
<script>
export default {
  methods: {
    changeColor: function (line) {
      document.getElementById(line).style.backgroundColor = "rgb(254, 201, 97)";
      if (this.click != "" && this.click != line) {
        document.getElementById(this.click).style.backgroundColor = "white";
      }
      this.click = line;
    },
  },
  data() {
    return {
      sessoes: [0],
      newSession: 0,
      click: "",
    };
  },
};
</script>



<style scoped>
#boxAtendimento {
  border-radius: 2%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin: 0 auto;
  padding: 30px;
}
.btn {
  background-color: #983e7b;
  color: white;
  border: none;

  width: 50px;
  justify-content: center;
  align-items: center;
  height: 30px;
}
a:link {
  text-decoration: none;
}
a {
  color: black;
}
a:hover {
  color: white;
}
</style>

