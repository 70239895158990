<template>
    <div style="height:100%;width:82%;position:absolute;left:18%;top:0%;margin-left:0%;"> 
        <br>
        <br>
       
        <div id="boxAssessment" > 
            <br>
            <h1 class="text-center">O Seu Grande Sonho e Crenças</h1>
            <br>
            <h5>[Desenvolvido a partir do trabalho de Anthony Robbins]</h5>
            <br>
            <h3><b>1. Crenças sobre a vida</b></h3>
            <br>
            
            <h5>O que você acredita sobre a vida?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3><b>2. Crenças sobre si</b></h3>
            <br>
            <h5>O que você acredita sobre si mesmo?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3><b>3. Valores</b></h3>
            <br>
            <h5>O que é realmente importante para você na vida?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3><b>4. Sonhos</b></h3>
            <br>
            <h5>Qual é o seu maior sonho? Quais são seus maiores sonhos de vida?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3><b>5. Limitações</b></h3>
            <br>
            <h5>O que está impedindo você de realizá-los? Onde estão as suas limitações?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3><b>6. Responsabilidade</b></h3>
            <br>
            <h5>Isso depende de quem?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3><b>7. Motivação pela dor</b></h3>
            <br>
            <h5>O que está custando para você não ter isso hoje?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3><b>8. Motivação pela dor no futuro</b></h3>
            <br>
            <h5>O que irá lhe custar no futuro se você não tiver isso?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3><b>9. Decisão e ação</b></h3>
            <br>
            <h5>O que você poderia fazer agora para mover-se em direção ao seu sonho?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3><b>10. Comprometimento</b></h3>
            <br>
            <h5>Qual é o seu nível de comprometimento?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            

            <div style="display: flex; justify-content: center;"> 
                 <button class="btn" >Salvar resultados</button>
            </div>
            <br>
            <br>

        </div>
    </div>
</template>

<style scoped>
*
{
    font-family: roboto;
}
#boxAssessment
{
    
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    
    color: black;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
</style>

<script>
export default {
     data() {
        return {
            
           
            opcoes: [
               {n: '0'},
               {n: '1'},
               {n: '2'},
               {n: '3'},
               {n: '4'},
               {n: '5'},
               {n: '6'},
               {n: '7'},
               {n: '8'},
               {n: '9'},
               {n: '10'},
            ]
        }
     }
   
}
</script>
