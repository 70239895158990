<template>
  <div>
    <Navbar></Navbar>
    <div class="row">
      <div class="col-sm colStyle">
        <br />
        <br />
        <h4 class="h4">Sou pessoa física</h4>
        <br />
        <br />
        <h2 class="h2">
          Cadastre-se e agende<br />
          seu horário!
        </h2>
        <br />
        <br />
        <div style="display: flex; justify-content: center">
          <router-link to="/dimensoes-da-vida" class="nav-link"
            ><button id="acess" class="btnT2">ACESSE</button></router-link
          >
        </div>
      </div>
      <div class="col-sm colStyle">
        <br />
        <br />
        <h4 class="h4">Sou especialista</h4>
        <br />
        <br />
        <h2 class="h2">Cadastre-se e acesse o <br />painel exclusivo</h2>
        <br />
        <br />
        <div style="display: flex; justify-content: center">
          <router-link to="/torne-se-um-especialista" class="nav-link"
            ><button class="btnT2" id="acess">ACESSE</button></router-link
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm colStyle">
        <br />
        <br />
        <h4 class="h4">Sou empresa</h4>
        <br />
        <br />
        <h2 class="h2">Cadastre-se</h2>
        <br />
        <br />
        <div style="display: flex; justify-content: center">
          <router-link to="/cadastro-empresa" class="nav-link"
            ><button id="acess" class="btnT2">ACESSE</button></router-link
          >
        </div>
      </div>
      <div class="col-sm colStyle">
        <br />
        <br />
        <h4 class="h4">Sou Colaborador</h4>
        <br />
        <br />
        <h2 class="h2">Realize seu login</h2>
        <br />
        <br />
        <div style="display: flex; justify-content: center">
          <router-link to="/login-colaborador" class="nav-link"
            ><button class="btnT2" id="acess">ACESSE</button></router-link
          >
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../components/navbar.vue";
import Footer from "../components/footer.vue";
export default {
  components: {
    Navbar,
    Footer,
  },
};
</script>
<style scoped>
#text2 {
  width: 100%;
  height: 500px;
}
.btnT2 {
  width: 200px;
  border: solid #e5b259;
  border-radius: 10px;
  background-color: transparent;
  height: 50px;
  text-align: center;
}
.colStyle {
  width: 50%;
  height: 400px;
}
.colStyle:hover {
  background-color: #983e7b;
}
.colStyle:hover .h4 {
  color: #e5b259;
}
.colStyle:hover .h2 {
  color: white;
}
.colStyle:hover #acess {
  color: white;
}
.h4 {
  color: gray;
  text-align: center;
  font-weight: bold;
  font-family: Montserrat;
}
.h2 {
  text-align: center;
  font-weight: bold;
  font-family: Montserrat;
  color: #983e7b;
}
</style>