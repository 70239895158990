<template>
    <div class="container-fluid"> 
        <br>
        <br>
        <br>
        <div id="boxAssessment" class="m-3">
            <h1 class="text-center">Modelo Skilled-Helper</h1>
            <br>
            <h5>[Desenvolvido a partir do trabalho de Gerard Egan]</h5>
            <br>
            <img src="../../../imgs/skilled_helper.jpg" width="100%">
            <div style="display: flex; justify-content: center;"> 
                    <a href="/nova-sessao"><button class="btn mr-3" >Voltar a pagina do cliente</button></a>
                    <button class="btn" >Salvar resultados</button>
            </div>
        </div>
    </div>
</template>

<style scoped>
*
{
    font-family: roboto;
}
#boxAssessment
{
  border-radius: 2%;  
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  margin: 0 auto;
  padding: 30px;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
</style>

<script>
export default {
     data() {
        return {
            
           
            opcoes: [
               {n: '0'},
               {n: '1'},
               {n: '2'},
               {n: '3'},
               {n: '4'},
               {n: '5'},
               {n: '6'},
               {n: '7'},
               {n: '8'},
               {n: '9'},
               {n: '10'},
            ]
        }
     }
   
}
</script>