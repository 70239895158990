<template>
  <div id="text2">
    <br />
    <br />
    <br />
    <h1>AUTOCONHECIMENTO, SUPERAÇÃO E AUTO-DESENVOLVIMENTO</h1>
    <br />
    <br />
    <p>
      São tópicos chaves de como você atingirá seu Florescimento e potencial
      pleno
    </p>
    <br />
    <br />
    <p>
      Para você é importante descobrir níveis elevados de desempenho, somados à
      otimização de sua resiliência ao lidar com os<br />desafios da vida?
    </p>
    <br />
    <br />
    <p>
      Nós ajudamos você na sua autogestão, ou seja, elevar a sua capacidade de
      gerir si mesmo, administrando suas emoções e<br />comportamentos, através
      da Ciência Psicologia Positiva e de técnicas de coaching – Ciência da
      Performance.
    </p>
    <br />
    <br />
    <p>
      Florescer é viver em um nível otimizado de funcionamento, que estimula o
      crescimento, o bem-estar e a realização.
    </p>
    <br />
    <br />

    <div id="divImg">
      <div>
        <img src="../../imgs/pilares_para_voce.png" />
        <br />
        <br />
        <br />
        <br />
        <div style="display: flex; justify-content: center">
          <a :href="VUE_APP_URL_ESPECIALISTA + '/dimensoes-da-vida'"><button class="btn">Avaliação
              Gratuita</button></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      VUE_APP_URL_ESPECIALISTA: process.env.VUE_APP_URL_ESPECIALISTA
    }
  }
};
</script>

<style scoped>
.btn {
  background-color: #fabe0a;
  width: 211px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  color: #692b55;
  font-size: 1.537vw;
}

.btn:hover {
  background-color: #fff051;
}

#text2 {
  max-height: 1676px;
}

img {
  max-height: 844px;
  max-width: 84.45vw;
}

#divImg {
  height: 1085px;
  background-color: #ab3585;
  width: 100%;
  display: flex;
  justify-content: center;
}

h1 {
  text-align: center;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  color: #ab3585;
  font-size: 2.2vw;
}

p {
  text-align: center;
  color: #ab3585;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1.458vw;
}

@media (min-width: 801px) and (max-width: 1100px) {
  img {
    max-height: 720px;
  }

  #divImg {
    max-height: 750px;
  }
}

@media (min-width: 620px) and (max-width: 800px) {
  #divImg {
    max-height: 680px;
  }

  img {
    max-height: 650px;
  }

  h1 {
    font-size: 3.2vw;
  }

  p {
    font-size: 2.458vw;
  }
}

@media (min-width: 500px) and (max-width: 620px) {
  #divImg {
    max-height: 520px;
  }

  img {
    max-height: 490px;
  }

  h1 {
    font-size: 3.2vw;
  }

  p {
    font-size: 2.458vw;
  }
}

@media (min-width: 376px) and (max-width: 499px) {
  #divImg {
    max-height: 360px;
  }

  img {
    max-height: 330px;
  }

  h1 {
    font-size: 3.2vw;
  }

  p {
    font-size: 2.458vw;
  }
}

@media (min-width: 0px) and (max-width: 375px) {
  #divImg {
    max-height: 310px;
  }

  img {
    max-height: 260px;
  }

  h1 {
    font-size: 3.2vw;
  }

  p {
    font-size: 2.458vw;
  }
}
</style>
