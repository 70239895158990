<template>
  <div>
    <Navbar></Navbar>
    <div id="rowConsumer" class="row">
      <div class="col-sm" id="message">
        <br />
        <br />
        <div style="display: flex; justify-content: center">
          <img id="imgLogo" src="../../imgs/florescer_desenvolvimento.png" />
        </div>
        <br />
        <h2>Que bom que você chegou até aqui!</h2>
        <p id="textDescription">
          Permita-se vivenciar a sua jornada de florescimento.
        </p>
        <div style="display: flex; justify-content: center">
          <img id="imgConsumer" src="../../imgs/jardim.png" />
        </div>
      </div>

      <div class="col-sm" id="formConsumer">
        <br />
        <br />

        <div id="form">
          <p id="msg1" style="color: red">{{ msgError }}</p>
          <form>
            <div>
              <label for="name">Nome: *</label>
              <br />
              <input class="input1 form-control" type="text" id="name" />
            </div>
            <br />

            <div class="row">
              <div class="col">
                <label for="email">E-mail: *</label>
                <br />
                <input class="form-control input2" type="email" id="email" />
              </div>
              <div class="col">
                <label for="tel">Telefone/Celular: *</label>
                <br />
                <input
                  class="form-control input2"
                  v-on:blur="editTel()"
                  maxlength="15"
                  type="text"
                  id="tel"
                />
              </div>
            </div>
            <br />

            <div>
              <label for="howFind">Como nos encontrou? *</label>
              <br />
              <select
                class="custom-select input1"
                v-on:click="showTextBox()"
                id="howFind"
                style="color: gray"
                required="required"
              >
                <option
                  style="color: gray"
                  v-for="op in howFind"
                  :key="op.midia"
                >
                  {{ op.midia }}
                </option>
              </select>
            </div>

            <br />
            <div v-if="boxOthers == true">
              <label for="name">Em qual outro meio nos encontrou? *</label>
              <br />
              <input class="input1 form-control" type="text" id="other" />
              <br />
            </div>
            <div class="row">
              <div class="col">
                <label for="password">Senha: *</label>
                <br />
                <input
                  maxlength="10"
                  class="input1 form-control"
                  v-on:click="showMsgPassword()"
                  type="password"
                  id="password"
                />
              </div>
              <div class="col">
                <label for="confirmPassword">Confirme sua senha: *</label>
                <br />
                <input
                  maxlength="10"
                  class="input1 form-control"
                  v-on:click="showMsgPassword()"
                  type="password"
                  id="confirmPassword"
                />
              </div>
            </div>
            <br />
            <div style="display: flex; justify-content: center">
              <input id="agreeTerms" type="checkbox" />
              <label>Ao registrar você concorda com nossos termos uso</label>
            </div>

            <div style="display: flex; justify-content: center">
              <button v-on:click="verifyForm($event)">Registrar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import api from "../services/api.js";
import Navbar from "../components/navbar.vue";
import Footer from "../components/footer.vue";
export default {
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      idQuestion: [],
      idUser: "",
      msgError: "",
      boxOthers: false,
      howFind: [
        { midia: "" },
        { midia: "Facebook" },
        { midia: "Twitter" },
        { midia: "Google" },
        { midia: "Instagram" },
        { midia: "Youtube" },
        { midia: "Noticias" },
        { midia: "Indicação de amigo" },
        { midia: "LinkedIn" },
        { midia: "Outros" },
      ],
    };
  },
  methods: {
    increaseSize: function () {
      document.getElementById("rowConsumer").style.height = "850px";
    },
    decreaseSize: function () {
      document.getElementById("rowConsumer").style.height = "750px";
    },
    showTextBox: function () {
      const howFind = document.getElementById("howFind").value;

      if (howFind == "Outros") {
        this.boxOthers = true;
        this.increaseSize();
      } else {
        this.boxOthers = false;
        this.decreaseSize();
      }
    },
    showMsgPassword: function () {
      this.msgError =
        "A senha deve conter pelo menos 6 caracteres, com letras e números";
    },
    verifyForm: function (event) {
      event.preventDefault();
      const name = document.getElementById("name").value;
      const email = document.getElementById("email").value;
      const tel = document.getElementById("tel").value;
      const howFind = document.getElementById("howFind").value;
      const password = document.getElementById("password").value;
      const confirmPassword = document.getElementById("confirmPassword").value;
      const agreeTerms = document.getElementById("agreeTerms");
      const passwordValid = this.verifyPassword(password);
      if (this.boxOthers == true) {
        var otherMedia = document.getElementById("other").value;
      }

      if (!name) {
        this.msgError = "Preencha todos os dados obrigatórios!";
      } else if (!email) {
        this.msgError = "Preencha todos os dados obrigatórios!";
      } else if (!tel) {
        this.msgError = "Preencha todos os dados obrigatórios!";
      } else if (howFind == "") {
        this.msgError = "Preencha todos os dados obrigatórios!";
      } else if (this.boxOthers == true && !otherMedia) {
        this.msgError = "Preencha todos os dados obrigatórios!";
      } else if (!password) {
        this.msgError = "Crie sua senha!";
      } else if (!confirmPassword) {
        this.msgError = "Confirme sua senha!";
      } else if (passwordValid == false) {
        this.showMsgPassword();
      } else if (password != confirmPassword) {
        this.msgError = "As senhas não correspondem";
      } else if (!agreeTerms.checked) {
        this.msgError = "Aceite os termos de uso para se cadastrar";
      } else {
        this.sendData();
      }
    },
    sendData: function () {
      const name = document.getElementById("name").value;
      const email = document.getElementById("email").value;
      const tel = document.getElementById("tel").value;
      const password = document.getElementById("password").value;
      if (this.boxOthers == true) {
        const otherMedia = document.getElementById("other").value;
        var howFind = otherMedia;
      } else {
        howFind = document.getElementById("howFind").value;
      }

      api
        .post("/register", {
          name: name,
          email: email,
          password: password,
          phoneNumber: tel,
          howFindUs: howFind,
          roleType: "User",
          useTerms: true,
        })
        .then(
          (response) => {
            this.idUser = response.data.user.id;
            this.$router.push("login-cliente");
            console.log(response);
          },
          (error) => {
            console.log(error);
            this.msgError = "Erro";
          }
        );
    },
    verifyPassword: function (password) {
      if (password.length >= 6) {
        if (/[0-9]/.test(password)) {
          if (/[a-z]/.test(password) || /[A-Z]/.test(password)) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    editTel: function () {
      var tel = document.getElementById("tel").value;

      if (tel.length >= 11) {
        const ddd = tel.slice(0, 2);
        const part1 = tel.slice(2, 7);
        const part2 = tel.slice(7, 11);
        if (
          isNaN(ddd) == true ||
          isNaN(part1) == true ||
          isNaN(part2) == true
        ) {
          document.getElementById("tel").value = "(11)99999-9999";
        } else {
          tel = `(${ddd})${part1}-${part2}`;
          document.getElementById("tel").value = tel;
        }
      } else if (tel.length == 10) {
        const ddd = tel.slice(0, 2);
        const part1 = tel.slice(2, 6);
        const part2 = tel.slice(6, 10);
        if (
          isNaN(ddd) == true ||
          isNaN(part1) == true ||
          isNaN(part2) == true
        ) {
          document.getElementById("tel").value = "(11)99999-9999";
        } else {
          tel = `(${ddd})${part1}-${part2}`;
          document.getElementById("tel").value = tel;
        }
      }
    },
  },
};
</script>
<style scoped>
#inputSpecialty {
  margin-left: 35%;
}
.labelFormation {
  font-size: 10px;
}
legend {
  font-size: 15px;
  font-weight: bold;
}
label {
  font-size: 14px;
}
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background-clip: content-box;
  background-color: #d3d3d3;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 2px;
}

input[type="checkbox"]:checked {
  background-color: #983e7b;
}

.labelArea {
  position: relative;
  bottom: 2px;
}
.button {
  background-color: #983e7b;
  color: white;
  border-radius: 10px;
  border-color: transparent;
  text-align: center;
  width: 40px;
  cursor: pointer;
}
#resume {
  resize: none;
  border-color: #983e7b;
  height: 377px;
  border-radius: 10px;
  width: 100%;
}
.input2 {
  border-color: #983e7b;
  height: 50px;
  border-radius: 10px;
  width: 100%;
  border-width: 1px;
}
.input1 {
  border-width: 1px;
  border-color: #983e7b;
  height: 50px;
  border-radius: 10px;
  width: 100%;
}
#form {
  margin-left: 5%;
  margin-right: 5%;
}

button {
  background-color: #983e7b;
  color: white;
  border-radius: 10px;
  border-color: transparent;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}
#formConsumer {
  width: 65%;
  background-color: #983e7b17;
  height: 100%;
}
#message {
  width: 35%;
  background-color: white;
  height: 100%;
}
#rowConsumer {
  width: 100%;
  margin: 0px;
  height: 750px;
}

input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background-clip: content-box;
  background-color: #d3d3d3;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 5px;
}

input[type="radio"]:checked {
  background-color: #983e7b;
}

.labelSpecialty {
  position: relative;
  bottom: 2px;
}

#textDescription {
  color: grey;
  text-align: center;
  font-size: 20px;
}
h2 {
  color: black;
  text-align: center;
}
#imgLogo {
  width: 320px;
  height: 120px;
}
#imgConsumer {
  width: 400px;
}
</style>