var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('nav',{attrs:{"id":"sidebar"}},[_vm._m(0),_c('ul',{staticClass:"list-unstyled components"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('li',{on:{"click":function($event){return _vm.changePage()}}},[_vm._m(5)]),_vm._m(6),_vm._m(7),_c('li',{on:{"click":function($event){return _vm.back()}}},[_vm._m(8)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar-header"},[_c('img',{attrs:{"width":"200px","src":require("../../imgs/logo_branco.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',[_c('img',{staticClass:"link",staticStyle:{"margin-right":"14px"},attrs:{"width":"24px","src":require("../../imgs/dashboard.png")}}),_vm._v("Dashboard")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',[_c('img',{staticClass:"link",staticStyle:{"margin-right":"14px"},attrs:{"width":"24px","src":require("../../imgs/profissional.png")}}),_vm._v("Meu perfil")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"dropdown-toggle",attrs:{"href":"#pageSubmenu2","data-toggle":"collapse","aria-expanded":"false"}},[_c('img',{staticClass:"link",staticStyle:{"margin-right":"24px"},attrs:{"width":"24px","src":require("../../imgs/clientes2.png")}}),_vm._v("Clientes")]),_c('ul',{staticClass:"collapse list-unstyled",staticStyle:{"text-align":"justify"},attrs:{"id":"pageSubmenu2"}},[_c('li',[_c('a',{attrs:{"href":"#"}},[_c('img',{staticClass:"sublink",staticStyle:{"margin-right":"14px"},attrs:{"width":"24px","src":require("../../imgs/pessoa-lendo.png")}}),_vm._v("Pacientes")])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_c('img',{staticClass:"sublink",staticStyle:{"margin-right":"14px"},attrs:{"width":"22px","src":require("../../imgs/empresario.png")}}),_vm._v("Funcionários")])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_c('img',{staticClass:"sublink",staticStyle:{"margin-right":"14px"},attrs:{"width":"22px","src":require("../../imgs/companhia.png")}}),_vm._v("Empresas")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',[_c('img',{staticClass:"link",staticStyle:{"margin-right":"14px"},attrs:{"width":"22px","src":require("../../imgs/agenda.png")}}),_vm._v("Minha agenda")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('img',{staticClass:"link",staticStyle:{"margin-right":"10px"},attrs:{"src":require("../../imgs/assessment.png")}}),_vm._v("Ferramentas")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',[_c('img',{staticClass:"link",staticStyle:{"margin-right":"10px"},attrs:{"src":require("../../imgs/tutorial.png")}}),_vm._v("Tutorial")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',[_c('img',{staticClass:"link",staticStyle:{"margin-right":"10px"},attrs:{"src":require("../../imgs/roda.png")}}),_vm._v("Configurações")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('img',{staticClass:"link",staticStyle:{"margin-right":"10px"},attrs:{"src":require("../../imgs/sair.png")}}),_vm._v("Sair")])
}]

export { render, staticRenderFns }