<template>
    <div style="width:82%;position:absolute;left:18%;top:0%;margin-left:0%;"> 
        <br>
        <br>
        
        <div id="boxAssessment" >
            <br>
            <h1 class="text-center">Avaliando a motivação</h1>
            <br>
            <br>
            <h5>Quando penso em meu objetivo, a frase que me vem à cabeça é:</h5>
            <input v-on:click="calcResult(1,'A')" class="A" name="question1" type="radio">
            <label>&nbsp;&nbsp;a) Eu tenho de alcançar esse objetivo.</label><br>
            <input v-on:click="calcResult(1,'B')" class="B" name="question1" type="radio">
            <label>&nbsp;&nbsp;b) Eu devo alcançar esse objetivo.</label><br>
            <input v-on:click="calcResult(1,'C')" class="C" name="question1"  type="radio">
            <label>&nbsp;&nbsp;c) Será bom pra mim alcançar esse objetivo.</label><br>
            <input v-on:click="calcResult(1,'D')" class="D" name="question1"  type="radio">
            <label>&nbsp;&nbsp;d) Eu quero alcançar esse objetivo.</label><br>
            <input v-on:click="calcResult(1,'E')" class="E" name="question1"  type="radio">
            <label>&nbsp;&nbsp;e) Eu me identifico totalmente com esse objetivo.</label><br>
            <hr>
            <br>
            <br>
            <h5>Pensar em meu objetivo provoca em mim as seguintes sensações:</h5>
            <input v-on:click="calcResult(2,'A')" class="A" name="question2" type="radio">
            <label>&nbsp;&nbsp;a) Fico animado pensando no que pode acontecer se eu conseguir/desanimado pensando no que pode acontecer.</label><br>
            <input v-on:click="calcResult(2,'B')" class="B" name="question2"  type="radio">
            <label>&nbsp;&nbsp;b) Aliviado se eu conseguir/culpado ou envergonhado se eu não conseguir.</label><br>
            <input v-on:click="calcResult(2,'C')" class="C" name="question2"  type="radio">
            <label>&nbsp;&nbsp;c) Sinto que vai ser útil para mim se eu conseguir, pois alcançar esse objetivo pode me trazer determinadas vantagens que eu estou buscando.</label><br>
            <input v-on:click="calcResult(2,'D')" class="D" name="question2"  type="radio">
            <label>&nbsp;&nbsp;d) Sinto me disposto a assumir essa responsabilidade, porque esse objetivo é peça fundamental para meus planos.</label><br>
            <input v-on:click="calcResult(2,'E')" class="E" name="question2"  type="radio">
            <label>&nbsp;&nbsp;e) Sinto um grande entusiasmo. Pensar em meu objetivo me deixa intensamente energizado.</label><br>
            <hr>
            <br>
            <br>
            <h5>A principal razão pela qual persigo esse objetivo é:</h5>
            <input v-on:click="calcResult(3,'A')" class="A" name="question3" type="radio">
            <label>&nbsp;&nbsp;a) Fico animado pensando no que pode acontecer se eu conseguir/desanimado pensando no que pode acontecer.</label><br>
            <input v-on:click="calcResult(3,'B')" class="B" name="question3"  type="radio">
            <label>&nbsp;&nbsp;b) Aliviado se eu conseguir/culpado ou envergonhado se eu não conseguir.</label><br>
            <input v-on:click="calcResult(3,'C')" class="C" name="question3"  type="radio">
            <label>&nbsp;&nbsp;c) Sinto que vai ser útil para mim se eu conseguir, pois alcançar esse objetivo pode me trazer determinadas vantagens que eu estou buscando.</label><br>
            <input v-on:click="calcResult(3,'D')" class="D" name="question3"  type="radio">
            <label>&nbsp;&nbsp;d) Sinto me disposto a assumir essa responsabilidade, porque esse objetivo é peça fundamental para meus planos.</label><br>
            <input v-on:click="calcResult(3,'E')" class="E" name="question3"  type="radio">
            <label>&nbsp;&nbsp;e) Sinto um grande entusiasmo. Pensar em meu objetivo me deixa intensamente energizado.</label><br>
            <hr>
            <br>
            <br>
            <h5>Meu objetivo se insere no contexto...</h5>
            <input v-on:click="calcResult(4,'A')" class="A" name="question4" type="radio">
            <label>&nbsp;&nbsp;a) Das coisas que eu tenho de fazer.</label><br>
            <input v-on:click="calcResult(4,'B')" class="B" name="question4"  type="radio">
            <label>&nbsp;&nbsp;b) Das coisas que eu devo fazer.</label><br>
            <input v-on:click="calcResult(4,'C')" class="C" name="question4"  type="radio">
            <label>&nbsp;&nbsp;c) Das coisas que podem ser boas para mimse eu fizer.</label><br>
            <input v-on:click="calcResult(4,'D')" class="D" name="question4"  type="radio">
            <label>&nbsp;&nbsp;d) Das coisas que eu quero fazer.</label><br>
            <input v-on:click="calcResult(4,'E')" class="E" name="question4"  type="radio">
            <label>&nbsp;&nbsp;e) Das coisas que eu amo fazer.</label><br>
            <hr>
            <br>
            <br>
            <h5>Meu objetivo atende...</h5>
            <input v-on:click="calcResult(5,'A')" class="A" name="question5" type="radio">
            <label>&nbsp;&nbsp;a) A necessidade de realizar ou completar algo que me deram para fazer.</label><br>
            <input v-on:click="calcResult(5,'B')" class="B" name="question5"  type="radio">
            <label>&nbsp;&nbsp;b) A necessidade de realizar ou completar algo que me sinto obrigado a fazer.</label><br>
            <input v-on:click="calcResult(5,'C')" class="C" name="question5"  type="radio">
            <label>&nbsp;&nbsp;c) Ao meu interesse em fazer algo que pode ser bom pra mim.</label><br>
            <input v-on:click="calcResult(5,'D')" class="D" name="question5"  type="radio">
            <label>&nbsp;&nbsp;d) A minha vontade de fazer algo que vai ajudar a atingir determinado fim.</label><br>
            <input v-on:click="calcResult(5,'E')" class="E" name="question5"  type="radio">
            <label>&nbsp;&nbsp;e) Ao meu intenso desejo de realizar algo que me trará prazer e realização.</label><br>
            <hr>
            <br>
            <p>Resultado: {{msg}}</p>
           
            <br>
            <br>
            <table id="tableAssessment">
                <tr>
                    <th colspan="5">motivação extrínseca</th>
                    <th>motivação intrínseca</th>
                </tr>
                <tr>
                    <td >Estilo regulatório</td>
                    <td class="2">Regulação externa</td>
                    <td class="3">Regulação introjetada</td>
                    <td class="4">Regulação Identificada</td>
                    <td class="5">Regulação integrada</td>
                    <td class="6">Regulação intrínseca</td>
                </tr>
                <tr>
                    <td >Controle percebido</td>
                    <td  class="2">Externo</td>
                    <td class="3">Mais externo do que interno</td>
                    <td class="4">Mais interno do que externo</td>
                    <td class="5">interno</td>
                    <td class="6">interno</td>

                </tr>
                <tr>
                    <td >Escolha</td>
                    <td class="2">Pouco ou não assumida</td>
                    <td class="3">Pouco ou não assumida</td>
                    <td class="4">Assumida</td>
                    <td class="5">Assumida</td>
                    <td class="6">Claramente assumida</td>

                </tr>
                <tr>
                    <td >Processos regulatórios</td>
                    <td class="2">Conformidade, dependência de punições e recompensas</td>
                    <td class="3">Ansiedade, conflitos internos Dependência de punições e recompensas</td>
                    <td class="4">Importância pessoal valorização consciente</td>
                    <td class="5">Congruência, autoconhecimento síntese com o "eu interior"</td>
                    <td class="6">Interesse, prazer, satisfação, vontade</td>

                </tr>
                <tr>
                    <td >Ownership</td>
                    <td class="2">Ausente</td>
                    <td class="3">Ausente</td>
                    <td class="4">Presente ou parcialmente presente</td>
                    <td class="5">Presente</td>
                    <td class="6">Presente</td>

                </tr>
                <tr>
                    <td >Autodeterminação</td>
                    <td class="2">Extremamente baixa</td>
                    <td class="3">baixa</td>
                    <td class="4">relativamente elevada</td>
                    <td class="5">elevada</td>
                    <td class="6">extremamente elevada</td>

                </tr>
                <tr>
                    <td>Frase típica</td>
                    <td class="2">"Tenho de fazer isso"</td>
                    <td class="3">"devo fazer isso"</td>
                    <td class="4">"será bom para mim se eu fizer"</td>
                    <td class="5">"quero fazer isso"</td>
                    <td class="6">"amo fazer isso"</td>

                </tr>
            </table>
           
            <br>
            <br>
            <div style="display: flex; justify-content: center;"> 
                 <button class="btn" >Salvar resultados</button>
            </div>
            <br>
            <br>
        </div>   
    </div>
</template>
<script>
export default {
    
    methods:
    {
        verifyResults: function()
        {   
            var answers = [this.answerQ1, this.answerQ2,this.answerQ3, this.answerQ4, this.answerQ5];
            
            for(var i=0; i<=4;i++)
            {
                if(answers[i] == 'A')
                {
                    this.selectA = this.selectA+1;
                }
                else
                    if(answers[i] == 'B')
                    {
                        this.selectB= this.selectB+1;
                    }
                    else
                        if(answers[i] == 'C')
                        {
                            this.selectC= this.selectC+1;
                        }
                        else
                            if(answers[i] == 'D')
                            {
                                this.selectD= this.selectD+1;
                            }
                            else
                                if(answers[i] == 'E')
                                {
                                    this.selectE= this.selectE+1;
                                }
                                

            }
            var equal = false;
            if(this.selectA == 2 && this.selectB == 2)
            {
                this.highest = 'A';
                equal=true;
            }
            else
                if(this.selectA == 2 && this.selectC == 2)
                {
                    this.highest = 'A';
                    equal=true;
                }
                else
                    if(this.selectA == 2 && this.selectD == 2)
                    {
                        this.highest = 'A';
                        equal=true;
                    }
                    else
                        if(this.selectA == 2 && this.selectE == 2)
                        {
                            this.highest = 'A';
                            equal=true;
                        }
                        else
                            if(this.selectB == 2 && this.selectC == 2)
                            {
                                this.highest = 'B';
                                equal=true;
                            }
                            else
                                if(this.selectB == 2 && this.selectD == 2)
                                {
                                    this.highest = 'B';
                                    equal=true;
                                }
                                else
                                    if(this.selectB == 2 && this.selectE == 2)
                                    {
                                        this.highest = 'B';
                                        equal=true;
                                    }
                                    else
                                        if(this.selectC == 2 && this.selectD == 2)
                                        {
                                            this.highest = 'C';
                                            equal=true;
                                        }
                                        else
                                            if(this.selectC == 2 && this.selectE == 2)
                                            {
                                                this.highest = 'C';
                                                equal=true;
                                            }
                                            else
                                                if(this.selectD == 2  && this.selectE == 2)
                                                {
                                                    this.highest = 'D';
                                                    equal=true;
                                                }
                                                else
                                                    if(this.selectA == 1  && this.selectB == 1 && this.selectC == 1  && this.selectD == 1 && this.selectE ==1)
                                                    {
                                                        this.highest = 'E';
                                                        equal=true;
                                                    }
            if(equal == false)
            {
                if(this.selectA>this.selectB && this.selectA>this.selectC && this.selectA>this.selectD && this.selectA>this.selectE )
                {
                    this.highest = 'A';
                }
                else
                {
                    if(this.selectB>this.selectA && this.selectB>this.selectC && this.selectB>this.selectD && this.selectB>this.selectE)
                    {
                        this.highest = 'B';
                    }
                    else
                    {
                        if(this.selectC>this.selectA && this.selectC>this.selectB && this.selectC>this.selectD && this.selectC>this.selectE)
                        {
                                this.highest = 'C';
                        }
                        else
                        {
                            if(this.selectD>this.selectA && this.selectD>this.selectC && this.selectD>this.selectB && this.selectD>this.selectE)
                            {
                                this.highest = 'D';
                            }
                            else
                            {
                                if(this.selectE>this.selectA && this.selectE>this.selectB && this.selectE>this.selectC && this.selectE>this.selectD)
                                {
                                    this.highest = 'E';
                                }
                            }
                        }
                    }
                }
            }
            
            this.selectA=0;
            this.selectB=0;
            this.selectC=0;
            this.selectD=0;
            this.selectE=0;
         
            
        },
        calcResult: function(question, answer)
        {
            if(question == 1)
            {
                this.answerQ1 = answer;
            }
            else
                if(question == 2)
                {
                    this.answerQ2 = answer;
                }
                else
                    if(question == 3)
                    {
                        this.answerQ3 = answer;
                    }
                    else
                        if(question == 4)
                        {
                            this.answerQ4 = answer;
                        }
                        else
                            if(question == 5)
                            {
                                this.answerQ5 = answer;
                            }
            this.verifyResults();
            this.showTextResult();
            this.paintTable();
            
        },
       
        paintTable: function()
        {

            
                
                var nCol;
                if(this.highest == 'A')
                {
                    nCol = 2;
                }
                else
                        if(this.highest == 'B')
                        {
                            nCol = 3;
                        }
                        else
                            if(this.highest == 'C')
                            {
                            nCol = 4;
                            }
                            else
                                if(this.highest == 'D')
                                {
                                nCol = 5;
                                }
                                else    
                                    if(this.highest == 'E')
                                    {
                                        nCol = 6;
                                    }
            if(nCol != this.previowCol)
            {
                var col = document.getElementsByClassName(nCol);
                for(var i=0;i<7;i++)
                {
                    col[i].style.backgroundColor="#983e7b";
                    col[i].style.color="white";
                }
                if(this.previowCol!= 0)
                {
                    var pCol = document.getElementsByClassName(this.previowCol);
                    for(i=0;i<7;i++)
                    {
                            pCol[i].style.backgroundColor="white";
                            pCol[i].style.color="black";
                    } 
                }
                this.previowCol = nCol;
            }
          
        },
        showTextResult: function()
        {
                
                if(this.highest == 'A')
                {
                    this.msg = "Maioria das respostas com alternativas A - indicam motivação extrínseca com regulação externa";
                }
                else
                    if(this.highest == 'B')
                    {
                        this.msg = "Maioria das respostas com alternativas B - indicam motivação extrínseca com regulação introjetada";
                    }
                    else
                        if(this.highest == 'C')
                        {
                            this.msg = "Maioria das respostas com alternativas C - indicam motivação extrínseca com regulação identificada";
                        }
                        else
                            if(this.highest == 'D')
                            {
                                this.msg = "Maioria das respostas com alternativas D - indicam motivação extrínseca com regulação integrada";
                            }
                            else
                                if(this.highest == 'E')
                                {
                                    this.msg = "Maioria das respostas com Alternativas E - indicam motivação intrínseca";
                                }
                           
            
        }
    },
    
    data()
    {
        return{
            msg:"",
            highest:"",
            answerQ1:"",
            selectA:0,
            selectB:0,
            selectC:0,
            selectD:0,
            selectE:0,
            previowCol:0
        }
        

    }
}
</script>
<style scoped>
table tr td
{
    text-align: center;
    width:200px;
    border-color:#D3D3D3;
    border-style: solid;
     
    border-width: 1px;
}
table tr th
{
    
    
    background-color: #D3D3D3;
    border-width: 1px;
    text-align: center;
    width:200px;
}

input
{
    margin-bottom: -2px;
}
input[type="radio"] {

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  
 
  width: 15px;
  height: 15px;
  

  background-clip: content-box;
  
  background-color: #D3D3D3;
  border-radius: 50%;
}


input[type="radio"]:checked {
  background-color: #983e7b;
  
}



h2
{
    text-align:justify;
}    
h1
{
    
    text-align:center;  
}
h5
{
    text-align:justify;    
}
p
{
    text-align: justify;
}
*
{
    font-family: roboto;
}

#boxAssessment
{
    padding-right:2%;
    padding-left:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    
    color: black;
}

.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
a:hover{
    color:#fec961;
}

</style>

