<template>
   <div  class="modal fade" id="modalConsumer"  >    
            <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Complete seu perfil</h5>
                    
                </div>

                 <div class="modal-body">
                    <span>{{msgError}}</span>
                    <form>
                        <div v-if="part==1">
                                <div class="row">
                                    <div class="col form-group">
                                        <label for="recipient-name" class="col-form-label">Data de nascimento:</label>
                                        <input v-on:blur="calculateAge()" type="date" class="form-control" id="date">
                                    </div>
                                
                                    <div class="col form-group">
                                        <label for="message-text" class="col-form-label">CPF:</label>
                                        <input type="text" autocomplete="off" v-on:keyup="putMaskCpf($event)" class="form-control" id="cpf">
                                    </div>
                                </div>

                                <div class="row" v-if="age<18">
                                    <div class="col form-group">
                                        <label for="recipient-name" class="col-form-label">Nome do responsável:</label>
                                        <input type="text" class="form-control" id="nameResponsible">
                                    </div>
                                
                                    <div class="col form-group">
                                        <label for="message-text" class="col-form-label">Telefone do responsável:</label>
                                        <input type="text" class="form-control" id="telResponsible">
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col form-group">
                                        <label for="recipient-name" class="col-form-label">CEP:</label>
                                        <input type="text" v-on:keyup="putMaskCep($event)" v-on:blur="completeAddress()" class="form-control" id="cep">
                                    </div>
                                
                                    <div class="col form-group">
                                        <label for="message-text" class="col-form-label">Cidade:</label>
                                        <input type="text" class="form-control" id="city">
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col form-group">
                                        <label for="recipient-name" class="col-form-label">Estado:</label>
                                        <select class="custom-select input2" id="state" style="color:gray;" required="required">
                                                    <option  style="color:gray;" v-for="op in states" :key="op.uf">
                                                        {{ op.uf }}
                                                    </option>
                                        </select>   
                                    </div>
                                
                                    <div class="col form-group">
                                        <label for="message-text" class="col-form-label">Rua:</label>
                                        <input type="text" class="form-control" id="street">
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-6 form-group">
                                        <label for="recipient-name" class="col-form-label">Bairro:</label>
                                        <input type="text" class="form-control" id="district">
                                    </div>
                                    
                                    <div class="col-2 form-group">
                                        <label for="recipient-name" class="col-form-label">Número:</label>
                                        <input type="text" class="form-control" id="number">
                                    </div>

                                    <div class="col-4 form-group">
                                        <label for="message-text" class="col-form-label">Complemento:</label>
                                        <input type="text" class="form-control" id="complement">
                                    </div>
                                </div>
                        </div>
                        <div v-if="part==2">
                            <div class="row">
                                <div class="col form-group">
                                    <label for="recipient-name" class="col-form-label">Estado Civil:</label>
                                    <select  class="custom-select input1" id="maritalStatus" style="color:gray;"  required="required">
                                        <option  style="color:gray;" v-for="op in maritalStatus" :key="op.status">
                                            {{ op.status }}
                                        </option>
                                    </select>
                                </div>
                            
                                <div class="col form-group">
                                    <label for="message-text" class="col-form-label">Formação:</label>
                                    <select class="custom-select input2" id="formation" style="color:gray;" required="required">
                                                <option  style="color:gray;" v-for="op in formation" :key="op.level">
                                                    {{ op.level }}
                                                </option>
                                    </select>    
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="message-text" class="col-form-label">Profissão:</label>
                                <input type="text" class="form-control" id="profession">
                            </div>

                            <div class="form-group">
                                <label for="message-text" class="col-form-label">Nome da mãe:</label>
                                <input type="text" class="form-control" id="motherName">
                            </div>

                            <div class="form-group">
                                <label for="message-text" class="col-form-label">Principais objetivos que deseja alcançar:</label>
                                <textarea class="form-control" id="goals" ></textarea>
                            </div>
                            <br>
                        </div>
                        <div v-if="part==3">
                            <p style="font-size:10px;">Escolha a sua foto de perfil</p>
                            <div style="width:20px">
                                <input  type="file" id="filePic" accept="image/*" v-on:change="previewImage($event)">
                            </div>
                            
                            
                            <div style="margin-top:10px;display: flex; justify-content: center;"> 
                                <img height="400px" width="500px"  src="" id="output_image"/>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="modal-footer">
                    <button type="button" v-on:click="verifyForm()" id="submit" class="btn">{{msg}}</button>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossorigin="anonymous"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js" integrity="sha384-ZMP7rVo3mIykV+2+9J3UJ46jBk0WLaUAdn689aCwoqbBJiSnjAK/l8WvCWPIPm49" crossorigin="anonymous"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/js/bootstrap.min.js" integrity="sha384-ChfqqxuZUCnJSK3+MXmPNIyE6ZbWh2IMqE241rYiqJxyMiZ6OW/JmZQ5stwEULTy" crossorigin="anonymous"></script>
  
<script>
import api from '../services/api.js';
import axios from 'axios';
export default {
    data()
    {
        return{
            userId:"",
            complement:"",
            date:"",
            cpf:"",
            cep:"",
            city:"",
            stateSelected:"",
            street:"",
            district:"",
            number:"",
            nameResponsible:"",
            telResponsible:"",
            msgError:"",
            msg:"Continuar",
            part:1,
            date:"",
            age:"18",
            maritalStatusSelected:"",
            formationSelected:"",
            profession:"",
            motherName:"",
            goals:"",
            maritalStatus:[
                {status: 'Solteiro(a)'},
                {status: 'Casado(a)'},
                {status: 'Divorciado(a)'},
                {status: 'União Estável'},
                {status: 'Viuvo(a)'}
             ],
             states: [
              {uf: 'SP'},
              {uf: 'RJ'},
              {uf: 'ES'},
              {uf: 'MG'},
              {uf: 'BA'},
              {uf: 'SE'},
              {uf: 'PE'},
              {uf: 'AL'},
              {uf: 'PB'},
              {uf: 'RN'},
              {uf: 'CE'},
              {uf: 'PI'},
              {uf: 'MA'},
              {uf: 'PA'},
              {uf: 'AP'},
              {uf: 'AM'},
              {uf: 'RR'},
              {uf: 'AC'},
              {uf: 'DF'},
              {uf: 'GO'},
              {uf: 'RO'},
              {uf: 'TO'},
              {uf: 'MT'},
              {uf: 'MS'},
              {uf: 'PR'},
              {uf: 'SC'},
              {uf: 'RS'},
              
            ],
            formation:[
                {level: 'Ensino fundamental incompleto'},
                {level: 'Ensino fundamental completo'},
                {level: 'Ensino médio incompleto'},
                {level: 'Ensino médio completo'},
                {level: 'Ensino superior incompleto'},
                {level: 'Ensino superior completo'},
             ],
        }
    },
    mounted()
    {
        
        this.openModal();
    },
    methods:
    {
        
        previewImage: function(event)
        {
            this.file=true;
            if(event.target.files.length > 0)
            {
                var src = URL.createObjectURL(event.target.files[0]);
                var preview = document.getElementById("output_image");
                preview.src = src;
                preview.style.display = "block";
            }
        },
        verifyForm: function()
        {
            if(this.part==1)
            {
                this.verifyPart1();
            }
            else
                if(this.part==2)
                {
                    this.verifyPart2();
                }
                else 
                    if(this.part==3)
                    {
                        this.sendData();
                    }
        },
        nextPart: function()
        {
            this.part= this.part+1;
            this.msgError="";
            
            if(this.part==3)
                this.msg="Confirmar";
            
        },
        sendData: function()
        {
            this.userId = localStorage.getItem('userId');
            const filePic = document.getElementById("filePic").value;
            
            
            api.put('/user-person-update',{
                                    
                                       

                                            userID:this.userId,
                                            cpf:this.cpf,
                                            birthDate: this.date,
                                            formation: this.formationSelected,
                                            maritalStatus: this.maritalStatusSelected,
                                            motherName: this.motherName,
                                            job: this.profession,
                                            objectives: this.goals,
                                            address: this.street,
                                            houseNumber: this.number,
                                            neighborhood: this.district,
                                            city:this.city,
                                            state:this.stateSelected,
                                            zip:this.cep,
                                            complement:this.complement,
                                            sponsor:this.nameResponsible,
                                            sponsorContact: this.telResponsible,
                                            profilePic:filePic

                            }).then((response) => {

                                        console.log(response);
                                        localStorage.setItem('completed', true);
                                        //document.location.reload(true);
                                        this.closeModal();
                                    }, (error) => {
                                        
                                        console.log(error);
                                        
                                        
            });
        },
        openModal: function()
        {
            
            $('#modalConsumer').modal('show');
        },
        closeModal: function()
        {
            
            $('#modalConsumer').modal('hide');
        },
        verifyPart1: function()
        {
            this.complement = document.getElementById("complement").value;
            this.date = document.getElementById("date").value;
            this.cpf = document.getElementById("cpf").value;
            this.cep = document.getElementById("cep").value;
            this.city = document.getElementById("city").value;
            this.stateSelected = document.getElementById("state").value;
            this.street = document.getElementById("street").value;
            this.district = document.getElementById("district").value;
            this.number = document.getElementById("number").value;
            this.nameResponsible = "";
            this.telResponsible= "";
            if(this.age < 18)
            {
               
                this.telResponsible = document.getElementById("telResponsible").value;
                this.nameResponsible =  document.getElementById("nameResponsible").value;
                
                
                
                if(!this.date)
                {
                        this.msgError ="Preencha todos os dados obrigatórios!";
                }
                else
                    if(!this.cpf)
                    {
                        this.msgError ="Preencha todos os dados obrigatórios!";
                    }
                    else
                        if(this.nameResponsible == "" )
                        {
                            
                            this.msgError ="Preencha todos os dados obrigatórios!";
                        }
                        else
                            if(this.telResponsible == "")
                            { 
                                this.msgError="Preencha todos os dados obrigatórios";
                            }
                            else
                                if(!this.cep)
                                {
                                    this.msgError ="Preencha todos os dados obrigatórios!";
                                }
                                else
                                    if(!this.city)
                                    {
                                        this.msgError ="Preencha todos os dados obrigatórios!";
                                    }
                                    else
                                        if(!this.stateSelected)
                                        {
                                            this.msgError ="Preencha todos os dados obrigatórios!";
                                        }
                                        else
                                            if(!this.street) 
                                            {
                                                this.msgError ="Preencha todos os dados obrigatórios!";
                                            }
                                            else
                                                if(!this.district)
                                                {
                                                    this.msgError ="Preencha todos os dados obrigatórios!";
                                                }
                                                else
                                                    if(!this.number)
                                                    {
                                                        this.msgError ="Preencha todos os dados obrigatórios!";
                                                    }
                                                    else
                                                        if(this.age <12)
                                                        {
                                                            this.msgError = "Só é aceito maiores de 12 anos de idade";
                                                        }
                                                        else 
                                                            if(this.age>100)
                                                            {
                                                                this.msgError = "Idade inválida";
                                                            }
                                                            else
                                                            {
                                                                this.nextPart(); 
                                                            }
            }
            else
            {
                
                if(!this.date)
                {
                        this.msgError ="Preencha todos os dados obrigatórios!";
                }
                else
                    if(!this.cpf)
                    {
                        this.msgError ="Preencha todos os dados obrigatórios!";
                    }
                    else
                            if(!this.cep)
                            {
                                    this.msgError ="Preencha todos os dados obrigatórios!";
                            }
                            else
                                if(!this.city)
                                {
                                    this.msgError ="Preencha todos os dados obrigatórios!";
                                }
                                else
                                    if(!this.stateSelected)
                                    {
                                        this.msgError ="Preencha todos os dados obrigatórios!";
                                    }
                                    else
                                        if(!this.street) 
                                        {
                                            this.msgError ="Preencha todos os dados obrigatórios!";
                                        }
                                        else
                                            if(!this.district)
                                            {
                                                this.msgError ="Preencha todos os dados obrigatórios!";
                                            }
                                            else
                                                if(!this.number)
                                                {
                                                    this.msgError ="Preencha todos os dados obrigatórios!";
                                                }
                                                else
                                                    if(this.age <12)
                                                    {
                                                        this.msgError = "Só é aceito maiores de 12 anos de idade";
                                                    }
                                                    else 
                                                        if(this.age>100)
                                                        {
                                                            this.msgError = "Idade inválida";
                                                        }
                                                        else
                                                        {
                                                            this.nextPart(); 
                                                        }
            }
        },
        verifyPart2: function()
        {
            
            this.maritalStatusSelected = document.getElementById("maritalStatus").value;
            this.formationSelected = document.getElementById("formation").value;
            this.profession  = document.getElementById("profession").value;
            this.motherName = document.getElementById("motherName").value;
            this.goals = document.getElementById("goals").value;

            if(!this.maritalStatusSelected)
            {
                this.msgError ="Preencha todos os dados obrigatórios!";
            }
            else
                if(!this.formationSelected)
                {
                    this.msgError ="Preencha todos os dados obrigatórios!";
                }
                else
                   if(!this.profession)
                   {
                       this.msgError ="Preencha todos os dados obrigatórios!";
                   }
                   else
                        if(!this.motherName)
                        {
                            this.msgError ="Preencha todos os dados obrigatórios!";
                        }
                        else
                            if(!this.goals)
                            {
                                this.msgError="Preencha todos os dados obrigatórios!";
                            }
                            else
                            {
                                
                                this.nextPart();
                            }
        },
        putMaskCep: function(event)
        {
            if(event.keyCode != 8)
            {
                this.cep = document.getElementById("cep").value;
                if(this.cep.length==5)
                {
                    document.getElementById("cep").value=this.cep+'-';
                }
                
            }
        },
        calculateAge: function()
        {
            
                this.date = document.getElementById("date").value;
                var partDate = this.date.split("-");
                var year = parseInt(partDate[0]);
                var month = parseInt(partDate[1]);
                var day = parseInt(partDate[2]);
                var currentYear = parseInt(new Date().getFullYear());
                var currentMonth = parseInt(new Date().getMonth()+1);
                var currentDay = parseInt(new Date().getDate());
                this.date=`${year}-${month}-${day}`;
                this.age = currentYear - year;
                var remainingMonths = currentMonth - month;
                var remainingDays = currentDay - day;
                if(remainingMonths < 0)
                {
                        this.age= this.age -1;
                }
                else
                        if(remainingDays < 0)
                        {
                            this.age = this.age-1;
                        }
        },
        completeAddress: function()
        {
            
            const cep = document.getElementById("cep").value;
            const url = `https://viacep.com.br/ws/${cep}/json/`;
            if(this.cep)
            {
                
                axios.get(url)
                    .then(response => {
                        console.log(response);
                        if(response.data.localidade)
                        {
                            this.currentCity =  response.data.localidade;
                            document.getElementById("city").value = this.currentCity;
                        }

                        if(response.data.uf)
                        {
                            this.currentState = response.data.uf;
                            document.getElementById("state").value = this.currentState;
                        }
                        
                        if(response.data.logradouro)
                        {
                            this.currentStreet = response.data.logradouro;
                            document.getElementById("street").value = this.currentStreet;
                        }

                        if(response.data.bairro)
                        {
                            this.currentDistrict = response.data.bairro;
                            document.getElementById("district").value = this.currentDistrict;
                           

                        }
                        
                        
                })
                .catch(error => {
                    console.log(error)
                    this.errored = true
                })
                
            }
        },
        putMaskCpf: function(event)
        {
            if(event.keyCode != 8)
            {
                this.cpf = document.getElementById("cpf").value;
                if(this.cpf.length==3)
                {
            
                    document.getElementById("cpf").value=this.cpf+'.';
                }
                else
                    if(this.cpf.length==7)
                    {
                        document.getElementById("cpf").value=this.cpf+'.';
                    }
                    else
                        if(this.cpf.length==11)
                        {
                            document.getElementById("cpf").value=this.cpf+'-';
                        }
            }
        },
    }
}
</script>
<style scoped>
img
{
    display:none;
}
#submit
{
    background-color:#983e7b;
    color:white;
}
#modalConsumer
{
    overflow-y: hidden;
}
span
{
    font-size:10px;
    color:red;
}
.form-group
{
    height: 70px;
}
</style>