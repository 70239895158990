<template>
    <div style="height:806px;width:82%;background-color:#f7f8fc;position:absolute;left:18%">
      <br>
        <br>
        <div class="row" style="margin-left:5%">
            <div class="col-sm">
                <h6 ><b>Dashboard do Administardor - Bem-vindo (a)</b></h6>
            </div>
            <div class="col-sm" >
                <div class="row" style="margin-top:2px">
                    <div class="col">
                        <img style="width:20px;height:20px" src="../../imgs/notificacao.png">
                    </div>
                    <div class="col">
                        <div class="row" >
                            <h6 style="margin-left:50%">{{fisrtName}}</h6>
                            <img style="width:20px;height:20px;margin-left:20px" src="../../imgs/usuario-de-perfil.png">
                        </div>
                    </div>
                </div>
                
                
                   
            </div>
        </div>
        
    </div>
</template>
<script>
export default{
    mounted()
    { 
        this.fisrtName = "Giliani";
        
        
    },
    data()
    {
        return{
            userName:"",
            fisrtName:""
        }
    }
}


</script>
<style scoped>
#boxSession
{
    background-color: #983e7b; 
    color:#D3D3D3;
    opacity: 0.5;
}
#table1 
{
    width:100%;
    height:80px;
}
#table1 tr td
{
    border-style:solid;
    border-left-color:transparent;
    border-top-color:#D3D3D3;
    border-right-color:transparent;
    border-bottom-color:transparent;
    border-width: 1px;
   
    
}
#table1 tr th
{
    color:#D3D3D3;
    background-color:#983e7b; 
    font-weight: normal;
}
#set
{
    cursor: pointer;
}
table tr th
{
    font-size:12px;
    text-align:center;
}
table tr td
{
    text-align:center;
}
#boxClient
{
    border-style: solid;
    background-color: white;
    border-radius: 15px;
    border-color: #D3D3D3;
    margin-right: 8%;
    height:400px;
    border-width: 1px;
}
#boxUser
{
    border-style: solid;
    background-color: white;
    border-radius: 15px;
    border-color: #D3D3D3;
    
    width:450px;
    height:400px;
    border-width: 1px;
}

.box
{
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
    height:120px;
    width:450px;
    border-radius: 15px;
    text-align: center;
    color: black;
}

</style>