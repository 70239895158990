import { render, staticRenderFns } from "./modal-foto-perfil-especialista.vue?vue&type=template&id=49260164&scoped=true&"
import script from "./modal-foto-perfil-especialista.vue?vue&type=script&lang=js&"
export * from "./modal-foto-perfil-especialista.vue?vue&type=script&lang=js&"
import style0 from "./modal-foto-perfil-especialista.vue?vue&type=style&index=0&id=49260164&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49260164",
  null
  
)

export default component.exports