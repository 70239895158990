<template>
    <div  style="height:100%;width:82%;position:absolute;left:18%;top:0%;margin-left:0%;" > 
        <br>
        <br>
        <br>
        <div id="boxAssessment">
            <br>
            <h1 class="text-center">Roadmap</h1>
            <br>
            <h3>Planejando a rota  do final para o começo</h3>
            <br>
            <br>
            <h5>Data de hoje: {{day}}/{{month}}/{{year}}</h5>
            <br>
            <hr>
            <h5>Data da realização</h5>
            <input type="date">
            <br>
            <br>
            <h5>Ponto estratégico</h5>
            <input style="width:100%" type="text">
            <br>
            <br>
            <h5>Quais são as táticas para objetivos-meio desse ponto estratégico</h5>
            <input style="width:100%" type="text">
            <br>
            <br>
            <hr>
            <br>
            <br>
            <hr>
            <h5>Data da realização</h5>
            <input type="date">
            <br>
            <br>
            <h5>Ponto estratégico</h5>
            <input style="width:100%" type="text">
            <br>
            <br>
            <h5>Quais são as táticas para objetivos-meio desse ponto estratégico</h5>
            <input style="width:100%" type="text">
            <br>
            <br>
            <hr>
            <br>
            <br>
            <hr>
            <h5>Data da realização</h5>
            <input type="date">
            <br>
            <br>
            <h5>Ponto estratégico</h5>
            <input style="width:100%" type="text">
            <br>
            <br>
            <h5>Quais são as táticas para objetivos-meio desse ponto estratégico</h5>
            <input style="width:100%" type="text">
            <br>
            <br>
            <hr>
            <br>
            <br>
            <hr>
            <h5>Data da realização</h5>
            <input type="date">
            <br>
            <br>
            <h5>Ponto estratégico</h5>
            <input style="width:100%" type="text">
            <br>
            <br>
            <h5>Quais são as táticas para objetivos-meio desse ponto estratégico</h5>
            <input style="width:100%" type="text">
            <br>
            <br>
            <hr>
            <br>
            <br>
            <hr>
            <h5>Data da realização</h5>
            <input type="date">
            <br>
            <br>
            <h5>Ponto estratégico</h5>
            <input style="width:100%" type="text">
            <br>
            <br>
            <h5>Quais são as táticas para objetivos-meio desse ponto estratégico</h5>
            <input style="width:100%" type="text">
            <br>
            <br>
            <hr>
            <br>
            <br>
            <hr>
            <h5>Data da realização</h5>
            <input type="date">
            <br>
            <br>
            <h5>Ponto estratégico</h5>
            <input style="width:100%" type="text">
            <br>
            <br>
            <h5>Quais são as táticas para objetivos-meio desse ponto estratégico</h5>
            <input style="width:100%" type="text">
            <br>
            <br>
            <hr>
            <br>
            <br>
            <hr>
            <h5>Data da realização</h5>
            <input type="date">
            <br>
            <br>
            <h5>Ponto estratégico</h5>
            <input style="width:100%" type="text">
            <br>
            <br>
            <h5>Quais são as táticas para objetivos-meio desse ponto estratégico</h5>
            <input style="width:100%" type="text">
            <br>
            <br>
            <hr>
            <br>
            <br>
            <hr>
            <h5>Data da realização</h5>
            <input type="date">
            <br>
            <br>
            <h5>Ponto estratégico</h5>
            <input style="width:100%" type="text">
            <br>
            <br>
            <h5>Quais são as táticas para objetivos-meio desse ponto estratégico</h5>
            <input style="width:100%" type="text">
            <br>
            <br>
            <hr>
            <br>
            <br>

            <h1></h1>
            <h5>Comentários e anotações</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <div style="display: flex; justify-content: center;"> 
                <button class="btn" >Salvar resultados</button>
            </div>
            <br>

        </div>
    </div>
</template>

<style scoped>
*
{
    font-family: roboto;
}
.numberLevel
{
    text-align:center;
    color:#983e7b;
}
.inputLevel
{
    width:100%;
}
h5
{
    text-align:justify;    
}
p
{
    text-align:justify;
}
#boxAssessment
{
    
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    
    color: black;
}
*
{
    
    font-family: roboto;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
a:hover{
    color:#fec961;
}
</style>
<script>
export default {
    data()
    {
        return{
            day:0,
            month:0,
            year:0,
        }
    },
    mounted()
    {
        var data = new Date();
        this.day = data.getDate();
        this.month = data.getMonth() +1;
        this.year=data.getFullYear();
    }
}
</script>