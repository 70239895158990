<template>
  <div id="header2">
    <div id="text">
      <h1>
        Ações positivas para<br /><span>FLORESCER</span><br />empreendimentos
      </h1>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
span {
  color: #fabe0a;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 5.95vw;
  font-weight: bold;
  font-family: Source Sans Pro;
  font-style: normal;
}
#header2 {
  color: white;
  margin-top: 8.3%;
  background-image: url("../../imgs/imagem_hero.png");
  height: 699px;
  background-repeat: no-repeat;
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100vw;
}
#text {
  text-align: right;
  position: absolute;
  top: 40%;
  right: 7.8%;
}
h1 {
  font-style: normal;
  color: white;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 3.308vw;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
@media (min-width: 450px) and (max-width: 700px) {
  h1 {
    font-size: 4.508vw;
  }
  span {
    font-size: 7.85vw;
  }
}
@media (min-width: 0px) and (max-width: 449px) {
  h1 {
    font-size: 4.908vw;
  }
  span {
    font-size: 7.55vw;
  }
}
</style>