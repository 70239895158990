import { render, staticRenderFns } from "./assessment-14.vue?vue&type=template&id=166f5a3c&scoped=true&"
var script = {}
import style0 from "./assessment-14.vue?vue&type=style&index=0&id=166f5a3c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "166f5a3c",
  null
  
)

export default component.exports