<template>
  <div id="header3">
    <div class="row">
      <div class="col">
        <h1
          style="
            margin-top: 10%;
            margin-left: 10%;
            color: #f6c56f;
            font-size: 30px;
            font-family: Montserrat;
          "
        >
          <b
            >Seu time é nossa prioridade<br />E o resultado nossa
            <span>satisfação</span></b
          >
        </h1>
        <br />
        <h5 style="margin-left: 10%; color: white; font-size: 18px">
          Uma equipe feliz e motivada é a <b>chave</b> para um bom desempenho
          pessoal e profissional ao longo da vida. Empresas <b>alavacam</b> com
          potencial e encontram o sucesso em colaboradores que estejam motivados
        </h5>
      </div>
      <div style="z-index: 4" class="col">
        <img
          style="position: absolute; left: 22%"
          width="500px"
          height="400px"
          src="../../imgs/modulos.png"
        />
      </div>
    </div>
    <div style="z-index: 0; position: relative">
      <h1
        style="
          color: white;
          width: 100%;
          font-size: 240px;
          opacity: 0.2;
          margin-left: -4%;
          margin-top: 20px;
        "
      >
        <b>MÓDULOS</b>
      </h1>
    </div>
    <div style="z-index: 1; position: relative">
      <img
        style="margin-top: -12%; width: 100%"
        src="../../imgs/ondulatoria.png"
      />
    </div>
    <div
      style="
        position: relative;
        z-index: 3;
        display: flex;
        justify-content: center;
        margin-top: -132px;
      "
    >
      <router-link to="/contato" class="nav-link"
        ><button class="btn1">
          <b>FALE CONOSCO PARA SABER MAIS!</b>
        </button></router-link
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.btn1 {
  background-image: linear-gradient(180deg, #fd5392, #f86f64);
  color: white;
  border: solid transparent;
  width: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 10px;
  font-family: Montserrat;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 4px;
  padding-right: 4px;
}
video {
  width: 100%;
}
#text {
  margin: 20px;
}
#header3 {
  width: 100%;
  height: 530px;
  background-color: #9370db;
}
span {
  color: #f6c56f;
  font-weight: bold;
  font-size: 30px;
  font-family: Monotype Corsiva;
}
.cover {
  color: white;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 30%;
  left: 0;
}

.btn1:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

#header {
  width: 100%;
}
* {
  margin: 0;
  padding: 0;
}
</style>

