<template>
    <div  style="height:100%;width:82%;position:absolute;left:18%;top:0%;margin-left:0%;"  > 
        <br>
        <br>
        
        <div id="boxAssessment">
            <br>
            <h1 class="text-center">GROW</h1>
            <br>
            <h5>[Desenvolvido a partir do trabalho de Alexander e Whitmore]</h5>
            <br>
            <h3 ><b>GOAL</b></h3>
            <br>
            
            <h5>Definição de metas para curto e longo prazo.</h5> 
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h3 ><b>REALITY</b></h3>
            <br>
            
            <h5>Verificação da realidade para explorar situação atual.</h5> 
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h3 ><b>OPTIONS</b></h3>
            <br>
            
            <h5>Opções e estratégias atuais de ações em curso.</h5> 
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
             <h3 ><b>WHAT, WHEN, WHOM, WILL</b></h3>
            <br>
            
            <h5>O que deve ser feito, quando, por quem e a vontade de fazer.</h5> 
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <div style="display: flex; justify-content: center;"> 
                 <button class="btn" >Salvar resultados</button>
            </div>
            <br>
        </div>
    </div>
</template>

<style scoped>
*
{
    font-family: roboto;
}
#boxAssessment
{
    
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    
    color: black;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
</style>

<script>
export default {
     data() {
        return {
            
           
            opcoes: [
               {n: '0'},
               {n: '1'},
               {n: '2'},
               {n: '3'},
               {n: '4'},
               {n: '5'},
               {n: '6'},
               {n: '7'},
               {n: '8'},
               {n: '9'},
               {n: '10'},
            ]
        }
     }
   
}
</script>