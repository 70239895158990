<template>
    <div style="width:82%;position:absolute;left:18%;top:0%;margin-left:0%;"> 
        <br>
        <br>
        
        <div id="boxAssessment">
            <br>
            <h1 class="text-center">Roteiro para mudança de carreira</h1>
            <br>
            <h3><b>I – Conduza o cliente ao estilo vigilante de tomada de decisão</b></h3>
            <br>
            <h5>Aplique o Assessment para Mudança de Carreira.</h5>
            
            <br>
            <br>
            <h5>Verifique se as necessidades do cliente podem ser atendidas por meio de
mudança de cargo, de função, de empresa ou de atitude, ou se é realmente o
caso de mudar de carreira.</h5>
            <br>
            <br>
            <hr>
            
            <h3><b>II – Comece a estabelecer a Rota de Ação</b></h3>
            <br>
            <br>
            
            <h5>Avalie o grau de consciência do cliente quanto às implicações da mudança
de carreira.</h5>
            <br>
            <br>
            <h5>Pergunte quais são os benefícios que ele vê nessa decisão.</h5>
            <br>
            <br>
            <h5>Questione suas motivações.</h5>
            <br>
            <br>
            <h5>Explore alternativas.</h5>
            
            <br>
            <br>
            <h5>Prossiga, caso ele se mantenha firme em sua decisão.</h5>
           
            <br>
            <br>
            <br>
            <br>
            <hr>

            <h3><b>III – Entre no estágio Preparando a Mudança</b></h3>
            <br>
            <h5>Passo 1 – O que o cliente quer fazer? Isso está de acordo com seus valores,
interesses etc.? Faça o Mapeamento Pessoal que você aprendeu no Treinamento
Iniciando uma Carreira I.</h5>
            <br>
            <h5>Passo 2 – Ajude o cliente a reunir informações sobre a nova carreira e o mer-
cado de trabalho. Que desafios o cliente irá enfrentar? Quais são as exigências

para ingressar na nova carreira? Quais são as competências e requisitos necessá-
rios? Faça os Mapeamentos de Competência e de Mercado que você aprendeu no

Treinamento Iniciando uma Carreira I.</h5>
            <br>
            <h5>Passo 3 – Reality Check. Verifique:
Depois de avaliar todas as informações obtidas nos passos anteriores, o cliente ainda
quer mudar de carreira?</h5>
            <br>
            <br>
            <br>
            <br>
            <hr>

            <h3><b>IV – Dê início à etapa Planejando a Transição</b></h3>
            <br>
            <h5>Parte 1 – Formulação de objetivos
Utilize as técnicas de formulação de objetivos aprendidas no Personal & Professional
Coaching®.</h5>
            <br>
            <h5>Parte 2 – Ação e organização <br><br>
                a) Ações estratégicas:
            </h5>
            <ul>
                <li>O que o cliente precisa fazer para atingir seus objetivos?</li>
                <li>Como e quando ele irá fazer?</li>
            </ul>
            <h5>b) Organização do tempo:</h5>
            <ul>
                <li>Use técnicas de gestão do tempo para que o cliente possa realizar as ações
estratégicas sem stress e desgaste.</li>


            </ul>
            <h5>c) Organização financeira:</h5>
            <ul>
                <li>Como o cliente irá se manter durante a transição? Ajude-o a organizar seu
orçamento e controlar gastos.</li>
            </ul>

            <h5>d) Organização familiar:</h5>
            <ul>
                <li>O cliente conta com o apoio da família para mudar de carreira?</li>
                <li>Como ele pode garantir esse apoio e lidar com receios e objeções de seus
familiares</li>
            </ul>
            <br>
            <h5>Comentários e anotações</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <div style="display: flex; justify-content: center;"> 
                <button class="btn" >Salvar resultados</button>
            </div>
            <br>
        </div>   
    </div>
</template>

<style scoped>
*
{
    font-family: roboto;
}
#boxAssessment
{
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    
    color: black;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
a:hover{
    color:#fec961;
}
</style>

<script>
export default {
    
   
}
</script>