<template>
  <div style="margin-left: 10%; margin-right: 10%">
    <br />
    <br />

    <div>
      <h1 style="text-align: center; color: #bb6081">
        <b
          >Para que essas sementes germinem e nasçam, faz-se necessário alguns
          cuidados diários. Concorda?
        </b>
      </h1>
      <br />
      <div
        style="
          width: 10%;
          margin-left: 45%;
          margin-right: 45%;
          border-bottom: solid black;
        "
      ></div>
      <br />
      <p style="text-align: center">
        o preparo do <span>PLANTIO</span> é algo fundamental para que o
        <span>SEMEAR</span><br />obtenha êxito e sucesso na sua
        <span>COLHEITA</span>
      </p>
      <div class="row">
        <div class="col" style="margin-left: -15%">
          <img id="img" src="../../imgs/plantio.png" />
        </div>
        <div class="col" id="colText">
          <p style="font-size: 20px; text-align: justify">
            E nesse sentindo, é fundamental cuidar, zelar pelo solo fértil e ter
            um clima harmônico, para que então as <span>SEMENTES</span> que são
            os nossos colaboradores e vidas de pessoas em geral, consigam
            experimentar e explorar sua essência, competência e habilidades, na
            execução do seu dia a dia.
          </p>
          <p style="font-size: 20px; text-align: justify">
            <span>A Terra</span> aqui citada está sendo representada pelas
            <b>empresas, casa, escolas.</b>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style scoped>
#textStory {
  text-align: center;
}
span {
  color: #bb6081;
  font-weight: bold;
}
@media (min-width: 531px) and (max-width: 768px) {
  #colText {
    text-align: center;
  }
  #img {
    width: 380px;
  }
}
@media (min-width: 525px) and (max-width: 530px) {
  h1 {
    font-size: 20px;
  }
  #colText {
    margin-top: 10%;
    text-align: center;
  }
}

@media (min-width: 345px) and (max-width: 524px) {
  #img {
    width: 350px;
  }
  h1 {
    font-size: 20px;
  }
  #colText {
    margin-top: 10%;
    text-align: center;
  }
}

@media (min-width: 0px) and (max-width: 344px) {
  #img {
    width: 300px;
  }
  h1 {
    font-size: 20px;
  }
  #colText {
    margin-top: 10%;
    text-align: center;
  }
}
</style>
