<template>
    <div style="height:100%;width:82%;position:absolute;left:18%;top:0%;margin-left:0%;"> 
        <br>
        <br>
        
        <div id="boxAssessment">
            <br>
            <h1 class="text-center">House of change</h1>
            <br>
            <br>
            <div style="display: flex; justify-content: center;"> 
                <img src="../../../imgs/house-of-change.jpg">
            </div>
            <br>
            <br>
            <br>
            <h5>Qual é a situação? Qual é o problema? </h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3>Sobre o cliente</h3>
            <br>
            <br>
            <h5>Qual o objetivo do cliente? </h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Quais os pensamentos do cliente? </h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Quais os sentimentos do cliente? </h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Quais os comportamentos do cliente? </h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Quais os valores e proprósitos do cliente? </h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3>Sobre o envolvido</h3>
            <br>
            <br>
            <h5>Qual o objetivo do envolvido?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Quais os pensamentos do envolvido?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Quais os sentimentos do envolvido?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Quais os comportamentos do envolvido?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Quais os valores e propósitos do envolvido?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3>Sobre o envolvido indiretamente</h3>
            
            <br>
            <br>
            <h5>Qual o objetivo do envolvido indiretamente?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Quais os pensamentos do envolvido indiretamente?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Quais os sentimentos do envolvido indiretamente?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Quais os comportamentos do envolvido indiretamente?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Quais os valores e propositos do envolvido indiretamente?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3>Fechamento</h3>
            <br>
            <br>
            <h5>Quais aprendizados você teve ao responder essa ferramenta?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Qual ação você poderia ter em cima desse aprendizado?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Como você irá se lembrar de fazer isso?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Depende de quem fazer essa ação e tornar sua vida melhor? </h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>O quanto você esta comprometido a fazer essa ação?</h5>
            <input id="level1" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel1').innerHTML = this.value;"  min="0" max="10" value="5" />
            <h1 id="showLevel1" class="numberLevel">5</h1>
            <br>
            <br>
            <h5>Qual resultado você espera ter realizando esta tarefa?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>De 0 a 10, o quanto realizar esta tarefa irá impactar positivamente na realização do seu objetivo?</h5>
            <input id="level2" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel2').innerHTML = this.value;"  min="0" max="10" value="5" />
            <h1 id="showLevel2" class="numberLevel">5</h1>
            <br>
            <br>



            <h5>Comentários e anotações</h5>
            
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <div style="display: flex; justify-content: center;"> 
                  <button class="btn" >Salvar resultados</button>
            </div>
            <br>
        </div>   
    </div>
</template>

<style scoped>
input[type='range'] {
  cursor: pointer;
}

input[type='range'],
input[type='range']::-webkit-slider-runnable-track,
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}

/** 
 * Use o pseudo-seletor "::-webkit-slider-runnable-track" para customizar o "trilho" do campo.
 */
input[type='range']::-webkit-slider-runnable-track {
  
  height: 10px;
  background: #983e7b;
  border-radius: 13px;
}

/** 
 * Use o pseudo-seletor "::-webkit-slider-thumb" para customizar o "seletor" do campo.
 */
input[type='range']::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  margin-top: -4.5px;
  background-color: #fec961;
  border-radius: 20px;
}
input[type='range']{
    width:100%;
    background-color: white;
    border-color:#983e7b;
    height:50px;
    border-radius:10px;
}
*
{
    font-family: roboto;
}
.numberLevel
{
    text-align:center;
    color:#983e7b;
}
.inputLevel
{
    width:100%;
}
*
{
    font-family: roboto;
}
#boxAssessment
{
    
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    
    color: black;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
a:hover{
    color:#fec961;
}
</style>

<script>
export default {
    
   
}
</script>