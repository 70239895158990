<template>
  <div class="container-fluid">
    <br />
    <br />

    <br />

    <div id="boxSessao" class="ml-5 mr-5 mt-3">
      <h1 class="text-center">Histórico do cliente</h1>
      <br />
      <p class="subtitle">Nome: Luan Reis De Carvalho</p>
      <p class="subtitle">Email: luan.reisc@hotmail.com</p>
      <p class="subtitle">Data de nascimento: 28/04/1998</p>
      <br />
      <br />

      <div class="boxSessoes">
        <h5>Sessões já realizadas</h5>
        <p>Selecione qual sessão deseja ver informações</p>
        <button
          v-on:click="teste(sessao.n)"
          class="btn2 ml-3"
          v-for="sessao in sessoes"
          :key="sessao.n"
        >
          {{ sessao.n }}
        </button>
      </div>

      <br />

      <div class="alert alert-warning ml-6 mr-6" v-if="article == true">
        <div class="message-header">
          <h1>Sessão {{ sessionSelect }}</h1>
        </div>
        <div class="message-body">
          <p class="bold">Informações da sessão</p>
          <br />
          <p>Data: 28/04/2020</p>

          <p>Horário de inicio: 13:00</p>

          <p>Horário de termino: 13:50</p>
          <br />
          <br />
          <div v-if="sessionSelect > 1">
            <p>Qual foi o resultado da última tarefa?</p>
            <p>
              O resultado foi... O resultado foi... O resultado foi... O
              resultado foi... O resultado foi... O resultado foi... O resultado
              foi... O resultado foi... O resultado foi... O resultado foi... O
              resultado foi... O resultado foi... O resultado foi... O resultado
              foi... O resultado foi...
            </p>
            <button v-on:click="editar()" class="btn3" style="width: 100px">
              Editar
            </button>

            <br />
            <br />
            <br />
            <br />
            <p>
              Você teve alguma nova conclusão ou aprendizado desde nossa última
              sessão?
            </p>
            <p>
              sim sim sim sim sim sim sim sim sim sim sim sim sim sim sim sim
              sim sim sim sim sim sim
            </p>
            <button v-on:click="editar()" class="btn3" style="width: 100px">
              Editar
            </button>
            <br />
            <br />
            <br />
            <br />
            <p>
              Qual foi sua evolução nos objetivos definidos na primeira sessão?
            </p>
            <p>Vários vários vários vários vários</p>
            <button v-on:click="editar()" class="btn3" style="width: 100px">
              Editar
            </button>
            <br />
            <br />
            <br />
            <br />
          </div>
          <p>Proposta da sessão:</p>
          <p>Fazer isso isso aquilo</p>
          <button v-on:click="editar()" class="btn3" style="width: 100px">
            Editar
          </button>
          <br />
          <br />
          <br />
          <br />

          <p>Desenvolvimento:</p>
          <p>
            sim sim sim sim sim sim sim sim sim sim sim sim sim sim sim sim sim
            sim sim sim sim sim
          </p>
          <button v-on:click="editar()" class="btn3" style="width: 100px">
            Editar
          </button>
          <br />
          <br />
          <br />
          <br />
          <p>Aprendizado:</p>
          <p>
            sim sim sim sim sim sim sim sim sim sim sim sim sim sim sim sim sim
            sim sim sim sim sim
          </p>
          <button v-on:click="editar()" class="btn3" style="width: 100px">
            Editar
          </button>
          <br />
          <br />
          <br />
          <br />
          <p>Tarefas:</p>
          <p>
            sim sim sim sim sim sim sim sim sim sim sim sim sim sim sim sim sim
            sim sim sim sim sim
          </p>
          <button v-on:click="editar()" class="btn3" style="width: 100px">
            Editar
          </button>
          <br />
          <br />
          <br />
          <br />
          <p>Prazo de conclusão</p>
          <p>29/10/2020</p>
          <button v-on:click="editar()" class="btn3" style="width: 100px">
            Editar
          </button>
          <br />
          <br />
          <br />
          <br />
          <p>Outras ferramentas utilizadas</p>
          <p>Ferramenta X, Ferramenta Y, Ferramenta Z</p>
          <button v-on:click="editar()" class="btn3" style="width: 100px">
            Editar
          </button>
          <br />
          <br />
          <br />
          <br />
          <p>Grafico de evolução</p>
          <img
            src="../../imgs/grafico_evolucao.png"
            width="100%"
            height="400px"
          />
          <br />
          <br />
          <h3 class="text-center">Assessments usados</h3>

          <br />
          <br />
          <h1 class="text-center">Life</h1>
          <br />
          <div class="row">
            <div class="col-sm">
              <br />

              <a href="/assessment/assessment-1"
                ><button id="1" style="width: 100%" class="btn m-1">
                  Definição de resultados esperados
                </button></a
              >
              <a href="/assessment/assessment-2"
                ><button id="2" style="width: 100%" class="btn m-1">
                  Ganhos & perdas
                </button></a
              >
              <a href="/assessment/assessment-3"
                ><button id="3" style="width: 100%" class="btn m-1">
                  Missão & Propósito
                </button></a
              >
              <a href="/assessment/assessment-4"
                ><button id="4" style="width: 100%" class="btn m-1">
                  Dreamlist
                </button></a
              >
              <a href="/assessment/assessment-5"
                ><button id="5" style="width: 100%" class="btn m-1">
                  Especificação de objetivos
                </button></a
              >
              <a href="/assessment/assessment-6"
                ><button id="6" style="width: 100%" class="btn m-1">
                  Administração do tempo
                </button></a
              >
              <a href="/assessment/assessment-7"
                ><button id="7" style="width: 100%" class="btn m-1">
                  Identificação de Valores
                </button></a
              >
              <a href="/assessment/assessment-8"
                ><button id="8" style="width: 100%" class="btn m-1">
                  O Seu Grande Sonho e Crenças
                </button></a
              >
              <a href="/assessment/assessment-9"
                ><button id="9" style="width: 100%" class="btn m-1">
                  SWOT Pessoal
                </button></a
              >
              <a href="/assessment/assessment-10"
                ><button id="10" style="width: 100%" class="btn m-1">
                  Levanta Astral
                </button></a
              >
              <a href="/assessment/assessment-13"
                ><button id="11" style="width: 100%" class="btn m-1">
                  Roadmap
                </button></a
              >
              <a href="/assessment/assessment-14"
                ><button id="12" style="width: 100%" class="btn m-1">
                  Projeção de Objetivos na Linha do Tempo
                </button></a
              >
              <a href="/assessment/assessment-15"
                ><button style="width: 100%" class="btn m-1">
                  Ensaio mental
                </button></a
              >
            </div>
            <div class="col-sm">
              <br />

              <a href="/assessment/assessment-16"
                ><button
                  id="13"
                  style="background-color: #983e7b; color: white; width: 100%"
                  class="btn m-1"
                >
                  Análise Estratégica SWOT
                </button></a
              >
              <a href="/assessment/assessment-17"
                ><button id="14" style="width: 100%" class="btn m-1">
                  Análise do Campo de Força
                </button></a
              >
              <a href="/assessment/assessment-18">
                <button id="15" style="width: 100%" class="btn m-1">
                  Modelo IDEAL
                </button></a
              >
              <a href="/assessment/assessment-19">
                <button id="16" style="width: 100%" class="btn m-1">
                  Modelo Seis Segundos
                </button></a
              >
              <a href="/assessment/assessment-20">
                <button
                  id="17"
                  style="background-color: #983e7b; color: white; width: 100%"
                  class="btn m-1"
                >
                  Âncoras
                </button></a
              >
              <a href="/assessment/assessment-21">
                <button id="18" style="width: 100%" class="btn m-1">
                  Modelo AF
                </button></a
              >
              <a href="/assessment/assessment-22">
                <button id="19" style="width: 100%" class="btn m-1">
                  Ideias Fixas
                </button></a
              >
              <a href="/assessment/assessment-23">
                <button id="20" style="width: 100%" class="btn m-1">
                  O Crítico Interno
                </button></a
              >
              <a href="/assessment/assessment-24">
                <button
                  id="21"
                  style="background-color: #983e7b; color: white; width: 100%"
                  class="btn m-1"
                >
                  Ensaio Dramático
                </button></a
              >
              <a href="/assessment/assessment-25">
                <button id="22" style="width: 100%" class="btn m-1">
                  Ensaio Mental
                </button></a
              >

              <a href="/assessment/assessment-26">
                <button
                  id="23"
                  style="background-color: #983e7b; color: white; width: 100%"
                  class="btn m-1"
                >
                  Modelo Skilled-Helper
                </button></a
              >
              <a href="/assessment/assessment-27">
                <button id="24" style="width: 100%" class="btn m-1">
                  GROW
                </button></a
              >
            </div>
          </div>
          <br />
          <br />
          <h1 class="text-center">Carreira</h1>
          <div class="row">
            <div class="col-sm">
              <br />
              <a href="/assessment/assessment-28"
                ><button
                  id="25"
                  style="background-color: #983e7b; color: white; width: 100%"
                  class="btn m-1"
                >
                  Iniciando uma carreira I
                </button></a
              >
              <a href="/assessment/assessment-29"
                ><button id="26" style="width: 100%" class="btn m-1">
                  Identificação e avaliação de competências
                </button></a
              >
              <a href="/assessment/assessment-30"
                ><button id="27" style="width: 100%" class="btn m-1">
                  Entrevista para o primeiro emprego
                </button></a
              >
              <a href="/assessment/assessment-31"
                ><button id="28" style="width: 100%" class="btn m-1">
                  Plano de carreira para iniciantes
                </button></a
              >
              <a href="/assessment/assessment-32"
                ><button
                  id="29"
                  style="background-color: #983e7b; color: white; width: 100%"
                  class="btn m-1"
                >
                  inventário de atitudes para início de carreiras
                </button></a
              >
              <a href="/assessment/assessment-33"
                ><button id="30" style="width: 100%" class="btn m-1">
                  Medindo a autoeficácia
                </button></a
              >
            </div>
            <div class="col-sm">
              <br />
              <a href="/assessment/assessment-34"
                ><button id="31" style="width: 100%" class="btn m-1">
                  Assessment para mudança de carreira
                </button></a
              >
              <a href="/assessment/assessment-35"
                ><button
                  id="32"
                  style="background-color: #983e7b; color: white; width: 100%"
                  class="btn m-1"
                >
                  Plano de desenvolvimento de carreira
                </button></a
              >
              <a href="/assessment/assessment-36"
                ><button id="33" style="width: 100%" class="btn m-1">
                  Roteiro para mudança de carreira
                </button></a
              >
              <a href="/assessment/assessment-37"
                ><button id="34" style="width: 100%" class="btn m-1">
                  Roteiro para trabalhar com o cliente desempregado
                </button></a
              >
              <a href="/assessment/assessment-38"
                ><button id="35" style="width: 100%" class="btn m-1">
                  Resourceful coach
                </button></a
              >
            </div>
          </div>
          <br />
          <br />
          <button
            id="end"
            style="
              background-color: red;
              border: none;
              width: 400px;
              color: white;
            "
            v-on:click="endSession()"
          >
            Sessão ainda não encerrada
          </button>
        </div>
      </div>

      <div style="display: flex; justify-content: center">
        <a target="_blank" href="/nova-sessao"
          ><button class="btn3 mr-3">
            Preencher parecer de uma nova sessão
          </button></a
        >
        <button v-if="article == true" class="btn3">Salvar alterações</button>
      </div>
    </div>

    <br />
    <br />
    <br />
    <br />
    <br />
  </div>
</template>

<style scoped>
.input {
  width: 100%;
}
.btn {
  background-color: #fafafa;
  color: black;
  border: none;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}
.btn2 {
  background-color: #983e7b;
  color: white;
  border: none;
  width: 30px;

  height: 30px;
}
.btn3 {
  background-color: #983e7b;
  color: white;
  border: none;
  width: 300px;
  height: 30px;
}
ul li {
  background-color: white;
  text-align: center;
  margin-top: 4px;
  list-style-type: none;
  border-radius: 2px;
}
#boxSessao {
  border-radius: 2%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin: 0 auto;
  padding: 30px;
}
</style>


<script>
export default {
  methods: {
    editar: function () {},
    teste: function (n) {
      this.article = true;
      this.sessionSelect = n;
    },

    endSession: function () {
      if (this.end == false) {
        if (
          confirm(
            "Isso irá marcar a sessão como encerrada, deseja continuar?"
          ) == true
        ) {
          document.getElementById("end").style.backgroundColor = "green";
          document.getElementById("end").innerText = "Sessão encerrada";
          this.end = true;
        }
      } else {
        if (
          confirm(
            "Isso irá marcar a sessão como não encerrada, deseja continuar?"
          ) == true
        ) {
          document.getElementById("end").style.backgroundColor = "red";
          document.getElementById("end").innerText =
            "Sessão ainda não encerrada";
          this.end = false;
        }
      }
    },
  },
  data() {
    return {
      sessoes: [{ n: 1 }, { n: 2 }, { n: 3 }, { n: 4 }, { n: 5 }, { n: 6 }],
      sessions: [1],
      newSession: 1,
      sessionSelect: 0,
      click: "",
      article: false,
      last: 1,
      del: false,
      end: false,
    };
  },
};
</script>