<template>
  <div id="pageSpecialist">
    <dashboard></dashboard>
    <barraLateral></barraLateral>
    <modal></modal>
  </div>
</template>
<script>
import barraLateral from "../components/barra-lateral-especialista.vue";
import dashboard from "../components/dashboard-especialista.vue";
import modal from "../components/modal-foto-perfil-especialista.vue";
export default {
  components: {
    modal,
    barraLateral,
    dashboard,
  },
};
</script>
<style scoped>
#pageSpecialist {
  width: 100%;
  height: 800px;
}
* {
  margin-top: -1%;
}
</style>