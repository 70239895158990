<template>
  <div
    style="width: 82%; position: absolute; left: 18%; top: 0%; margin-left: 0%"
  >
    <br />
    <br />
    <div id="boxAssessment">
      <br />
      <h1 class="text-center">Avaliação Inicial</h1>
      <br />
      <br />
      <h5>
        De 0 a 10, avalie os resultados que você deseja obter nos itens a
        seguir. No final do desenvolvimento será feito novamente esse teste e
        comparado os resultados.
      </h5>
      <br />
      <br />
      <h5>
        Identificação e formulação de objetivos alinhados com propósitos e
        prioridades
      </h5>
      <br />
      <input
        id="level1"
        v-on:change="alterValue()"
        class="inputLevel"
        type="range"
        oninput="getElementById('showLevel1').innerHTML = this.value;"
        min="0"
        max="10"
        value="5"
      />
      <br />
      <h1 id="showLevel1" class="numberLevel">5</h1>

      <br />
      <textarea
        required="required"
        style="resize: vertical; width: 100%"
      ></textarea>
      <br />
      <br />
      <hr />
      <br />
      <br />

      <h5>
        Identificação de estratégias e elaboração de um plano de ação para
        atingir objetivos
      </h5>
      <br />
      <input
        id="level2"
        v-on:change="alterValue()"
        class="inputLevel"
        type="range"
        oninput="getElementById('showLevel2').innerHTML = this.value;"
        min="0"
        max="10"
        value="5"
      />
      <br />
      <h1 id="showLevel2" class="numberLevel">5</h1>

      <br />
      <textarea
        required="required"
        style="resize: vertical; width: 100%"
      ></textarea>
      <br />
      <br />
      <hr />
      <br />
      <br />
      <h5>Atingir objetivos</h5>
      <br />
      <input
        id="level3"
        v-on:change="alterValue()"
        class="inputLevel"
        type="range"
        oninput="getElementById('showLevel3').innerHTML = this.value;"
        min="0"
        max="10"
        value="5"
      />
      <br />
      <h1 id="showLevel3" class="numberLevel">5</h1>

      <br />
      <textarea
        required="required"
        style="resize: vertical; width: 100%"
      ></textarea>
      <br />
      <br />
      <hr />
      <br />
      <br />
      <h5>
        Identificação e superação de bloqueios que o impedem de usar todo o seu
        potencial
      </h5>
      <br />
      <input
        id="level4"
        v-on:change="alterValue()"
        class="inputLevel"
        type="range"
        oninput="getElementById('showLevel4').innerHTML = this.value;"
        min="0"
        max="10"
        value="5"
      />
      <br />
      <h1 id="showLevel4" class="numberLevel">5</h1>

      <br />
      <textarea
        required="required"
        style="resize: vertical; width: 100%"
      ></textarea>
      <br />
      <br />
      <hr />
      <br />
      <br />
      <h5>Administração do tempo</h5>
      <br />
      <input
        id="level5"
        v-on:change="alterValue()"
        class="inputLevel"
        type="range"
        oninput="getElementById('showLevel5').innerHTML = this.value;"
        min="0"
        max="10"
        value="5"
      />
      <br />
      <h1 id="showLevel5" class="numberLevel">5</h1>

      <br />
      <textarea
        required="required"
        style="resize: vertical; width: 100%"
      ></textarea>
      <br />
      <br />
      <hr />
      <br />
      <br />
      <h5>Controle e redução do stress</h5>

      <p>
        Estou consciente de meu nível de stress e de como ele afeta
        negativamente minha vida e minha performance? Conheço as causas de meu
        stress e atuo para<br />
        reduzi-las ou eliminá-las? Conheço e utilizo atividades e estratégias de
        controle de stress?
      </p>

      <br />
      <input
        id="level6"
        v-on:change="alterValue()"
        class="inputLevel"
        type="range"
        oninput="getElementById('showLevel6').innerHTML = this.value;"
        min="0"
        max="10"
        value="5"
      />
      <br />
      <h1 id="showLevel6" class="numberLevel">5</h1>

      <br />
      <textarea
        required="required"
        style="resize: vertical; width: 100%"
      ></textarea>
      <br />
      <br />
      <hr />
      <br />
      <br />
      <h5>Lidar com suas próprias emoções e com as dos outros</h5>

      <p>
        Estou consciente de minhas próprias emoções e do modo como elas afetam
        meu comportamento? Sei controlar minhas emoções? Compreendo as
        emoções<br />
        de outras pessoas e sei como lidar com elas?
      </p>
      <br />
      <input
        id="level7"
        v-on:change="alterValue()"
        class="inputLevel"
        type="range"
        oninput="getElementById('showLevel7').innerHTML = this.value;"
        min="0"
        max="10"
        value="5"
      />
      <br />
      <h1 id="showLevel7" class="numberLevel">5</h1>

      <br />
      <textarea
        required="required"
        style="resize: vertical; width: 100%"
      ></textarea>
      <br />
      <br />
      <hr />
      <br />
      <br />
      <h5>Relacionamentos pessoais</h5>

      <p>
        Estou satisfeito com meus relacionamentos pessoais? Sei construir
        relaciona- mentos pessoais saudáveis e produtivos? Sei identificar<br />
        e resolver problemas que possam afetar negativamente os meus
        relacionamentos pessoais?
      </p>
      <br />
      <input
        id="level8"
        v-on:change="alterValue()"
        class="inputLevel"
        type="range"
        oninput="getElementById('showLevel8').innerHTML = this.value;"
        min="0"
        max="10"
        value="5"
      />
      <br />
      <h1 id="showLevel8" class="numberLevel">5</h1>

      <br />
      <textarea
        required="required"
        style="resize: vertical; width: 100%"
      ></textarea>
      <br />
      <br />
      <hr />
      <br />
      <br />

      <h5>Relacionamentos profissionais</h5>

      <p>
        Estou satisfeito com meus relacionamentos profissionais? Sei construir
        relaciona- mentos profissionais saudáveis e produtivos? Sei
        identificar<br />
        e resolver problemas que possam afetar negativamente os meus
        relacionamentos profissionais?
      </p>
      <br />
      <input
        id="level9"
        v-on:change="alterValue()"
        class="inputLevel"
        type="range"
        oninput="getElementById('showLevel9').innerHTML = this.value;"
        min="0"
        max="10"
        value="5"
      />
      <br />
      <h1 id="showLevel9" class="numberLevel">5</h1>

      <br />
      <textarea
        required="required"
        style="resize: vertical; width: 100%"
      ></textarea>
      <br />
      <br />
      <hr />
      <br />
      <br />
      <h5>Comunicação</h5>

      <p>
        Entendo a importância da comunicação em minha vida? Consigo me comunicar
        com clareza e objetividade? Sou capaz de me fazerentender quando me<br />
        comunico? Sei usar a comunicação para obter apoio, administrar conflitos
        e atingir meus objetivos?
      </p>
      <br />
      <input
        id="level10"
        v-on:change="alterValue()"
        class="inputLevel"
        type="range"
        oninput="getElementById('showLevel10').innerHTML = this.value;"
        min="0"
        max="10"
        value="5"
      />
      <br />
      <h1 id="showLevel10" class="numberLevel">5</h1>

      <br />
      <textarea
        required="required"
        style="resize: vertical; width: 100%"
      ></textarea>
      <br />
      <br />
      <hr />
      <br />
      <br />

      <h5>Assertividade</h5>

      <p>
        Sou capaz de dizer não sem criar confrontos e conflitos desnecessários?
        Consigo expressar claramente o que penso e sinto? Sinto confiança e
        convicção<br />

        quando me expresso? Consigo me expressar com objetividade quando me
        sinto desrespeitado ou quando algo me desagrada?
      </p>

      <br />
      <input
        id="level11"
        v-on:change="alterValue()"
        class="inputLevel"
        type="range"
        oninput="getElementById('showLevel11').innerHTML = this.value;"
        min="0"
        max="10"
        value="5"
      />
      <br />
      <h1 id="showLevel11" class="numberLevel">5</h1>

      <br />
      <textarea
        required="required"
        style="resize: vertical; width: 100%"
      ></textarea>
      <br />
      <br />
      <hr />
      <br />
      <br />
      <h5>Energia e motivação para agir</h5>

      <p>
        Costumo me sentir energizado e motivado com frequência, ou é cada vez
        mais difícil encontrar energia e motivação? Ultimamente, como eu
        avaliaria<br />
        meu grau de energia e motivação para superar dificuldades, realizar
        minhas atividades diárias e atingir meus objetivos?
      </p>
      <br />
      <input
        id="level12"
        v-on:change="alterValue()"
        class="inputLevel"
        type="range"
        oninput="getElementById('showLevel12').innerHTML = this.value;"
        min="0"
        max="10"
        value="5"
      />
      <br />
      <h1 id="showLevel12" class="numberLevel">5</h1>

      <br />
      <textarea
        required="required"
        style="resize: vertical; width: 100%"
      ></textarea>
      <br />
      <br />
      <hr />
      <br />
      <br />

      <h5>Encontrar e manter o foco e o direcionamento</h5>

      <p>
        Sei exatamente que direção estou seguindo e por quê? Consigo manter o
        foco no que estou fazendo e não perder o senso de direcionamento?
      </p>
      <br />
      <input
        id="level13"
        v-on:change="alterValue()"
        class="inputLevel"
        type="range"
        oninput="getElementById('showLevel13').innerHTML = this.value;"
        min="0"
        max="10"
        value="5"
      />
      <br />
      <h1 id="showLevel13" class="numberLevel">5</h1>

      <br />
      <textarea
        required="required"
        style="resize: vertical; width: 100%"
      ></textarea>
      <br />
      <br />
      <hr />
      <br />
      <br />
      <h5>Tomada de decisões</h5>

      <p>
        Consigo entender clara e rapidamente a decisão que tenho de tomar e o
        objetivo que busco atingir com isso? Sou capaz de encontrar e de
        analisar<br />
        diferentes op- ções e cursos de ação, mesmo sob pressão? Percebo e
        avalio as consequências de cada decisão antes de tomá-la?
      </p>

      <br />
      <input
        id="level14"
        v-on:change="alterValue()"
        class="inputLevel"
        type="range"
        oninput="getElementById('showLevel14').innerHTML = this.value;"
        min="0"
        max="10"
        value="5"
      />
      <br />
      <h1 id="showLevel14" class="numberLevel">5</h1>

      <br />
      <textarea
        required="required"
        style="resize: vertical; width: 100%"
      ></textarea>
      <br />
      <br />
      <hr />
      <br />
      <br />
      <h5>Desenvolvimento pessoal</h5>

      <p>
        Conheço minhas necessidades e meus objetivos de desenvolvimento pessoal?
        Eles são prioridade para mim? Costumo investir em meu<br />
        desenvolvimento pessoal de modo sistemático e planejado?
      </p>

      <br />
      <input
        id="level15"
        v-on:change="alterValue()"
        class="inputLevel"
        type="range"
        oninput="getElementById('showLevel15').innerHTML = this.value;"
        min="0"
        max="10"
        value="5"
      />
      <br />
      <h1 id="showLevel15" class="numberLevel">5</h1>

      <br />
      <textarea
        required="required"
        style="resize: vertical; width: 100%"
      ></textarea>
      <br />
      <br />
      <hr />
      <br />
      <br />
      <h5>Progresso financeiro</h5>

      <p>
        Estou satisfeito com meu progresso financeiro? Costumo estabelecer metas
        financeiras, planejar e atingir essas metas? Tenho total controle de
        meu<br />
        orçamento pessoal e familiar? Costumo obter um saldo mensal positivo,
        isto é, ganhar mais do que gasto?
      </p>

      <br />
      <input
        id="level16"
        v-on:change="alterValue()"
        class="inputLevel"
        type="range"
        oninput="getElementById('showLevel16').innerHTML = this.value;"
        min="0"
        max="10"
        value="5"
      />
      <br />
      <h1 id="showLevel16" class="numberLevel">5</h1>

      <br />
      <textarea
        required="required"
        style="resize: vertical; width: 100%"
      ></textarea>
      <br />
      <br />
      <hr />
      <br />
      <br />

      <h5>Mudança de hábitos e comportamentos negativos ou improdutivos</h5>

      <p>
        Sou capaz de identificar e reconhecer os hábitos e comportamentos que
        não estão produzindo bons resultados em minha vida? Entendo suas
        consequências negativas e aceito a responsabilidade de mudá-los?
      </p>

      <br />
      <input
        id="level17"
        v-on:change="alterValue()"
        class="inputLevel"
        type="range"
        oninput="getElementById('showLevel17').innerHTML = this.value;"
        min="0"
        max="10"
        value="5"
      />
      <br />
      <h1 id="showLevel17" class="numberLevel">5</h1>

      <br />
      <textarea
        required="required"
        style="resize: vertical; width: 100%"
      ></textarea>
      <br />
      <br />
      <hr />
      <br />
      <br />
      <h5>Autoestima e autoconfiança</h5>

      <p>
        Acredito em mim mesmo? Confio em minha capacidade e em meu potencial?
        Tenho uma imagem positiva de mim mesmo? Consigo manter a fé em<br />
        mim mesmo, inclusive quando estou sob pressão ou enfrento dificuldades e
        oposição? Sei identi- ficar e utilizar meus pontos fortes?
      </p>

      <br />
      <input
        id="level18"
        v-on:change="alterValue()"
        class="inputLevel"
        type="range"
        oninput="getElementById('showLevel18').innerHTML = this.value;"
        min="0"
        max="10"
        value="5"
      />
      <br />
      <h1 id="showLevel18" class="numberLevel">5</h1>

      <br />
      <textarea
        required="required"
        style="resize: vertical; width: 100%"
      ></textarea>
      <br />
      <br />
      <hr />
      <br />
      <br />
      <h5>Saúde, bem-estar e qualidade de vida</h5>

      <p>
        Eu realmente compreendo a importância da saúde e do bem-estar? Busco
        manter uma alimentação saudável e me exercito regularmente? >Realizo
        atividades que promovam o meu bem-estar? Estou satisfeito com minha
        qualidade de vida? Ajo concreta e continuamente paramelhorar minha saúde
        mental, emocional, física e psicológica?
      </p>

      <br />
      <input
        id="level19"
        v-on:change="alterValue()"
        class="inputLevel"
        type="range"
        oninput="getElementById('showLevel19').innerHTML = this.value;"
        min="0"
        max="10"
        value="5"
      />
      <br />
      <h1 id="showLevel19" class="numberLevel">5</h1>

      <br />
      <textarea
        required="required"
        style="resize: vertical; width: 100%"
      ></textarea>
      <br />
      <br />
      <hr />
      <br />
      <br />
      <h5>Iniciativa e proatividade</h5>

      <p>
        Costumo fazer mais do que é pedido? Ofereço novas ideias e realizo ações
        para promover melhorias, mesmo sem ser solicitado? Sinto-me
        suficientemente<br />
        confiante para usar o que tenho de melhor e contribuir ativamente para
        buscar e implantar soluções, gerar melhorias e aumentar resultados?<br />
        Demonstro espírito de liderança?
      </p>

      <br />
      <input
        id="level20"
        v-on:change="alterValue()"
        class="inputLevel"
        type="range"
        oninput="getElementById('showLevel20').innerHTML = this.value;"
        min="0"
        max="10"
        value="5"
      />
      <br />
      <h1 id="showLevel20" class="numberLevel">5</h1>

      <br />
      <textarea
        required="required"
        style="resize: vertical; width: 100%"
      ></textarea>
      <br />
      <br />
      <hr />
      <br />
      <br />
      <h5>Resolução de problemas e administração de conflitos</h5>

      <p>
        Ajo rapidamente para resolver problemas? Acumulo problemas, ou sei como
        delegar o que deve ser delegado? Sinto que estou sempre às voltas<br />
        com os mesmos problemas e conflitos, ou consigo encontrar soluções
        eficazes e novas opções? Conheço e utilizo técnicas de resolução de
        problemas<br />
        e administração de conflitos?
      </p>

      <br />
      <input
        id="level21"
        v-on:change="alterValue()"
        class="inputLevel"
        type="range"
        oninput="getElementById('showLevel21').innerHTML = this.value;"
        min="0"
        max="10"
        value="5"
      />
      <br />
      <h1 id="showLevel21" class="numberLevel">5</h1>

      <br />
      <textarea
        required="required"
        style="resize: vertical; width: 100%"
      ></textarea>
      <br />
      <br />
      <hr />
      <br />
      <br />
      <div style="display: flex; justify-content: center">
        <button class="btn">Salvar resultados</button>
      </div>
      <br />
      <br />
    </div>
  </div>
</template>

<style scoped>
input[type="range"] {
  cursor: pointer;
}

input[type="range"],
input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

/** 
 * Use o pseudo-seletor "::-webkit-slider-runnable-track" para customizar o "trilho" do campo.
 */
input[type="range"]::-webkit-slider-runnable-track {
  height: 10px;
  background: #983e7b;
  border-radius: 13px;
}

/** 
 * Use o pseudo-seletor "::-webkit-slider-thumb" para customizar o "seletor" do campo.
 */
input[type="range"]::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  margin-top: -4.5px;
  background-color: #fec961;
  border-radius: 20px;
}
input {
  background-color: white;
  border-color: #983e7b;
  height: 50px;
  border-radius: 10px;
}
.numberLevel {
  text-align: center;
  color: #983e7b;
}
.inputLevel {
  width: 100%;
}
h5 {
  text-align: justify;
}
p {
  text-align: justify;
}

#boxAssessment {
  padding-left: 2%;
  padding-right: 2%;
  margin-right: 4%;
  margin-left: 4%;
  border-style: solid;
  border-width: 1px;
  border-color: #d3d3d3;
  background-color: white;

  border-radius: 15px;
  text-align: center;
  color: black;
}

* {
  font-family: roboto;
}
.btn:hover {
  background-color: #fec961;
}
.btn {
  background-color: #983e7b;
  color: white;
  border: none;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}
a:hover {
  color: #fec961;
}
</style>
<script>
export default {};
</script>