<template>
  <div>
    <Navbar></Navbar>
    <Text1></Text1>
    <Text2></Text2>
    <Text3></Text3>
    <Text4></Text4>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from "../components/navbar.vue";
import Footer from "../components/footer.vue";
import Text1 from "../components/text1-contato";
import Text2 from "../components/text2-contato";
import Text3 from "../components/text3-contato";
import Text4 from "../components/text4-contato";

export default {
  components: {
    Navbar,
    Footer,
    Text1,
    Text2,
    Text3,
    Text4,
  },
};
</script>
