<template>
    <div style="width:82%;position:absolute;left:18%;top:0%;margin-left:0%;"> 
        <br>
        <br>
        
        <div id="boxAssessment">
            <br>
            <h1 class="text-center">Ganhos & perdas</h1>
            <br>
            <br>
            <div class="row">
                <div class="col">
                    <p>Pense na sua vida após a realização de seu objetivo. Como ela será?<br></p>
                    <p>Quais os ganhos que você terá após a conquista do seu objetivo?</p>
                    <p>Quais os ganhos que sua família terá após a conquista dos seus objetivos?</p>
                    <p>Quais os ganhos que sua qualidade de vida terá após a conquista de seu objetivo?</p>
                    <br>
                    
                </div>
                <div class="col">
                    <p>Quais as perdas que você terá após a conquista do seu objetivo?<br></p>
                    <p>Quais as perdas que a sua familia terá após a conquista dos seus objetivo?</p>
                    <p>Quais as perdas que sua qualidade de vida terá após a conquista do seu objetivo?</p>
                    <br>
                    <br>
                    
                    
                </div>
               


            </div> 
            <div class="row">
                    <div class="col">
                        <textarea type="text" style="width:100%;height:300px"></textarea>
                    </div>
                    <div class="col">
                        <textarea type="text" style="width:100%;height:300px"></textarea>
                    </div>
            </div>
            <br>
            <br>
            <div class="row">
                <div class="col">
                    <p>Agora pense no processo de conquista do seu objetivo<br></p>
                    <p>Pense na sua caminhada até a conquista acontecer</p>
                    <p>O que faz da sua vida tão boa, que faça com que você não queira fazer nada para conquistá-lo?</p>
                    <p>Quais os ganhos que você tem com a sua vida de hoje?</p>
                    <p>O que faz da sua vida legal e gostosa?</p>
                    <br>
                    
                </div>
                <div class="col">
                    <p>Como você se sentirá daqui a 10 anos se perceber que a vida passou e você não fez nada para conquistar seu sonho? <br></p>
                    <p>Como você se sentirá se perceber que sua vida piorou por não ter feito nada?</p>
                    <br>
                    <br>
                    
                    
                </div>
               


            </div> 
            <div class="row">
                    <div class="col">
                        <textarea type="text" style="width:100%;height:300px"></textarea>
                    </div>
                    <div class="col">
                        <textarea type="text" style="width:100%;height:300px"></textarea>
                    </div>
            </div>
            <br>
            <br>
            <h5>1 - O que fazer para minimizar as possíveis perdas?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>2 - O que você pode fazer para continuar tendo os atuais ganhos?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>3 - O seu objetivo ou resultado afeta negativamente a vida de outra pessoa ou do meio ao qual você faz parte?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>4 - Se sim, como podemos ajustar seu objetivo para evitar esse impacto?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <div style="display: flex; justify-content: center;"> 
                     <button class="btn" >Salvar resultados</button>
            </div>
            <br>
        </div>
    </div>
</template>

<style scoped>
h5
{
    text-align:justify;

}
p
{
    text-align: justify;
}

#boxAssessment
{
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    text-align: center;
    color: black;
}
*
{  
    font-family: roboto;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
a:hover{
    color:#fec961;
}
</style>

<script>
export default {
     data() {
        return {
            
           
            opcoes: [
               {n: '0'},
               {n: '1'},
               {n: '2'},
               {n: '3'},
               {n: '4'},
               {n: '5'},
               {n: '6'},
               {n: '7'},
               {n: '8'},
               {n: '9'},
               {n: '10'},
            ]
        }
     }
   
}
</script>