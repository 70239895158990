<template>
  <div id="aboutUs">
    <br />
    <br />
    <div id="text">
      <h1>
        O que te <span>MOTIVA</span><br />
        a entrar em ação?
      </h1>
      <br />
      <h3>
        Buscamos semear o melhor para a sua vida todos os<br />dias trazendo
        soluções inovadores.
      </h3>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
img {
  margin-top: -152px;
}
#text {
  margin-top: 70px;
}
#aboutUs {
  background-size: cover;
  background-position: center center;
  height: 698px;
  background-image: url("../../imgs/banner_sobre.png");
}
h1 {
  margin-left: 7.8%;
  font-family: Open Sans;
  font-style: normal;
  color: white;
  font-weight: bold;
  font-size: 3.508125vw;
}
h3 {
  font-size: 1.4811111111111vw;
  margin-left: 7.8%;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  color: white;
}
button {
  border-color: transparent;
  margin-left: 7.8%;
  margin-top: 24px;
  background-color: #fabe0a;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  width: 180px;
  height: 52px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  color: #692b55;
}
span {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #fabe0a;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 900;
}
@media (max-width: 700px) {
  h1 {
    font-size: 4.508125vw;
  }
  h3 {
    font-size: 2.4811111111111vw;
  }
}
</style>