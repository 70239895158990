<template>
    <div style="width:82%;position:absolute;left:18%;top:0%;margin-left:0%;"> 
        <br>
        <br>
      
        <div id="boxAssessment" >
            <br>
            <h1 class="text-center">Avaliação de humor</h1>
            <br>
            <br>
            <h5><b>Vamos medir seus sentimentos e emoções?</b></h5>
            <p>Esta escala consiste em várias palavras que descrevem diferentes sentimentos e 
                emoções. Leia cada item e clique na lista suspensa ao lado da palavra e selecione 
                uma das respostas. Indique até que ponto você se sente assim agora, ou seja, no momento 
                presente.
            </p>
            <br>
            <table>
                <tr>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                </tr>
                <tr>
                    <td>Muito ligeramente ou nada</td>
                    <td>um pouco</td>
                    <td>Moderamente</td>
                    <td>Bastante</td>
                    <td>Extremamente</td>
                </tr>
            </table>
            <br>
            <br>
            <h5>Todas as perguntas devem ser respondidas para que este questionário seja pontuado.</h5>
            <br>
            <br>
            <h5>Interessado</h5>
            <input id="level1" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel1').innerHTML = this.value;"  min="1" max="5" value="3" />
            <h1 id="showLevel1" class="numberLevel">3</h1>
            <br>
            <hr>
            <br>

            <h5>Angustiado</h5>
            <input id="level2" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel2').innerHTML = this.value;"  min="1" max="5" value="3" />
            <h1 id="showLevel2" class="numberLevel">3</h1>
            <br>
            <hr>
            <br>

            <h5>Animado</h5>
            <input id="level3" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel3').innerHTML = this.value;"  min="1" max="5" value="3" />
            <h1 id="showLevel3" class="numberLevel">3</h1>
            <br>
            <hr>
            <br>

            <h5>Chateado</h5>
            <input id="level4" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel4').innerHTML = this.value;"  min="1" max="5" value="3" />
            <h1 id="showLevel4" class="numberLevel">3</h1>
            <br>
            <hr>
            <br>

            <h5>Forte</h5>
            <input id="level5" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel5').innerHTML = this.value;"  min="1" max="5" value="3" />
            <h1 id="showLevel5" class="numberLevel">3</h1>
            <br>
            <hr>
            <br>

            <h5>Culpado</h5>
            <input id="level6" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel6').innerHTML = this.value;"  min="1" max="5" value="3" />
            <h1 id="showLevel6" class="numberLevel">3</h1>
            <br>
            <hr>
            <br>

            <h5>Assustado</h5>
            <input id="level7" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel7').innerHTML = this.value;"  min="1" max="5" value="3" />
            <h1 id="showLevel7" class="numberLevel">3</h1>
            <br>
            <hr>
            <br>

            <h5>Hostil</h5>
            <input id="level8" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel8').innerHTML = this.value;"  min="1" max="5" value="3" />
            <h1 id="showLevel8" class="numberLevel">3</h1>
            <br>
            <hr>
            <br>
            
            <h5>Entusiasmado</h5>
            <input id="level9" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel9').innerHTML = this.value;"  min="1" max="5" value="3" />
            <h1 id="showLevel9" class="numberLevel">3</h1>
            <br>
            <hr>
            <br>

            <h5>Orgulhoso</h5>
            <input id="level10" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel10').innerHTML = this.value;"  min="1" max="5" value="3" />
            <h1 id="showLevel10" class="numberLevel">3</h1>
            <br>
            <hr>
            <br>

            <h5>Irritável</h5>
            <input id="level11" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel11').innerHTML = this.value;"  min="1" max="5" value="3" />
            <h1 id="showLevel11" class="numberLevel">3</h1>
            <br>
            <hr>
            <br>

            <h5>Alerta</h5>
            <input id="level12" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel12').innerHTML = this.value;"  min="1" max="5" value="3" />
            <h1 id="showLevel12" class="numberLevel">3</h1>
            <br>
            <hr>
            <br>

            <h5>Inspirado</h5>
            <input id="level13" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel13').innerHTML = this.value;"  min="1" max="5" value="3" />
            <h1 id="showLevel13" class="numberLevel">3</h1>
            <br>
            <hr>
            <br>
            

            <h5>Nervoso</h5>
            <input id="level14" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel14').innerHTML = this.value;"  min="1" max="5" value="3" />
            <h1 id="showLevel14" class="numberLevel">3</h1>
            <br>
            <hr>
            <br>

            <h5>Determinado</h5>
            <input id="level15" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel15').innerHTML = this.value;"  min="1" max="5" value="3" />
            <h1 id="showLevel15" class="numberLevel">3</h1>
            <br>
            <hr>
            <br>
            

            <h5>Atento</h5>
            <input id="level16" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel16').innerHTML = this.value;"  min="1" max="5" value="3" />
            <h1 id="showLevel16" class="numberLevel">3</h1>
            <br>
            <hr>
            <br>
            

            <h5>Ansioso</h5>
            <input id="level17" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel17').innerHTML = this.value;"  min="1" max="5" value="3" />
            <h1 id="showLevel17" class="numberLevel">3</h1>
            <br>
            <hr>
            <br>
            

            <h5>Ativo</h5>
             <input id="level18" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel18').innerHTML = this.value;"  min="1" max="5" value="3" />
            <h1 id="showLevel18" class="numberLevel">3</h1>
            <br>
            <hr>
            <br>
           

            <h5>Com medo</h5>
            <input id="level19" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel19').innerHTML = this.value;"  min="1" max="5" value="3" />
            <h1 id="showLevel19" class="numberLevel">3</h1>
            <br>
            <hr>
            <br>
            
            <h5>Comentários e anotações</h5>
            
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <div style="display: flex; justify-content: center;"> 
                 <button class="btn" >Salvar resultados</button>
            </div>
            <br>
            <br>
        </div>   
    </div>
</template>
<script>
export default {
    data() {
        return {
        options: [
                { text: 'Um', value: 'A' },
                { text: 'Dois', value: 'B' },
                { text: 'Três', value: 'C' }
           ],
           opcoes: [
               {midia: 'Facebook'},
               {midia: 'Twitter'},
               {midia: 'Google'},
               {midia: 'Instagram'},
               {midia: 'Youtube'},
               {midia: 'Noticias'},
               {midia: 'Indicação de amigo'},
               {midia: 'LinkedIn'},
               {midia: 'Outros'}
            ],
            
            gender:[
                {sexo: 'masculino'},
                {sexo: 'feminino'}
            ],
            
        }
    }
}
</script>

<style scoped>
input[type='range'] {
  cursor: pointer;
}

input[type='range'],
input[type='range']::-webkit-slider-runnable-track,
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}

/** 
 * Use o pseudo-seletor "::-webkit-slider-runnable-track" para customizar o "trilho" do campo.
 */
input[type='range']::-webkit-slider-runnable-track {
  
  height: 10px;
  background: #983e7b;
  border-radius: 13px;
}

/** 
 * Use o pseudo-seletor "::-webkit-slider-thumb" para customizar o "seletor" do campo.
 */
input[type='range']::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  margin-top: -4.5px;
  background-color: #fec961;
  border-radius: 20px;
}
input{
    background-color: white;
    border-color:#983e7b;
    height:50px;
    border-radius:10px;
}
.numberLevel
{
    text-align:center;
    color:#983e7b;
}
.inputLevel
{
    width:100%;
}
table tr td
{
    text-align: center;
    width:150px;
    border-bottom-color:#D3D3D3;
    border-bottom-style: solid;
     
    border-width: 1px;
}
table tr th
{
    border-top-color:#D3D3D3;
    border-top-style: solid;
     
    border-width: 1px;
    text-align: center;
    width:200px;
}
select
{
    margin-left:-80%;
}
h2
{
    text-align:justify;
}    
h1
{
     text-align:center;  
}
h5
{
    text-align:justify;    
}
p
{
    text-align: justify;
}
#boxAssessment
{
   padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    text-align: center;
    color: black;
}
*
{
    
    font-family: roboto;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
a:hover{
    color:#fec961;
}

 
</style>

<script>
export default {
    
   
}
</script>