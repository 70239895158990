<template>
    <div class="container-fluid"> 
        <br>
        <br>
        <br>
        <div id="boxAssessment" class="m-3">
            <h1 class="text-center">Especificação de objetivos </h1>
            <br>
            <h3><b>Objetivo:</b></h3>
            <br>
            
            <h5>O que você deseja especificamente?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            
            <h5>Qual o contexto? (Onde você quer que isso aconteça? Quem participa desse objetivo? Quando?)</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>

           
            <h5>Está formulado de maneira positiva?</h5>
            <input type="radio" id="s1" name="s1" value="sim">
            <label class="ml-2" for="male"> Sim</label><br>
            <input type="radio" id="s1" name="s1" value="não">
            <label class="ml-2" for="male"> Não</label><br>
            <br>
            <br>
            <h5>É Alcançavel?</h5>
            <input type="radio" id="s1" name="s1" value="sim">
            <label class="ml-2" for="male"> Sim</label><br>
            <input type="radio" id="s1" name="s1" value="não">
            <label class="ml-2" for="male"> Não</label><br>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3><b>Evidência:</b></h3>
            <br>
            <h5>Qual será a evidência de que você conseguiu?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Qual é a representação interna mental do seu último passo?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3><b>Motivadores/Ganhos:</b></h3>
            <br>
            <h5>O que você ganha com isso? Quais são os benefícios que você obterá?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3><b>Sabotadores/Perdas:</b></h3>
            <br>
            <h5>O que você perde com isso?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Esse objetivo ou resultado esperado afeta
negativamente outras pessoas ou o meio do qual você faz parte? (Se a resposta for sim: O que
você precisa alterar no seu objetivo para que afete apenas positivamente outras pessoas ou
o seu meio?)</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3><b>Valores/Relevância:</b></h3>
            <br>
            <h5>Por que isso é importante para você?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Quais os valores que você irá satisfazer com essa meta?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Por que isso é relevante para você?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3><b>Recursos:</b></h3>
            <br>
            <h5>Do que você vai precisar? (Recursos financeiros, conhecimento/métodos, tempo,
qualidades/habilidades/capacidades pessoais...)</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3><b>Estratégias:</b></h3>
            <br>
            <h5>Quais são as formas para conseguir isso?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Quais serão suas estratégias?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Você conhece alguém que já fez isso?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Como essa pessoa conseguiu?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3><b>Ações:</b></h3>
            <br>
            <h5>Quais são os passos para conseguir isso?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Qual será o seu plano de ação?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3><b>Primeiro passo:</b></h3>
            
            <br>
            <h5>Qual será o seu primeiro passo?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h3><b>Responsabilidade:</b></h3>
            
            <br>
            <h5>Depende de quem para que seu objetivo seja realizado?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <h5>O que você pode fazer para que esse objetivo dependa de você para ser iniciado hoje e mantido?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3><b>Comprometimento:</b></h3>
            
            <br>
            <h5>Qual o seu grau de comprometimento em realizar isso?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>O que você pode fazer para aumentar o seu grau de comprometimento?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>

            <div style="display: flex; justify-content: center;"> 
                    <a href="/nova-sessao"><button class="btn mr-3" >Voltar a pagina do cliente</button></a>
                    <button class="btn" >Salvar resultados</button>
            </div>


        </div>
    </div>
</template>

<style scoped>

*
{
    font-family: roboto;
}
#boxAssessment
{
  border-radius: 2%;  
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  margin: 0 auto;
  padding: 30px;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
</style>

<script>
export default {
     data() {
        return {
            
           
            opcoes: [
               {n: '0'},
               {n: '1'},
               {n: '2'},
               {n: '3'},
               {n: '4'},
               {n: '5'},
               {n: '6'},
               {n: '7'},
               {n: '8'},
               {n: '9'},
               {n: '10'},
            ]
        }
     }
   
}
</script>