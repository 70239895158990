<template>
    <div  style="width:82%;position:absolute;left:18%;top:0%;margin-left:0%;"> 
        <br>
        <br>
        
        <div id="boxAssessment" >
            <br>
            <h1 class="text-center">6 Segundos</h1>
            <br>
            <br>
            <h3>Passo 1 - Determine o problema</h3>
            <br>
            <br>
            <h5>Determine a situação que faz com que o cliente exploda ou imploda.</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Identifique o estado atual: o que a pessoa sente na hora da situação?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Identifique o estado desejado: O que a pessoa deseja sentir na hora da situação? </h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3>Passo 2 - Determine as emoções negativas</h3>
            <br>
            <br>
            <h5>Quais as emoções negativas que limitam a performance? </h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Quais os pensamentos que causam essas emoções? </h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3>Passo 3 - Determine o gatilho</h3>
            <br>
            <br>
            <h5>Qual evento que dispara essas emoções? É algo que você vê, pensa, ouve ou sente?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3>Passo 4 - Ressignificação: Seis segundos</h3>
            <br>
            <br>
            <h5>Pergunte para a pessoa 6 coisas que fazem ela ter a emoção desejada.</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Pergunte para a pessoa 3 frases que ela poderia falar para ela mesma na situação que façam ela ter a emoção que deseja ter.</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3>Passo 5 - Ensaio mental</h3>
            <h5>Faça o cliente reviver o momento de explosão na hora do gatilho faça com que ele fale as 6 coisas que o fazem ter a emoção desejada e depois as frases de emoção positiva. Repita até a pesssoa se acalmar</h5>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <h3>Fechamento</h3>
            <br>
            <br>
            <h5>Quais aprendizados você teve ao responder essa ferramenta?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Qual ação você poderia ter em cima desse aprendizado?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Como você irá se lembrar de fazer isso?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <h5>Depende de quem fazer essa ação e tornar sua vida melhor? </h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>O quanto você esta comprometido a fazer essa ação? (0 - 10)</h5>
            <input id="level1" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel1').innerHTML = this.value;"  min="0" max="10" value="5" />
            <h1 id="showLevel1" style="text-align:center;color:#983e7b;" class="numberLevel">5</h1>
            
            <br>
            <br>
            <h5>Qual resultado você espera ter realizando esta tarefa?</h5>
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <br>
            <br>
            <h5>De 0 a 10, o quanto realizar esta tarefa irá impactar positivamente na realização do seu objetivo?</h5>
            <input id="level2" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel2').innerHTML = this.value;"  min="0" max="10" value="5" />
            <h1 id="showLevel2" style="text-align:center;color:#983e7b;" class="numberLevel">5</h1>
            <br>
            <br>



            <h5>Comentários e anotações</h5>
            
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <div style="display: flex; justify-content: center;"> 
                    <button class="btn" >Salvar resultados</button>
            </div>
            <br>
            <br>
        </div>   
    </div>
</template>
<script>
export default {
    methods:
    {
        alterValue: function()
        {
             this.nivel1 = parseInt(document.getElementById("level1").value);
             this.nivel2 = parseInt(document.getElementById("level2").value);
             this.nivel3 = parseInt(document.getElementById("level3").value);
             this.nivel4 = parseInt(document.getElementById("level4").value);
             this.nivel5 = parseInt(document.getElementById("level5").value);
             this.total = this.nivel1 + this.nivel2 + this.nivel3 + this.nivel4 + this.nivel5;   
             
        }
    }
}
</script>
<style scoped>
input[type='range'] {
  cursor: pointer;
}

input[type='range'],
input[type='range']::-webkit-slider-runnable-track,
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}

/** 
 * Use o pseudo-seletor "::-webkit-slider-runnable-track" para customizar o "trilho" do campo.
 */
input[type='range']::-webkit-slider-runnable-track {
  
  height: 10px;
  background: #983e7b;
  border-radius: 13px;
}

/** 
 * Use o pseudo-seletor "::-webkit-slider-thumb" para customizar o "seletor" do campo.
 */
input[type='range']::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  margin-top: -4.5px;
  background-color: #fec961;
  border-radius: 20px;
}
input[type='range']{
    width:100%;
    background-color: white;
    border-color:#983e7b;
    height:50px;
    border-radius:10px;
}
*
{
    font-family: roboto;
}

#boxAssessment
{
    
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    
    color: black;

}
*
{
    
    font-family: roboto;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
a:hover{
    color:#fec961;
}
</style>