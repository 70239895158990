<template>
    <div  id="profileConsumer"> 
        <br>
        <br>
       
        <div id="boxAssessment" >
            <br>
            <h1 class="text-center">{{userName}}</h1>
            <br>
            <div style="display: flex; justify-content: center;">    
                    <img  class="link" width="240px" style="margin-right:10px" src="../../imgs/perfil.png">
            </div>
            <br>
            <div class="row">
                <div  class="col-sm">
                    <label for="email">Razão Social: *</label>
                    <br>
                    <input  class="form-control input2" type="text" id="corporateName" />
                </div>
                <div  class="col-sm">
                    <label for="tel">Nome Fantasia: *</label>
                    <br>
                    <input class="form-control input2" type="text" id="name" />        
                </div>
            </div>
            <br>
            <br>
             <div class="row">
                <div  class="col-sm">
                    <label for="email">Nome do responsável: *</label>
                    <br>
                    <input  class="form-control input2" type="text" id="name" />
                </div>
                <div  class="col-sm">
                    <label for="tel">Email do responsável: *</label>
                    <br>
                    <input class="form-control input2" type="text" id="email" />        
                </div>
            </div>
            <br>
            <br>
            <div class="row">
                <div  class="col-sm">
                    <label for="email">Telefone/Celular do responsável: *</label>
                    <br>
                    <input class="form-control input2" type="text" id="tel" />
                </div>
                <div  class="col-sm">
                    <label for="tel">Senha: *</label>
                    <br>
                    <input class="form-control input2" type="password" id="password" />        
                </div>
            </div>
            <br>
            <br>
            <div class="row">
                <div  class="col-sm">
                    <label for="email">CNPJ: *</label>
                    <br>
                    <input class="form-control input2" type="text" id="tel" />
                </div>
                <div  class="col-sm">
                    <label for="tel">Inscrição Estadual: *</label>
                    <br>
                    <input class="form-control input2" type="password" id="password" />        
                </div>
            </div>
            <br>
            <br>
                  
                                    
            <div class="row">
                <div  class="col-sm">
                    <label for="email">CEP: *</label>
                    <br>
                    <input class="form-control input2" type="text" id="cep" />
                </div>
                <div  class="col-sm">
                    <label for="tel">Estado: *</label>
                    <br>
                    <select class="custom-select input2" id="state" style="color:gray;" required="required">
                                <option  style="color:gray;" v-for="op in states" :key="op.uf">
                                    {{ op.uf }}
                                </option>
                    </select>       
                </div>
            </div>
            <br>
            <br>
            <div class="row">
                <div  class="col-sm">
                    <label for="email">Cidade: *</label>
                    <br>
                    <input class="form-control input2" type="text" id="city" />
                </div>
                <div  class="col-sm">
                    <label for="tel">Rua: *</label>
                    <br>
                    <input class="form-control input2" type="text" id="street" />        
                </div>
            </div>
            <br>
            <br>
            <div class="row">
                <div  class="col-sm">
                    <label for="email">Bairro: *</label>
                    <br>
                    <input class="form-control input2" type="text" id="district" />
                </div>
                <div  class="col-sm">
                    <label for="tel">Número: *</label>
                    <br>
                    <input class="form-control input2" type="number" id="number" />        
                </div>
            </div>
            <br>
            <br>
            <div class="row">
                <div  class="col-sm">
                    <label for="email">Complemento</label>
                    <br>
                    <input class="form-control input2" type="text" id="complement" />
                </div>
                <div  class="col-sm">
                    <label for="tel">Tempo de atuação no mercado:</label>
                    <br>
                    <input class="form-control input2" type="text" id="timeMarket" />        
                </div>
            </div>
            <br>
            <br>
            <div class="row">
                <div  class="col-sm">
                    <label for="email">Número de colaboradores: *</label>
                    <br>
                    <input class="form-control input2" type="text" id="numbersEmployes" />
                </div>
                <div  class="col-sm">
                    <label for="tel">Perfil do linkedin: *</label>
                    <br>
                    <input class="form-control input2" type="text" id="linkedin" />        
                </div>
            </div>
            <br>
            <br>
            
            <br>
            <br>
            <div style="display: flex; justify-content: center;"> 
                <button >Salvar alterações</button>      
            </div> 
            <br>
        </div> 

         
    </div>
</template>

<script>
export default {
     mounted()
     {
        this.userName = localStorage.getItem('nameCompany');
     },
     data()
     {
         return{
             userName:"",
             formation:[
                {level: 'Ensino fundamental incompleto'},
                {level: 'Ensino fundamental completo'},
                {level: 'Ensino médio incompleto'},
                {level: 'Ensino médio completo'},
                {level: 'Ensino superior incompleto'},
                {level: 'Ensino superior completo'},
             ],
             maritalStatus:[
                {status: 'Solteiro(a)'},
                {status: 'Casado(a)'},
                {status: 'Divorciado(a)'},
                {status: 'União Estável'},
                {status: 'Viuvo(a)'}
             ],
             states: [
              {uf: 'SP'},
              {uf: 'RJ'},
              {uf: 'ES'},
              {uf: 'MG'},
              {uf: 'BA'},
              {uf: 'SE'},
              {uf: 'PE'},
              {uf: 'AL'},
              {uf: 'PB'},
              {uf: 'RN'},
              {uf: 'CE'},
              {uf: 'PI'},
              {uf: 'MA'},
              {uf: 'PA'},
              {uf: 'AP'},
              {uf: 'AM'},
              {uf: 'RR'},
              {uf: 'AC'},
              {uf: 'DF'},
              {uf: 'GO'},
              {uf: 'RO'},
              {uf: 'TO'},
              {uf: 'MT'},
              {uf: 'MS'},
              {uf: 'PR'},
              {uf: 'SC'},
              {uf: 'RS'},
              
            ],
         }
     }
}
</script>

<style scoped>
.input2
{
    border-color:#983e7b;
    height:50px;
    border-radius:10px;
    width:100%;
}
button
{
    background-color:#983e7b;
    color:white;
    border-radius:10px;
    border-color:transparent;
    padding-left:30px;
    padding-right:30px;
    padding-top:10px;
    padding-bottom:10px;
}
#profileConsumer
{
    width:82%;
    position:absolute;
    left:18%;
    top:0%;
    margin-left:0%;
    height:2000px;
    background-color:#f7f8fc;

}
textarea
{
    width:100%;
}
h5
{
    text-align:justify;

}
p
{
    text-align: justify;
}

#boxAssessment
{
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    text-align: center;
    color: black;
}

.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
a:hover{
    color:#fec961;
}
input
{
    width:100%;
}
ul li
{
    text-align:justify;
}
#goals
{
    height:170px;
}
</style>

