<template>
    <div style="width:82%;position:absolute;left:18%;top:0%;margin-left:0%;"> 
        <br>
        <br>
        
        <div id="boxAssessment">
            <br>
            <h1 class="text-center">Accomplishment scale (Escala de realização)</h1>
            
            <br>
           
           
            <h5>Responda de acordo com a escala abaixo:</h5>
            <br>
            <br>
            <table>
                <tr>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                
                </tr>
                <tr>
                    <td>Discordo totalmente</td>
                    <td>Discordo</td>
                    
                    <td>Não sei</td>
                    
                    <td>Concordo</td>
                    <td>Concordo totalmente</td>
                </tr>
            </table>
           
            <br>
            <br>
            <br>
            <h5>Há coisas na minha vida que eu quero alcançar absolutamente</h5>
            <br>
            <input id="level1" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel1').innerHTML = this.value;"  min="1" max="5" value="3" />
            <h1 id="showLevel1" class="numberLevel">3</h1>
            <br>
            <hr>
            <br>
            <h5>Não há nada melhor que ter alcançado um objetivo que estava aspirando</h5>
            <br>
            <input id="level2" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel2').innerHTML = this.value;"  min="1" max="5" value="3" />
            <h1 id="showLevel2" class="numberLevel">3</h1>
            <br>
            <hr>
            <br>
            <h5>Eu tenho ambições</h5>
            <br>
            <input id="level3" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel3').innerHTML = this.value;"  min="1" max="5" value="3" />
            <h1 id="showLevel3" class="numberLevel">3</h1>
            <br>
            <hr>
            <br>
            <h5>A maioria das coisas que eu faço permitem sentir-me realizado</h5>
            <br>
            <input id="level4" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel4').innerHTML = this.value;"  min="1" max="5" value="3" />
            <h1 id="showLevel4" class="numberLevel">3</h1>
            <br>
            <hr>
            <br>
            <h5>Eu sou capaz de completar a maioria das coisas que faço com sucesso</h5>
            <br>
            <input id="level5" v-on:change="alterValue()" class="inputLevel" type="range" oninput="getElementById('showLevel5').innerHTML = this.value;"  min="1" max="5" value="3" />
            <h1 id="showLevel5" class="numberLevel">3</h1>
            <br>
            <hr>
            <br>
            
            <h5>Comentários e anotações</h5>
            
            <textarea required="required" style="resize: vertical;width:100%" ></textarea>
            <br>
            <br>
            <div style="display: flex; justify-content: center;"> 
                    <button class="btn" >Salvar resultados</button>
            </div>
            <br>
            <br>
        </div>   
    </div>
</template>
<script>
export default {
    
    data(){

        return{
            nivel1:3,
            nivel2:3,
            nivel3:3,
            nivel4:3,
            nivel5:3,
          
        }
    
    },
    methods:
    {
        alterValue: function(id)
        {
           
            if(id == 1)
            {
                 this.nivel1 = parseInt(document.getElementById("inputRangeAss"+id).value);
            }
            else
                if(id == 2)
                {
                    this.nivel2 = parseInt(document.getElementById("inputRangeAss"+id).value);
                }
                else
                    if(id == 3)
                    {
                        this.nivel3 = parseInt(document.getElementById("inputRangeAss"+id).value);
                    }
                    else
                        if(id == 4)
                        {
                            this.nivel4 = parseInt(document.getElementById("inputRangeAss"+id).value);
                        }
                        else
                            if(id == 5)
                            {
                                this.nivel5 = parseInt(document.getElementById("inputRangeAss"+id).value);
                                
                            }
                            else
                                if(id == 6)
                                {
                                    this.nivel6 = parseInt(document.getElementById("inputRangeAss"+id).value);
                                    
                                }
                                else
                                    if(id == 7)
                                    {
                                        this.nivel7 = parseInt(document.getElementById("inputRangeAss"+id).value);
                                        
                                    }
                                    else
                                        if(id == 8)
                                        {
                                            this.nivel8 = parseInt(document.getElementById("inputRangeAss"+id).value);
                                            
                                        }
        }
    }
}
</script>

<style scoped>
h2
{
    text-align:justify;
}    
.numberLevel
{
    text-align:center;
    color:#983e7b;
}
.inputLevel
{
    width:100%;
}
h5
{
    text-align:justify;    
}
p
{
    text-align: justify;
}
h2
{
    text-align:justify;
}    
h1
{
     text-align:center;  
}
h5
{
    text-align:justify;    
}
p
{
    text-align: justify;
}
table tr td
{
    text-align: center;
    width:200px;
    border-bottom-color:#D3D3D3;
    border-bottom-style: solid;
     
    border-width: 1px;
}
table tr th
{
    border-top-color:#D3D3D3;
    border-top-style: solid;
     
    border-width: 1px;
    text-align: center;
    width:200px;
}

*
{
    font-family: roboto;
}
#boxAssessment
{
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    
    color: black;
}
*
{
    
    font-family: roboto;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
a:hover{
    color:#fec961;
}


input[type='range'] {
  cursor: pointer;
}

input[type='range'],
input[type='range']::-webkit-slider-runnable-track,
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}

/** 
 * Use o pseudo-seletor "::-webkit-slider-runnable-track" para customizar o "trilho" do campo.
 */
input[type='range']::-webkit-slider-runnable-track {
  border-radius: 13px;
  height: 10px;
  background: #983e7b;
  
}

/** 
 * Use o pseudo-seletor "::-webkit-slider-thumb" para customizar o "seletor" do campo.
 */
input[type='range']::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  margin-top: -4.5px;
  background-color: #fec961;
  border-radius: 20px;
}
input{
    background-color: white;
    border-color:#983e7b;
    height:50px;
    border-radius:10px;
}
</style>