<template>
  <div>
    <Navbar></Navbar>
    <ImageHeader></ImageHeader>
    <Text1 id="text1"></Text1>
    <Text2 id="text2"></Text2>
    <Text3 href="#" id="text3"></Text3>
    <Text4></Text4>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from "../components/navbar.vue";
import Footer from "../components/footer.vue";
import ImageHeader from "../components/image_header.vue";
import Text1 from "../components/text1-home.vue";
import Text3 from "../components/text3-home.vue";
import Text2 from "../components/text2-home.vue";
import Text4 from "../components/text4-home.vue";

export default {
  name: "app",
  components: {
    Navbar,
    Footer,
    ImageHeader,
    Text4,
    Text3,
    Text1,
    Text2,
  },
  mounted() {
    let aboutUs = sessionStorage.getItem("aboutUs");
    if (aboutUs == "true") {
      window.location.href = "#text3";
    }
    sessionStorage.setItem("aboutUs", false);
  },
};
</script>

<style scoped>
#Text1 {
  z-index: 2;
}
#Text2 {
  z-index: 4;
}
</style>
