<template>
    <div  style="width:82%;position:absolute;left:18%;top:0%;margin-left:0%;"> 
        <br>
        <br>
       
        <div id="boxAssessment" >
            <br>
            <h1 class="text-center">Especificação de objetivos</h1>
            <br>
            <br>
            
            <table style="width:100%">
                <tr>
                    <th colspan="2" style="height:80px">O QUE</th>
                </tr>
                <tr>
                    <td>Objetivo<br>Contextualizando, específico, positivo, iniciado e mantido pelo indivíduo, alcançavel (O que? Quando? Onde? Com quem?)<br>Data do objetivo: <input style="border-radius:20px;border-color:#D3D3D3;border-width:1px" type="date"></td>
                    <td>Evidência<br>(Qual será a evidência de que você conseguiu?)</td>
                </tr>
                <tr>
                    <td>
                        <textarea></textarea>
                    </td>
                    <td>
                        <textarea></textarea>
                    </td>
                </tr>
            </table>
            <br>
            <br>
            <br>
            <table style="width:100%">
                <tr>
                    <th colspan="3" style="height:80px">POR QUE</th>
                </tr>
                <tr>
                    <td>Motivadores<br>Ganhos (O que você ganha com isso?)</td>
                    <td>Sabotadores<br>Perdas (O que você/outros perdem com isso? O que você pode fazer para minimizar possíveis perdas?)</td>
                    <td>Valores<br>(Por que é importante? Qual a relevância?)</td>
                </tr>
                <tr>
                    <td>
                        <textarea></textarea>
                    </td>
                    <td>
                        <textarea></textarea>
                    </td>
                    <td>
                        <textarea></textarea>
                    </td>
                </tr>
            </table>

            <br>
            <br>
            <br>
            <table style="width:100%">
                <tr>
                    <th colspan="3" style="height:80px">COMO</th>
                </tr>
                <tr>
                    <td>Estratégicas<br>(Quais são as formas de conseguir isso?)</td>
                    <td>Ações<br>(Quais são os passos para conseguir isso? e o grau de comprometimento?)</td>
                    <td>Recursos<br>(Do que você vai precisar?)</td>
                </tr>
                <tr>
                    <td>
                        <textarea></textarea>
                    </td>
                    <td>
                        <textarea></textarea>
                    </td>
                    <td>
                        <textarea></textarea>
                    </td>
                </tr>
            </table>
           
           <br>
           <br>
           
            <div style="display: flex; justify-content: center;"> 
                   
                    <button class="btn" >Salvar resultados</button>

            </div>
            <br>
            <br>
        </div>   
    </div>
</template>
<script>
export default {
    mounted()
    {
        
    },
    data() {
        return {
           msg: "",
           nivel1:4,
           nivel2:4,
           nivel3:4,
           nivel4:4,
           nivel5:4,
           valueInput1:0,
           total:16
            
        }
    },
    methods:
    {
        alterValue: function()
        {
             this.nivel1 = parseInt(document.getElementById("level1").value);
             this.nivel2 = parseInt(document.getElementById("level2").value);
             this.nivel3 = parseInt(document.getElementById("level3").value);
             this.nivel4 = parseInt(document.getElementById("level4").value);
             this.nivel5 = parseInt(document.getElementById("level5").value);
             this.total = this.nivel1 + this.nivel2 + this.nivel3 + this.nivel4 + this.nivel5;   
             this.changeMsg(); 
        },
        changeMsg: function()
        {
            if(this.total <= 9)
            {
                this.msg = "05 a 09 - Extremamente infeliz - Sentem-se infelizes com tudo, indicando desequilibrio em diversas áreas de sua vida, nesse caso é indicado a ajuda de um especialista imediatamente."
            }
            else    
                if(this.total >= 10 && this.total <= 14)
                {
                    this.msg = "10 a 14 - Insatisfeito - Pessoas nessa faixa de pontuação sentem-se substancialmente insatisfeitas com suas vidas. Elas podem estar mal em em diversas áreas e muito mal em uma ou duas áreas de suas vidas.";
                }
                else
                    if(this.total >= 15 && this.total <= 19)
                    {
                        this.msg = "15 a 19 - Pouco satisfeito - Pessoas que pontuam nesta faixa geralmente possuem pequenos, porém significativos, problemas em diversas áreas de suas vidas. Ou então, estão bem em diversas áreas, mas possuem uma área que representa um problema susbstacial para elas."
                    }
                    else
                        if(this.total >= 20 && this.total <=35 )
                        {
                            this.msg = "20 a 35 - De satisfeito a extremamente satisfeito - As pessoas que estão na faixa acima de 20 pontos, estão caminhando para a busca de uma maior significado de vida e que muitas"
                                        "coisas estão indo muito bem em sua vida, sente uma motivação e uma votade imensa de vencer. Para a maioria das pessoas que estão nessa faixa, percebe a vida como prazerosa"
                                        "e os principais dominios de suas existências funcionam a contento: Trabalho, família, relacionamentos, saúde, qualidade de vida, espiritualidade e paz de espírito.";
                        }

        }
    }
}
</script>

<style scoped>
textarea
{
    width:100%;
    height:300px;    
}
h2
{
    text-align:justify;
}    

h5
{
    text-align:justify;    
}
p
{
    text-align: justify;
}
table tr td
{
    text-align: center;
    width:150px;
    
    border-width: 1px;
}
table tr th
{
    border-top-color:#D3D3D3;
    border-top-style: solid;
     
    border-width: 1px;
    text-align: center;
    width:150px;
}



*
{
    font-family: roboto;
}

#boxAssessment
{
    
    padding-left:2%;
    padding-right:2%;
    margin-right:4%;
    margin-left:4%;
    border-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color:white;
   
    border-radius: 15px;
    
    color: black;

}
*
{
    
    font-family: roboto;
}
.btn:hover{
        background-color:#fec961;
}
.btn
{
        background-color:#983e7b;
        color:white;
        border: none;
        width:300px;
        display: flex;
        justify-content: center;
         align-items: center;
        height:30px;
}
a:hover{
    color:#fec961;
}

 
</style>

