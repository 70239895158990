<template>
  <div class="container-fluid">
    <br />
    <br />

    <br />

    <div id="boxSessao" class="ml-5 mr-5 mt-3">
      <h1 class="text-center">Parecer da nova sessão</h1>
      <br />

      <br />

      <div class="alert alert-warning ml-6 mr-6">
        <div class="message-header">
          <h1>Sessão {{ sessionSelect }}</h1>
        </div>
        <div class="message-body">
          <p class="bold">Informações da sessão</p>
          <br />
          <p>Data</p>
          <input class="input" required="required" id="data" type="date" />
          <br />
          <br />
          <p>Horário de inicio</p>
          <input class="input" required="required" id="timestart" type="time" />
          <br />
          <br />
          <p>Horário de fim</p>
          <input class="input" required="required" id="timeend" type="time" />
          <br />
          <br />
          <div v-if="sessionSelect > 1">
            <p>Qual foi o resultado da última tarefa?</p>
            <textarea
              required="required"
              style="resize: vertical; width: 100%"
            ></textarea>
            <br />
            <br />
            <p>
              Você teve alguma nova conclusão ou aprendizado desde nossa última
              sessão?
            </p>
            <textarea
              required="required"
              style="resize: vertical; width: 100%"
            ></textarea>
            <br />
            <br />

            <p>
              Qual foi sua evolução nos objetivos definidos na primeira sessão?
            </p>
            <textarea
              required="required"
              style="resize: vertical; width: 100%"
            ></textarea>
            <br />
            <br />
          </div>
          <p>Proposta da sessão:</p>
          <textarea
            required="required"
            style="resize: vertical; width: 100%"
          ></textarea>
          <br />
          <br />
          <p>Desenvolvimento:</p>
          <textarea
            class="input"
            required="required"
            style="resize: vertical; width: 100%"
          ></textarea>
          <br />
          <br />
          <p>Aprendizado:</p>
          <textarea
            class="input"
            required="required"
            style="resize: vertical; width: 100%"
          ></textarea>
          <br />
          <br />
          <p>Tarefas:</p>
          <textarea
            class="input"
            required="required"
            style="resize: vertical; width: 100%"
          ></textarea>
          <br />
          <br />
          <p>Prazo de conclusão</p>
          <input type="date" style="width: 100%" />
          <br />
          <br />

          <p>Outras ferramentas utilizadas</p>
          <textarea
            class="input"
            required="required"
            style="resize: vertical; width: 100%"
          ></textarea>
          <br />
          <br />
          <br />
          <br />
          <h3 class="text-center">
            Escolha os Assessment que você irá utilizar nessa sessão
          </h3>
          <br />
          <br />
          <h1 class="text-center">Avaliações iniciais</h1>
          <br />
          <br />
          <div class="row">
            <div class="col">
              <a target="_blank" href="/avaliacao-pre"
                ><button style="width: 100%" class="btn m-1">
                  Avaliação pré desenvolvimento
                </button></a
              >
            </div>
            <div class="col">
              <a target="_blank" href="/assessment/assessment-1"
                ><button style="width: 100%" class="btn m-1">
                  Definição de resultados esperados
                </button></a
              >
            </div>
          </div>
          <br />
          <br />
          <h1 class="text-center">Psicologia Positiva</h1>
          <div class="row">
            <div class="col">
              <br />
              <a target="_blank" href="/assessment/assessment-43"
                ><button style="width: 100%" class="btn m-1">
                  Avaliação de esperança
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-45"
                ><button style="width: 100%" class="btn m-1">
                  Escala de satisfação com a vida
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-47"
                ><button style="width: 100%" class="btn m-1">
                  Funcionamento otimizado
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-49"
                ><button style="width: 100%" class="btn m-1">
                  Accomplishment Scale
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-51"
                ><button style="width: 100%" class="btn m-1">
                  Eu ideal
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-53"
                ><button style="width: 100%" class="btn m-1">Mova-se</button></a
              >
              <a target="_blank" href="/assessment/assessment-55"
                ><button style="width: 100%" class="btn m-1">
                  Respostas fortalecedoras
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-57"
                ><button style="width: 100%" class="btn m-1">
                  7 caminhos do casamento feliz
                </button></a
              >
            </div>
            <div class="col">
              <br />
              <a target="_blank" href="/assessment/assessment-44"
                ><button style="width: 100%" class="btn m-1">
                  Avaliação de humor
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-46"
                ><button style="width: 100%" class="btn m-1">
                  Avaliação de motivação
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-48"
                ><button style="width: 100%" class="btn m-1">
                  Flourishing Scale
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-50"
                ><button style="width: 100%" class="btn m-1">
                  Helicopter view pessoal
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-52"
                ><button style="width: 100%" class="btn m-1">
                  Ative emoções positivas
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-54"
                ><button style="width: 100%" class="btn m-1">
                  Pratique mindfulness
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-56"
                ><button style="width: 100%" class="btn m-1">
                  Cavaleiros do apocalipse
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-58"
                ><button style="width: 100%" class="btn m-1">
                  Teoria da autoeficácia
                </button></a
              >
            </div>
          </div>

          <br />
          <br />
          <h1 class="text-center">Life</h1>
          <br />
          <div class="row">
            <div class="col-sm">
              <br />

              <a target="_blank" href="/assessment/assessment-2"
                ><button style="width: 100%" class="btn m-1">
                  Ganhos & perdas
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-3"
                ><button style="width: 100%" class="btn m-1">
                  Missão & Propósito
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-4"
                ><button style="width: 100%" class="btn m-1">
                  Dreamlist
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-5"
                ><button style="width: 100%" class="btn m-1">
                  Especificação de objetivos
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-6"
                ><button style="width: 100%" class="btn m-1">
                  Administração do tempo
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-7"
                ><button style="width: 100%" class="btn m-1">
                  Identificação de Valores
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-8"
                ><button style="width: 100%" class="btn m-1">
                  O Seu Grande Sonho e Crenças
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-9"
                ><button style="width: 100%" class="btn m-1">
                  SWOT Pessoal
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-10"
                ><button style="width: 100%" class="btn m-1">
                  Levanta Astral
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-13"
                ><button style="width: 100%" class="btn m-1">Roadmap</button></a
              >
              <a target="_blank" href="/assessment/assessment-14"
                ><button style="width: 100%" class="btn m-1">
                  Projeção de Objetivos na Linha do Tempo
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-15"
                ><button style="width: 100%" class="btn m-1">
                  Ensaio mental
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-39"
                ><button style="width: 100%" class="btn m-1">
                  6 Segundos
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-41"
                ><button style="width: 100%" class="btn m-1">
                  Congelamento de imagem
                </button></a
              >
            </div>
            <div class="col-sm">
              <br />

              <a target="_blank" href="/assessment/assessment-16"
                ><button style="width: 100%" class="btn m-1">
                  Análise Estratégica SWOT
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-17"
                ><button style="width: 100%" class="btn m-1">
                  Análise do Campo de Força
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-18">
                <button style="width: 100%" class="btn m-1">
                  Modelo IDEAL
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-19">
                <button style="width: 100%" class="btn m-1">
                  Modelo Seis Segundos
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-20">
                <button style="width: 100%" class="btn m-1">Âncoras</button></a
              >
              <a target="_blank" href="/assessment/assessment-21">
                <button style="width: 100%" class="btn m-1">
                  Modelo AF
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-22">
                <button style="width: 100%" class="btn m-1">
                  Ideias Fixas
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-23">
                <button style="width: 100%" class="btn m-1">
                  O Crítico Interno
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-24">
                <button style="width: 100%" class="btn m-1">
                  Ensaio Dramático
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-25">
                <button style="width: 100%" class="btn m-1">
                  Ensaio Mental
                </button></a
              >

              <a target="_blank" href="/assessment/assessment-26">
                <button style="width: 100%" class="btn m-1">
                  Modelo Skilled-Helper
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-27">
                <button style="width: 100%" class="btn m-1">GROW</button></a
              >
              <a target="_blank" href="/assessment/assessment-40">
                <button style="width: 100%" class="btn m-1">
                  House of change
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-42">
                <button style="width: 100%" class="btn m-1">
                  Alinhamento de valores
                </button></a
              >
            </div>
          </div>
          <br />
          <br />
          <h1 class="text-center">Carreira</h1>
          <div class="row">
            <div class="col-sm">
              <br />
              <a target="_blank" href="/assessment/assessment-28"
                ><button style="width: 100%" class="btn m-1">
                  Iniciando uma carreira I
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-29"
                ><button style="width: 100%" class="btn m-1">
                  Identificação e avaliação de competências
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-30"
                ><button style="width: 100%" class="btn m-1">
                  Entrevista para o primeiro emprego
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-31"
                ><button style="width: 100%" class="btn m-1">
                  Plano de carreira para iniciantes
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-32"
                ><button style="width: 100%" class="btn m-1">
                  inventário de atitudes para início de carreiras
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-33"
                ><button style="width: 100%" class="btn m-1">
                  Medindo a autoeficácia
                </button></a
              >
            </div>
            <div class="col-sm">
              <br />
              <a target="_blank" href="/assessment/assessment-34"
                ><button style="width: 100%" class="btn m-1">
                  Assessment para mudança de carreira
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-35"
                ><button style="width: 100%" class="btn m-1">
                  Plano de desenvolvimento de carreira
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-36"
                ><button style="width: 100%" class="btn m-1">
                  Roteiro para mudança de carreira
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-37"
                ><button style="width: 100%" class="btn m-1">
                  Roteiro para trabalhar com o cliente desempregado
                </button></a
              >
              <a target="_blank" href="/assessment/assessment-38"
                ><button style="width: 100%" class="btn m-1">
                  Resourceful coach
                </button></a
              >
            </div>
          </div>
          <br />
          <br />
          <h1 class="text-center">Avaliações finais</h1>
          <div class="row">
            <div class="col">
              <a target="_blank" href="/avaliacao-pos"
                ><button style="width: 100%" class="btn m-1">
                  Avaliação pós desenvolvimento
                </button></a
              >
            </div>
            <div class="col">
              <a target="_blank" href="/assessment/assessment-11"
                ><button style="width: 100%" class="btn m-1">
                  Avaliação de Resultados do desenvolvimento
                </button></a
              >
            </div>
          </div>
          <br />
          <br />
          <button
            id="end"
            style="
              background-color: red;
              border: none;
              width: 400px;
              color: white;
            "
            v-on:click="endSession()"
          >
            Sessão ainda não encerrada
          </button>
          <br />
        </div>
      </div>

      <div style="display: flex; justify-content: center">
        <a href="historico-cliente" target="_blank"
          ><button class="btn mr-3">Histórico de sessões do cliente</button></a
        >
        <button class="btn">Salvar alterações</button>
      </div>
    </div>

    <br />
    <br />
    <br />
    <br />
    <br />
  </div>
</template>

<style scoped>
.input {
  width: 100%;
}
.btn {
  background-color: #983e7b;
  color: white;
  border: none;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}
.btn2 {
  background-color: #983e7b;
  color: white;
  border: none;
  width: 30px;

  height: 30px;
}
ul li {
  background-color: white;
  text-align: center;
  margin-top: 4px;
  list-style-type: none;
  border-radius: 2px;
}
#boxSessao {
  border-radius: 2%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin: 0 auto;
  padding: 30px;
}
.btn:hover {
  background-color: #fec961;
}
a:hover {
  font-style: none;
}
</style>


<script>
export default {
  methods: {
    teste: function (n) {
      this.article = true;
      this.sessionSelect = n;
    },

    endSession: function () {
      if (this.end == false) {
        if (
          confirm(
            "Isso irá marcar a sessão como encerrada, deseja continuar?"
          ) == true
        ) {
          document.getElementById("end").style.backgroundColor = "green";
          document.getElementById("end").innerText = "Sessão encerrada";
          this.end = true;
        }
      } else {
        if (
          confirm(
            "Isso irá marcar a sessão como não encerrada, deseja continuar?"
          ) == true
        ) {
          document.getElementById("end").style.backgroundColor = "red";
          document.getElementById("end").innerText =
            "Sessão ainda não encerrada";
          this.end = false;
        }
      }
    },
  },
  data() {
    return {
      sessoes: [{ n: 1 }, { n: 2 }, { n: 3 }, { n: 4 }, { n: 5 }, { n: 6 }],
      sessions: [1],
      newSession: 1,
      sessionSelect: 0,
      click: "",
      article: false,
      last: 1,
      del: false,
      end: false,
    };
  },
};
</script>