<template>
  <div id="app">
    <div>
      <router-view class="pt-2" />
    </div>
    <div id="popup" v-if="keyPopup == 0" class="row">
      <div class="col-md-10">
        <p id="textpopup">
          Olá! Nós utilizamos, de acordo com nossa
          <a target="_blank" :href="VUE_APP_URL_PAINEL + '/politica-privacidade'">Política de
            Privacidade</a>, cookies e tecnologias semelhantes para melhorar sua experiência. Ao
          continuar navegando, você concorda com essas condições.
        </p>
      </div>
      <div class="col-md-2 mb-1">
        <button v-on:click="clickedPopup()">Ok</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      keyPopup: 0,
      VUE_APP_URL_PAINEL: process.env.VUE_APP_URL_PAINEL
    };
  },
  mounted() {
    const popup = localStorage.getItem("popup");
    if (popup == 1) {
      this.keyPopup = 1;
    }

    //const user = localStorage.getItem('user');
  },
  updated() {
    var popup = localStorage.getItem("popup");

    if (popup == 1) {
      this.keyPopup = 1;
    }
  },
  methods: {
    clickedPopup: function () {
      localStorage.setItem("popup", 1);
      this.keyPopup = 1;
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap");

button {
  margin-left: 40px;
  margin-top: 15px;
  width: 100px;
  background-color: transparent;
  border-style: solid;
  color: white;
  border-color: white;
  border-radius: 20px;
}

#textpopup {
  text-align: justify;
  margin: 10px;
  color: white;
}

#popup {
  z-index: 9000000;
  background-color: #808080;
  position: fixed;
  bottom: 0px;
  max-height: 40%;
  min-height: 10%;
  width: 100%;
}

a {
  color: #fec961;
}

nav {
  width: 100%;
  height: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin: 0 auto;
}

* {
  margin: 0px;
  padding: 0px;
  font-family: open sans;
}

header {
  width: 100%;
}
</style>

